import React, { useRef } from 'react';
import styles from "./TextInput.module.scss";
import classNames from 'classnames';
import { IHint } from "../IHint"

export interface TextInputProps {
  className?: string
  value: string
  placeholder?: string
  icon?: string
  textAbove?: string
  hideTextAbove?: boolean
  textBelow?: string
  hint?: string
  required?: boolean
  disabled?: boolean
  disabledValue?: string
  postfix?: string
  onChange: (value: string) => void
  onBlur?: () => void
  onClickOnIcon?: () => void
  isLarge?: boolean
}

function getTextWidth(inputText: string, font: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context) { context.font = font; }
  const width = context?.measureText(inputText).width || 0;
  const formattedWidth = Math.ceil(width) + "px";
  canvas.remove()
  return formattedWidth
}

export default function TextInput({
  className, value, icon, placeholder, textAbove, textBelow, hint, required, isLarge,
  onChange, disabled, disabledValue, postfix, onBlur, onClickOnIcon, hideTextAbove
}: TextInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    onChange(e.target.value);
  }

  let postfixElement
  if (postfix) {
    const getFont = () => {
      if (inputRef.current) {
        // const styles = inputRef.current?.style;
        const computed = window.getComputedStyle(inputRef.current).getPropertyValue("font");
        return computed
      }
      return '600 14px / 19px "Nunito Sans"'
    }
    const font = getFont()
    let textWidth = getTextWidth(value, font)
    postfixElement = <span className={styles.postfix} style={{ left: textWidth, font }}>{postfix}</span>
    if (placeholder && (!value || value === "")) {
      postfixElement = ""
    }
  }
  return (
    <div className={classNames(styles.wrap, className, {[styles.large]: isLarge})}>
      {!hideTextAbove && <span className={styles.text_above}>
        {textAbove && <span className={styles.text}>{textAbove}</span>}
        {required && <span className={styles.required}>*</span>}
      </span>}
      <div className={styles.input}>
        <input ref={inputRef} type="text" disabled={disabled} placeholder={placeholder} onChange={handleChange} value={disabled ? (disabledValue || value) : value} onBlur={onBlur} />
        {icon && <img className={styles.icon} src={icon} alt="" onClick={onClickOnIcon} />}
        {hint && <IHint hint={hint} className={styles.hint} />}
        {postfixElement}
      </div>
      {textBelow && <span className={classNames(styles.text_below, styles.text)}>{textBelow}</span>}
    </div>
  )
}
