import React from 'react'
import { CustomHint } from '../CustomHint'

import styles from './IconBtn.module.scss'
import classNames from 'classnames'

// import lock_close from './assets/lock_close.svg'
// import {ReactComponent as ReactLogo} from './assets/lock_open.svg';
// import Lock from './parts/Lock'

export enum IconType {
  Trash = 'Trash',
  LockOpen = 'LockOpen',
  LockClose = 'LockClose',
  Save = 'Save',
  Edit = 'Edit',
  Reload = "Reload",
  Upload = "Upload",
  V = "V",
  X = "X",
  Plus = "Plus",
  Dots = "Dots",
  MenuTrash = 'MenuTrash',
  MenuCopy = 'MenuCopy',
  MenuEdit = 'MenuEdit',
  BoxArrow = 'BoxArrow',
}
export enum IconSize {
  Normal = 'Normal'
}

type Props = {
  icon: IconType
  size?: IconSize
  hint?: string
  hintWidth?: number
  className?: string
  onClick?: () => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  active?: boolean
}

export default function IconBtn({ icon, onClick, onMouseDown, hint, hintWidth, className, disabled, active }: Props) {

  let iconClassName: string | undefined

  // можно сипользовать компаненты но тогда у всех svg нужно изменить названия атрибутов на camelCase 
  switch (icon) {
    case IconType.BoxArrow:
      iconClassName = styles.box_arrow
      break;
    case IconType.Trash:
      iconClassName = styles.tarsh
      break;
    case IconType.MenuTrash:
      iconClassName = styles.menu_tarsh
      break;
    case IconType.MenuCopy:
      iconClassName = styles.menu_copy
      break;
    case IconType.MenuEdit:
      iconClassName = styles.menu_edit
      break;
    case IconType.LockClose:
      iconClassName = styles.lock_open
      break;
    case IconType.LockOpen:
      iconClassName = styles.lock_close
      break;
    case IconType.Save:
      iconClassName = styles.save
      break;
    case IconType.Edit:
      iconClassName = styles.edit
      break;
    case IconType.Reload:
      iconClassName = styles.reload
      break;
    case IconType.Upload:
      iconClassName = styles.upload
      break;
    case IconType.V:
      iconClassName = styles.v
      break;
    case IconType.X:
      iconClassName = styles.x
      break;
    case IconType.Plus:
      iconClassName = styles.plus
      break;
    case IconType.Dots:
      iconClassName = styles.dots
      break;

    default:
      break;
  }

  const handleClick = () => {
    if (!disabled) { onClick && onClick() }
  }
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) { onMouseDown && onMouseDown(e) }
  }

  const btn = <div onClick={handleClick} onMouseDown={handleMouseDown}
  className={classNames(styles.icon, className, iconClassName, {
    [styles.disabled]: disabled,
    [styles.active]: active,
  })} />

  if (hint) {
    return <CustomHint hint={hint} hintWidth={hintWidth} >{btn}</CustomHint>
  }
  return btn
}
