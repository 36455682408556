import React, { useState } from 'react'
import styles from './ProductsSlider.module.scss'
import { ProductsDTO } from '../../../types/swagger/llyApi_new'
import classNames from 'classnames'

type Props = {
  product: ProductsDTO
  selectedId?: string
  onSelect: (products: ProductsDTO) => void
}

export default function Product({ product, selectedId, onSelect }: Props) {
  const [hintIsActive, setHintIsActive] = useState(false)
  const handleClick = () => {
    onSelect(product)
  }

  const handleMouseEnter = () => {
    if ((product.article?.length !== undefined && product.article?.length > 12)
      || (product.name?.length !== undefined && product.name?.length > 29)
    ) {
      setHintIsActive(true)
    }
  }
  const handleMouseLeave = () => {
    setHintIsActive(false)
  }

  return (
    <div className={classNames(styles.product_wrap, { [styles.selected]: product.id === selectedId })}>
      <div className={classNames(styles.hint, { [styles.active]: hintIsActive })}>
        <div>Артикул: {product.man_article}</div>
        <div>{product.name}</div>
      </div>
      <div onClick={handleClick}
        className={classNames(styles.product, { [styles.selected]: product.id === selectedId })}
        style={{ backgroundImage: `url(${product.url})` }}>
        <div className={styles.info} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div>Артикул: {product.man_article}</div>
          <div className={styles.product_name}>{product.name}</div>
        </div>
        <div className={styles.color}>{product.main_color}</div>
      </div>
    </div>
  )
}
