import React, { useState } from 'react'
import { IBox, IOrder, copyBox, editBox, removeBox } from '../../../../../../../../../store/slices/fbs'
import styles from './Order.module.scss'
import classNames from 'classnames'
import DraggableItem from './DraggableItem'
import Item from './Item'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store'
import { IconBtn, IconType } from '../../../../../../../../simples/IconBtn'

type Props = {
  box: IBox
  order: IOrder
  // index: number
  isTarget?: boolean
}

export default function Box({ box, order, isTarget }: Props) {

  const dispatch = useAppDispatch()
  const [menuIsActive, setMenuIsActive] = useState(false)
  const { activeItem } = useAppSelector(state => state.fbs)
  const { h, l, w } = box.size
  const { weight } = box

  const _isTarget = isTarget && activeItem
  const empty = box.itemIds.length === 0 && !_isTarget

  const handleShowMenu = () => {
    setMenuIsActive(true)
  }
  const handleCloseMenu = () => {
    setMenuIsActive(false)
  }
  const handleCopyBox = () => {
    dispatch(copyBox({ orderId: order.id, boxId: box.id }))
    setMenuIsActive(false)
  }
  const handleRemoveBox = () => {
    dispatch(removeBox({ orderId: order.id, boxId: box.id }))
    setMenuIsActive(false)
  }
  const handleEditBox = () => {
    dispatch(editBox({ orderId: order.id, boxId: box.id }))
    setMenuIsActive(false)
  }
  return (
    <div className={styles.box}>
      <div className={styles.head}>
        <div className={styles.head_body}>
          <div className={styles.title}>Коробка {box.id}</div>
          <div>{l}x{w}x{h} <span className={weight>0?'':styles.error}>{weight}кг</span></div>
        </div>
        <IconBtn icon={IconType.Dots} onClick={handleShowMenu} />
        <div className={classNames(styles.menu, { [styles.active]: menuIsActive })}>
          <div className={styles.bg} onClick={handleCloseMenu}></div>
          <div className={styles.items}>
            <div
              className={styles.menu_item}
              onClick={handleEditBox}
            ><IconBtn icon={IconType.MenuEdit} /><span>Редактировать</span></div>
            <div
              className={styles.menu_item}
              onClick={handleRemoveBox}
            ><IconBtn icon={IconType.MenuTrash} /><span>Удалить</span></div>
            <div
              className={styles.menu_item}
              onClick={handleCopyBox}
            ><IconBtn icon={IconType.MenuCopy} /><span>Копировать</span></div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.box_body, { [styles.empty]: empty })}>
        {empty && <div className={styles.info}>Перетащите вещи в коробки из столбца “не распределенные вещи”</div>}
        {_isTarget && <Item item={activeItem} className={styles.target_place} orderId={order.id} />}
        {box.itemIds.map(iid => {
          const item = order.items.find(item => item.id === iid)
          if (!item) return <>Error!!!</>
          return <DraggableItem key={item.id} item={item} orderId={order.id} />
        })}
      </div>
    </div>
  )
}
