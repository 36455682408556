import classNames from "classnames";
import React, {FC} from "react";
import styles from "./checbox.module.scss";

interface PropsType {
  checked: boolean;
  onClick: () => void;
}

const Checkbox: FC<PropsType> = ({checked, onClick}) => {
  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  }
  return <div onClick={handleOnClick} className={classNames(styles.checkbox, {[styles.checked]: checked})}></div>;
};

export default Checkbox;
