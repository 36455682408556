import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setAboutValue } from '../../../../../store/slices/product'
import { CustomSelect } from '../../../../simples/CustomSelect'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import Title from '../Title'

import styles from "./About.module.scss"

export default function About() {

  const dispatch = useAppDispatch()

  const { country_id, brand, name } = useAppSelector(state => state.product.about.values)
  const countries = useAppSelector(state => state.product.commonData.countries)

  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const options = countries.map(c => ({ label: c.name || '', value: c.id }))


  const handleChangeValue = ({ name, value }) => {
    dispatch(setAboutValue({ name, value }))
  }

  return (
    <div className={styles.about}>
      <Title text='О товаре' />
      <div className={styles.list}>
        <CustomTextInput
          name='name'
          placeholder='Укажите наименование'
          label='Наименование'
          required={true}
          value={name}
          onChange={handleChangeValue}
          readonly={readonly}
        />

        <CustomTextInput
          name='brand'
          placeholder='Укажите бренд'
          label='Бренд'
          required={true}
          value={brand}
          onChange={handleChangeValue}
          readonly={readonly}
        />

        <CustomSelect name='country_id'
          value={country_id}
          options={options}
          onChange={handleChangeValue}
          label='Страна производителя'
          required={true}
          placeholder='Выберите страну'
          readonly={readonly}
        />
      </div>
    </div>
  )
}
