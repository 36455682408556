import React from 'react'
import { ModalDialog } from '../../../simples/ModalDialog'
import { ButtonType } from '../../../simples/BlueButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { removeCert, setCertIdForRemove } from '../../../../store/slices/certificates'

export default function ConfirmRemoveCertModal() {

  const dispatch = useAppDispatch()
  const certIdForRemoveConfirmation = useAppSelector(state => state.certificates.certIdForRemoveConfirmation)
  const isCertRemoveConfirmation = certIdForRemoveConfirmation !== undefined

  const handleConfirm = () => {
    if (certIdForRemoveConfirmation !== undefined) { dispatch(removeCert(certIdForRemoveConfirmation)) }
  }
  const handleClose = () => {
    dispatch(setCertIdForRemove(undefined))
  }

  return (
    <ModalDialog isActive={isCertRemoveConfirmation}
      handleClose={handleClose}
      title='Удалить сертификат?'
      text='При удалении сертификата связанные с ним карточки товаров будут скрыты от покупателей'
      buttonsConfig={[{
        text: 'Да',
        onClick: handleConfirm
      }, {
        text: 'Нет',
        onClick: handleClose,
        type: ButtonType.No,
      }]}
    />
  )
}
