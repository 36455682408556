import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import styles from '../Brands.module.scss'
import { TextInput } from '../../../simples/TextInput'
import classNames from 'classnames'
import CreateCertModal from './CreateCertModal'
import { fetchCertificates, startCreationCert } from '../../../../store/slices/certificates'
import Cert from './Cert'
import { BlueButton } from '../../../simples/BlueButton'
import ConfirmRemoveCertModal from './ConfirmRemoveCertModal'

export enum Order { ASC, DESC }


export default function CertificatesTab() {

  const dispatch = useAppDispatch()
  const [searchText, setSearchText] = useState('')
  const [sortByColumn, setSortByColumn] = useState('name')
  const [order, setOrder] = useState(Order.ASC)

  let certificatesList = useAppSelector(state => state.certificates.certificates) || []
  if (searchText.length > 0) {
    const _searchText = searchText.toLocaleLowerCase()
    certificatesList = certificatesList.filter(b => b.name.toLocaleLowerCase().indexOf(_searchText) > -1)
  }

  certificatesList = [...certificatesList].sort((b1, b2) => {
    if (order === Order.ASC) {
      return b1[sortByColumn]?.localeCompare(b2[sortByColumn]) || 0;
    } else {
      return b2[sortByColumn]?.localeCompare(b1[sortByColumn]) || 0;
    }
  })

  useEffect(() => {
    dispatch(fetchCertificates())
  }, [])

  const handleSort = (column: string) => () => {
    if (column === sortByColumn) {
      if (order === Order.DESC) {
        setOrder(Order.ASC)
      } else {
        setOrder(Order.DESC)
      }
    } else {
      setSortByColumn(column)
    }
  }

  const handleStartCreateCert = () => {
    dispatch(startCreationCert())
  }


  return (
    <div className={styles.brands_certs}>
      <CreateCertModal />
      <ConfirmRemoveCertModal />
      <div className={styles.warning}>Загружаемый файл должен быть в формате .pdf, .jpeg, .jpg или .png, а размер не должен превышать 32 Мб</div>
      <TextInput onChange={setSearchText} value={searchText} placeholder='Поиск' className={styles.search} />
      <h1>Сертифицируемые бренды</h1>
      <table className={classNames(styles.grid, styles.certs)}>
        <thead>
          <tr className={classNames({
            [styles.order_asc]: order === Order.ASC,
            [styles.order_desc]: order === Order.DESC,
          })}>
            <th onClick={handleSort('name')} className={classNames({ [styles.sort]: sortByColumn === 'name' })}><span>Бренд</span></th>
            <th onClick={handleSort('issued')} className={classNames({ [styles.sort]: sortByColumn === 'issued' })}><span>Дата выдачи</span></th>
            <th onClick={handleSort('expired')} className={classNames({ [styles.sort]: sortByColumn === 'expired' })}><span>Дата истечения</span></th>
            <th>Товары, шт</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {certificatesList.map(b => <Cert key={b.id} cert={b} />)}
        </tbody>
      </table>
      <div className={styles.cert_btns}>
        <BlueButton title='Загрузить сертификат' onClick={handleStartCreateCert} />
      </div>
    </div>
  )
}
