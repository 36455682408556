import React from "react";
import styles from "./addGoodsBtnsMain.module.scss";
import IconsAddTable from "../../../../../assets/icons/9026055_table_icon.svg";
import IconsAddOne from "../../../../../assets/icons/9026065_plus_circle_icon_1.svg";
import {BtnAddGoods} from "./parts/BtnAddGoods";
import { Routes } from "../../../../../constants/routes";
import { GoodsListTexts } from "../../../../../constants/goodsListTexts";

function AddGoodsBtnsMain() {
  return (
    <div className={styles.wrapper}>
      <BtnAddGoods
        text={GoodsListTexts.AddGoodsBtnsMain_add_many}
        icons={IconsAddTable}
        to={Routes.IMPORT_TABLE}
      />
      <BtnAddGoods
        text={GoodsListTexts.AddGoodsBtnsMain_add_one}
        icons={IconsAddOne}
        to={Routes.PRODUCT}
      />
    </div>
  );
}

export default AddGoodsBtnsMain;
