import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { addSeason, removeSeason, setSeasonValue } from '../../../../../store/slices/product'
import { CustomSelect, Option } from '../../../../simples/CustomSelect'
import Title from '../Title'

import add_icon from '../../assets/plus.svg'

import styles from "./Seasons.module.scss"
import { TrashIcon } from '../../../../simples/TrashIcon'
import classNames from 'classnames'

type Props = {
  season: number | null
  seasonIndex: number
}

export default function Season({ season, seasonIndex }: Props) {
  // const referenceBookDetails = useAppSelector(state => state.product.referenceBook.details)

  const dispatch = useAppDispatch()
  const seasons = useAppSelector(state => state.product.seasons.values)
  const commonDataSeasons = useAppSelector(state => state.product.commonData.seasons)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const options: Option[] = commonDataSeasons
    // .filter(o => seasons.indexOf(o.id) !== -1 || o.id !== season)
    .filter(o => seasons.indexOf(o.id) === -1 || o.id === season)
    .map(o => ({ label: o.name, value: o.id })) || []


  const handleChange = ({ name, value }) => {
    if(readonly) return
    dispatch(setSeasonValue({ seasonIndex: name, value }))
  }
  const handleAdd = () => {
    if(readonly) return
    if (options.length > 0) {
      dispatch(addSeason(options.filter(o => o.value !== season)[0].value as number))
    }
  }
  const handleRemove = (seasonIndex) => {
    if(readonly) return
    dispatch(removeSeason(seasonIndex))
  }

  // if (readonly) {
  //   return (<div>
      // <CustomSelect
      //   name={seasonIndex}
      //   value={season}
      //   options={options}
      //   onChange={handleChange}
      //   label={(seasonIndex === 0) ? 'Сезон' : ''}
      //   required={seasonIndex === 0}
      //   placeholder='Выберите сезон'
      //   hideError={true}
      //   readonly={readonly}
      // />
  //   </div>)
  // }

  return (
    <div className={styles.season}>
      <CustomSelect
        name={seasonIndex}
        value={season}
        options={options}
        onChange={handleChange}
        label={(seasonIndex === 0) ? 'Сезон' : ''}
        // required={seasonIndex === 0}
        placeholder='Выберите сезон'
        hideError={true}
        readonly={readonly}
      />
      <div className={classNames(styles.controls, { [styles.first_row]: seasonIndex === 0 })}>
        {/* {(seasonIndex > 0) && <TrashIcon id={seasonIndex} onRemove={handleRemove} />} */}
        {(seasonIndex > 0 || seasons[seasonIndex] !== null ) && <TrashIcon id={seasonIndex} onRemove={handleRemove} />}
        {(options.length > 1 && season) && <img src={add_icon} onClick={handleAdd} />}
      </div>
    </div>
  )
}
