import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../utils/api';
import { CertificateDTO, CertificatesConformityTypes, CertificatesDocumentTypes, Files } from "../../types/swagger/llyApi_new";
import { toast } from "react-toastify";
import { AxiosProgressEvent } from "axios";
import { RootState } from "..";
import { formatDateForServer, parseServerDate } from "../../utils/action";


function getDefultCertData() {
  return {
    id: undefined as number | undefined,
    selectedRequirements: undefined as number | undefined,
    selectedDocType: undefined as number | undefined,
    title: '',
    docId: '',
    fromDate: undefined as number | undefined,
    toDate: undefined as number | undefined,
    termless: false,
  }
}

const initialState = {
  isCertCreation: false,
  isLoadingTypes: false,
  isLoadingCertificates: false,
  uploadingProgress: undefined as number | undefined,
  uploadedDoc: undefined as Files | undefined,
  requirements: [] as CertificatesConformityTypes[],
  docTypes: [] as CertificatesDocumentTypes[],
  certData: getDefultCertData(),
  certificates: [] as CertificateDTO[],
  certIdForRemoveConfirmation: undefined as number | undefined,
  selectedCertCardId: undefined as number | undefined,
};

const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });

type CreateCertResponse = {
  success?: boolean
  data?: CertificateDTO[]
  errors?: string[]
}

type FetchTypesResponse = {
  success?: boolean
  data?: {
    conformity_types?: CertificatesConformityTypes[]
    documents_types?: CertificatesDocumentTypes[]
  }
}


type UploadDocsResponse = {
  success?: boolean
  data?: Files[]
  errors?: string[]
}

type FetchCertificatesResponse = {
  success?: boolean
  data?: CertificateDTO[]
}

type AddCertProductsResponse = {
  success?: boolean
  data?: CertificateDTO[]
  errors?: string[]
}
type RemoveCertProductResponse = {
  success?: boolean
  errors?: string[]
}


export const fetchCertificates = createAsyncThunk<FetchCertificatesResponse>("Certificates/fetchCertificates", async () => {
  let response: FetchCertificatesResponse = { success: false }
  await api.get<FetchCertificatesResponse>('/certificates').then(data => {
    response = data.data
  }).catch(console.log)
  return response
});

export const fetchTypes = createAsyncThunk<FetchTypesResponse>("Certificates/fetchBrands", async () => {
  let response: FetchTypesResponse = { success: false }
  await api.get<FetchTypesResponse>('/certificates/get-types').then(data => {
    response = data.data
  }).catch(console.log)
  return response
});

export const addCertProducts = createAsyncThunk<AddCertProductsResponse, number[], { state: RootState }>(
  "Certificates/addCertProducts", async (products_ids, { getState, dispatch }) => {
    let response: AddCertProductsResponse = { success: false }
    const state = getState()
    const cid = state.certificates.selectedCertCardId

    if (cid === undefined || products_ids.length < 1) return response

    await api.post<AddCertProductsResponse>('/certificates/link-products/' + cid, products_ids).then(data => {
      response = data.data
    }).catch(console.log)
    if (!response.success) { errorMsg() }
    dispatch(fetchCertificates())
    return response
  });

export const removeCertProduct = createAsyncThunk<RemoveCertProductResponse, number, { state: RootState }>(
  "Certificates/removeCertProduct", async (pid, { getState, dispatch }) => {
    let response: RemoveCertProductResponse = { success: false }
    const state = getState()
    const cid = state.certificates.selectedCertCardId

    if (cid === undefined) return response

    await api.post<RemoveCertProductResponse>('/certificates/unlink-product/' + cid, [pid]).then(data => {
      response = data.data
    }).catch(console.log)
    if (!response.success) { errorMsg() }
    dispatch(fetchCertificates())
    return response
  });


export const uploadDoc = createAsyncThunk<UploadDocsResponse, { file: File }>(
  "Certificates/uploadDoc", async ({ file }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
    let response: UploadDocsResponse = { success: false }
    dispatch(setUploadingProgress(0));

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / (progressEvent.total || -1)) * 100) as any,
      );
      dispatch(setUploadingProgress(uploadPercentage));
    }

    await api.post<UploadDocsResponse>('/brands/upload-documents',
      { file: [file] },
      { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress },
    ).then(data => {
      if (data.data.success && data.data.data) {
        dispatch(setUploadedDoc(data.data.data[0]));
      }
      response.success = data.data.success
    }).catch(console.log)

    if (!response.success) {
      errorMsg()
    }
    dispatch(setUploadingProgress(undefined));

    return response
  });


const newCertIsValid = () => true

export const createCert = createAsyncThunk<CreateCertResponse, void, { state: RootState }>(
  "Certificates/createCert", async (_, { getState, dispatch }) => {
    let response: CreateCertResponse = { success: false }
    const state = getState()
    const { certData, uploadedDoc } = state.certificates
    if (newCertIsValid()) {
      const data = {
        name: certData.title,
        conformity_type_id: certData.selectedRequirements,
        document_type_id: certData.selectedDocType,
        number: certData.docId,
        issued: formatDateForServer(certData.fromDate),
        expired: formatDateForServer(certData.toDate),
        is_perpetual: certData.termless,
        document_id: uploadedDoc?.id
      }
      await api.post<CreateCertResponse>('/certificates/add', data).then(data => {
        response.success = data.data.success
        saveSuccessMsg()
      }).catch(console.log)
      if (!response.success) {
        errorMsg()
      }
      // dispatch(setUploadedDoc(undefined));
      dispatch(fetchCertificates())
      dispatch(clearNewCertForm())
      dispatch(stopCreationCert())
    }
    return response
  });



type RemoveCertResponse = {
  success?: boolean
  errors?: string[]
}
export const removeCert = createAsyncThunk<RemoveCertResponse, number, { state: RootState }>("Certificates/removeCert", async (cid, { dispatch }) => {
  let response: RemoveCertResponse = { success: false }
  await api.delete<RemoveCertResponse>('/certificates/delete/' + cid).then(data => {
    response.success = data.data.success
  }).catch(console.log)
  if (!response.success) {
    errorMsg()
  }
  dispatch(fetchCertificates())
  dispatch(setCertIdForRemove(undefined))
  return response
});


export const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  reducers: {
    setUploadingProgress: (state, action: PayloadAction<number | undefined>) => {
      state.uploadingProgress = action.payload
    },
    setUploadedDoc: (state, action: PayloadAction<Files | undefined>) => {
      state.uploadedDoc = action.payload
    },
    setCertDataValue: (state, action: PayloadAction<{ name: string, value: string | number | boolean }>) => {
      state.certData[action.payload.name] = action.payload.value
    },
    clearNewCertForm: (state) => {
      state.certData = getDefultCertData()
      state.uploadedDoc = undefined
    },
    startCreationCert: (state) => {
      state.isCertCreation = true
    },
    stopCreationCert: (state) => {
      state.isCertCreation = false
    },
    setCertIdForRemove: (state, action: PayloadAction<number | undefined>) => {
      state.certIdForRemoveConfirmation = action.payload
    },
    setSelectedCertCardId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedCertCardId = action.payload
      if (action.payload !== undefined) {
        const certificate = state.certificates.find(c => c.id === action.payload)
        state.certData.id = action.payload
        state.certData.title = certificate?.name || ''
        state.certData.selectedRequirements = certificate?.conformity_type_id
        state.certData.selectedDocType = certificate?.document_type_id
        state.certData.docId = certificate?.number || ''
        state.certData.fromDate = certificate?.issued ? parseServerDate(certificate?.issued).getTime() : undefined
        state.certData.toDate = certificate?.expired ? parseServerDate(certificate?.expired).getTime() : undefined
        state.certData.termless = certificate?.is_perpetual || false
        // state.certData.products = certificate?.products || []
        state.uploadedDoc = {
          name: certificate?.document_name || '',
          id: certificate?.document_id || -1,
          size: 0,
          url: '',
        }
      } else {
        state.certData = getDefultCertData()
        state.uploadedDoc = undefined
      }

    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypes.pending, (state) => {
      state.isLoadingTypes = true;
    });
    builder.addCase(fetchTypes.fulfilled, (state, action: PayloadAction<FetchTypesResponse>) => {
      if (action.payload.success) {
        state.requirements = action.payload.data?.conformity_types || []
        state.docTypes = action.payload.data?.documents_types || []
      }
      state.isLoadingTypes = false;
    });
    builder.addCase(fetchTypes.rejected, (state) => {
      state.isLoadingTypes = false;
    });


    builder.addCase(fetchCertificates.pending, (state) => {
      state.isLoadingCertificates = true;
    });
    builder.addCase(fetchCertificates.fulfilled, (state, action: PayloadAction<FetchCertificatesResponse>) => {
      if (action.payload.success) {
        state.certificates = action.payload.data || []
      }
      state.isLoadingCertificates = false;
    });
    builder.addCase(fetchCertificates.rejected, (state) => {
      state.isLoadingCertificates = false;
    });


  },
});

const { setUploadingProgress, setUploadedDoc, clearNewCertForm } = certificatesSlice.actions
export const { setCertDataValue, startCreationCert, stopCreationCert, setCertIdForRemove, setSelectedCertCardId } = certificatesSlice.actions

export default certificatesSlice.reducer;
