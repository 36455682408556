import React, { useState, useEffect } from 'react'
import { Layout } from '../../complexes/Layout'

import styles from './Schedule.module.scss'
import { CustomTextInput } from '../../simples/CustomTextInput'
import { BlueButton, ButtonType } from '../../simples/BlueButton'
import DaySelector from './parts/DaySelector'
import classNames from 'classnames'
import { ButtonConfig, ModalDialog } from '../../simples/ModalDialog'
// import { WorksTimesDTO } from '../../../types/swagger/llyApi_new'
import api from '../../../utils/api'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { fetchUserData } from '../../../store/slices/auth'

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export const WeekDays = [{
  day: DayOfWeek.Monday,
  title: 'Пн'
}, {
  day: DayOfWeek.Tuesday,
  title: 'Вт'
}, {
  day: DayOfWeek.Wednesday,
  title: 'Ср'
}, {
  day: DayOfWeek.Thursday,
  title: 'Чт'
}, {
  day: DayOfWeek.Friday,
  title: 'Пт'
}, {
  day: DayOfWeek.Saturday,
  title: 'Сб'
}, {
  day: DayOfWeek.Sunday,
  title: 'Вс'
}]

type FetchDaysAnswer = {
  success?: boolean
  data?: {
    unfinished_tasks: boolean
    days_of_week: boolean[]
  }
  errors?: string[]
}
type SaveAnswer = {
  success?: boolean
}


const saveSuccess = () => toast.success("Данные сохранены", { theme: "light" });
const lockSuccess = () => toast.success("Магазин скрыт", { theme: "light" });
const unLockSuccess = () => toast.success("Магазин активирован", { theme: "light" });
const saveError = () => toast.error("Ошибка", { theme: "light" });

// function saveDays(days: boolean[], lead_time: number) {
async function saveDays(days: boolean[]) {
  const answer: SaveAnswer = {}
  await api.post<SaveAnswer>('/companies/set-work-days', { days_of_week: days, lead_time: 72 }).then(data => {
    answer.success = data.data.success
  })
  if (answer.success) {
    saveSuccess()
  } else {
    saveError()
  }
}

function countTrueValues(data: boolean[]) {
  return data.filter(d => d === true).length
}
const MINIMUM_DAYS = 5

export default function Schedule() {
  // const [selectedDays, setSelectedDays] = useState<DayOfWeek[]>([])
  const [selectedDays, setSelectedDays] = useState<boolean[]>([false, false, false, false, false, false, false])
  const [dialogActive, setDialogActive] = useState(false)
  const [hasUnfinishedTasks, setHasUnfinishedTasks] = useState(false)
  // const [pickingTime, setPickingTime] = useState(72)

  const shopIsHidden = useAppSelector(state => state.auth.user?.companyStatus?.hidden)
  // const shopIsHidden = useAppSelector(state => state.auth.user?.companyStatus?.lock)

  const dispatch = useAppDispatch()

  // const shopIsActive = true

  function fetchDays() {
    api.get<FetchDaysAnswer>('/companies/get-work-days').then(data => {
      // console.log(data);
      const days: boolean[] = data.data.data?.days_of_week || [false, false, false, false, false, false, false]
      setSelectedDays(days)
      setHasUnfinishedTasks(data.data.data?.unfinished_tasks || false)
      // setPickingTime(data.data.data?.lead_time || 72)
    })
  }

  const activDaysCount = countTrueValues(selectedDays)

  useEffect(() => {
    fetchDays()
  }, [])


  const handleToggleShopActive = () => {
    setDialogActive(true)
  }

  const handleSave = () => {
    // saveDays(selectedDays, pickingTime)
    saveDays(selectedDays)
  }

  const handleConfirmDialog = () => {
    const url = shopIsHidden ? '/companies/show' : '/companies/hide'
    // const url = shopIsHidden ? '/companies/unlock' : '/companies/lock'
    const showSuccessMsg = shopIsHidden ? unLockSuccess : lockSuccess
    api.post<SaveAnswer>(url).then(data => {
      if (data.data.success) {
        showSuccessMsg()
      } else {
        saveError()
      }
    }).catch(e => {
      console.log(e);
      saveError()
    }).finally(() => {
      setDialogActive(false)
      dispatch(fetchUserData())
    })
  }
  const handleCloseDialog = () => {
    setDialogActive(false)
  }

  const modalBtns: ButtonConfig[] = [{
    text: 'Да',
    onClick: handleConfirmDialog
  }, {
    text: 'Нет',
    onClick: handleCloseDialog,
    type: ButtonType.No
  }]


  return (
    <Layout title='График работы'>
      <ModalDialog isActive={dialogActive} buttonsConfig={modalBtns} width={530} handleClose={handleCloseDialog}>
        <>{shopIsHidden ? 'Активировать магазин?' : 'Скрыть магазин?'}</>
      </ModalDialog>
      <div className={styles.schedule}>
        <article>
          <h1>Рабочие дни магазина</h1>
          <div className={styles.text}>Выберите не меньше 5 дней в неделю</div>
          <DaySelector selectedDays={selectedDays} onChange={setSelectedDays} />
        </article>
        <article>
          <h1>Время на сборку</h1>
          <div className={styles.warning}>Время на выполнение сборочного задания 72 часа</div>
          {/* <CustomTextInput value={pickingTime} placeholder='72 часа' name='' onChange={handle} className={styles.time_input} /> */}
          <div className={styles.warning}>Заказы, которые поступили в нерабочие дни,  будут активны в последующий рабочий день с 00:01 мин. в сборочном задании</div>
          <BlueButton title='Подтвердить' onClick={handleSave} disabled={activDaysCount < MINIMUM_DAYS} />
        </article>
        <article>
          <h1>Статус магазина</h1>
          <div className={styles.info}>При необходимости магазин можно скрыть от покупателей, поменяв его статус</div>
          {shopIsHidden && hasUnfinishedTasks && <div className={classNames(styles.big_warning)}>! У Вас остались незавершённые сборочные задания</div>}
          <div className={styles.warning}>После скрытия магазина необходимо завершить все сборочные задания FBS</div>
        </article>
        <div className={styles.btns}>
          <BlueButton onClick={handleToggleShopActive} title='Скрыть магазин' disabled={shopIsHidden} />
          <BlueButton onClick={handleToggleShopActive} title='Активировать магазин' disabled={!shopIsHidden} />
        </div>
      </div>
    </Layout>
  )
}
