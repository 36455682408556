import React, { FC, useState } from "react";
import { Layout } from "../../complexes/Layout";
import styles from "./BrandLogos.module.scss";
import { HeaderLogo } from "./parts/HeaderLogo";
import { SideBar } from "./parts/SideBar";
import { LogoList } from "./parts/LogoList";
import { LogoForm } from "./parts/LogoForm";

const BrandLogos: FC = () => {
  const [viewType, setViewType] = useState(true);
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const [upload, setUpload] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleSelected = (id: number) => {
    setSelectedId(id);
    setIsUpdate(true);
    setUpload(false);
  };

  return (
    <Layout title='Логотипы' hint='Здесь будут отражены все логотипы, которые Вы создали. Добавляйте новые логотипы и редактируйте старые'>
      {/* <Layout isDisableHeader={true} ></Layout> */}
      {/* <HeaderLogo /> */}
      {/* <HeaderLogo /> */}
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <LogoForm
              selectedId={selectedId || null}
              setSelectedId={setSelectedId}
              upload={upload}
              setUpload={setUpload}
              isUpdate={isUpdate}
            />
            <LogoList
              setViewType={setViewType}
              selectedId={selectedId || null}
              viewType={viewType}
              handleSelected={handleSelected}
              setUpload={setUpload}
              setIsUpdate={setIsUpdate}
            />
          </div>
        </div>
        <div className={styles.wrapper_side}>
          <SideBar
            title={"Размер логотипа"}
            description={"Логотип должен быть 600px в длину и 600px в высоту в формате .png <br/> <br/> Изображения, используемые в качестве логотипов, должны соответствовать <a href='#'>правилам размещения логотипов</a>"}
          />
          <SideBar
            title={"Все логотипы проходят модеряцию"}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BrandLogos;