import React from 'react'
import { PanelMini } from '../../simples/PanelMini'
import { IndicatorsTrait } from '../../../types/swagger/llyApi_new'
import Skeleton from 'react-loading-skeleton'

// import styles from '../MainPage.module.scss'
import styles from './Indicators.module.scss'
import GrowthValue from '../../pages/MainPage/parts/GrowthValue'
import classNames from 'classnames'

type Props = {
  title: string
  data?: IndicatorsTrait
  navigate: () => void
  isLoading?: boolean
  isSelected?: boolean
}

export default function Indicator({ title, data, navigate, isLoading, isSelected }: Props) {

  return (
    <PanelMini title={title} className={classNames(styles.indicator, {[styles.selected]: isSelected})} onClick={navigate} isSelected={isSelected}>
      <div className={styles.indicator_message}>{data?.message || <Skeleton />}</div>
      <GrowthValue value={data?.growth} />
    </PanelMini>
  )
}
