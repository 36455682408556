import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MenuItem } from "../../components/complexes/Layout/parts/MainSidebar/parts/Menu";
import MenuConfig from "../../constants/mainMenu";

interface MainMenuState {
  openedMenuItems: Array<string[]>
}


function getDefaultOpened(items: MenuItem[], lvl: number, res: Array<string[]>) {
  // const res: string[] = []
  if (!res[lvl]) { res[lvl] = [] }
  for (const item of items) {
    if (item.isOpenByDefault) res[lvl].push(item.id)
    if (item.submenu && item.submenu.length > 0) {
      // res[lvl].push(...getDefaultOpened(item.submenu, lvl + 1, res))
      getDefaultOpened(item.submenu, lvl + 1, res)
    }
  }
  return res
}

const initialState: MainMenuState = {
  openedMenuItems: getDefaultOpened(MenuConfig, 0, [])
};


export const mainMenuSlice = createSlice({
  name: "mainMenu",
  initialState,
  reducers: {
    toggleSubmenus: (state, action: PayloadAction<{ id: string, lvl: number }>) => {
      const { id, lvl } = action.payload
      // console.log(action.payload, state.openedMenuItems[lvl].length);
      
      if (state.openedMenuItems[lvl]?.indexOf(id) === -1) {
        state.openedMenuItems[action.payload.lvl].push(id)
      } else {
        state.openedMenuItems[lvl] = state.openedMenuItems[lvl].filter(_id => _id !== id)
      }
      if (lvl === 1 && state.openedMenuItems[lvl].length > 2) {
        state.openedMenuItems[lvl] = state.openedMenuItems[lvl].slice(-2)
      }
    },
  }
});

export const { toggleSubmenus } = mainMenuSlice.actions;

export default mainMenuSlice.reducer;