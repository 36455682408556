import React from "react";
import styles from "./productsListMain.module.scss";
import { AddGoodsBtnsMain } from "./parts/AddGoodsBtnsMain";
import { GoodsSearch } from "./parts/GoodsSearch";
import { Warning } from "./parts/Warning";
import { GoodsListMain } from "./parts/GoodsListMain";
import { GoodsListFooter } from "./parts/GoodsListFooter";
import { Layout } from "../../complexes/Layout";
import { DialogDeleteGoods } from "./parts/DialogDeleteGoods";
import ModalNotification from "./parts/ModalNotification/ModalNotification";

function ProductsListMain() {

  return (
    <Layout title='Список товаров' hint='Здесь можно добавить товар и отредактировать карточку товара, отметив нужные строки галочкой и нажав кнопку "Массовое редактирование"'>
      <DialogDeleteGoods />
      <ModalNotification />
      <section className={styles.wrapper}>
        <section className={styles.wrapper__controls}>
          <div className={styles.body__controls}>
            <AddGoodsBtnsMain />
            <GoodsSearch />
          </div>

          <section className={styles.wrapper__warning}>
            <Warning />
          </section>
        </section>

        <section className={styles.wrapper__btns_navigate}>
          <GoodsListMain />
          <GoodsListFooter />
        </section>

        <footer className={styles.footer}></footer>
      </section>
    </Layout>
  );
}

export default ProductsListMain;
