import React, { useState, useEffect } from 'react'
import { ButtonConfig, ModalDialog } from '../../../../../../../../simples/ModalDialog'
import { CustomTextInput_v2, IOnChange } from '../../../../../../../../simples/CustomTextInput_v2'
import styles from './BoxEditor.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store'
import { BoxType, cancelBoxCreation, createBox, updateBox } from '../../../../../../../../../store/slices/fbs'
import { CustomSelect_v2, IOnSelectChange, Option } from '../../../../../../../../simples/CustomSelect_v2'


export default function BoxEditor() {
  const dispatch = useAppDispatch()
  // const { newBoxOrderId, editingBoxId } = useAppSelector(state => state.fbs)
  // const box = useAppSelector(state => {
  //   const order = state.fbs.request.orders.find(order => order.id)
  //   return order?.boxes.find(b => b.id === editingBoxId)
  // })
  const config = useAppSelector(state => state.fbs.boxEditorConfig)
  // const newBosIndex = useAppSelector(state => {
  //   const order = state.fbs.request.orders.find(order => order.id)
  //   return (order?.boxes.length || 0) + 1
  // })

  const [values, setValues] = useState({
    l: 145,
    w: 23,
    h: 12,
  })
  const [weight, setWeight] = useState(0.5)
  const [boxType, setBoxType] = useState(BoxType.soft)


  // if (box) {
  //   setValues({ ...box.size })
  //   setWeight(box.weight)
  //   setBoxType(box.type)
  // }

  useEffect(() => {
    if (!config || !config.boxConfig) return
    setValues({ ...config.boxConfig.size })
    setWeight(config.boxConfig.weight)
    setBoxType(config.boxConfig.type)
  }, [config])



  const handleChange = ({ name, value }: IOnChange) => {
    // console.log({ name, value });
    if (name === 'weight') {
      value = parseFloat(value.toString()) || 0
      if (value < 0) { value = 0 }
      setWeight(value)
    } else {
      setValues({ ...values, [name]: value })
    }
  }
  const handleChangeBoxType = ({ value }: IOnSelectChange) => {
    setBoxType(value as BoxType)
  }

  const handleSave = () => {
    if (!config || !config.boxOrderId || !config.boxConfig) return
    if (config.isNewBox) {
      dispatch(createBox({
        oid: config.boxOrderId,
        sizes: values,
        weight,
        type: boxType,
      }))
    } else {
      dispatch(updateBox({
        oid: config.boxOrderId,
        sizes: values,
        weight,
        type: boxType,
        boxId: config.boxConfig.id,
      }))
    }
  }
  const handleClose = () => {
    dispatch(cancelBoxCreation())
  }
  const buttonsConfig: ButtonConfig[] = [{
    text: "Сохранить",
    onClick: handleSave,
    disabled: !(values.h > 0 && values.w > 0 && values.l > 0 && weight > 0)
  }]

  // const isBoxCreation = (config.boxOrderId !== null);
  const boxTypeOptions: Option[] = [{
    value: BoxType.soft,
    label: 'Мягкая упаковка'
  }, {
    value: BoxType.hard,
    label: 'Твёрдая упаковка'
  }, {
    value: BoxType.pallet,
    label: 'Паллет'
  }]

  return (
    <ModalDialog buttonsConfig={buttonsConfig} isActive={config.isActive} width={460} hasCloseBtn handleClose={handleClose} disableOverflowScroll >
      <div>
        <h1>Коробка {config.boxConfig?.id}</h1>
      </div>
      <div className={styles.newboxbuilder_body}>
        <div className={styles.inputs}>
          <CustomTextInput_v2 name='l' onChange={handleChange} label='Длина' className={styles.input} value={values.l} isNumber />
          <CustomTextInput_v2 name='w' onChange={handleChange} label='Ширина' className={styles.input} value={values.w} isNumber />
          <CustomTextInput_v2 name='h' onChange={handleChange} label='Высота' className={styles.input} value={values.h} isNumber />
          <CustomSelect_v2 name='boxType' onChange={handleChangeBoxType} label='Тип упаковки' options={boxTypeOptions} value={boxType} className={styles.box_type} />
          <CustomTextInput_v2 name='weight' onChange={handleChange} label='Вес, кг' className={styles.input} value={weight} />
        </div>
      </div>
    </ModalDialog>
  )
}
