import { TypeFilterValue } from "./types"

export const StarFilterLabels = ['Одна звезда', 'Две звезды', 'Три звезды', 'Четыре звезды', 'Пять звёзд']

export const TypeFilterOptions = [
  { title: "Все", value: 0, id: TypeFilterValue.ALL },
  { title: "Без ответа", value: 0, id: TypeFilterValue.NO_ANSWER },
  { title: "С ответом", value: 0, id: TypeFilterValue.WITH_ANSWER },
  { title: "Архив", value: 0, id: TypeFilterValue.ARCHIVE },
  { title: "На модерации", value: 0, id: TypeFilterValue.ON_MODERATION },
]