import classNames from 'classnames'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { addVariant, removeVariant, selectVariant } from '../../../../../store/slices/product'
import Title from '../Title'

import x_icon from './../../assets/x.svg'

import styles from './VariantSelector.module.scss'

type Props = {
  className: string
}

export default function VariantSelector({ className }: Props) {

  const dispatch = useAppDispatch()
  const variants = useAppSelector(state => state.product.variants.values)
  const { activeVariantIndex } = useAppSelector(state => state.product.variants)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const handleAddVariant = () => {
    if(readonly) return
    dispatch(addVariant())
  }
  const handleRemoveVariant = (index: number) => (e: React.MouseEvent<HTMLImageElement>) => {
    if(readonly) return
    e.stopPropagation()
    dispatch(removeVariant(index))
  }
  const handleSelectVariant = (index: number) => () => {
    dispatch(selectVariant(index))
  }

  return (
    <div className={classNames(styles.variants, className, {[styles.readonly]: readonly})}>
      <Title text='Цвет' className={styles.title} />
      {variants.map((v, index) => <div
        className={classNames(styles.variant, { [styles.selected]: index === activeVariantIndex })}
        key={v.id}
        onClick={handleSelectVariant(index)}
      >
        <span>{`Цвет ${index + 1}`}</span>
        {variants.length > 1 && !readonly && <img src={x_icon} onClick={handleRemoveVariant(index)} />}
      </div>)}
      {!readonly && <div className={styles.add_color} onClick={handleAddVariant}>Добавить цвет</div>}
    </div>
  )
}
