import classNames from 'classnames'
import React from 'react'
import deselect_icon from "../../assets/deselect.svg"
import Stars from '../Stars';


import styles from "./Filters.module.scss";

interface Props {
  text: string
  value?: number
  starsCount?: number
  selected?: boolean
  select?: () => void
  deselect?: () => void
}

export default function FilterLabel({ text, value, selected, select, deselect, starsCount }: Props) {
  const handleDeselect = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    deselect && deselect()
  }
  return (
    <div className={classNames(styles.filter_label, { [styles.selected]: selected })} onClick={select}>
      <span>{text}</span>
      {value !== undefined && <span className={styles.filter_label_value}>{value}</span>}
      {starsCount !== undefined && <Stars starsCount={starsCount} />}
      {selected && <img className={styles.deselect_btn} src={deselect_icon} onClick={handleDeselect} />}
    </div>
  )
}
