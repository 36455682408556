import React, { useEffect, useRef, useState } from 'react'
import styles from './ProductsSlider.module.scss'
import Product from './Product'
import { ProductsDTO } from '../../../types/swagger/llyApi_new'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'


type Props = {
  products: ProductsDTO[]
  selectedId?: string
  onSelect: (products: ProductsDTO) => void
  isLoading?: boolean
}


const ItemWidth = 180 + 2;
const Gap = 24;
const FullItemWidth = ItemWidth + Gap;

export default function ProductsSlider({ products, onSelect, selectedId, isLoading }: Props) {

  const [position, setPosition] = useState(0)

  const [visibleCount, setVisibleCount] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const preventDefault = e => {
    e.preventDefault()
  }

  const updateVisibleCount = () => {
    const w = ref?.current?.getBoundingClientRect().width || 0
    setVisibleCount(Math.floor(w / FullItemWidth))
  }

  useEffect(() => {
    window.addEventListener("resize", updateVisibleCount);
    ref?.current?.addEventListener('wheel', preventDefault)
    updateVisibleCount()

    return () => {
      window.removeEventListener('resize', updateVisibleCount)
      ref?.current?.removeEventListener('wheel', preventDefault)
    }
  }, [])

  const handleWheel = (e: any) => {
    if (e.deltaY > 0) {
      handleScrollForward()
    } else {
      handleScrollBack()
    }
  }

  const handleScrollForward = () => {
    if (position < products.length - 1 && position + visibleCount < products.length) {
      setPosition(position + 1)
    }
  }
  const handleScrollBack = () => {
    if (position > 0) {
      setPosition(position - 1)
    }
  }

  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [scrollStyle, setScrollStyle] = useState<React.CSSProperties>()

  useEffect(() => {
    let _scrollStyle: React.CSSProperties;
    let _hasNext = true
    let _hasPrev = position !== 0

    const canScrollCount = products.length - visibleCount - 1// + ItemWidth*2

    if (position > canScrollCount) {
      const w = ref?.current?.getBoundingClientRect().width || 0
      const psw = products.length * FullItemWidth - Gap
      let scrollPosition = psw - w
      if (scrollPosition < 0) { scrollPosition = 0 }
      _scrollStyle = { left: `${-scrollPosition}px` }
      _hasNext = false
    } else {
      let scrollPosition = position * FullItemWidth
      if (scrollPosition < 0) { scrollPosition = 0 }
      _scrollStyle = { left: `${-scrollPosition}px` }
    }

    setHasNext(_hasNext)
    setHasPrev(_hasPrev)
    setScrollStyle(_scrollStyle)

  }, [ref?.current, products, position, visibleCount])


  return (
    <div className={styles.slider_wrap} onWheel={handleWheel} ref={ref}>
      {isLoading
        ? <Skeleton height={246} />
        : products.length > 0
          ? <div className={styles.slider}>
            <div className={classNames(styles.arrow_back, { [styles.visible]: hasPrev })} onClick={handleScrollBack}></div>
            <div className={classNames(styles.arrow_next, { [styles.visible]: hasNext })} onClick={handleScrollForward}></div>
            <div className={styles.products} style={scrollStyle}>
              {products.map((p, i) => <Product key={i} product={p} onSelect={onSelect} selectedId={selectedId} />)}
            </div>
          </div>
          : <div className={styles.slider_gag}>Список пуст</div>
      }
    </div>
  )
}
