import React from 'react'
import xIcon from "../../assets/x-icon.svg";
import styles from "./FilterLabel.module.scss"

type Props = {
  text: string,
  onClickRemove?: (fid: string) => void,
  fid: string
}

export default function FilterLabel({ fid, text, onClickRemove }: Props) {
  const handleClickRemove = () => {
    onClickRemove && onClickRemove(fid)
  }
  return (
    <div className={styles.wrap}>
      <span className={styles.text}>{text}</span>
      {onClickRemove && <img onClick={handleClickRemove} className={styles.remove} src={xIcon} alt="X" />}
    </div>
  )
}
