import React from 'react'
import { useAppSelector } from '../../../../../hooks/store'
import { ModalProgress } from '../../../../simples/ModalProgress'

export default function Progress() {
  const isFetchingArch = useAppSelector(state => state.massEdit.isFetchingArch);
  const fetchingArchProgress = useAppSelector(state => state.massEdit.fetchingArchProgress);
  // const fetchingArchError = useAppSelector(state => state.massEdit.fetchingArchError);

  const isUploadingArch = useAppSelector(state => state.massEdit.isUploadingArch);
  const uploadingProgress = useAppSelector(state => state.massEdit.uploadingProgress);
  // const uploadingError = useAppSelector(state => state.massEdit.uploadingError);

  if (isFetchingArch) {
    return (
      <ModalProgress isActive={true} progress={fetchingArchProgress} text="Скачивание архива" />
    )
  }

  if (isUploadingArch) {
    return (
      <ModalProgress isActive={true} progress={uploadingProgress} text="Отправка архива" />
    )
  }

  return null;
}
