import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../../../complexes/SearchInput'
import { BlueButton } from '../../../../simples/BlueButton';

import styles from './DeliveryRequests.module.scss'
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { setOrdersSearch } from '../../../../../store/slices/fbs';
import NewOrders from '../Orders/NewOrders';
import CollectedOrders from '../Orders/CollectedOrders';
import SentOrders from '../Orders/SentOrders';
import ClosedOrders from '../Orders/ClosedOrders';
import { PeriodDates } from '../Orders/DateSelector';
import { formatDateForServer } from '../../../../../utils/action';
import download from '../../../../../utils/download';
import api from '../../../../../utils/api'
import { TabsCounter } from '../../../../../types/swagger/llyApi_new';
import { toast } from 'react-toastify';

export enum OrderState {
  newOrder = 0,
  collected = 1,
  sent = 2,
  closed = 3,
}

const tabs = [{
  title: 'Заказы',
  count: 3,
  statusId: OrderState.newOrder,
}, {
  title: 'Собрано',
  count: 3,
  statusId: OrderState.collected,
}, {
  title: 'Отправления',
  count: 3,
  statusId: OrderState.sent,
}, {
  title: 'Закрытые',
  count: 3,
  statusId: OrderState.closed,
},]

function buildDates(pd: PeriodDates) {
  const from = formatDateForServer(Math.min(pd.from, pd.to))
  const to = formatDateForServer(Math.max(pd.from, pd.to))
  return `&from_date=${from}&to_date=${to}`
}

type Answer = {
  success?: boolean
  data?: TabsCounter[]
}

export default function DeliveryRequests() {

  const dispatch = useAppDispatch()
  const { ordersSearch, closedPeriodDates, sentPeriodDates } = useAppSelector(state => state.fbs)
  // const [search, setSearch] = useState('')
  const [selectedTabId, setSelectedTabId] = useState(OrderState.newOrder)
  const [itemsCount, setItemsCount] = useState<{ [key: number]: number }>({
    [OrderState.newOrder]: 0,
    [OrderState.collected]: 0,
    [OrderState.sent]: 0,
    [OrderState.closed]: 0,
  })

  useEffect(() => {
    api.get<Answer>('/fbs-orders/tabs').then(data => {
      if (data.data.success && data.data.data) {
        const values = {}
        for (const v of data.data.data) {
          switch (v.status) {
            case 'NEW_ORDER':
              values[OrderState.newOrder] = v.count
              break;
            case 'COLLECTED':
              values[OrderState.collected] = v.count
              break;
            case 'DEPARTURES':
              values[OrderState.sent] = v.count
              break;
            case 'CLOSED':
              values[OrderState.closed] = v.count
              break;
            default:
              break;
          }
        }
        setItemsCount(values)
      }
    })
  }, [])


  // React.useEffect(() => {
  //   const tab = tabs.find(t => t.statusId === selectedTabId)
  //   if (tab) {
  //     dispatch(fetchOrders(tab.statusId));
  //   }
  // }, [selectedTabId]);

  const handleSelectTab = (id: OrderState) => () => {
    setSelectedTabId(id)
  }

  let content: JSX.Element
  switch (selectedTabId) {
    case OrderState.newOrder:
      content = <NewOrders />
      break;
    case OrderState.collected:
      content = <CollectedOrders />
      break;
    case OrderState.sent:
      content = <SentOrders />
      break;
    case OrderState.closed:
      content = <ClosedOrders />
      break;
    default:
      content = <></>
      break;
  }

  const handleChangeSearch = (text: string) => {
    dispatch(setOrdersSearch(text))
  }


  // let link = `?search=${ordersSearch}&status=${selectedTabId}`
  // if (selectedTabId === OrderState.sent) {
  //   link += buildDates(sentPeriodDates)
  // } else if (selectedTabId === OrderState.closed) {
  //   link += buildDates(closedPeriodDates)
  // }
  const handleDLTable = async () => {
    // const url = `/fbs-orders/to-excel${link}`
    let url = `/fbs-orders/`
    // console.log(url);
    switch (selectedTabId) {
      case OrderState.newOrder:
        url += `to-excel?search=${ordersSearch}`
        break;
      case OrderState.collected:
        url += `collected-to-excel?search=${ordersSearch}`
        break;
      case OrderState.sent:
        url += `delivered-to-excel?search=${ordersSearch}${buildDates(sentPeriodDates)}`
        break;
      case OrderState.closed:
        url += `closed-to-excel?search=${ordersSearch}${buildDates(closedPeriodDates)}`
        break;
      default:
        break;
    }
    try {
      await download({ req: url })
    } catch (error) {
      toast.error("Ошибка", { theme: "light" })
    }
    // window.open(url, "_blank", "noreferrer");
  }
  const handlePrintTable = async () => {
    // const url = `/fbs-orders/to-pdf${link}`
    let url = '/fbs-orders/'
    switch (selectedTabId) {
      case OrderState.newOrder:
        url += `to-pdf?search=${ordersSearch}`
        break;
      case OrderState.collected:
        url += `collected-to-pdf?search=${ordersSearch}`
        break;
      case OrderState.sent:
        url += `delivered-to-pdf?search=${ordersSearch}${buildDates(sentPeriodDates)}`
        break;
      case OrderState.closed:
        url += `closed-to-pdf?search=${ordersSearch}${buildDates(closedPeriodDates)}`
        break;
      default:
        break;
    }
    // await download({ req: url })
    api.get<{ "success": boolean, "data": string }>(url).then(resp => {
      console.log(resp);
      if (resp.data.success) {
        window.open(resp.data.data, "_blank", "noreferrer");
      }
    }).catch(e => toast.error("Ошибка", { theme: "light" }))

  }


  return (
    <div className={styles.delivery_orders}>
      <div className={styles.controls}>
        <SearchInput onChange={handleChangeSearch} className={styles.search} />
        <div className={styles.btns}>
          <BlueButton title='Скачать таблицу' onClick={handleDLTable} />
          <BlueButton title='Распечатать таблицу' onClick={handlePrintTable} />
        </div>
      </div>
      <div className={styles.info}>С базовой стоимостью логистики товаров можно ознакомиться <a>здесь</a></div>
      <div>
        <div className={styles.tab_btns}>
          {tabs.map(tab => <div key={tab.statusId}
            onClick={handleSelectTab(tab.statusId)}
            className={classNames(styles.tab_btn, { [styles.selected]: (tab.statusId === selectedTabId) })}
          >{tab.title}<span className={styles.count}>{itemsCount[tab.statusId]}</span></div>)}
        </div>
      </div>
      <div className={styles.content}>
        {content}
      </div>
    </div>
  )
}
