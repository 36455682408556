import React from 'react'

import styles from './InfoPanel.module.scss'

type Props = {
  title: string
  children: JSX.Element | JSX.Element[] | string
}

export default function InfoPanel({ title, children }: Props) {
  return (
    <div className={styles.info_panel}>
      <div className={styles.head}>{title}</div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}
