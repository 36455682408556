import React from 'react'
import { useAppSelector } from '../../../../../hooks/store'
import Title from '../Title'
import Detail from './Detail'

import styles from "./Details.module.scss"

export default function Details() {
  const referenceBookDetails = useAppSelector(state => state.product.referenceBook.details)

  return (
    <div className={styles.details}>
      <Title text='Детали' />
      <div className={styles.list}>
        {referenceBookDetails.map(d => <Detail detail={d} key={d.id} />)}
      </div>
    </div>
  )
}
