import React, { useState } from 'react'

import styles from '../Schedule.module.scss'
import classNames from 'classnames'
import { WeekDays } from '../Schedule'


type Props = {
  selectedDays: boolean[]
  onChange: (data: boolean[]) => void
}

// function countTrueValues(data: boolean[]) {
//   return data.filter(d => d === true).length
// }

// const MINIMUM_DAYS = 5

// selectedDays - массив дней недели, true - выходной, false - рабочий
export default function DaySelector({ selectedDays, onChange }: Props) {

  const handleToggleDay = (dayIndex: number) => () => {
    const days = [...selectedDays]
    days[dayIndex] = !days[dayIndex]

    // елси рабочих дней слишком мало, добавляем рабочих
    // while (countTrueValues(days) < MINIMUM_DAYS && countTrueValues(days) < days.length - 2) {
    //   const i = days.findIndex((d, i) => (d === true && i !== dayIndex))
    //   if (i !== -1) days[i] = false // делаем рабочим
    // }
    onChange(days)
  }

  return (<div className={styles.day_selector}>
    {WeekDays.map((d, i) => <div key={d.day}
      onClick={handleToggleDay(i)}
      className={classNames(styles.day, {
        [styles.selected]: selectedDays[i]
      })}>{d.title}</div>)}
  </div>)
}
