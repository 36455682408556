import React, { FC } from "react";
import styles from "./listProducts.module.scss";
import { toggleProductSelection } from "../../../../../../../../../store/slices/goodsList";
import { Checkbox } from "../Checkbox";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../hooks/store";
import { RootState } from "../../../../../../../../../store";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../../../../../constants/routes";
import defaultPhoto from "../../../../../../../../../assets/icons/goods_list_default_img.svg";
import { ProductsDTO } from "../../../../../../../../../types/swagger/llyApi_new";
import { format } from "date-fns";
import { DISCOUNT_CATEGORY_ID } from "../../../../../../../../../services/GoodsListService";

interface PropsType {
  list: Array<ProductsDTO>;
  categoryID: number;
}

const ListProducts: FC<PropsType> = ({ list, categoryID }) => {

  const dispatch = useAppDispatch();
  const selectedGoods = useAppSelector((state: RootState) => state.productsList.selectedGoods);

  const handleCheckboxClick = id => () => {
    dispatch(toggleProductSelection(id));
  };

  const navigate = useNavigate();
  const handleClickOnRow = (id, color_id) => () => {
    // navigate(`${Routes.UPDATE_PRODUCT_CARD}/${id}`);
    // navigate(`${Routes.UPDATE_PRODUCT_CARD}/${id}/${color_id}`);
    navigate(`${Routes.PRODUCT}${id}/${color_id}`);
  };


  return (
    <>
      {list.map(
        ({ id, name, brand, category, subcategory, seasons, created_at, gender, type, url, barcode, size_name, product_id, product_color_id, main_color, price, fbo = 0, fbs = 0, actual_discount_percent }) => {
          const create_data = new Date((created_at || 0) * 1000);
          // const create_data_text = `${create_data.getDate()}.${create_data.getMonth() + 1}.${create_data.getFullYear()}`;
          const create_data_text = format(create_data, "dd.MM.yyyy");

          // const seasons_text = seasons.map(season => season.name).map(s => <span key={s}>{s}</span>);
          const seasons_text = seasons?.map(s => <span key={s}>{s}</span>);

          // let photo;
          // let sizes: string[] = [];
          // let barcode = "";
          // if (productsColors.length > 0) {
          //   const product0 = productsColors[0];
          //   // sizes = product0.sizes.join(" ");
          //   sizes = product0.sizes.map(s => (s.name));
          //   if (product0.productsPhotos.length > 0) {
          //     photo = product0.productsPhotos[0].file.url;
          //   }
          //   if (product0.productsColorsSizes.length > 0) {
          //     barcode = product0.productsColorsSizes[0].barcode.toString();
          //   }
          // }
          // if (!photo) {
          //   photo = defaultPhoto;
          // }

          const isSelected = selectedGoods.indexOf(id) !== -1;
          let discount_text = (actual_discount_percent && actual_discount_percent > 0) ? `${actual_discount_percent}%` : "2+1"

          return (
            <tr
              className={classNames(styles.wraper_row, { [styles.selected]: isSelected })}
              onClick={handleClickOnRow(product_id, product_color_id)}
              key={id}>
              <td className={styles.checkbox}>
                <Checkbox onClick={handleCheckboxClick(id)} checked={isSelected} />
              </td>
              <td className={styles.foto_product}>
                <div style={{ backgroundImage: `url("${url || defaultPhoto}")` }}></div>
              </td>
              <td className={styles.article}>{barcode}</td>
              <td className={styles.name_prod}> {name}</td>
              <td className={classNames(styles.brand, styles.align_center)}>{brand}</td>
              <td className={classNames(styles.sex, styles.align_center)}>{gender}</td>
              <td className={styles.type}>{type}</td>
              <td className={styles.category}>{category}</td>
              <td className={styles.subcategory}>{subcategory}</td>
              <td className={classNames(styles.size, styles.align_center)}>{size_name}</td>

              <td className={classNames(styles.main_color, styles.align_center)}>{main_color}</td>
              <td className={classNames(styles.price, styles.align_center)}>{`${(price || 0)?.toLocaleString('ru-RU')} ₽`}</td>

              {(categoryID === DISCOUNT_CATEGORY_ID) && <td className={classNames(styles.actual_discount_percent, styles.align_center)}>{discount_text}</td>}

              <td className={classNames(styles.season, styles.align_center)}>{seasons_text}</td>

              <td className={classNames(styles.fbo, styles.align_center, {
                [styles.warning]: (fbo > 0 && fbo < 49),
                [styles.low]: (fbo > 49 && fbo < 100),
                [styles.empty]: (fbo === 0),
              })}>{fbo}</td>
              <td className={classNames(styles.fbs, styles.align_center, {
                [styles.warning]: (fbs > 0 && fbs < 49),
                [styles.low]: (fbs > 49 && fbs < 100),
                [styles.empty]: (fbs === 0),
              })}>{fbs}</td>
              {/* <td className={styles.status}>
                -
                <ProductStatus status={status} />
              </td> */}
              {/* <td className={styles.barcode}>{barcode}</td> */}
              <td className={classNames(styles.create_data, styles.align_center)}>{create_data_text}</td>
            </tr>
          );
        },
      )}
    </>
  );
};

export default ListProducts;
