import classNames from "classnames";
import { ChangeEvent, FC } from "react";
import styles from "./Select.module.scss";


export type Option = {
  label: string;
  value: string | number;
};


interface SelectProps {
  value: string | number;
  label?: string;
  onChange: ({ name, value }: { name: string; value: string; }) => void;
  options: Option[];
  error?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const Select: FC<SelectProps> = ({
  label,
  value,
  onChange,
  options,
  error,
  name,
  required,
  placeholder,
  disabled,
  className,
}) => {

  const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    target.value !== value &&
      onChange({ name: target.name, value: target.value });
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, className)}>
      <label
        className={required ? styles.required : styles.label}
        htmlFor={name}>
        {label}
      </label>
      <div className={styles.selectWrapper}>
        <select
          disabled={disabled}
          id={name}
          className={styles.select}
          name={name}
          value={value || ""}
          onChange={handleChange}
        >
          {placeholder && <option disabled hidden value="">{placeholder}</option>}
          {options.map(o => (
            <option className={styles.option} key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Select;
