import React from 'react'
import styles from './Planning.module.scss'
import classNames from 'classnames'

type Props = {
  title: string
  text: string
  onClick: () => void
  selected?: boolean
}

export default function Panel({ onClick, text, title, selected }: Props) {

  return (
    <div className={classNames(styles.panel, {[styles.selected]: selected})} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}
