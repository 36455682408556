import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FBSService, { CreateNewOrderResponse, IGetOrders } from "../../services/FBSService";
import { CollectedOrder, OrderDTO, OrderDocumentsDTO, OrderItemDTO } from "../../types/swagger/llyApi_new";
import { sub } from "date-fns";
import { PeriodDates } from "../../components/pages/Logistics_FBS/parts/Orders/DateSelector";
import { RootState } from "..";
import { toast } from "react-toastify";

export enum Step {
  Confirmation = 1,
  Package = 2,
  Planning = 3,
  GearSheet = 4
}
export enum BoxType { soft, hard, pallet }

export type IBoxSize = {
  w: number
  l: number
  h: number
}
export type IItem = {
  id: string
  itemId: string
  dbId: number
  name: string
  code: string
}
export type IBoxID = number
export type IBox = {
  id: IBoxID
  itemIds: string[]
  size: IBoxSize
  weight: number
  type: BoxType
}

export type IOrder = {
  id: string
  dedline: number
  date: number
  consumer_name: string
  address: string
  items: IItem[]
  boxes: IBox[]
  documents: OrderDocumentsDTO[]
  isReady: boolean
  hasErrors: boolean
  submissionId?: number
}
export type IRequest = {
  orders: IOrder[]
  isCourier: boolean
  fromLocationId: number | undefined
}

// function getId() {
//   return Math.random().toString() + Date.now()
// }
function getNewBoxId(boxes: IBox[]) {
  return ((boxes.sort((b1, b2) => b2.id - b1.id))[0]?.id || 0) + 1
}

function buildBox(id: IBoxID, config?: { oid: string, sizes: IBoxSize, weight: number, type: BoxType }) {
  return {
    id,
    size: config?.sizes || { h: 53, l: 145, w: 234 },
    weight: config?.weight || 0,
    type: config?.type || BoxType.soft,
    itemIds: [],
  }
}

export type SelectedOrdersItems = { [key: string]: string[] }

function newRequest(ordersDTO: OrderDTO[], selectedOrdersItems: SelectedOrdersItems): IRequest {
  const orders: IOrder[] = ordersDTO.map(order => ({
    id: order.id.toString(),
    boxes: [], //[buildBox(1)],
    documents: [],
    dedline: (new Date(order.delivery_date)).getTime(),
    date: (new Date(order.date)).getTime(),
    consumer_name: order.consumer_name,
    address: order.address,
    isReady: false,
    hasErrors: false,
    items: order.items.filter(item => selectedOrdersItems[order.id] && selectedOrdersItems[order.id].includes(item.id)).map(item => {
      const items: IItem[] = []
      for (let i = 0; i < item.count; i++) {
        let dbId = -1;
        if (item?.item_ids) {
          dbId = item?.item_ids[i] || -1
        }
        items.push({
          id: `${item.id}_${i}`,
          itemId: item.id,
          dbId,
          code: item.article,
          name: item.product_name
        })
      }
      return items
    }).flat()
  })).filter(o => o.items.length > 0)
  // const r = {
  //   orders: [{
  //     id: '43546',
  //     items: [{
  //       id: 'item 1.1',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 1',
  //     }, {
  //       id: 'item 1.2',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 2',
  //     }, {
  //       id: 'item 1.3',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 3',
  //     }, {
  //       id: 'item 1.4',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 4',
  //     }],
  //     boxes: [
  //       buildBox(1),
  //       buildBox(2),
  //       buildBox(3),
  //       buildBox(4),
  //     ]
  //   }, {
  //     id: '43547',
  //     items: [{
  //       id: 'item 2.1',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 1',
  //     }, {
  //       id: 'item 2.2',
  //       code: '15560B22222XS',
  //       name: 'Ботинки 2',
  //     }],
  //     boxes: [
  //       buildBox(1)]
  //   }]
  // }

  return { orders } as IRequest
}

function buildRemainings(newDeliveryUnicItems: { [key: string]: OrderItemDTO; }, request: IRequest): IRemainings[] {
  /*
  export type IRemainings = {
    item: IItem
    count: number
    warehouses: WarehouseRemainings[]
  }
  export type WarehouseRemainings = {
    id: string
    name: string
    balance: number
    shipment: number
  }
  export type IOrder = {
    id: string
    items: IItem[]
    boxes: IBox[]
  }
  export type IRequest = {
    orders: IOrder[]
  }
  export type IItem = {
    id: string
    name: string
    code: string
  }
  */


  const remainings: IRemainings[] = []
  // const itemsCount: { [key: string]: number } = {}
  for (const rOrder of request.orders) {
    for (const rItem of rOrder.items) {
      // if (!itemsCount.includes(rItem.itemId)) {
      const r = remainings.find(r => r.item.itemId === rItem.itemId)
      if (r) {
        r.count++
      } else {
        // остатки
        const warehouses: WarehouseRemainings[] = []
        const item = newDeliveryUnicItems[rItem.itemId]
        for (const r of item.remains) {
          if (r.remains > 0) {
            warehouses.push({
              id: r.id.toString(),
              name: r.name,
              balance: r.remains,
              shipment: 0
            })
          }
        }
        remainings.push({
          item: rItem,
          count: 1,
          warehouses
        })
      }

    }
  }

  for (const r of remainings) {
    // если товары есть только на одном складе сразу подставляем значение в отправку
    if (r.warehouses.length === 1) {
      r.warehouses[0].shipment = r.count
    }
  }

  // console.log(remainings);

  return remainings


  // for (const order of orders) {
  //   // allItems.push(...order.items.map(item => JSON.parse(JSON.stringify(item))))
  //   for (const item of order.items) {
  //     const row = initialState.remainings.find(data => data.item.id === item.id)
  //     if (row) {
  //       row.count++
  //     } else {
  //       initialState.remainings.push({
  //         item: JSON.parse(JSON.stringify(item)),
  //         count: 1,
  //         warehouses: [{
  //           balance: 10,
  //           id: 'w' + Math.random(),
  //           name: 'г. Челябинск, ул. Адмиралтейства 12',
  //           shipment: 0
  //         }, {
  //           balance: 3,
  //           id: 'w' + Math.random(),
  //           name: 'г. Москва, ул. Ленина 34',
  //           shipment: 0
  //         }]
  //       })
  //     }
  // }
  // }

}



export type WarehouseRemainings = {
  id: string
  name: string
  balance: number
  shipment: number
}

export type IRemainings = {
  item: IItem
  count: number
  warehouses: WarehouseRemainings[]
}


export const steps = [
  { title: "Подтвердите отправку", num: Step.Confirmation },
  { title: "Упаковка", num: Step.Package },
  { title: "Планирование", num: Step.Planning },
  { title: "Лист передач", num: Step.GearSheet },
];




export const initialState = {
  isNewDeliveryRequestBuilding: false,
  newDeliveryRequestOrdersIDs: [] as string[],
  step: Step.Confirmation,
  isOrdersCollected: false,
  request: {
    orders: [],
    isCourier: false,
    fromLocationId: undefined,
  } as IRequest, //newRequest(),
  // dedline: {
  //   date: '20.04.2023',
  //   time: '12:00'
  // },
  remainings: [] as IRemainings[],
  costOfDelivery: '12 435 руб',
  activeItem: null as null | IItem,
  // isBoxCreation: false,
  // newBoxOrderId: null as string | null,
  // editingBoxId: null as string | null,
  boxEditorConfig: {
    isActive: false,
    boxOrderId: null as string | null,
    boxConfig: null as null | IBox,
    isNewBox: false,
  },

  isLoadingNewOrders: false,
  isLoadingCollectedOrders: false,
  isLoadingShippedOrders: false,
  isLoadingClosedOrders: false,
  isCreatingNewOrder: false,
  isReloadingOrders: false,

  newOrders: [] as OrderDTO[],
  collectedOrders: [] as CollectedOrder[],
  shippedOrders: [] as CollectedOrder[],
  closedOrders: [] as CollectedOrder[],
  ordersSearch: '',

  sentPeriodDates: { from: sub(Date.now(), { months: 1 }).getTime(), to: Date.now() },
  closedPeriodDates: { from: sub(Date.now(), { months: 1 }).getTime(), to: Date.now() },

  newDeliveryUnicItems: {} as { [key: string]: OrderItemDTO },
};

// demo remainings
// const allItems: IItem[] = []


export const fetchNewOrders = createAsyncThunk("FBS/fetchNewOrders", async () => {
  const data = FBSService.getNewOrders({});
  return data;
});
export const fetchCollectedOrders = createAsyncThunk("FBS/fetchCollectedOrders", async () => {
  const data = FBSService.getCollectedOrders({});
  return data;
});
export const fetchShippedOrders = createAsyncThunk("FBS/fetchShippedOrders", async (config: IGetOrders) => {
  const data = FBSService.getShippedOrders(config);
  return data;
});
export const fetchClosedOrders = createAsyncThunk("FBS/fetchClosedOrders", async (config: IGetOrders) => {
  const data = FBSService.getClosedOrders(config);
  return data;
});
export const postNewOrder = createAsyncThunk<CreateNewOrderResponse, void, { state: RootState }>("FBS/postNewOrder", async (_, { getState }) => {
  const state = getState() as RootState
  const data = await FBSService.createNewOrder({
    request: state.fbs.request,
    remainings: state.fbs.remainings
  });
  return { collectedOrders: data };
});
export const reloadOrders = createAsyncThunk("FBS/reloadOrders", async (ids: number[]) => {
  const data = FBSService.reloadOrders(ids);
  return data;
});

// export const fetchItemsBalance = createAsyncThunk("FBS/fetchItemsBalance", async (ids: string[]) => {
//   const data = FBSService.getItemsBalance(ids);// /storages/balance
//   return data;
// });

// export const fetchUserData = createAsyncThunk("/user", async () => {
//   const data = await UserService.getUserData();
//   return data;
// });

function _removeItemFromBoxes(state: typeof initialState, payload: { orderId: string, itemId: string }) {
  const { orderId, itemId } = payload
  const order = state.request.orders.find(o => o.id === orderId)
  order?.boxes.forEach(box => {
    if (box?.itemIds) {
      box.itemIds = box?.itemIds.filter(id => id !== itemId)
    }
  })
}

function _addItemToBox(state, payload: { orderId: string, itemId: string, boxId: IBoxID }) {
  _removeItemFromBoxes(state, payload)
  const { orderId, boxId, itemId } = payload
  const order = state.request.orders.find(o => o.id === orderId)
  const box = order?.boxes.find(b => b.id === boxId)

  box?.itemIds.unshift(itemId)
}

export const fbsSlice = createSlice({
  name: "FBS",
  initialState,
  reducers: {
    setIsCourier: (state, action: PayloadAction<boolean>) => {
      state.request.isCourier = action.payload
    },
    setFromLocationId: (state, action: PayloadAction<number | undefined>) => {
      state.request.fromLocationId = action.payload
    },
    buildRequest: (state, action: PayloadAction<SelectedOrdersItems>) => {
      const request = newRequest(state.newOrders, action.payload)
      state.request = request
      state.remainings = buildRemainings(state.newDeliveryUnicItems, request)
    },
    setClosedPeriodDates: (state, action: PayloadAction<PeriodDates>) => {
      state.closedPeriodDates = action.payload
    },
    setSentPeriodDates: (state, action: PayloadAction<PeriodDates>) => {
      state.sentPeriodDates = action.payload
    },
    setOrdersSearch: (state, action: PayloadAction<string>) => {
      state.ordersSearch = action.payload
    },
    nextStep: (state) => {
      const currentSetpIndex = steps.findIndex(step => step.num == state.step)
      if (steps.length >= currentSetpIndex + 1) {
        state.step = steps[currentSetpIndex + 1].num
      } else {
        // console.log();
        alert('TODO')
      }
      window.scrollTo(0, 200);
    },
    setRemainings: (state, action: PayloadAction<{ itemId: string, warehousId: string, shipmentValue: number }>) => {
      const { itemId, warehousId, shipmentValue } = action.payload
      const remainings = state.remainings.find(r => r.item.id === itemId)
      const warehous = remainings?.warehouses.find(w => w.id === warehousId)
      if (warehous) {
        warehous.shipment = shipmentValue
      }
    },
    buildNewDeliveryRequest: (state, action: PayloadAction<string[]>) => {
      // const items = state.newOrders.filter(o => action.payload.includes(o.id.toString()))
      // console.log(items.map(item => item.items));
      const ids = action.payload
      state.newDeliveryRequestOrdersIDs = ids
      const newDeliveryUnicItems: { [key: string]: OrderItemDTO } = {}
      state.newOrders.filter(o => ids.includes(o.id.toString())).forEach(o => {
        o.items.forEach(item => {
          newDeliveryUnicItems[item.id] = item
        })
      })
      state.newDeliveryUnicItems = newDeliveryUnicItems
      state.isNewDeliveryRequestBuilding = true
      state.step = Step.Confirmation
      state.isOrdersCollected = false
    },
    cancelNewDeliveryRequest: (state) => {
      state.isNewDeliveryRequestBuilding = false
      // TODO очистить заявку
    },
    setStep: (state, action: PayloadAction<Step>) => {
      state.step = action.payload
    },
    addItemToBox: (state, action: PayloadAction<{ orderId: string, itemId: string, boxId: IBoxID }>) => {
      _addItemToBox(state, action.payload)
      // _removeItemFromBoxes(state, action)
      // const { orderId, boxId, itemId } = action.payload
      // const order = state.request.orders.find(o => o.id === orderId)
      // const box = order?.boxes.find(b => b.id === boxId)
      // box?.itemIds.unshift(itemId)
    },
    addItemTo1stBox: (state, action: PayloadAction<{ itemId: string, orderId: string }>) => {
      const { orderId, itemId } = action.payload
      const order = state.request.orders.find(o => o.id === orderId)
      const box = order?.boxes[0]

      if (box) { _addItemToBox(state, { boxId: box.id, itemId, orderId }) }
    },
    removeItemFromBoxes: (state, action: PayloadAction<{ orderId: string, itemId: string }>) => {
      _removeItemFromBoxes(state, action.payload)
    },
    setActiveItem: (state, action: PayloadAction<IItem | null>) => {
      state.activeItem = action.payload
    },
    createBox: (state, action: PayloadAction<{ oid: string, sizes: IBoxSize, weight: number, type: BoxType }>) => {
      // state.isBoxCreation = false
      const order = state.request.orders.find(o => o.id === action.payload.oid)
      if (!order) return

      const newBox = buildBox(getNewBoxId(order?.boxes), action.payload)
      order?.boxes.unshift(newBox)
      // state.newBoxOrderId = null
      // state.editingBoxId = null
      state.boxEditorConfig.isActive = false
      state.boxEditorConfig.boxConfig = JSON.parse(JSON.stringify(newBox)) // save last new box
    },
    updateBox: (state, action: PayloadAction<{ boxId: IBoxID, oid: string, sizes: IBoxSize, weight: number, type: BoxType }>) => {
      // state.isBoxCreation = false
      const { oid, boxId, sizes, type, weight } = action.payload
      const order = state.request.orders.find(o => o.id === oid)
      const box = order?.boxes.find(b => b.id === boxId)
      if (box) {
        box.size = sizes
        box.type = type
        box.weight = weight
      }
      state.boxEditorConfig.isActive = false
      // state.newBoxOrderId = null
      // state.editingBoxId = null
    },
    startBoxCreation: (state, action: PayloadAction<string>) => {
      // state.isBoxCreation = true
      // state.newBoxOrderId = action.payload
      const order = state.request.orders.find(o => o.id === action.payload)
      if (order) {
        const newBoxId = getNewBoxId(order?.boxes || [])
        const boxConfig = state.boxEditorConfig.boxConfig
          ? state.boxEditorConfig.boxConfig
          : buildBox(newBoxId)
        boxConfig.id = newBoxId

        state.boxEditorConfig = {
          isActive: true,
          isNewBox: true,
          boxConfig,
          boxOrderId: order.id,
        }
      }
    },
    cancelBoxCreation: (state) => {
      // state.isBoxCreation = false
      // state.newBoxOrderId = null
      // state.editingBoxId = null
      state.boxEditorConfig.isActive = false
    },
    copyBox: (state, action: PayloadAction<{ orderId: string, boxId: IBoxID }>) => {
      const { boxId, orderId } = action.payload
      const order = state.request.orders.find(o => o.id === orderId)
      const box = order?.boxes.find(b => b.id === boxId)
      if (box) {
        const boxCopy = JSON.parse(JSON.stringify(box)) as IBox
        boxCopy.itemIds = []
        boxCopy.id = getNewBoxId(order?.boxes || [])
        order?.boxes.unshift(boxCopy)
      }
    },
    removeBox: (state, action: PayloadAction<{ orderId: string, boxId: IBoxID }>) => {
      const { boxId, orderId } = action.payload
      const order = state.request.orders.find(o => o.id === orderId)
      const box = order?.boxes.find(b => b.id === boxId)
      if (order && box) {
        order.boxes = order?.boxes.filter(b => b.id !== boxId)
      }
    },
    editBox: (state, action: PayloadAction<{ orderId: string, boxId: IBoxID }>) => {
      const { boxId, orderId } = action.payload
      const order = state.request.orders.find(o => o.id === orderId)
      const box = order?.boxes.find(b => b.id === boxId)
      if (box && order) {
        // state.newBoxOrderId = order.id
        // state.editingBoxId = box.id
        state.boxEditorConfig = {
          isActive: true,
          isNewBox: false,
          boxConfig: JSON.parse(JSON.stringify(box)),
          boxOrderId: order.id
        }
      }
    },
    // removeItemFromBoxes: (state, action: PayloadAction<{ orderId: string, itemId: string}>) => {
    //   const { orderId, itemId } = action.payload
    //   const order = state.request.orders.find(o => o.id === orderId)
    //   order?.boxes.forEach(box => {
    //     if(box?.itemIds){
    //       box.itemIds = box?.itemIds.filter(id => id === itemId)
    //     }
    //   })
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewOrders.pending, (state) => {
      state.isLoadingNewOrders = true;
    });
    builder.addCase(fetchNewOrders.fulfilled, (state, action) => {
      // if (action.payload) {
      //   state.categories = action.payload;
      // }
      state.newOrders = action.payload
      state.isLoadingNewOrders = false;
    });
    builder.addCase(fetchNewOrders.rejected, (state) => {
      state.isLoadingNewOrders = false;
    });

    builder.addCase(fetchCollectedOrders.pending, (state) => {
      state.isLoadingCollectedOrders = true;
    });
    builder.addCase(fetchCollectedOrders.fulfilled, (state, action) => {
      state.collectedOrders = action.payload
      state.isLoadingCollectedOrders = false;
    });
    builder.addCase(fetchCollectedOrders.rejected, (state) => {
      state.isLoadingCollectedOrders = false;
    });

    builder.addCase(fetchShippedOrders.pending, (state) => {
      state.isLoadingShippedOrders = true;
    });
    builder.addCase(fetchShippedOrders.fulfilled, (state, action) => {
      state.shippedOrders = action.payload
      state.isLoadingShippedOrders = false;
    });
    builder.addCase(fetchShippedOrders.rejected, (state) => {
      state.isLoadingShippedOrders = false;
    });

    builder.addCase(fetchClosedOrders.pending, (state) => {
      state.isLoadingClosedOrders = true;
    });
    builder.addCase(fetchClosedOrders.fulfilled, (state, action) => {
      state.closedOrders = action.payload
      state.isLoadingClosedOrders = false;
    });
    builder.addCase(fetchClosedOrders.rejected, (state) => {
      state.isLoadingClosedOrders = false;
    });


    builder.addCase(postNewOrder.pending, (state) => {
      state.isCreatingNewOrder = true;
    });
    builder.addCase(postNewOrder.fulfilled, (state, action) => {
      // state.closedOrders = action.payload
      console.log(action.payload);
      state.isCreatingNewOrder = false;
      state.isOrdersCollected = true;
      action.payload.collectedOrders.forEach(o => {

        const ro = state.request.orders.find(_o => {
          // console.log(_o.id.toString(), o.order_id.toString());
          return _o.id.toString() === o.order_id.toString()
        })
        if (!ro) { toast.error('Ошибка!'); throw new Error("Wrong data!"); }
        ro.documents = o.documents || []
        ro.submissionId = o.id
      })
    });
    builder.addCase(postNewOrder.rejected, (state) => {
      state.isCreatingNewOrder = false;
    });

    builder.addCase(reloadOrders.pending, (state) => {
      state.isReloadingOrders = true;
    });
    builder.addCase(reloadOrders.fulfilled, (state, action) => {
      // state.closedOrders = action.payload
      // console.log(action.payload);
      action.payload.forEach(o => {
        const ro = state.request.orders.find(ro => ro.id.toString() === o.order_id.toString())
        if(!ro) {
          return toast.error('Ошибка!')
        }else{
          ro.hasErrors = o.has_errors || false
          ro.isReady = o.is_ready || false
          ro.documents = o.documents || []
        }
      })

      state.isReloadingOrders = false;
    });
    builder.addCase(reloadOrders.rejected, (state) => {
      state.isReloadingOrders = false;
    });


    // builder.addCase(fetchItemsBalance.pending, (state) => {
    //   state.isLoadingItemsBalance = true;
    // });
    // builder.addCase(fetchItemsBalance.fulfilled, (state, action) => {
    //   // state.closedOrders = action.payload
    //   state.isLoadingItemsBalance = false;
    // });
    // builder.addCase(fetchItemsBalance.rejected, (state) => {
    //   state.isLoadingItemsBalance = false;
    // });

  },
});

export const {
  buildNewDeliveryRequest, cancelNewDeliveryRequest, setStep, addItemToBox, removeItemFromBoxes,
  setActiveItem, createBox, startBoxCreation, cancelBoxCreation, addItemTo1stBox, copyBox, removeBox,
  editBox, updateBox, setRemainings, nextStep, setOrdersSearch, setClosedPeriodDates, setSentPeriodDates,
  buildRequest, setIsCourier, setFromLocationId
} = fbsSlice.actions

export default fbsSlice.reducer;
