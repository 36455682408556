import { AxiosProgressEvent } from "axios";
import { Endpoints } from "../constants/server";
import { GoodsAnswer, ListProductDataType } from "../types/productListTypes";
import { ProductDataType } from "../types/update-product";
import api from "../utils/api";

interface IGetArchTemplate {
  category: number;
  onDownloadProgress?: (percent: number) => void;
}

interface IGetArch {
  products_id: number[];
  onDownloadProgress?: (percent: number) => void;
}

type GetArchAnswer = {
  success: boolean;
  error?: string;
}

interface IUploadArch {
  category: number;
  formDataFile: any,
  onUploadProgress?: (percent: number) => void;
}

interface IUploadEditedArch {
  formDataFile: any,
  onUploadProgress?: (percent: number) => void;
}


interface ServerResponseData<T> {
  message?: string;
  // messages?: string[];
  status: number;
  success?: boolean;
  data?: T;
  meta?: { totalCount: number };
}

type UploadArchAnswer = {
  success: boolean;
  response?: ServerResponseData<UploadArchAnswerData>;
  error?: string;
}

type UploadArchAnswerData = {
  errors: number[];
  products: ProductDataType[];
}

type SendToModerateAnswer = {
  success?: boolean;
  data?: {
    moderate: number;
    saved: number;
  }
  error?: string;
}




async function download(req: string, onDownloadProgress?: (percent: number) => void, defaultFileName?: string) {
  let answer: GetArchAnswer = { success: false };
  await api
    .get<Blob>(req, {
      "responseType": "blob",
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / (progressEvent?.total || 100)) * 100) as any,
        );
        onDownloadProgress && onDownloadProgress(uploadPercentage);
      }
    })
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = url;

      const filename = response.headers['content-disposition']?.split('filename=')[1].replaceAll('"', "") || defaultFileName || "template";

      link.setAttribute(
        "download",
        filename
      );
      document.body.appendChild(link);

      link.click();

      window.URL.revokeObjectURL(link.href);
      link.parentNode?.removeChild(link);
      answer.success = true;
    })
    .catch((err) => {
      console.log(err)
    });

  return answer;
}

async function upload(
  url: string,
  formDataFile: any,
  onUploadProgress?: (percent: number) => void
) {
  let answer: UploadArchAnswer = { success: false };

  const fileHeader: any = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: function (progressEvent: ProgressEvent) {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100) as any,
      );
      onUploadProgress && onUploadProgress(uploadPercentage);
    }//.bind(this),
  };

  await api
    .post(url, { file: formDataFile }, fileHeader)
    .then(response => {
      answer.response = response?.data;
      answer.success = true;
    }).catch(response => {
      answer.response = response?.response?.data;
      answer.success = false;
      answer.error = response.message || "error!";
    });

  return answer;
}

class MassImportEditService {
  static async getArchTemplate({ category, onDownloadProgress }: IGetArchTemplate) {
    let req = `${Endpoints.GET_ARCHIVE_TEMPLATE}${category}`;
    return await download(req, onDownloadProgress);
  }
  static async getArch({ products_id, onDownloadProgress }: IGetArch) {
    let req = `${Endpoints.GET_ARCHIVE}${products_id.join(',')}`;
    return await download(req, onDownloadProgress);
  }


  static async uploadArch({ category, formDataFile, onUploadProgress }: IUploadArch) {
    const url = `${Endpoints.UPLOAD_ARCHIVE}${category}`;
    return await upload(url, formDataFile, onUploadProgress);
  }
  static async uploadEditedArch({ formDataFile, onUploadProgress }: IUploadEditedArch) {
    const url = `${Endpoints.UPLOAD_EDITED_ARCHIVE}`;
    return await upload(url, formDataFile, onUploadProgress);
  }

  static async getGoods({ ids }: { ids: number[] }) {
    const data: GoodsAnswer = { success: false };
    const req = `${Endpoints.GET_SELECTED_PRODUCTS}`;
    await api
      .post<GoodsAnswer>(req, { products: ids })
      .then((res) => {
        data.data = res.data?.data || [];
        data.success = true;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }


  static async sendToModerate() {
    let data: SendToModerateAnswer = { success: false };
    let req = `${Endpoints.SEND_TO_MODERATE}`;
    await api
      .post(req, { test: 1 })
      .then((response) => {
        data.success = true;
        data.data = response.data.data;
      })
      .catch((err) => {
        console.log(err)
      });
    return data;
  }
}

export default MassImportEditService;
