import React from "react";
import Layout from "../../complexes/Layout/Layout";
import { SellProductContent } from "./parts/SellProductContent";
import { useParams } from "react-router-dom";
import { SellProductTabConfig, SellProductTabId } from "./parts/SellProductContent/const";

function SellProduct() {

  // костыль изза "уникальной" подсказки
  const params = useParams();
  const tabId = params.tabId

  let hint='Здесь представлены наиболее популярные теги, которые покупатели используют при поиске товаров'
  if(SellProductTabConfig[SellProductTabId.products].id === tabId){
    hint = 'В разделе "Статистика по товару" можно сравнить собственные продажи с продажами магазинов. Статистика доступна для карточек с одинаковыми товарами по полю "Штрихкод производителя"'
  }

  return (
    <Layout title='Что продавать на LL' hint={hint} >
      <SellProductContent />
    </Layout>
  );
}

export default SellProduct;
