import React, { useState } from 'react'
import { Layout } from '../../complexes/Layout'
import { ITab, Tabs } from '../../complexes/Tabs'
import { User } from './parts/User'

import styles from "./ProfileAccount.module.scss"
import Remove from './parts/Remove/Remove'
import { AboutShop, Info } from './parts/AboutShop'
import { useLocation, useParams } from 'react-router-dom'
import { ProfileTabConfig } from './const'
// import { ToastContainer } from 'react-toastify'

export default function Profile() {
  const tabs: ITab[] = []

  // const location = useLocation(); // TODO переделать через константы

  const params = useParams();
  const tabId = params.tabId

  tabs.push({
    // label: "Аккаунт",
    ...ProfileTabConfig.account,
    content: <User />
  })
  tabs.push({
    // label: "Информация о магазине",
    ...ProfileTabConfig.about_shop,
    content: <AboutShop />,
    rightSideBar: <Info />
  })
  tabs.push({
    // label: "Удаление аккаунта",
    ...ProfileTabConfig.removal,
    content: <Remove />
  })

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)

  return (
    <Layout title='Учетная запись' hint='Отредактируйте основную информацию о профиле'>
      {/* <ToastContainer /> */}
      <div className={styles.wrap}>
        <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
      </div>
    </Layout>
  )
}
