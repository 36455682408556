import React, { useState, useEffect } from 'react'
import { BlueButton } from '../../simples/BlueButton'
import { Layout } from '../../complexes/Layout'
import AddEmployee from './AddEmployee'
import { EmployeeDTO } from '../../../types/swagger/llyApi_new';
import styles from './Employees.module.scss'
import { CustomHint } from '../../simples/CustomHint';
import { IconBtn, IconType } from '../../simples/IconBtn';
import api from "../../../utils/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatPhone } from '../../../utils/employees';
import { CustomTextInput } from '../../simples/CustomTextInput';
import ConfirmDialog from './ConfirmDialog';
import { Action } from './const';
import { postRequest } from './utils';
// import { CustomSelect_v2 } from '../../simples/CustomSelect_v2';

type FetchEmployeesResponse = {
  success?: boolean
  data?: EmployeeDTO[]
  main_phone?: string
}


export default function Employees() {

  const [mainPhone, setMainPhone] = useState('')
  const [employees, setEmployees] = useState<EmployeeDTO[]>([])

  const [addingUser, setAddingUser] = useState(false)

  const someError = () => toast.error("Ошибка!", { theme: "light" });


  const unlockSuccess = () => toast.warn("Сотрудник разблокирован", { theme: "light" });


  const fetchEmployees = async () => {
    const req = '/employees'

    api.get<FetchEmployeesResponse>(req).then(resp => {
      if (resp.data.success) {
        setEmployees(resp.data.data || [])
        setMainPhone(resp.data.main_phone || '')
      } else {
        someError()
      }
    }).catch(e => {
      someError()
      console.log(e);
    })
  }

  useEffect(() => {
    fetchEmployees()
  }, [])


  const handleAddUser = () => {
    setAddingUser(true)
  }
  const handleClose = () => {
    setAddingUser(false)
    fetchEmployees()
  }

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const [action, setAction] = useState<Action | null>(null)
  const handleLockUser = (id: number) => () => {
    setSelectedUserId(id)
    setAction(Action.Lock)
  }
  const handleUnLockUser = (id: number) => () => {
    // setSelectedUserId(id)
    // setAction(Action.Unlock)
    postRequest({ action: Action.Unlock, id }).then(resp => {
      if (resp.success) { unlockSuccess() } else { someError }
    }).catch(e => {
      console.log(e);
      someError()
    }).finally(fetchEmployees)

  }
  const handleRemoveUser = (id: number) => () => {
    setSelectedUserId(id)
    setAction(Action.Remove)
  }

  const handleCloseConfirmDialog = () => {
    setSelectedUserId(null)
    setAction(null)
    fetchEmployees()
  }

  return (
    <Layout title='Сотрудники'>
      <div className={styles.wrap}>
        <AddEmployee isActive={addingUser} onClose={handleClose} />
        {action !== null && selectedUserId !== null && <ConfirmDialog isActive={true} userId={selectedUserId} action={action} onClose={handleCloseConfirmDialog} />}
        {/* <ToastContainer /> */}
        <div className={styles.employees}>
          <CustomTextInput className={styles.main_phone} name='main_phone' label='Основной телефон' value={formatPhone(mainPhone || '')} onChange={() => { return }} readonly />
          <div className={styles.head}>
            <h1>Сотрудники</h1>
            <CustomHint hint='Добавьте телефоны своих сотрудников для доступа в кабинет продавца' hintWidth={360} />
          </div>

          <div className={styles.list}>
            {employees.map(e => <div key={e.id} className={styles.row}>
              <div className={styles.role}>{e.role}</div>
              <div>
                <div className={styles.name}>{e.name}</div>
                <div className={styles.phone}>{formatPhone(e.phone)}</div>
              </div>
              <div className={styles.btns}>
                <IconBtn icon={IconType.Trash} hint='Удалить номер' hintWidth={170} onClick={handleRemoveUser(e.id)} />
                {e.is_lock
                  ? <IconBtn icon={IconType.LockClose} hint='Разблокировать' onClick={handleUnLockUser(e.id)} />
                  : <IconBtn icon={IconType.LockOpen} hint='Заблокировать' onClick={handleLockUser(e.id)} />
                }
              </div>
            </div>)}
          </div>
          <div>
            <BlueButton title='Добавить сотрудника' onClick={handleAddUser} />
          </div>
          {/* <p></p>
          <div style={{width: '200px'}}>
            <CustomSelect_v2 name='' onChange={() => { return }} options={[{ value: 'asdf', label: 'dsfd' }, { value: 'asdf1', label: 'dsfd1' }]} hasSearchInput />
          </div> */}
        </div>
      </div>
    </Layout >
  )
}
