import React, { useEffect } from 'react'
import LowControls, { Mode, hasErrors } from './LawControls'
import { LegalCompanyInfoDTO } from '../../../../../types/swagger/llyApi_new'
import { ModalDialog } from '../../../../simples/ModalDialog'

import styles from './LawInfo.module.scss'
import Uploads from './Uploads'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { isDocumentsUploaded, updateLawData } from '../../../../../store/slices/law'

type Props = {
  data: LegalCompanyInfoDTO
  isActive: boolean
  onCloseUpdate: () => void
}


export default function LawUpdate({ data, isActive, onCloseUpdate }: Props) {


  useEffect(() => {
    // setIpDocs({
    //   [DocType.TYPE_OGRNIP]: {
    //     type: DocType.TYPE_OGRNIP,
    //     title: 'Свидетельство ОГРНИП'
    //   },
    //   [DocType.TYPE_INN]: {
    //     type: DocType.TYPE_INN,
    //     title: 'Свидетельство ИНН'
    //   },
    //   [DocType.TYPE_PASSPORT]: {
    //     type: DocType.TYPE_PASSPORT,
    //     title: 'Копия паспорта ИП (два разворота в одном файле - с фотографией и регистрацией)'
    //   },
    // })

    // setUlDocs({
    //   [DocType.TYPE_OGRNIP]: {
    //     type: DocType.TYPE_OGRN,
    //     title: 'Свидетельство ОГРН (или лист записи ЕГРЮЛ)'
    //   },
    //   [DocType.TYPE_INN]: {
    //     type: DocType.TYPE_INN,
    //     title: 'Свидетельство ИНН'
    //   },
    //   [DocType.TYPE_ORDER]: {
    //     type: DocType.TYPE_ORDER,
    //     title: 'Устав компании с отметками налогового органа о регистрации'
    //   },
    //   [DocType.TYPE_ARTICLE]: {
    //     type: DocType.TYPE_ARTICLE,
    //     title: 'Решение или приказ о назначении генерального директора'
    //   },
    // })
  }, [])


  const lawData = useAppSelector(state => state.law.lawData)
  const documentsUploaded = useAppSelector(isDocumentsUploaded())


  const dispatch = useAppDispatch()

  const handleSave = () => {
    dispatch(updateLawData())
  }



  const isHasErrors = hasErrors(lawData)

  return (
    <ModalDialog buttonsConfig={[{ text: 'Сохранить изменения', onClick: handleSave, disabled: (isHasErrors || !documentsUploaded) }]} isActive={isActive}
      handleClose={onCloseUpdate} hasCloseBtn width={920} warning='! Обязательно обновите все документы и поля, когда Вы нажмёте на “Сохранить изменения”, предыдущие данные и документы будут удалены' >
      <div className={styles.modal_controls_wrap}>
        <LowControls mode={Mode.modal} />
        {/* <LowControls data={lawData} mode={Mode.modal} onChange={setLawData} /> */}
        {/* <BlueButton title='Сохранить изменения' onClick={handleSave} /> */}
        {/* <Uploads onChange={handleChangeUpload} is_individual={lawData.is_individual} config={lawData.is_individual ? ipDocs : ulDocs} /> */}
        <Uploads />
      </div>
      {/* <div className={styles.update_warning}>! Обязательно обновите все документы и поля, когда Вы нажмёте на “Сохранить изменения”, предыдущие данные и документы будут удалены</div> */}
    </ModalDialog>
  )
}
