import classNames from 'classnames'
import React from 'react'

import styles from "./CustomLink.module.scss"

interface Props {
  text: string
  icon?: string
  className?: string
  isFile?: boolean
  onClick?: () => void
}

export default function CustomLink({ text, icon, className, isFile, onClick }: Props) {
  return (
    <div
      className={classNames(styles.link, className, { [styles.file]: isFile })}
      onClick={onClick}
    >
      {icon && <img src={icon} />}
      {text}
    </div>
  )
}
