import React, { useState, useMemo, useEffect } from "react";

import { TagsType } from "../../../../../../../../../types/product";
import searchLogo from "../../../../../../../../../assets/icons/search_icon.svg";
import arrowSvg from "../../../../../../../../../assets/icons/arrow_up.png";

import styles from "./SearchAndSort.module.scss";
import { DebouncedInput } from "../../../../../../../../complexes/DebouncedInput";

type Props = {
  searcheText: string
  setSearcheText: (text: string) => void
  sortByAlpabet: () => void
  sortByAmount: () => void
}

const SearchAndSort = ({ searcheText, setSearcheText, sortByAlpabet, sortByAmount }: Props) => {
  return (
    <div>
      {/* <div className={styles.searchFieldWrapper}>
        <input
          value={searcheText}
          onChange={(e) => setSearcheText(e.target.value)}
          className={styles.searchField}
          placeholder="Поиск"
        />
        <img className={styles.searchLogo} src={searchLogo} />
      </div> */}
      <DebouncedInput className={styles.searchFieldWrapper} debounceDelay={200} onChange={setSearcheText} value={searcheText} placeholder="Поиск" icon={searchLogo} />

      <div className={styles.sort}>
        <div onClick={sortByAlpabet}>
          <p>По алфавиту</p>
          <img src={arrowSvg} alt="arrow" />
        </div>
        <div onClick={sortByAmount}>
          <p>Запросы пользователей</p>
          <img src={arrowSvg} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default SearchAndSort;
