import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { createPack, setDeliveryAddress, setIsActive, setPackingType, setSelectedProducts, setStep } from '../../../../../store/slices/fbo'
import { StepsIndicator } from '../../../../complexes/StepsIndicator'
import { BlueButton } from '../../../../simples/BlueButton';
import styles from './Proposal.module.scss'
import { ModalProductSearch } from '../../../../complexes/ModalProductSearch';
import { ProductList } from '../../../../complexes/ProductList';
import { ProductsDTO } from '../../../../../types/swagger/llyApi_new';
import { GoodsAnswer } from '../../../../../types/productListTypes';
import { Endpoints } from '../../../../../constants/server';
import api from '../../../../../utils/api'
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders';
import { RadioButtonsWithContent } from '../../../../simples/RadioButtonsWithContent';
import Limits, { Limit } from './parts/Limits/Limits';
import Calendar from './parts/Calendar/Calendar';
import { CustomHint } from '../../../../simples/CustomHint';
import Packs from './parts/Packs/Packs';
import Passes from './parts/Passes/Passes';

const steps = [
  { title: "Выберите карточки", num: 1 },
  { title: "Планирование", num: 2 },
  { title: "Дата отправки", num: 3 },
  { title: "Упаковка", num: 4 },
  { title: "Пропуск", num: 5 },
];


// const DeliveryAddresses = [{
//   id: '1',
//   address: 'г. Москва, ул. Пушкина, д 12',
// }, {
//   id: '2',
//   address: 'г. Москва, ул. Адмиралтейства, д 12',
// }]
// const PackingTypes = [{
//   id: '1',
//   title: 'Мягкая упаковка',
//   description: 'Мягкие упаковки предназначены для небольшой партии вещей',
// }, {
//   id: '2',
//   title: 'Короб',
//   description: 'Товары в коробе находятся в индивидуальной упаковке. Каждый короб имеет уникальную маркировку',
// }, {
//   id: '3',
//   title: 'Монопалет',
//   description: 'Допускается поместить на палету до трёх разных карточек товаров',
// }]

const deliveryLimits: Limit[] = [{
  date: '28.03.23',
  values: [{
    title: 'Короб',
    value: '12 345'
  }, {
    title: 'Мягкая',
    value: '235'
  }, {
    title: 'Суперсейф',
    value: '246'
  },]
}, {
  date: '29.03.23',
  values: [{
    title: 'Короб',
    value: '12 345'
  }, {
    title: 'Мягкая',
    value: '235'
  }, {
    title: 'Суперсейф',
    value: '246'
  },]
}, {
  date: '30.03.23',
  values: [{
    title: 'Короб',
    value: '12 345'
  }, {
    title: 'Мягкая',
    value: '235'
  }, {
    title: 'Суперсейф',
    value: '246'
  },]
}]

export default function Proposal() {

  const dispatch = useAppDispatch()
  const { step, selectedProducts, deliveryAddress, packingType, packs, deliveryAddresses, packingTypes } = useAppSelector(state => state.fbo)

  const [isSelectProducts, setIsSelectProducts] = useState(false)

  // const handleCnacelCeration = () => {
  //   dispatch(setIsActive(false))
  // }


  const loadProducts = async (ids: string[]) => {
    let data: ProductsDTO[] = []
    if (ids.length > 0) {
      const req = `${Endpoints.GET_SELECTED_PRODUCTS}`;
      await api
        .post<GoodsAnswer>(req, { products: ids })
        .then((res) => {
          data = res.data?.data || [];
        })
        .catch((err) => console.log(err));
    }
    return data
  }

  const handleClick = (step: number) => {
    if (process.env.NODE_ENV !== "production") {
      dispatch(setStep(step))
    }
    // else {
    //   // возврат на предидущий шаг, на первый нельзя вернуться
    //   if (step > 1 && step === step - 1) {
    //     dispatch(setStep(step))
    //   }
    // }
  }

  let content
  switch (step) {
    case 1: {
      const handleAddProducts = () => {
        setIsSelectProducts(true)
      }
      const handleOnSelectProducts = async (list: string[]) => {
        setIsSelectProducts(false)
        const products = await loadProducts(list)
        dispatch(setSelectedProducts(products))
      }
      const handleNextStep = () => {
        dispatch(setStep(2))
      }

      const headers = [
        DefaultHeaders.photo,
        DefaultHeaders.barcode,
        DefaultHeaders.name,
        DefaultHeaders.retail_price,
        DefaultHeaders.discountValue,
        DefaultHeaders.stockBalance,
        DefaultHeaders.brand,
        DefaultHeaders.sex,
        DefaultHeaders.category,
        DefaultHeaders.subcategory,
        DefaultHeaders.size,
        DefaultHeaders.season,
        DefaultHeaders.dateOfCreation,
      ]

      content = <>
        <div>
          <ModalProductSearch isVisible={isSelectProducts} onClose={handleOnSelectProducts} />
          <BlueButton title='Добавить карточку для отправки' onClick={handleAddProducts} />
          {/* <div>{selectedProducts.length}</div> */}
          <ProductList data={selectedProducts} headers={headers} />
          <div className={styles.next_btn}>
            <BlueButton title='Далее' disabled={selectedProducts.length < 1} onClick={handleNextStep} />
          </div>
        </div>
      </>
      break;
    }
    case 2: {
      const handleChangeDelivery = (id: string) => {
        dispatch(setDeliveryAddress(id))
      }
      const handleChangePacking = (id: string) => {
        dispatch(setPackingType(id))
      }
      const handleNextStep = () => {
        dispatch(setStep(3))
      }
      const deliveryOptions = deliveryAddresses.map(da => ({
        value: da.id,
        content: <>{da.address}</>
      }))
      const packingOptions = packingTypes.map(da => ({
        value: da.id,
        content: <div className={styles.packing_option}>
          <div className={styles.packing_options_title}>{da.title}</div>
          <div className={styles.packing_options_description}>{da.description}</div>
        </div>
      }))

      const isValid = deliveryAddress !== undefined && packingType !== undefined

      content = <>
        <div>
          <h2 className={styles.title}>Адрес доставки</h2>
          <RadioButtonsWithContent value={deliveryAddress} options={deliveryOptions} onChange={handleChangeDelivery} />
        </div>
        <div>
          <h2 className={styles.title}>Тип упаковки</h2>
          <RadioButtonsWithContent value={packingType} options={packingOptions} onChange={handleChangePacking} />
        </div>
        <div>
          <h2 className={styles.title}>Лимиты доставки</h2>
          <Limits limits={deliveryLimits} />
        </div>
        <div className={styles.next_btn}>
          <BlueButton title='Далее' disabled={!isValid} onClick={handleNextStep} />
        </div>
      </>
      break;
    }
    case 3: {

      const handleNextStep = () => {
        dispatch(setStep(4))
      }

      // const calendarConfig: CalendarConfig = {
      //   items: [{
      //     availableSlots: [
      //       { time: '12:00' }, { time: '13:00' }, { time: '14:00' }, { time: '15:00', disabled: true },
      //       { time: '16:00' }, { time: '17:00', disabled: true }, { time: '18:00' }, { time: '19:00' },
      //       { time: '20:00' }],
      //     date: '12 января',
      //     gate: {
      //       id: '1',
      //       title: 'Гейт 17'
      //     }
      //   }, {
      //     availableSlots: [
      //       { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
      //       { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
      //       { time: '20:00', disabled: true }],
      //     date: '13 января',
      //     gate: {
      //       id: '1',
      //       title: 'Гейт 17'
      //     }
      //   }, {
      //     availableSlots: [
      //       { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
      //       { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
      //       { time: '20:00', disabled: true }],
      //     date: '14 января',
      //     gate: {
      //       id: '2',
      //       title: 'Гейт 15'
      //     }
      //   }]
      // }

      content = <>
        <div>
          <h2 className={styles.title}>Выберите дату доставки на склад</h2>
          <Calendar />
        </div>
        <div className={styles.next_btn}>
          <BlueButton title='Далее' disabled={false} onClick={handleNextStep} />
        </div>
      </>
      break;
    }
    case 4: {
      // const packs: IPack[] = [{
      //   id: '1',
      //   packName: 'Груз 1',
      //   docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }, {title: 'Термопринт.pdf', link: ''}],
      //   sizes: { count: 100, height: 100, width: 100, length: 100 },
      //   weight: 100
      // }, {
      //   id: '2',
      //   packName: 'Груз 2',
      //   docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }],
      //   sizes: { count: 100, height: 100, width: 100, length: 100 },
      //   weight: 100
      // }, {
      //   id: '3',
      //   packName: 'Груз 3',
      //   docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }],
      //   sizes: { count: 100, height: 100, width: 100, length: 100 },
      //   weight: 100
      // }]

      const handleNextStep = () => {
        dispatch(setStep(5))
      }
      const handleAddPack = () => {
        dispatch(createPack())
      }

      content = <>
        <div className={styles.btns}>
          <BlueButton title='Создать короб' onClick={handleAddPack} />
          <BlueButton title='Скачать документацию' />
          <CustomHint hint='Распечатайте и прикрепите штрихкод к упаковке' hintWidth={425} />
        </div>
        <Packs packs={packs} />
        <div className={styles.next_btn}>
          <BlueButton title='Далее' disabled={false} onClick={handleNextStep} />
        </div>
      </>
      break;
    }
    case 5: {
      const handleSaveProposal = () => {
        console.log('p');
      }
      content = <>
        <Passes />
        <div className={styles.next_btn}>
          <BlueButton title='Отправить заявку' disabled={false} onClick={handleSaveProposal} />
        </div>
      </>
      break;
    }
    default:
      break;
  }


  return (
    <div>
      <StepsIndicator steps={steps} currentStep={step} onClick={handleClick} />
      <div className={styles.content}>
        {content}
      </div>
    </div>
  )
}
