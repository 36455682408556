import React, { useState } from 'react'
import styles from './Orders.module.scss'
import { ItemsList } from '../../../../complexes/ItemsList'
import { BlueButton } from '../../../../simples/BlueButton'
import { ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { buildNewDeliveryRequest, fetchClosedOrders, setClosedPeriodDates } from '../../../../../store/slices/fbs'
import Skeleton from 'react-loading-skeleton'
import { format, sub } from 'date-fns'
import DateSelector, { PeriodDates } from './DateSelector'

const Headers: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "280px",
  width: "280px"
}, {
  name: 'sendDate',
  content: <>Дата отправления</>,
  type: ListDataType.content,
  sortable: true,
  minWidth: "210px",
  width: "210px"
}, {
  name: 'status',
  text: "Статус",
  type: ListDataType.text,
  sortable: true,
  minWidth: "200px",
  width: "200px"
}, {
  name: 'addressTo',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "280px",
  width: "280px"
}, {
  name: 'addressFrom',
  type: ListDataType.content,
  content: <>Адрес почтового отделения</>,
  sortable: true,
  minWidth: "280px",
  width: "280px",
}]


export default function ClosedOrders() {

  const { isLoadingClosedOrders: isLoadingOrders, ordersSearch, closedOrders, closedPeriodDates } = useAppSelector(state => state.fbs)
  // const [periodDates, setPeriodDates] = useState({ from: Date.now(), to: sub(Date.now(), { months: 1 }).getTime() })
  const dispatch = useAppDispatch()

  // React.useEffect(() => {
  //   dispatch(fetchClosedOrders());
  // }, []);
  React.useEffect(() => {
    dispatch(fetchClosedOrders({ fromDate: new Date(closedPeriodDates.from), toDate: new Date(closedPeriodDates.to) }));
  }, [closedPeriodDates]);

  const _s = ordersSearch.toLocaleLowerCase()
  const orders = closedOrders.filter(o => o.number?.toLocaleLowerCase().includes(_s)
    || o.address.toLocaleLowerCase().includes(_s)
    || o.consumer_name.toLocaleLowerCase().includes(_s)
    || o.id.toString().toLocaleLowerCase().includes(_s)
  )


  const items: ListRow[] = orders.map(o => {
    // const expired = o.hours_remain === 0
    return {
      key: o.id.toString(),
      data: [{
        name: 'id',
        type: ListDataType.content,
        content: <div className={styles.id}>
          <div className={styles.number}>№{o.id}</div>
          <div className={styles.date}>{o.date}</div>
        </div>
      }, {
        name: 'sendDate',
        type: ListDataType.content,
        content: <div className={styles.sendDate}>{}</div>
      }, {
        name: 'status',
        type: ListDataType.content,
        content: <div className={styles.status}>
          <div className={o.status ? styles.cancel : styles.closed}>{o.status}</div>
          <div>{o.closed_at && format(o.closed_at, 'dd.MM.yyyy')}</div>
        </div>
      }, {
        name: 'addressTo',
        className: styles.addressFrom,
        type: ListDataType.text,
        text: o.address
      }, {
        name: 'addressFrom',
        type: ListDataType.text,
        className: styles.addressTo,
        text: o.delivery_from
      }]
    }
  })


  // const handleBuildOrder = () => {
  //   dispatch(buildNewDeliveryRequest())
  // }
  const handleSetPeriodDates = (data: PeriodDates) => {
    dispatch(setClosedPeriodDates(data))
  }

  if (isLoadingOrders) {
    return <Skeleton height={80} count={5} />
  }
  return (
    <div className={styles.new_orders}>
      <DateSelector periodDates={closedPeriodDates} setPeriodDates={handleSetPeriodDates} />
      <div className={styles.table}>
        {isLoadingOrders
          ? <Skeleton height={80} count={5} />
          : <ItemsList headers={Headers} items={items} emptyText={<div className={styles.empty_text}><h1>Нет заказов</h1><span>Но они скоро появятся! </span></div>} />
        }
      </div>
      {/* <div className={styles.footer}>
        <BlueButton title='Собрать заказ' onClick={handleBuildOrder} />
      </div> */}
    </div>
  )
}
