import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { activeVariantSelector, setPriceForVariantSize, setSizeValue, toggleSize } from '../../../../../store/slices/product'
import { CategoriesSizes } from '../../../../../types/swagger/llyApi_new'
import { Checkbox } from '../../../../simples/Checkbox'
import { CustomTextInput } from '../../../../simples/CustomTextInput'


import styles from './VariantSizes.module.scss'


type Props = {
  size: CategoriesSizes
}

export default function Size({ size }: Props) {


  const dispatch = useAppDispatch()
  const { sizes: selectedSizes } = useAppSelector(activeVariantSelector)
  const { activeVariantIndex } = useAppSelector(state => state.product.variants)

  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const sizeIndex = selectedSizes.findIndex(s => s.size_id === size.id)
  const sizeState = selectedSizes[sizeIndex]
  const isChecked = !!sizeState

  const handleToggle = () => {
    dispatch(toggleSize(size.id))
  }
  // const handleChangeManBarcode = ({ name, value }) => {
  //   dispatch(setSizeValue({ sizeIndex, name, value }))
  // }

  const handleChangeManBarcode = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 22) {
      dispatch(setSizeValue({ sizeIndex, name: 'man_barcode', value: e.target.value }))
    }
  }
  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseInt(e.target.value)
    dispatch(setPriceForVariantSize({ sizeIndex, variantIndex: activeVariantIndex, price }))
  }

  return (
    <div className={styles.size}>
      <div className={styles.checkbox}>{!readonly && <Checkbox checked={isChecked} onChange={handleToggle} />} {size.name}</div>
      {/* <div className={styles.price}>{
        isChecked && (readonly ? sizeState?.price : <input type="text" value={sizeState?.man_barcode} onChange={handleChangePrice} />)
      }</div> */}
      <div className={styles.man_barcode}>{
        isChecked && (readonly ? sizeState?.man_barcode : <input type="text" value={sizeState?.man_barcode} onChange={handleChangeManBarcode} />)
      }</div>
      <div className={styles.barcode}>{sizeState?.barcode}</div>
    </div>
  )
}
