import React, { useState } from "react";
import styles from "./TagsBlock.module.scss";
import Tags from "./parts/Tags/Tags";
import TagsField from "./parts/TagsField/TagsField";
import { useAppSelector } from "../../../../../hooks/store";
import Title from "../Title";


function TagsBlock() {
    const allTags = useAppSelector((state) => state.product.commonData.tags);
    const readonly = useAppSelector(state => state.product.isReadonlyMode)

    const [showTagsField, setShowTagsField] = useState(false);


    const onOpenModalTags = (evt: React.MouseEvent) => {
        if (readonly) return
        evt.preventDefault();
        setShowTagsField(true)
    };

    const onCloseModalTags = () => {
        // evt.preventDefault();
        setShowTagsField(false)
    };

    return <div className={styles.tags}>
        {/* <Title text='Теги' /> */}
        <div className={styles.title}>Теги</div>
        <Tags showTagsField={onOpenModalTags} />

        {allTags.length && (
            <TagsField
                active={showTagsField}
                onClose={onCloseModalTags}
            />
        )}
    </div>
}


export default React.memo(TagsBlock);