import classNames from 'classnames';
import React from 'react'
import { textAsIntValidator } from '../../../../../utils/validators';
import { CustomDatePicker, DatePickerDate } from '../../../../complexes/CustomDatePicker';
import { datePickerDateToDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker';
import { DebouncedInput } from '../../../../complexes/DebouncedInput';
import { Checkbox } from '../../../../simples/Checkbox';
import { RadioButtons } from '../../../../simples/RadioButtons';
import styles from "./Discount.module.scss"


export enum DiscountType {
  standard = "standard",
  twoPlusOne = "twoPlusOne"
}

enum DiscountValueType {
  percent = "percent",
  value = "value"
}

type Props = {
  dateFrom?: DatePickerDate
  setDateFrom: (value: DatePickerDate) => void
  dateTo?: DatePickerDate
  setDateTo: (value: DatePickerDate) => void
  valueInPercent: boolean
  setValueInPercent: (value: boolean) => void
  discountInPercent: boolean
  setDiscountInPercent: (value: boolean) => void
  isStandardDiscount: boolean
  setIsStandardDiscount: (value: boolean) => void
  count: number
  setCount: (value: number) => void
  discount: number
  setDiscount: (value: number) => void
}

const now = new Date()

export default function Discount({
  discountInPercent, setDateFrom, setDateTo, setDiscountInPercent, setValueInPercent,
  valueInPercent, dateFrom, dateTo, isStandardDiscount, setIsStandardDiscount,
  discount, setDiscount, setCount, count
}: Props) {


  const countValidator = (v: string) => {
    let _v = parseInt(v) || 0
    if (isNaN(_v) || _v < 0) { _v = 0 }
    if (valueInPercent) {
      if (_v > 100) _v = 100
    }
    return _v > 0 ? _v.toString() : ""
  }
  const handleChangeCount = (v: string) => {
    setCount(parseInt(v))
  }


  const discountValidator = (v: string) => {
    let _v = parseInt(v) || 0
    if (isNaN(_v) || _v < 0) { _v = 0 }
    if (discountInPercent) {
      if (_v > 90) _v = 90
      if (_v < 5) _v = 0
    }
    return _v > 0 ? _v.toString() : ""
  }
  const handleChangeDiscount = (v: string) => {
    setDiscount(parseInt(v))
  }


  const handleChangeDiscountType = (v: string) => {
    setIsStandardDiscount(v === DiscountType.standard)
  }
  const handleChangeDiscountInPercent = (v: string) => {
    const _dip = v === DiscountValueType.percent
    setDiscountInPercent(_dip)
    if (_dip) {
      if (discount > 90) { setDiscount(90) }
      if (discount < 5) { setDiscount(0) }
    }
  }

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    if (d.getTime() > now.getTime()) {
      setDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    let d1 = now
    if (dateTo) {
      d1 = datePickerDateToDate(dateTo)
    }

    if (d.getTime() > d1.getTime()) {
      setDateTo(date)
    }
  }
  const handleSetValueInPercent = (v: boolean) => {
    setValueInPercent(v)
    if (v && count > 100) {
      setCount(100)
    }
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.grid_item}>
        <h4>Опубликовать скидку</h4>
        <div className={styles.dates}>
          <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
          <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
        </div>
      </div>
      <div className={styles.grid_item}>
        <h4>Вид скидки</h4>
        <RadioButtons
          value={isStandardDiscount ? DiscountType.standard : DiscountType.twoPlusOne}
          onChange={handleChangeDiscountType}
          options={[{ label: "Стандартная скидка", value: DiscountType.standard }, { label: "Скидка 2 + 1", value: DiscountType.twoPlusOne }]}
        />
      </div>
      <div className={styles.grid_item}>
        <DebouncedInput
          debounceDelay={1000}
          preValidator={textAsIntValidator}
          validator={countValidator}
          value={count > 0 ? count.toString() : ""}
          postfix={count > 0 ? (valueInPercent ? "%" : "") : ""}
          onChange={handleChangeCount}
          required={true}
          placeholder="Введите значение..."
          textAbove="Количество товаров, учавствующих в скидке"
          textBelow='укажите 0, если скидка без ограничений по количеству'
          hint="Скидка закончится автоматически, как только весь товар будет распродан"
        />
        <Checkbox checked={valueInPercent} onChange={handleSetValueInPercent} label="Указывать количество в процентах" />
      </div>
      <div className={classNames(styles.grid_item, { [styles.disabled]: !isStandardDiscount })}>
        <DebouncedInput
          debounceDelay={1000}
          preValidator={textAsIntValidator}
          validator={discountValidator}
          disabled={!isStandardDiscount}
          value={(discount > 0) ? discount.toString() : ""}
          onChange={handleChangeDiscount}
          textAbove="Размер скидки"
          required={true}
          placeholder="Введите значение..."
          // className={classNames({ [styles.disabled]: !priceInPercent })}
          textBelow="не более 90%"
          postfix={discount > 0 ? (discountInPercent ? '%' : ' ₽') : ""} />
        <div>
          <RadioButtons
            disabled={!isStandardDiscount}
            value={discountInPercent ? DiscountValueType.percent : DiscountValueType.value}
            onChange={handleChangeDiscountInPercent}
            options={[{ label: "Скидка в рублях", value: DiscountValueType.value }, { label: "Скидка в процентах", value: DiscountValueType.percent }]}
          />
        </div>
      </div>
    </div>
  )
}
