import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ServerError } from "../../components/complexes/ModalServerError";
import { Endpoints } from "../../constants/server";
import { Categories, Promotions } from "../../types/swagger/llyApi";
import api from "../../utils/api";

export interface SellProductState {
  categories?: Categories[]
  openedCategories: number[]
  searchText: string
  gagText: string
  serverError?: ServerError
}

type FetchResponse = {
  success: boolean
  data: Categories[]
  errors?: ServerError
}

export const fetchCategories = createAsyncThunk<any, { text: string }, { state: RootState }>(
  "sellProduct/fetchCategories", async ({ text }, { getState, rejectWithValue, fulfillWithValue }) => {

    let req: string = Endpoints.GET_STATISTICS
    if (text) {
      req += `?search=${text}`
    }

    const resp = await api.get<FetchResponse>(req);

    if (!resp.data || !resp.data.success) {
      return rejectWithValue(resp.data.errors || "error");
    } else {
      return fulfillWithValue(resp.data.data);
    }
  }
);

export const initialState: SellProductState = {
  openedCategories: [],
  searchText: "",
  gagText: ""
};


export const sellProductSlice = createSlice({
  name: "sellProduct",
  initialState,
  reducers: {
    setOpenedCategories: (state, action) => {
      state.openedCategories = action.payload;
    },
    setCategories: (state, action) => {
      state.openedCategories = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setGagText: (state, action) => {
      state.gagText = action.payload;
    },
    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.gagText = "Загрузка...";
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      const data: Categories[] = action.payload
      state.categories = data
      if (data.length > 0) {
        state.gagText = "";
      } else {
        state.gagText = "Пусто";
      }
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.gagText = ""
      state.serverError = action.payload as ServerError
    });
  }
});


// TODO remove фикс, в сваггере не прописано поле
export interface MyCategories extends Omit<Categories, "category"> {
  children?: Categories[]
}
const findCategory = (categories: MyCategories[], id: number): Categories | undefined => {
  if (!categories) return
  for (let i = 0; i < categories.length; i++) {
    const c = categories[i];
    if (c.id === id) { return c }
    if (c.children && c.children.length > 0) {
      const cc = findCategory(c.children, id)
      if (cc) return cc
    }
  }
}

export const getSearchText = (state: RootState) => state.sellProduct.searchText

export const getCategories = () => (state: RootState) => state.sellProduct.categories;
export const getCategory = (id: number) => (state: RootState) => findCategory(state.sellProduct.categories as MyCategories[], id);
export const getOpenedCategories = () => (state: RootState) => state.sellProduct.openedCategories;
export const getGagText = () => (state: RootState) => state.sellProduct.gagText;
export const getServerError = () => (state: RootState) => state.sellProduct.serverError;

export const { setOpenedCategories, setCategories, setGagText, setServerError } = sellProductSlice.actions;

export default sellProductSlice.reducer;