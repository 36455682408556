import React from "react";
import styles from "./QuestionHint.module.scss";
import classNames from "classnames";

type Props = {
  hint: string;
  className?: string;
};

const QuestionHint = ({ hint, className }: Props) => {
  const classes = classNames(styles.question, className || "");
  return (
    <span className={classes} data-hover={hint} />
  );
};

export default QuestionHint;
