import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Routes} from "../../../../../constants/routes";
import useDebounce from "../../../../../hooks/lifecycle";
import {AuthService} from "../../../../../services/AuthService";
import CompanyService from "../../../../../services/CompaniesService";
import {RegistrationData} from "../../../../../types/auth";
import {PaymentInfo} from "../../../../../types/registration";
import {OrganisationSendData} from "../../../../../types/registration";
import {getOnlyNumbers} from "../../../../../utils/composeString";
import BicInfo from "../BicInfo/BicInfo";
import {LeftBicPart} from "../LeftBicPart";
import ModarateModal from "../ModarateModal/ModarateModal";

const initialPaymentInfo: PaymentInfo = {
  correspondentAccount: "",
  paymentAccount: "",
  name: "",
};

type TinData = {
  tin: string;
  withVat: boolean;
};

type LocationType = {
  organisationInfo: OrganisationSendData;
  phone: string;
  code: string;
  tinData: TinData;
};

const BicCard = () => {
  const [isError, setIsError] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(initialPaymentInfo);
  const [bic, setBic] = useState("");
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const debaunsedBic = useDebounce(bic, 500);

  const signup = async (paymentAccount: string) => {
    const {organisationInfo, tinData, phone, code}: LocationType =
      location.state;

    const registationData: RegistrationData = {
      name: organisationInfo.name,
      fullName: organisationInfo.fullName,
      director: organisationInfo.director || "",
      INN: tinData.tin,
      VAT_included: tinData.withVat ? 1 : 0,
      OGRN: organisationInfo.OGRN,
      BIK: bic,
      KPP: organisationInfo.KPP,
      accaunt: paymentAccount,
      phone: getOnlyNumbers(phone),
      code: code,
    };
    if (!isError) {
      const authData = await AuthService.signup(registationData);
      authData && setShowModal(true);
    }
  };

  const refreshData = async () => {
    const paymentInfo = await CompanyService.getPaymentInfoByBic(bic);
    paymentInfo && setPaymentInfo(paymentInfo);
    !paymentInfo && setPaymentInfo(initialPaymentInfo);
    setIsError(!paymentInfo);
  };

  const goToAuth = () => {
    setShowModal(false);
    navigate(Routes.AUTH);
  };

  useEffect(() => {
    setPaymentInfo(location.state.paymentInfo);
    setBic(location.state.bic);
  }, [location.state.bic, location.state.paymentInfo]);

  useEffect(() => {
    if (bic.length === 9) {
      debaunsedBic && refreshData();
    } else {
      setIsError(!!bic.length);
      setPaymentInfo(initialPaymentInfo);
    }
  }, [debaunsedBic]);

  return (
    <>
      <LeftBicPart isError={isError} bic={bic} setBic={setBic} />
      <BicInfo
        signup={signup}
        paymentInfo={paymentInfo}
        isBicError={isError || !bic.length}
      />
      <ModarateModal isShow={showModal} onClose={goToAuth} />
    </>
  );
};

export default BicCard;
