import React, {useState, useEffect} from 'react'
import Order from './parts/Order/Order'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'

import styles from './Packaging.module.scss'
import BoxEditor from './parts/BoxEditor/BoxEditor'
import Remainings from './parts/Remainings/Remainings'
import { BlueButton } from '../../../../../../simples/BlueButton'
import { nextStep } from '../../../../../../../store/slices/fbs'
import { format } from 'date-fns'

export default function Packaging() {

  const dispatch = useAppDispatch()
  const { request, remainings } = useAppSelector(state => state.fbs)
 

  let minDedline = request.orders[0].dedline
  for (const r of request.orders) {
    if (r.dedline < minDedline) { minDedline = r.dedline }
  }
  const d = new Date(minDedline)
  const dedline = {
    date: format(d, 'dd.MM.yyy'), // '20.04.2023',
    time: format(d, 'hh:mm')// '12:00'
  }

  let allItemsInBoxes = true
  let allBoxesHasWeight = true
  const { orders } = request
  for (const order of orders) {
    const itemsInBoxes: string[] = []
    for (let i = 0; i < order.boxes.length; i++) {
      const box = order.boxes[i];
      if (!box.weight || box.weight === 0) { allBoxesHasWeight = false }
      itemsInBoxes.push(...box.itemIds)
    }
    if (itemsInBoxes.length !== order.items.length) {
      allItemsInBoxes = false
    }
  }

  const handleNextStep = () => {
    dispatch(nextStep())
  }

  let isRemainingsCompleted = true
  for (const r of remainings) {
    let shipmentCount = 0
    for (const w of r.warehouses) {
      shipmentCount += w.shipment || 0
    }
    if (shipmentCount !== r.count) { isRemainingsCompleted = false }
  }


  const isBtnDisabled = !allItemsInBoxes || !isRemainingsCompleted || !allBoxesHasWeight

  

  return (
    <div className={styles.packaging}>
      <div className={styles.head}>
        <div className={styles.dedline}><span>Отправить не позднее</span> <span className={styles.value}>{dedline.date}</span> <span className={styles.value}>{dedline.time}</span></div>
        {/* {costOfDelivery && <div className={styles.cost_of_delivery}>
          <div>Стоимость доставки</div>
          <div className={styles.cost}>{costOfDelivery}</div>
        </div>} */}
      </div>
      <div className={styles.orders}>
        <BoxEditor />
        {request.orders.map(o => <Order key={o.id} order={o} />)}
      </div>
      <Remainings />
      <div className={styles.controls}>
        <BlueButton title='Далее' disabled={isBtnDisabled} onClick={handleNextStep} />
      </div>
    </div>
  )
}
