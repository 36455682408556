import { Endpoints } from "../constants/server";
import { ProductFormType } from "../store/slices/productEdit";
import { BarcodesResponseType, OtherFieldsSelect } from "../types/product";
import api from "../utils/api";

type CreateResponseType = {
  data: ProductFormType;
  status: number;
  success: boolean;
};

const categiriesApi = "categories";
const parentCategoryApi = "categories/parent";
const colorsApi = "colors";
const seasonsApi = "seasons";
const tagsApi = "tags";
const packageTypesApi = "categories/packages-types";
const materialsApi = "categories/materials";
const detailsApi = "categories/details";
const countryApi = "countries";
const sizesApi = "categories/sizes";
const fileApi = "files/create";
const productsCreateApi = "products/create";
const productsCompoundApi = "categories/compounds";
const productUpdateApi = "products/update/";
const sendModerationApi = "/products/for-moderation/";

class ProductEditService {
  static async getCategories(id?: number) {
    const { data } = id
      ? await api.get(categiriesApi + `?id=${id}`)
      : await api.get(categiriesApi);
    return data.data;
  }

  static async getParentCategory(id: number) {
    const { data } = await api.get(parentCategoryApi + `?id=${id}`);
    return data;
  }

  static async getPackagesTypes() {
    const { data } = await api.get(packageTypesApi);
    return data;
  }

  static async getColors() {
    const { data } = await api.get(colorsApi);
    return data;
  }

  static async getSeasons() {
    const { data } = await api.get(seasonsApi);
    return data;
  }

  static async getTags() {
    const { data } = await api.get(tagsApi);
    return data;
  }

  static async getSearchTags(value: string) {
    const { data } = await api.get(tagsApi + `?name=${value}`);
    return data;
  }

  static async getMaterials(id: number) {
    const { data } = await api.get(materialsApi + `?id=${id}`);
    return data;
  }

  static async getDetailsById(id: number) {
    const { data } = await api.get(detailsApi + `?id=${id}`);
    return data;
  }

  static async getCountry() {
    const { data } = await api.get(countryApi);
    return data;
  }

  static async getSizes(id: number) {
    const { data } = await api.get(sizesApi + `?id=${id}`);
    return data;
  }

  static async sendFile(file: File) {
    const formdata = new FormData();
    formdata.append("file", file);
    const { data } = await api.post(fileApi, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }

  static async getCompoundsList(id: number) {
    const { data } = await api.get(productsCompoundApi + `?id=${id}`);
    return data;
  }

  static async sendFormData(form: any) {
    try {
      const data = await api.post<CreateResponseType>(productsCreateApi, form);

      return data;
    } catch (error) {
      throw new Error("Send Failed");
    }
  }

  static async updateProduct(
    payload: any,
    id: number,
    otherFields?: OtherFieldsSelect[],
  ) {
    let formState = payload;

    if (otherFields) {
      const correctOtherFields = otherFields.map(o => ({
        compound_id: o.compound_id,
        material_id: o.material_id,
        value: o.value,
      }));

      // const correctDeteals =
      //   formState.productsDetails.filter(d => d.option_id > 0) || [];
      formState = {
        ...formState,
        productsCompound: [
          ...formState.productsCompound,
          ...correctOtherFields,
        ],
      };
    }
    try {
      const { data } = await api.post<CreateResponseType>(
        productUpdateApi + id,
        formState,
      );

      return data;
    } catch (error) {
      throw new Error("Send Failed");
    }
  }

  static async getAllProducts(status: number) {
    const { data } = await api.get(
      `${Endpoints.GET_PRODUCTS}?status_id=${status}`,
    );
    return data;
  }

  static async generateBarCodes({ product_id, color_id, sizes }: { product_id: number, color_id: number, sizes: { size_id: number }[] }) {
    try {
      const data = {
        product_id,
        color_id,
        sizes
      }
      const resp = await api.post<BarcodesResponseType>(`${Endpoints.CREATE_BARCODES}`, data);

      return resp;
    } catch (error) {
      throw new Error("Generate Barcodes Failed");
    }
  }

  static async sendModeration(id: number) {
    const { data } = await api.post<CreateResponseType>(sendModerationApi + id);
    return data;
  }
}

export default ProductEditService;
