const RouteBase = '/what_to_sell/'

export enum SellProductTabId {
  categories = 'categories',
  tags = 'tags',
  products = 'products'
}

type TabConfig = {
  id: SellProductTabId
  label: string
  route: string
}

type ISellProductTabConfig = {
  [key in SellProductTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: SellProductTabId) {
  return routeBase + tabId
}

export const SellProductTabConfig: ISellProductTabConfig = {
  [SellProductTabId.categories]: {
    id: SellProductTabId.categories,
    label: 'Категории товаров',
    route: buildTabRoute(RouteBase, SellProductTabId.categories)
  },
  [SellProductTabId.tags]: {
    id: SellProductTabId.tags,
    label: 'Популярные теги',
    route: buildTabRoute(RouteBase, SellProductTabId.tags)
  },
  [SellProductTabId.products]: {
    id: SellProductTabId.products,
    label: 'Статистика по товару',
    route: buildTabRoute(RouteBase, SellProductTabId.products)
  },
}