import React, { useImperativeHandle } from "react";
import styles from "./VariantPhotos.module.scss";
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../../../../hooks/store";
import classNames from "classnames";


type DropzoneHandle = {
  dropzoneContent?: JSX.Element
  open_dialog: () => void,
  formats: string[]
  onDrop: (file: File[]) => void
  multiple?: boolean
  className?: string
  isEmpty: boolean
}

const MIMEs = {
  'png': 'image/png',
  'jpg': 'image/jpg'
}

const CustonDropzone = ({ dropzoneContent, formats, onDrop, multiple, className, isEmpty }, ref: React.Ref<DropzoneHandle>) => {

  const dispatch = useAppDispatch();
  // const uploadingError = useAppSelector(getUploadingError());

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   if (acceptedFiles.length === 1) {
  //     dispatch(uploadArch({ formDataFile: acceptedFiles[0] }));
  //   }
  // }, []);

  const accept: { [key: string]: string[] } = {}
  formats.forEach(f => {
    if (MIMEs[f]) {
      accept[MIMEs[f]] = [`.${f}`]  // "image/png": [".png"]
    }
  });

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } = useDropzone({
    onDrop,
    multiple,
    // accept: { "application/zip": [".zip"], "application/vnd.rar": [".rar"], "application/x-7z-compressed": [".7z"] },
    accept,
    maxSize: 5 * 1024 ** 2
  });


  useImperativeHandle(ref, () => ({
    open_dialog() {
      open()
    },
    dropzoneContent,
    formats,
    onDrop,
    multiple,
    className,
    isEmpty
  }));


  return (
    <div  {...getRootProps()} className={
      classNames(
        styles.photo_dropzone,
        className,
        isEmpty ? styles.is_empty : styles.is_not_empty
      )}
    >
      <input {...getInputProps()} />
      {isEmpty
        ? dropzoneContent
        : <>
          <div className={styles.upload_text}>Загрузите, либо перетащите файл, <br />чтобы добавить фото</div>
          <div className={styles.upload_info}>Размер изображения не должен превышать 5Mb</div>
        </>}
      {/* {uploadingError && <span className={styles.error}>Ошибка: {uploadingError}</span>} */}
    </div >
  );
};

// export default Upload;
export default React.forwardRef(CustonDropzone);
