import React from "react";
import styles from "./Layout.module.scss";
import MainSidebar from "./parts/MainSidebar/MainSidebar";
import Header from "./parts/Header/Header";
import classNames from "classnames";


type Props = {
  isDisableHeader?: boolean
  children?: any
  title: string
  hint?: JSX.Element | string
  hintWidth?: number
  onBack?: () => void
  v2?: boolean // TODO допилить старые интерфейсы и перейти на этот вариант по умолчанию
  minWidth?: number
  maxWidth?: number
}

const Layout = ({ isDisableHeader, children, title, hint, hintWidth, onBack, v2, maxWidth, minWidth }: Props) => {
  const style: React.CSSProperties | undefined = {}
  if (maxWidth) { style.maxWidth = maxWidth + 'px' }
  if (minWidth) { style.minWidth = minWidth + 'px' }
  return (
    <main className={classNames(styles.layout, { [styles.v2]: v2 })} style={style}>
      <div className={styles.contentLayoutGrid}>
        <MainSidebar />
        <div className={styles.content}>
          {!isDisableHeader && <Header title={title || ''} hint={hint} hintWidth={hintWidth} onBack={onBack} />}
          {children}
        </div>
      </div>
      {/* todo: Продумать стили для footer */}
      {/*<footer className={styles.mainFooter}>Footer</footer>*/}
    </main>
  );
};

export default Layout;
