import React from 'react'
import { IItem, addItemTo1stBox } from '../../../../../../../../../store/slices/fbs'
import styles from './Order.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store'
import classNames from 'classnames'
import { IconBtn, IconType } from '../../../../../../../../simples/IconBtn'

type Props = {
  item: IItem
  orderId: string
  className?: string
}

export default function Item({ item, className, orderId}: Props) {
  const { activeItem } = useAppSelector(state => state.fbs)
  const dispatch = useAppDispatch()
  const handleMove2Box = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(addItemTo1stBox({itemId: item.id, orderId}))
  }
  return (
    <div className={classNames(
      styles.item,
      { [styles.active]: item.id === activeItem?.id },
      className
    )}>
      <div className={styles.item_body}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.code}>{item.code}</div>
      </div>
      <IconBtn icon={IconType.BoxArrow} onMouseDown={handleMove2Box} className={styles.arrow_btn} />
    </div>
  )
}
