/** composing string to only numbers */
export const getOnlyNumbers = (str: string): string => {
  return str.match(/\d/g)?.join("") || '';
};

/**
 * @param {string} time - string with format zz:yy:xx;
 * @return {string} string with format yy:xx;
 */
export const getWithoutHoursTime = (time:string):string => {
  return time.split(':').splice(1, 2).join(':');
}

// Delete "/" in end
export const trimTrailingSlash = (str:string) => {
  return str.replace(/\/+$/, '');
}