import React from 'react'

import styles from './CustomDropzome.module.scss'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

type Props = {
  title: string
  className?: string
  maxSize?: number
  onDrop?: (data: File[]) => void
  multiple?: boolean
}

const DefaultAccept = { "image/png": [".png"], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] }
const DefaultMaxSize = 32 * 1024 ** 2

export default function CustomDropzome({ title, className, onDrop, maxSize, multiple }: Props) {
  return (
    <Dropzone onDrop={onDrop} multiple={multiple} maxFiles={5} accept={DefaultAccept} maxSize={maxSize || DefaultMaxSize} >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <div className={classNames(styles.dropzone, className)}>
            <div className={styles.file}>
              {title}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  )
}
