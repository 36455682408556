import React from 'react'

import styles from './LawInfo.module.scss'
import { InfoPanel } from '../../../../simples/InfoPanel'

export default function Info() {
  return (
    <div className={styles.info}>
      <InfoPanel title='ФИО'>ФИО должны совпадать с ФИО генерального директора или индивидуального предпринимателя</InfoPanel>
      <InfoPanel title='Будьте внимательны!'>Данные будут использоваться для формирования договора</InfoPanel>
    </div>
  )
}
