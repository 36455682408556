import React from "react";
import HintTexts from "../../../../../constants/hints";
import {LightInput} from "../../../../simples/LightInput";
import {QuestionHint} from "../../../../simples/QuestionHint";
import styles from "./BicTextInput.module.scss";

type Props = {
  setBic: (bic: string) => void;
  className?: string;
  bic?: string;
};

const BicTextInput = ({className, setBic, bic}: Props) => {
  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setBic(value);
  };

  return (
    <div className={className}>
      <span className={styles.headerContainer}>
        <h3 className={styles.header}>БИК</h3>
        <QuestionHint hint={HintTexts.BIC} />
      </span>
      <LightInput
        className={styles.input}
        mask={"999999999"}
        onChange={onInput}
        value={bic}
      />
      <p className={styles.recomandation}>Введите БИК банка</p>
    </div>
  );
};

export default BicTextInput;
