import React from 'react'
import { CustomHint } from './CustomHint'
import { CustomHintDirection } from './CustomHint/CustomHint'

type Props = {
  className?: string
  direction?: CustomHintDirection
}

export default function ShopLvlHint({ className, direction }: Props) {
  return (
    <CustomHint
      className={className}
      direction={direction}
      hint={<div>Уровень магазина:<br /><br />
        0 - Новичок - 15%;<br /><br />
        1 - Начинающий - 13%;<br /><br />
        2 - Любитель - 11%;<br /><br />
        3 - Специалист 9%;<br /><br />
        4 - Пофессионал- 7%;<br /><br />
        5 - Мастер - 5%</div>}
      hintWidth={300} />
  )
}
