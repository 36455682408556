import React, { useState } from 'react'
import { Endpoints } from '../../../constants/server'
import { GoodsAnswer, ListDataType, ListHeadData, ListProductDataType } from '../../../types/productListTypes'
import { textAsIntValidator } from '../../../utils/validators'
import { DebouncedInput } from '../../complexes/DebouncedInput'
import { Layout } from '../../complexes/Layout'
import { AvailableProductData, IDiscountConfig, ProductList } from '../../complexes/ProductList'
import { BlueButton, ButtonSize, ButtonType } from '../../simples/BlueButton'
import { Checkbox } from '../../simples/Checkbox'
import styles from "./JoinInPromotion.module.scss"
import { Action } from './parts/Action'
import api from "../../../utils/api";
import { ModalProductSearch } from '../../complexes/ModalProductSearch'
import { ModalDialog } from '../../simples/ModalDialog'
import ModalNotification from './parts/ModalNotification/ModalNotification'
import { DiscountBanner } from '../../simples/DiscountBanner'
import { BannerConfig } from '../../../types/discount'
import { DefaultBannerConfig } from '../../../constants/discount'
import { ModalServerError, ServerError } from '../../complexes/ModalServerError'
import { useAppSelector } from '../../../hooks/store'

import banner21_icon from "../../../assets/icons/banner21.svg";
import plus_icon from "../../../assets/icons/plus.svg";
import { IHint } from '../../simples/IHint'
import { ProductsDTO } from '../../../types/swagger/llyApi_new'
import { DefaultHeaders } from '../../complexes/ProductList/DefaultHeaders'
import { AditionlParameter } from '../../complexes/ModalProductSearch/ModalProductSearch'

type CreateDiscountResponse = {
  data?: ListProductDataType[]
  status: number
  success: boolean
  errors?: any
}

const headers: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,
  DefaultHeaders.retail_price,
  DefaultHeaders.discountValue,
  DefaultHeaders.discountPercent,
  DefaultHeaders.priceWithDiscount,
  DefaultHeaders.stockBalance,
  DefaultHeaders.discountValue,
  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.size,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
  //   {
  //   name: AvailableProductData.photo,
  //   text: "Фото",
  //   type: ListDataType.image,
  //   minWidth: "60px",
  //   width: "60px"
  // },
  // {
  //   name: AvailableProductData.id,
  //   text: "Артикул",
  //   minWidth: "100px",
  //   width: "100px"
  // },
  // {
  //   name: AvailableProductData.name,
  //   text: "Наименование",
  //   sortable: true,
  //   minWidth: "140px",
  //   width: "140px"
  // }, 
  // {
  //   name: AvailableProductData.retail_price,
  //   text: "Цена",
  //   sortable: true,
  //   minWidth: "95px",
  //   width: "95px"
  // }, {
  //   name: AvailableProductData.discountValue,
  //   text: "Актуальная скидка",
  //   sortable: true,
  //   minWidth: "140px",
  //   width: "140px"
  // }, {
  //   name: AvailableProductData.discountPercent,
  //   text: "Скидка %",
  //   sortable: true,
  //   minWidth: "80px",
  //   width: "80px"
  // }, {
  //   name: AvailableProductData.priceWithDiscount,
  //   text: "Итоговая стоимость",
  //   sortable: true,
  //   minWidth: "140px",
  //   width: "140px"
  // }, {
  //   name: AvailableProductData.stockBalance,
  //   text: "Остаток на складе",
  //   sortable: true,
  //   minWidth: "135px",
  //   width: "135px"
  // },
  // {
  //   name: AvailableProductData.discountItemsCount,
  //   text: "Участвуют в скидке",
  //   sortable: true,
  //   minWidth: "150px",
  //   width: "150px"
  // }, {
  //   name: AvailableProductData.brand,
  //   text: "Бренд",
  //   sortable: true,
  //   minWidth: "85px",
  //   width: "85px"
  // }, {
  //   name: AvailableProductData.sex,
  //   text: "Пол",
  //   sortable: true,
  //   minWidth: "55px",
  //   width: "55px"
  // }, {
  //   name: AvailableProductData.category,
  //   text: "Категория",
  //   sortable: true,
  //   minWidth: "110px",
  //   width: "110px"
  // }, {
  //   name: AvailableProductData.subcategory,
  //   text: "Подкатегория",
  //   sortable: true,
  //   minWidth: "130px",
  //   width: "130px"
  // }, {
  //   name: AvailableProductData.size,
  //   text: "Размер",
  //   minWidth: "75px",
  //   width: "75px"
  // }, {
  //   name: AvailableProductData.season,
  //   text: "Сезон",
  //   sortable: true,
  //   minWidth: "75px",
  //   width: "75px"
  // }, {
  //   name: AvailableProductData.barcode,
  //   text: "Штрихкод",
  //   minWidth: "110px",
  //   width: "110px"
  // }, {
  //   name: AvailableProductData.dateOfCreation,
  //   text: "Дата создания",
  //   minWidth: "110px",
  //   width: "110px"
  // }
];
const headers21: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,
  DefaultHeaders.retail_price,
  DefaultHeaders.discountValue,
  DefaultHeaders.stockBalance,
  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.size,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
  //   {
  //   name: AvailableProductData.photo,
  //   text: "Фото",
  //   type: ListDataType.image,
  //   minWidth: "60px",
  //   width: "80px"
  // },
  //  {
  //   name: AvailableProductData.id,
  //   text: "Артикул",
  //   minWidth: "100px",
  //   width: "120px"
  // },
  // {
  //   name: AvailableProductData.name,
  //   text: "Наименование",
  //   sortable: true,
  //   minWidth: "140px",
  //   width: "160px"
  // }, 
  //  {
  //   name: AvailableProductData.retail_price,
  //   text: "Цена",
  //   sortable: true,
  //   minWidth: "95px",
  //   width: "115px"
  // }, {
  //   name: AvailableProductData.discountValue,
  //   text: "Актуальная скидка",
  //   sortable: true,
  //   minWidth: "140px",
  //   width: "160px"
  // }, {
  //   name: AvailableProductData.stockBalance,
  //   text: "Остаток на складе",
  //   sortable: true,
  //   minWidth: "135px",
  //   width: "155px"
  // }, 
  //  {
  //   name: AvailableProductData.brand,
  //   text: "Бренд",
  //   sortable: true,
  //   minWidth: "85px",
  //   width: "105px"
  // }, {
  //   name: AvailableProductData.sex,
  //   text: "Пол",
  //   sortable: true,
  //   minWidth: "55px",
  //   width: "75px"
  // }, {
  //   name: AvailableProductData.category,
  //   text: "Категория",
  //   sortable: true,
  //   minWidth: "110px",
  //   width: "130px"
  // }, {
  //   name: AvailableProductData.subcategory,
  //   text: "Подкатегория",
  //   sortable: true,
  //   minWidth: "130px",
  //   width: "150px"
  // }, {
  //   name: AvailableProductData.size,
  //   text: "Размер",
  //   minWidth: "75px",
  //   width: "95px"
  // }, {
  //   name: AvailableProductData.season,
  //   text: "Сезон",
  //   sortable: true,
  //   minWidth: "75px",
  //   width: "95px"
  // },
  // {
  //   name: AvailableProductData.barcode,
  //   text: "Штрихкод",
  //   minWidth: "110px",
  //   width: "130px"
  // },
  // {
  //   name: AvailableProductData.dateOfCreation,
  //   text: "Дата создания",
  //   minWidth: "110px",
  //   width: "130px"
  // }
];






const default_banner_JSON = JSON.stringify(DefaultBannerConfig)

export default function JoinInPromotion() {

  const action = useAppSelector(state => state.joinInPromotion.promotion);

  const [productsSearchMode, setProductsSearchrMode] = useState<boolean>(action?.isStandardDiscount || false);
  const [count, setCount] = useState("100")
  const [valueInPercent, setValueInPercent] = useState(false)
  const [products, setProducts] = useState<ProductsDTO[]>([])
  const [showCancelDiscountDialog, setShowCancelDiscountDialog] = useState(false)
  const [notificationText, setNotificationText] = useState("")
  const [serverError, setServerError] = useState<ServerError>()

  const [products21_1, setProducts21_1] = useState<ProductsDTO[]>([]);
  const [products21_2, setProducts21_2] = useState<ProductsDTO[]>([]);
  const [products21_3, setProducts21_3] = useState<ProductsDTO[]>([]);

  const [products21SearchMode, setProducts21SearchrMode] = useState(action?.isStandardDiscount ? 0 : 1); // 0 - off, 1 - products21_1, 2 - products21_2, 3 - products21_3


  if (!action) return <div>Error!</div>

  const loadProducts = async (ids: string[]) => {
    let data: ProductsDTO[] = []
    if (ids.length > 0) {
      const req = `${Endpoints.GET_SELECTED_PRODUCTS}`;
      await api
        .post<GoodsAnswer>(req, { products: ids })
        .then((res) => {
          data = res.data?.data || [];
        })
        .catch((err) => console.log(err));
    }
    return data
  }

  const reloadProducts = async () => {
    if (action.isStandardDiscount) {
      setProducts(await loadProducts(products.map(p => p.id)));
    } else {
      setProducts21_1(await loadProducts(products21_1.map(p => p.id)))
      setProducts21_2(await loadProducts(products21_2.map(p => p.id)))
      setProducts21_3(await loadProducts(products21_3.map(p => p.id)))
    }
  }

  const countValidator = (v: string) => {
    let _v = parseInt(v) || 0
    if (isNaN(_v) || _v < 0) { _v = 0 }
    if (valueInPercent) {
      if (_v > 100) _v = 100
    }
    return _v.toString()
  }

  const getProductsWithDiscount = () => {
    if (action.isStandardDiscount) {
      return products.filter(p => p.hasDiscount).map(p => p.id)
    } else {
      const productsWithDiscount1 = products21_1.filter(p => p.hasDiscount).map(p => p.id)
      const productsWithDiscount2 = products21_2.filter(p => p.hasDiscount).map(p => p.id)
      const productsWithDiscount3 = products21_3.filter(p => p.hasDiscount).map(p => p.id)

      return [
        ...productsWithDiscount1,
        ...productsWithDiscount2,
        ...productsWithDiscount3
      ]
    }
  }

  const discount: IDiscountConfig = { count: parseInt(count), discountInPercent: true, discount: action.percent || 0, valueInPercent }

  let productsWithDiscount: string[] = getProductsWithDiscount()

  let itemsSelector
  if (action.isStandardDiscount) {

    const handleRemoveItemsFromList = (ids: string[]) => {
      setProducts(products.filter(p => ids.indexOf(p.id) === -1))
    }
    const handleSelectProducts = () => {
      setProductsSearchrMode(true);
    }
    productsWithDiscount = products.filter(p => p.hasDiscount).map(p => p.id)

    let linkText = "товаров"
    let _v = products.length
    if (_v > 15) _v %= 10
    if (_v === 1) { linkText = "товар" }
    if (_v > 1 && _v < 5) { linkText = "товара" }
    linkText = `${products.length} ${linkText}`

    itemsSelector = <>
      <div className={styles.buttons}>
        <BlueButton title='Выберите карточки товаров для применения скидки' onClick={handleSelectProducts} />
      </div>
      <div className={styles.items_count_info}>
        <span>Всего участвует в скидке </span><span onClick={handleSelectProducts} className={styles.link}>{linkText}</span>
        {/* <span className={styles.count_info}>Всего участвует в скидке <span className={styles.link} onClick={handleSelectProducts}>{linkText}</span></span> */}
      </div>
      <div className={styles.list_wrap}>
        <ProductList
          data={products} headers={headers} errors={productsWithDiscount}
          discount={discount}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList}
        />
      </div>
    </>
  } else {

    const handleRemoveItemsFromList1 = (ids: string[]) => {
      setProducts21_1(products21_1.filter(p => ids.indexOf(p.id) === -1))
    }
    const handleRemoveItemsFromList2 = (ids: string[]) => {
      setProducts21_2(products21_2.filter(p => ids.indexOf(p.id) === -1))
    }
    const handleRemoveItemsFromList3 = (ids: string[]) => {
      setProducts21_3(products21_3.filter(p => ids.indexOf(p.id) === -1))
    }

    const handleSelectProducts21_1 = () => {
      setProducts21SearchrMode(1);
    }
    const handleSelectProducts21_2 = () => {
      setProducts21SearchrMode(2);
    }
    const handleSelectProducts21_3 = () => {
      setProducts21SearchrMode(3);
    }



    // const handleCreateDisount21 = () => {
    //   const d = {
    //     "count": count,
    //     "countInPercent": valueInPercent,
    //     "productsIds0": products21_1.map(p => p.id),
    //     "productsIds1": products21_2.map(p => p.id),
    //     "productsIds2": products21_3.map(p => p.id),
    //   }

    //   api.post<CreateDiscountResponse>(Endpoints.CREATE_DISCOUNT, d)
    //     .then((res) => {
    //       if (res.data.data) {
    //         setProducts(res.data.data);
    //         setNotificationText("Скидка успешно создана.")
    //       } else {
    //         // setNotificationText(JSON.stringify(res.data.errors))
    //         setServerError(res?.data?.errors)
    //       }
    //     })
    //     .catch((err) => {
    //       if (err?.response?.data?.errors) {
    //         setServerError(err?.response?.data?.errors)
    //       } else {
    //         setNotificationText("Ошибка на стороне сервера")
    //       }
    //     })
    // }
    // const handleShowCancelDisountDialog = () => {
    //   setShowCancelDiscountDialog(true)
    // }

    const handleCloseSearch21 = async (values: string[]) => {
      setProducts21SearchrMode(0);
      const data = await loadProducts(values)
      switch (products21SearchMode) {
        case 1:
          setProducts21_1(data)
          break;
        case 2:
          setProducts21_2(data)
          break;
        case 3:
          setProducts21_3(data)
          break;
        default:
          break;
      }
    }

    // const productsWithDiscount1 = products21_1.filter(p => p.hasDiscount).map(p => p.id)
    // const productsWithDiscount2 = products21_2.filter(p => p.hasDiscount).map(p => p.id)
    // const productsWithDiscount3 = products21_3.filter(p => p.hasDiscount).map(p => p.id)

    // productsWithDiscount = [
    //   ...productsWithDiscount1,
    //   ...productsWithDiscount2,
    //   ...productsWithDiscount3
    // ]

    itemsSelector = <>
      <ModalProductSearch onClose={handleCloseSearch21} isVisible={products21SearchMode > 0} />
      <div className={styles.banner21_info}>
        <div className={styles.banner21_hint}>
          <IHint className={styles.hint21} hint='Добавьте карточки в разделы: Товар1 , Товар2 и Товар3 (бесплатный) согласно акции 2+1' />
        </div>
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 1</span><img src={plus_icon} onClick={handleSelectProducts21_1} />
        </div>
        <ProductList
          data={products21_1} headers={headers21} errors={productsWithDiscount}
          discount={discount}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList1}
        />
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 2</span><img src={plus_icon} onClick={handleSelectProducts21_2} />
        </div>
        <ProductList
          data={products21_2} headers={headers21} errors={productsWithDiscount}
          discount={discount}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList2}
        />
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 3 (бесплатный)</span><img src={plus_icon} onClick={handleSelectProducts21_3} />
        </div>
        <ProductList
          data={products21_3} headers={headers21} errors={productsWithDiscount}
          discount={discount}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList3}
        />
      </div>
    </>
  }

  const handleCloseSearch = async (values: string[]) => {
    setProductsSearchrMode(false);
    const data = await loadProducts(values)
    setProducts(data);
  }



  const handleShowCancelDisountDialog = () => {
    setShowCancelDiscountDialog(true)
  }
  const handleCloseCancelDisountDialog = () => {
    setShowCancelDiscountDialog(false)
  }
  const handleRemoveDisount = () => {
    setShowCancelDiscountDialog(false)
    const ids = getProductsWithDiscount()
    api.post<CreateDiscountResponse>(Endpoints.REMOVE_DISCOUNT, { ids })
      .then(async (res) => {
        setNotificationText(res?.data?.data?.toString() || "")
        // const data = await loadProducts(ids)
        // setProducts(data);
        await reloadProducts()
      })
      .catch((err) => {
        const errorText = err?.response?.data?.errors || err.toString()
        setNotificationText(JSON.stringify(errorText))
      })
  }
  const handleCloseModalNotification = () => {
    setNotificationText("")
  }

  const handleSetValueInPercent = (v: boolean) => {
    setValueInPercent(v)
    const c = parseInt(count)
    if (v && c > 100) {
      setCount("100")
    }
  }
  const bannerConfig: BannerConfig = JSON.parse(action.banner_json || default_banner_JSON)

  const handleCreateDisount = () => {



    const d: any = {
      "promotion_id": action.id,
      "count": count,
      "countInPercent": valueInPercent,
      productsIds0: [],
      productsIds1: [],
      productsIds2: [],
    }

    if (action.isStandardDiscount) {
      d.productsIds0 = products.map(p => p.id)
    } else {
      d.productsIds0 = products21_1.map(p => p.id)
      d.productsIds1 = products21_2.map(p => p.id)
      d.productsIds2 = products21_3.map(p => p.id)
    }

    api.post<CreateDiscountResponse>(Endpoints.CREATE_DISCOUNT, d)
      .then(async (res) => {
        if (res.data.data) {
          // setProducts(res.data.data);
          setNotificationText("Скидка успешно создана.")
          await reloadProducts()
        } else {
          setServerError(res.data.errors)
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          setServerError(err?.response?.data?.errors)
        } else {
          setNotificationText("Ошибка на стороне сервера")
        }
      })
  }

  const handleClearServerError = () => {
    setServerError(undefined)
  }


  const aditionlParameters: AditionlParameter[] = []
  if (action.id !== undefined) {
    aditionlParameters.push({ key: 'promotion_id', value: action.id })
  }


  return (
    <Layout title='Цены и скидки'>
      <ModalServerError onClose={handleClearServerError} error={serverError} />
      <ModalNotification text={notificationText} onClose={handleCloseModalNotification} />
      <ModalProductSearch onClose={handleCloseSearch} isVisible={productsSearchMode} aditionlParameters={aditionlParameters} />
      <ModalDialog isActive={showCancelDiscountDialog}
        text="Отменить скидки на товары в таблице?"
        buttonsConfig={[{
          text: "Да",
          type: ButtonType.Yes,
          onClick: handleRemoveDisount,
          size: ButtonSize.Big
        }, {
          text: "Нет",
          type: ButtonType.Cancel,
          onClick: handleCloseCancelDisountDialog,
          size: ButtonSize.Big
        },]}
        handleClose={handleCloseCancelDisountDialog} />
      <div className={styles.wrap}>
        <Action action={action} />
        <div className={styles.controls}>
          <div className={styles.count}>
            <DebouncedInput
              debounceDelay={1000}
              preValidator={textAsIntValidator}
              validator={countValidator}
              value={count}
              postfix={valueInPercent ? "%" : ""}
              onChange={setCount}
              required={true}
              textAbove="Количество товаров, учавствующих в скидке"
              textBelow="укажите 0, если скидка без ограничений по количеству"
              hint='Скидка закончится автоматически, как только общая сумма товаров будет распродана'
            />
            <Checkbox checked={valueInPercent} onChange={handleSetValueInPercent} label="Указывать количество в процентах" />
          </div>
          <div className={styles.banner}>
            <div className={styles.banner_preview}>
              {action.isStandardDiscount ? <DiscountBanner config={bannerConfig} discountValue={action.percent || "0"} /> : <img src={banner21_icon} alt="" />}
            </div>
            <span className={styles.hint}>На товары будет установлен скидочный баннер</span>
          </div>
        </div>
        {itemsSelector}
        <div className={styles.footer}>
          <BlueButton disabled={productsWithDiscount.length === 0} onClick={handleShowCancelDisountDialog} title='Отменить скидки на все карточки' />
          <BlueButton onClick={handleCreateDisount} title='Сохранить' />
        </div>
      </div>
    </Layout>
  )
}
