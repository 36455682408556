import React, { useEffect, useRef, useState } from 'react'
import styles from './Map.module.scss'
import classNames from 'classnames';

// import deliverypoints_raw from './offices.json'
import DPInfo from './DPInfo';
import o from './assets/o.svg'
import { useAppDispatch } from '../../../../../../../../../hooks/store';
import { TextInput } from '../../../../../../../../simples/TextInput';
import { BlueButton } from '../../../../../../../../simples/BlueButton';
import api from '../../../../../../../../../utils/api'
import { CdekOffice } from '../../../../../../../../../types/swagger/llyApi_new';

// import api from '../../../../../utils/api'

// const deliverypoints = offices.data.response

type CdekOfficesResp = {
  data: {
    total?: number
    response?: CdekOffice[]
  }
}

// export type DeliveryPoint = {
//   code: string
//   name: string
//   work_time: string
//   take_only: boolean
//   note: string
//   location: {
//     address: string
//     address_full: string
//     longitude: number
//     latitude: number
//     location_id: number
//   }
//   work_time_list: { "day": number, "time": string }[]
// }

// const deliverypoints: DeliveryPoint[] = (deliverypoints_raw as DeliveryPoint[]).filter(p => !p.take_only)
// TODO fetch DPs /cdek/offices
// useEffect(() => {
//   api.get<CdekOfficesResp>('/cdek/offices').then(data => {
//     const points: DeliveryPoint[] = data.data.response?.map(o => ({
//       code: o.code
//       name: o.work_time_list,
//       work_time: o.work_time,
//       take_only: o.take_only,
//       note: o.note,
//       location: {
//         address: o.location.address,
//         address_full: o.location.address_full,
//         longitude: o.location.longitude,
//         latitude: o.location.latitude,
//         location_id: o.location_id
//       }
//       work_time_list: { "day": number, "time": string }[]
//     }))
//   })
// }, [])


type Props = {
  isActive?: boolean
  onClose: (selectedDP?: CdekOffice) => void
}

export default function Maps({ isActive, onClose }: Props) {

  // const dispatch = useAppDispatch()

  const [offices, setOffices] = useState<CdekOffice[]>([])
  const [isLoadingOffices, setIsLoadingOffices] = useState(false)
  // const [message, setMessage] = useState('')
  // const [deliverypoints, setDeliverypoints] = useState<DeliveryPoint[]>([])
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const [selectedDP, setSelectedDP] = useState<CdekOffice>()
  const [search, setSearch] = useState('')
  const [points, setPoints] = useState<CdekOffice[]>([])
  const [visiblePoints, setvisiblePoints] = useState<string[]>([])

  const updatePoints = (newPoints: CdekOffice[]) => {
    // console.log(newPoints);

    setPoints(newPoints)
    setvisiblePoints(newPoints.map(p => p.code))
    sendMsg(newPoints, 'setDeliverypoints')
  }

  useEffect(() => {
    setOffices([])
    setIsLoadingOffices(true)
    api.get<CdekOfficesResp>("/cdek/offices").then(resp => {
      const list = resp.data.data.response || []
      setOffices(list)
      // setPoints(list)
      // sendMsg(list, 'setDeliverypoints')
      updatePoints(list)
      setIsLoadingOffices(false)
    })
  }, [])


  // let c = 0;
  // let _v = 0;
  // for (let o of deliverypoints) {
  //   for (let t of o.work_time_list) {
  //     c++
  //     _v += t.time.length
  //     if (t.time.length > 11) {
  //       console.log(t.time);
  //     }
  //   }
  // }
  // console.log('l', deliverypoints.length, c, _v);

  const sendMsg = (message: any, type: string) => {
    iRef?.current?.contentWindow && iRef.current.contentWindow.postMessage({
      type, message,
    }, '*');
  }

  useEffect(() => {
    // setPoints(offices)
    const handler = (event: MessageEvent<{ type: string, message: any }>) => {

      if (typeof event.data !== 'object') return
      if (!event.data.type) return
      switch (event.data.type) {
        case 'ready':
          // sendMsg(offices, 'setDeliverypoints')
          updatePoints(offices)
          break;
        case 'selectDeliverypoint': {
          const code = event.data.message as string
          // console.log('selectDeliverypoint', code, offices);
          setSelectedDP(offices.find(_dp => _dp.code === code))
          sendMsg(code, 'selectDeliverypoint')
          break;
        }
        case 'setVisiblePoints':
          setvisiblePoints(event.data.message as string[])
          break;
        default:
          break;
      }

      // setMessage(event.data.message)
    }

    window.addEventListener('message', handler)

    // Don't forget to remove addEventListener
    return () => window.removeEventListener('message', handler)
  }, [offices])

  // useEffect(() => {
  //   updatePoints(offices)
  // }, [isActive])


  const handleSelectPoint = (code: string) => () => {
    setSelectedDP(offices.find(_dp => _dp.code === code))
    sendMsg(code, 'selectDeliverypoint')
  }



  const handleChangeSearch = (value: string) => {
    let s = value.toString()
    setSearch(s)
    s = s.toLocaleLowerCase()
    if (s.length > 1) {
      updatePoints(offices.filter(p => p.code.toLocaleLowerCase().includes(s) || p.location.address_full.toLocaleLowerCase().includes(s)))
    } else {
      updatePoints(offices)
    }
  }

  const handleClearSearch = () => {
    setSearch('')
    updatePoints(offices)
  }

  const handleNextStep = () => {
    // dispatch(nextStep())
    onClose(selectedDP)
  }

  if (!isActive) {
    return null;
  }

  const handleOnClose = () => {
    onClose(selectedDP)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.modalUnderflow} onClick={handleOnClose}></div>
      <div className={styles.content}>
        <h2>Карта складов</h2>

        {isLoadingOffices
          ? 'Загрузка...'
          : <div className={styles.maps}>
            {/* <div>{selectedDP}</div> */}
            <div className={styles.map_wrap}>
              <iframe src='/map/map.html' className={styles.map} ref={iRef} />
              {selectedDP && <DPInfo deliveryPoint={selectedDP} className={styles.dp_info} />}
            </div>
            <TextInput onChange={handleChangeSearch} value={search} placeholder='Поиск' className={styles.search} icon={o} onClickOnIcon={handleClearSearch} />
            <div className={styles.deliverypoints}>
              {points.filter(p => visiblePoints.indexOf(p.code) !== -1).slice(-100).map(dp => {
                const work_times = dp.work_time  // .split(', ')
                return <div
                  key={dp.code}
                  className={classNames(styles.dp, {
                    [styles.selected]: dp.code === selectedDP?.code
                  })}
                  onClick={handleSelectPoint(dp.code)}
                >
                  <div className={styles.title}>{dp.code}</div>
                  <div className={styles.address}>{dp.location.address_full}</div>
                  <div className={styles.work_times}>{work_times.map((wt, i) => {
                    const data = wt.split(' ')
                    return <div key={i} className={styles.work_time}>
                      <div className={styles.days}>{data[0].toUpperCase()}</div>
                      <div>{data[1].replace('-', ' - ')}</div>
                    </div>
                  })}
                  </div>
                </div>
              })}
            </div>
            <div className={styles.ok_btn}>
              <BlueButton title='Готово' disabled={!selectedDP} onClick={handleNextStep} />
            </div>
            {/* <YMaps
        query={{
          ns: "use-load-option",
          load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
        }}
      >
        <Map
          defaultState={{
            center: [55.75, 37.57],
            zoom: 9,
            controls: ["zoomControl", "fullscreenControl"],
          }}
        >
          <Placemark
            defaultGeometry={[55.75, 37.57]}
            properties={{
              balloonContentBody:
                "This is balloon loaded by the Yandex.Maps API module system",
            }}
          />
        </Map>
      </YMaps> */}
            {/* <YMaps>
        <div>
          My awesome application with maps!
          <Map
            defaultState={{
              // center: [55.75, 37.57],
              center: [34.0, 43.0],
              zoom: 9,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            {deliverypoints.map(p => <Placemark key={p.code} defaultGeometry={[p.location.longitude, p.location.latitude]}><div>test</div></Placemark>)}
          </Map>
        </div >
      </YMaps > */}
          </div>}
      </div>
    </div>
  )
}
