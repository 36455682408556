import React, { useState, useEffect } from 'react'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { BlueButton } from '../../../../simples/BlueButton'
import api from "../../../../../utils/api";
import { ShopAccountDTO } from '../../../../../types/swagger/llyApi_new';


import styles from './User.module.scss'
import { CustomHint } from '../../../../simples/CustomHint';
import { EmailRegexp, FioRegexp, PhoneRegexp, formatPhone } from '../../../../../utils/employees';
import ChangePhoneEmail, { ChangeMode } from './ChangePhoneEmail';


// /^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/


type FetchAccountAnswer = {
  success?: boolean
  data?: ShopAccountDTO
}


export default function User() {

  const [readonly, setReadonly] = useState(true)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [fio, setFio] = useState('')
  const [id, setId] = useState('')
  const [isSendSuccess, setIsSendSucess] = useState(false)
  const [editFioMode, setEditFioMode] = useState(false)
  const [newFio, setNewFio] = useState('')

  const [changePhoneEmailMode, setChangePhoneEmailMode] = useState<ChangeMode | undefined>()
  // const [changeEmailMode, setChangeEmailMode] = useState(false)

  const fetchAccount = () => {
    const req = '/companies/account'
    api.get<FetchAccountAnswer>(req)
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.success) {
          const data = res.data.data
          setEmail(data?.email || '')
          setPhone(data?.phone.toString() || '')
          setFio(data?.owner_name || '')
          setId(data?.id.toString() || '')
        }
      })
      .catch((err) => { alert(err) });
  }
  useEffect(() => {
    fetchAccount()
  }, [])



  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'fio':
        // setFio(value)
        setNewFio(value)
        break;
      case 'phone':
        setPhone(value)
        break;
      case 'email':
        setEmail(value)
        break;

      default:
        break;
    }
  }

  const handleEdit = () => {
    setReadonly(false)
    setIsSendSucess(false)
  }
  const handleSave = () => {
    const req = '/user/update'
    const data = {
      name: fio,
      email,
      phone
    }
    api.post<FetchAccountAnswer>(req, data)
      .then((res) => {
        if (res.data.success) {
          setIsSendSucess(true)
          setReadonly(true)
        }
      })
      .catch((err) => { alert(err) });
  }

  const emailHasError = !EmailRegexp.test(email)
  const phoneHasError = !PhoneRegexp.test(phone)
  const fioHasError = !FioRegexp.test(fio)// fio.length === 0

  // console.log(phone);

  const hasErrors = emailHasError || phoneHasError || fioHasError


  const handleCloseChangePhoneDialog = () => {
    setChangePhoneEmailMode(undefined)
  }
  const handleShowChangePhoneDialog = () => {
    setChangePhoneEmailMode(ChangeMode.phone)
  }
  const handleShowChangeEmailDialog = () => {
    setChangePhoneEmailMode(ChangeMode.email)
  }
  const handleEditFio = () => {
    setEditFioMode(true)
    setNewFio(fio)
  }
  const handleSaveFio = () => {
    setEditFioMode(false)
    api.post<FetchAccountAnswer>('/user/update', { name: newFio })
      .then((res) => {
        if (res.data.success) {
          setFio(newFio)
        }
      })
      .catch((err) => { alert(err) });
  }


  return (
    <div className={styles.account}>
      {changePhoneEmailMode !== undefined && <ChangePhoneEmail oldPhone={formatPhone(phone)} oldEmail={email} onClose={handleCloseChangePhoneDialog} changeMode={changePhoneEmailMode} />}
      <div className={styles.row_wrap}>
        <CustomTextInput className={styles.input} name='id' onChange={handleChange} value={id} label='Уникальный ID продавца Look Like' readonly={true} />
        <CustomHint hint='Отредактируйте основную информацию о профиле' />
      </div>
      <div className={styles.row_wrap}>
        {/* <CustomTextInput className={styles.input} name='fio' onChange={handleChange} value={fio} label='ФИО' readonly={readonly} maxLength={35} /> */}
        <CustomTextInput className={styles.input} name='fio' onChange={handleChange} value={editFioMode ? newFio : fio} label='ФИО' readonly={!editFioMode} maxLength={35} />
        {editFioMode
          ? <div className={styles.save_btn} onClick={handleSaveFio} />
          : <div className={styles.edit_btn} onClick={handleEditFio} />
        }
        {fioHasError && <div className={styles.warning}>Заполните поле</div>}
      </div>
      <div className={styles.row_wrap}>
        <CustomTextInput mask={"+7 (999) 999-99-99"} className={styles.input} name='phone' onChange={handleChange} value={readonly ? formatPhone(phone) : phone} label='Основной телефон' readonly={readonly} />
        <div className={styles.edit_btn} onClick={handleShowChangePhoneDialog} />
        {phoneHasError && <div className={styles.warning}>Заполните поле</div>}
      </div>
      <div className={styles.row_wrap}>
        <CustomTextInput className={styles.input} name='email' onChange={handleChange} value={email} label='Адрес электронной почты' readonly={readonly} />
        <div className={styles.edit_btn} onClick={handleShowChangeEmailDialog} />
        {emailHasError && <div className={styles.warning}>Заполните поле</div>}
        {/* {readonly && email.length > 0 && <CustomHint hint='Письмо с подтверждением отправлено на почту'><img src={v_icon} /></CustomHint>} */}
        {readonly && email.length > 0 && isSendSuccess && <div className={styles.info}>Письмо с подтверждением отправлено на почту</div>}
      </div>
      <div className={styles.btns}>
        {/* {readonly && <BlueButton onClick={handleEdit} title='Редактировать' />} */}
        {/* {!readonly && <BlueButton onClick={handleSave} disabled={hasErrors} title='Отправить на модерацию' />} */}
      </div>
    </div >
  )
}
