import React from 'react'
import styles from '../MainPage.module.scss'
import { News } from '../../../../types/swagger/llyApi_new'
import { formatDate } from '../../../../utils/dates'

type Props = {
  news: News
}

export default function NewsListItem({ news }: Props) {
  return (
    <div className={styles.news_item}>
      <div className={styles.news_img} style={{backgroundImage: `url(${news.url})`}} >
      </div>
      <div className={styles.news_body}>
        <div className={styles.news_title}>{news.title}</div>
        <div className={styles.news_text}>{news.text}</div>
        <div className={styles.news_date}>{formatDate(news.created_at*1000)}</div>
      </div>
    </div>
  )
}
