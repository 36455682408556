import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'

import styles from "./CalendarWeek.module.scss"
import WeekActions from './WeekActions'
import WeekDays from './WeekDays'

type Props = {
  actions: Promotions[]
  firstDayOfWeek: Date
  selectAction: (actionId: number) => void
}


export const DaysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"]

export default function CalendarWeek({ actions, firstDayOfWeek, selectAction }: Props) {

  return (
    <div className={styles.calendar_week}>
      <WeekDays />
      <WeekActions actions={actions} firstDayOfWeek={firstDayOfWeek} selectAction={selectAction} />
    </div>
  )
}
