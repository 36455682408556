import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { CompanyCertifiedBrandsList, Files } from '../../../../types/swagger/llyApi_new'
import styles from '../Brands.module.scss'
import { IconBtn, IconType } from '../../../simples/IconBtn'
import Dropzone from 'react-dropzone'
import { removeDocs, sendToModeration, uploadDocs } from '../../../../store/slices/brands'
import classNames from 'classnames'
import { toast } from 'react-toastify'

const accept = { "image/png": [".png"], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] }
const maxSize = 32 * 1024 ** 2


type Props = {
  brand: CompanyCertifiedBrandsList
}

export default function Brand({ brand }: Props) {
  const dispatch = useAppDispatch()
  // dispatch(uploadDocs())
  const uploadingProgress: number | undefined = useAppSelector(state => state.brands.uploadingProgress)[brand.id]
  const uploadedDocs: Files[] | undefined = useAppSelector(state => state.brands.uploadedDocs)[brand.id]

  const handle = () => { return }

  const onDrop = (data: File[]) => {
    if (data.length > 0) {
      if (data.length > 5) {
        return toast.error("Ошибка! Не более 5 файлов", { theme: "light" })
      }
      dispatch(uploadDocs({ bid: brand.id, files: data }))
    }
  }


  const handleSendToModeration = () => {
    dispatch(sendToModeration(brand.id))
  }


  let canSendTomoderation = false
  let hasDocs = false

  let docs: JSX.Element
  if (uploadingProgress !== undefined) {
    docs = <div>Загрузка... {uploadingProgress}%</div>
  } else if (uploadedDocs && uploadedDocs.length > 0) {
    canSendTomoderation = true
    hasDocs = true
    docs = <>
      {uploadedDocs.map(doc => <div key={doc.id} className={styles.uploaded_doc}>Загружено успешно &quot;{doc.base_name}.{doc.extension}&quot;</div>)}
    </>
  } else if (brand.documents && brand.documents?.length > 0) {
    hasDocs = true
    docs = <>
      {brand.documents.map(doc => <div key={doc.id} className={styles.doc}>{doc.name}</div>)}
    </>
  } else {
    docs = <></>
  }

  const handleRemoveDocs = () => {
    dispatch(removeDocs(brand.id))
  }

  /** Статус модерации: null - не был отправлен, -1 - отклонен, 0 - на модерации, 1 - действующий */
  const isActiveStatus = brand.status === 1

  const handleDropzoneError = console.log

  return (
    <tr>
      <td className={styles.brand}>{brand.name}</td>
      <td className={classNames(styles.status, { [styles.active]: isActiveStatus })}>{brand.status_name}</td>
      <td className={styles.documents}>{docs}</td>
      <td className={styles.btns}>
        <div className={styles.btns_wrap}>
          {/* <Dropzone onDrop={onDrop} multiple={true} maxFiles={5} accept={accept} maxSize={maxSize} > */}
          <Dropzone onDrop={onDrop} multiple={true} accept={accept} maxSize={maxSize} onError={handleDropzoneError} >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                {hasDocs
                  ? <IconBtn icon={IconType.Reload} hint='Перезагрузить документы' hintWidth={250} />
                  : <IconBtn icon={IconType.Upload} hint='Загрузить документы' hintWidth={250} />}
              </div>
            )}
          </Dropzone>
          <IconBtn icon={IconType.Trash} hint='Удалить документы' hintWidth={200} onClick={handleRemoveDocs} />
          {canSendTomoderation
            ? <IconBtn icon={IconType.V} active hint='Отправить на модерацию' hintWidth={250} onClick={handleSendToModeration} />
            : (brand.status_description ? <IconBtn icon={IconType.X} active hint={brand.status_description} hintWidth={250} /> : '')}
        </div>
      </td>
    </tr>
  )
}
