import React from "react";
import styles from "./WarningPanel.module.scss";
import icons from "./assets/infoForm.png";
import classNames from "classnames";

interface Props {
  title?: string;
  text?: string;
}

const WarningPanel: React.FunctionComponent<Props> = ({ title, text }) => {
  return (
    <article className={styles.body}>
      <section className={classNames(styles.body__warning, styles.body__warning_top)}>
        <div className={styles.title}>
          <div className={styles.icons}><img src={icons} /></div>
          <h3>{title}</h3>
        </div>
        <div className={styles.text}>{text}</div>
      </section>
    </article>
  );
};

export default WarningPanel;
