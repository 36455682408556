import React from 'react'
import { OptionSelector, Option } from '../../simples/OptionSelector'


export enum CalendarPeriod {
  year = "year",
  month = "month",
  week = "week",
  day = "day",
}

const CalendarPeriodOptions: Option<CalendarPeriod>[] = [{
  label: "День",
  value: CalendarPeriod.day
}, {
  label: "Неделя",
  value: CalendarPeriod.week
}, {
  label: "Месяц",
  value: CalendarPeriod.month
}, {
  label: "Год",
  value: CalendarPeriod.year
}]


type Props = {
  period?: CalendarPeriod
  onChange: (period: CalendarPeriod) => void
  className?: string
}

export default function CalendarPeriodSelector({ period, onChange, className }: Props) {
  return (
    <OptionSelector<CalendarPeriod>
      className={className}
      onSelect={onChange} options={CalendarPeriodOptions} selectedOption={period}
    />
  )
}
