import classNames from 'classnames'
import React from 'react'
import star_icon from "../assets/star.svg"
import empty_star_icon from "../assets/empty_star.svg"


import styles from "../Feedback.module.scss"

interface Props {
  starsCount: number
  className?: string
  showEmpty?: boolean
}

export default function Stars({ starsCount, className, showEmpty }: Props) {
  return (
    <div className={classNames(className, styles.stars)}>
      {Array(starsCount).fill("").map((_, i) => <img src={star_icon} key={i} />)}
      {showEmpty && Array(5 - starsCount).fill("").map((_, i) => <img src={empty_star_icon} key={i} />)}
    </div>
  )
}
