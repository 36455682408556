import React from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "../../../constants/routes";
import { Logo } from "../../simples/Logo";
import { ActiveBic } from "./parts/ActiveBic";
import { ActiveTinPart } from "./parts/ActiveTinPart";
import { BicCard } from "./parts/BicCard";
import { TinCard } from "./parts/TinCard";
import styles from "./Registration.module.scss";

function Registration() {
  const location = useLocation()

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} large={false} />
      <div className={styles.card}>
        {location.pathname === Routes.REGISTRATION && <ActiveTinPart />}
        {location.pathname === Routes.REGISTRATION_TIN && <TinCard />}
        {location.pathname === Routes.REGISTRATION_BIC_ACTIVE && <ActiveBic />}
        {location.pathname === Routes.REGISTRATION_BIC && <BicCard />}
      </div>
    </div>
  );
}

export default Registration;
