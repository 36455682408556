import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductsDTO } from "../../types/swagger/llyApi_new";

type Doc = {
  title: string
  link: string
}

type PaskSizes = {
  length?: number
  height?: number
  width?: number
  count?: number
}
export interface IPack {
  id: string
  packName: string
  docs: Doc[]
  sizes: PaskSizes
  weight?: number
}

export type CalendarAvailableSlot = {
  time: string
  disabled?: boolean
}
export type Gate = {
  id: string
  title: string
}
export type CalendarConfigItem = {
  date: string
  gate: Gate
  availableSlots: CalendarAvailableSlot[]
}
export type CalendarConfig = {
  items: CalendarConfigItem[]
}


export interface FBOState {
  // categories?: Categories[]
  // openedCategories: number[]
  // searchText: string
  // gagText: string
  // serverError?: ServerError
  isActive: boolean
  proposal: any
  step: number
  selectedProducts: ProductsDTO[]
  deliveryAddress?: string
  packingType?: string
  calendarConfig: CalendarConfig
  packs: IPack[]
  deliveryAddresses: {
    id: string,
    address: string,
  }[]
  packingTypes: {
    id: string,
    title: string,
    description: string,
  }[]
  passes: IPass[]
  newPass?: IPass
  selectedPasses: string[]
}

interface IPass {
  id?: string,
  fio: string,
  car: string,
  carNumber: string,
  phone: string,
}

// type FetchResponse = {
//   success: boolean
//   data: Categories[]
//   errors?: ServerError
// }
// export const fetchCategories = createAsyncThunk<any, { text: string }, { state: RootState }>(
//   "sellProduct/fetchCategories", async ({ text }, { getState, rejectWithValue, fulfillWithValue }) => {
//     let req: string = Endpoints.GET_STATISTICS
//     if (text) {
//       req += `?search=${text}`
//     }
//     const resp = await api.get<FetchResponse>(req);
//     if (!resp.data || !resp.data.success) {
//       return rejectWithValue(resp.data.errors || "error");
//     } else {
//       return fulfillWithValue(resp.data.data);
//     }
//   }
// );

const buildNewPack = (): IPack => {
  return {
    id: Math.random().toString(),
    packName: 'Груз 1',
    docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }, { title: 'Термопринт.pdf', link: '' }],
    sizes: {},
    // weight: undefined
  }
}

export const initialState: FBOState = {
  isActive: false,
  proposal: {},
  step: 1,

  //step 1
  selectedProducts: [],

  // step 2
  deliveryAddress: undefined,
  packingType: undefined,
  deliveryAddresses: [{
    id: '1',
    address: 'г. Москва, ул. Пушкина, д 12',
  }, {
    id: '2',
    address: 'г. Москва, ул. Адмиралтейства, д 12',
  }],
  packingTypes: [{
    id: '1',
    title: 'Мягкая упаковка',
    description: 'Мягкие упаковки предназначены для небольшой партии вещей',
  }, {
    id: '2',
    title: 'Короб',
    description: 'Товары в коробе находятся в индивидуальной упаковке. Каждый короб имеет уникальную маркировку',
  }, {
    id: '3',
    title: 'Монопалет',
    description: 'Допускается поместить на палету до трёх разных карточек товаров',
  }],

  // step 3
  calendarConfig: {
    items: [{
      availableSlots: [
        { time: '12:00' }, { time: '13:00' }, { time: '14:00' }, { time: '15:00', disabled: true },
        { time: '16:00' }, { time: '17:00', disabled: true }, { time: '18:00' }, { time: '19:00' },
        { time: '20:00' }],
      date: '12 января',
      gate: {
        id: '1',
        title: 'Гейт 17'
      }
    }, {
      availableSlots: [
        { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
        { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
        { time: '20:00', disabled: true }],
      date: '13 января',
      gate: {
        id: '1',
        title: 'Гейт 17'
      }
    }, {
      availableSlots: [
        { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
        { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
        { time: '20:00', disabled: true }],
      date: '14 января',
      gate: {
        id: '2',
        title: 'Гейт 15'
      }
    }]
  },

  // step 4
  packs: [{
    id: '1',
    packName: 'Груз 1',
    docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }, { title: 'Термопринт.pdf', link: '' }],
    sizes: { count: 100, height: 100, width: 100, length: 100 },
    weight: 100
  }, {
    id: '2',
    packName: 'Груз 2',
    docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }],
    sizes: { count: 100, height: 100, width: 100, length: 100 },
    weight: 100
  }, {
    id: '3',
    packName: 'Груз 3',
    docs: [{ title: 'Лист_отправления.pdf', link: '' }, { title: 'Накладная.pdf', link: '' }],
    sizes: { count: 100, height: 100, width: 100, length: 100 },
    weight: 100
  }],

  // step 5
  passes: [{
    id: '1',
    fio: 'Артем Джинибекович Валентинович',
    car: 'Мазда грузовая',
    carNumber: 'ке859н',
    phone: '+7 888 XXX xx xx',
  }, {
    id: '2',
    fio: 'Артем Джинибекович Валентинович',
    car: 'Мазда грузовая',
    carNumber: 'ке859н',
    phone: '+7 888 XXX xx xx',
  }],
  selectedPasses: []
  // openedCategories: [],
  // searchText: "",
  // gagText: ""
};


export const fboSlice = createSlice({
  name: "FBO",
  initialState,
  reducers: {
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    setSelectedProducts: (state, action: PayloadAction<ProductsDTO[]>) => {
      state.selectedProducts = action.payload
    },
    setDeliveryAddress: (state, action: PayloadAction<string>) => {
      state.deliveryAddress = action.payload
    },
    setPackingType: (state, action: PayloadAction<string>) => {
      state.packingType = action.payload
    },
    createPack: (state) => {
      state.packs.push(buildNewPack())
    },
    removePack: (state, action: PayloadAction<string>) => {
      state.packs = state.packs.filter(p => p.id !== action.payload)
    },
    setPackSize: (state, action: PayloadAction<{ packId: string, sizeName: string, value: number }>) => {
      const { sizeName, packId, value } = action.payload
      const sizes = state.packs.find(p => p.id === packId)?.sizes
      if (sizes) {
        sizes[sizeName] = value
      }
    },
    setPackWeight: (state, action: PayloadAction<{ packId: string, value: number }>) => {
      const p = state.packs.find(p => p.id === action.payload.packId)
      if (p) { p.weight = action.payload.value }
    },
    togglePass: (state, action: PayloadAction<string>) => {
      const id = action.payload
      if (state.selectedPasses.indexOf(id) === -1) {
        state.selectedPasses.push(id)
      } else {
        state.selectedPasses = state.selectedPasses.filter(pid => pid !== id)
      }
    },
    addPass: (state) => {
      state.newPass = { car: '', carNumber: '', fio: '', phone: '' }
    },
    removePass: (state, action: PayloadAction<string>) => {
      state.passes = state.passes.filter(p => p.id !== action.payload)
    },
    setPassValue: (state, action: PayloadAction<{ passId?: string, key: string, value: string }>) => {
      const { passId, key, value } = action.payload
      const pass = state.passes.find(p => p.id === passId)
      if (pass) {
        pass[key] = value
      } else if (state.newPass) {
        state.newPass[key] = value
      }
    },
    saveNewPass: (state) => {
      console.log(1);
      
      if (state.newPass) {
        const newPass: IPass = { ...state.newPass, id: Math.random().toString() }
        state.passes.push(newPass)
        state.newPass = undefined
      }
    },

    // setOpenedCategories: (state, action) => {
    //   state.openedCategories = action.payload;
    // },
    // setCategories: (state, action) => {
    //   state.openedCategories = action.payload;
    // },
    // setSearchText: (state, action) => {
    //   state.searchText = action.payload;
    // },
    // setGagText: (state, action) => {
    //   state.gagText = action.payload;
    // },
    // setServerError: (state, action) => {
    //   state.serverError = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchCategories.pending, (state) => {
    //   state.gagText = "Загрузка...";
    // });
    // builder.addCase(fetchCategories.fulfilled, (state, action) => {
    //   const data: Categories[] = action.payload
    //   state.categories = data
    //   if (data.length > 0) {
    //     state.gagText = "";
    //   } else {
    //     state.gagText = "Пусто";
    //   }
    // });
    // builder.addCase(fetchCategories.rejected, (state, action) => {
    //   state.gagText = ""
    //   state.serverError = action.payload as ServerError
    // });
  }
});


// TODO remove фикс, в сваггере не прописано поле
// export interface MyCategories extends Omit<Categories, "category"> {
//   children?: Categories[]
// }
// const findCategory = (categories: MyCategories[], id: number): Categories | undefined => {
//   if (!categories) return
//   for (let i = 0; i < categories.length; i++) {
//     const c = categories[i];
//     if (c.id === id) { return c }
//     if (c.children && c.children.length > 0) {
//       const cc = findCategory(c.children, id)
//       if (cc) return cc
//     }
//   }
// }

// export const getSearchText = (state: RootState) => state.sellProduct.searchText

// export const getCategories = () => (state: RootState) => state.sellProduct.categories;
// export const getCategory = (id: number) => (state: RootState) => findCategory(state.sellProduct.categories as MyCategories[], id);
// export const getOpenedCategories = () => (state: RootState) => state.sellProduct.openedCategories;
// export const getGagText = () => (state: RootState) => state.sellProduct.gagText;
// export const getServerError = () => (state: RootState) => state.sellProduct.serverError;

export const { setIsActive, setStep, setSelectedProducts, setDeliveryAddress, setPackingType, createPack,
  removePack, setPackSize, setPackWeight, togglePass, addPass, setPassValue, removePass, saveNewPass } = fboSlice.actions;

export default fboSlice.reducer;