import React, { FC, useEffect, useRef } from "react";
import { ListDataType, ListHeadData, ListProductDataType } from '../../../types/productListTypes';
import { Layout } from '../../complexes/Layout'
import ProductList, { AvailableProductData } from '../../complexes/ProductList/ProductList'
import { BlueButton } from '../../simples/BlueButton';
import styles from "./MassEdit.module.scss";

import infoIcon from "./assets/info.svg";
import downloadIcon from "./assets/download.svg";
import uploadIcon from "./assets/upload.svg";
import { Upload } from './parts/Upload/';
// import Upload from './parts/Upload/Upload';
import { fetchArch, fetchGoods, getImportedData, getImportedDataErrors, getSelectedIds, sendToModerate, setFakeBarcodes } from '../../../store/slices/massEdit';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { Progress } from "./parts/Progress";
import { DialogInfo } from "./parts/DialogInfo";
import { DefaultHeaders } from "../../complexes/ProductList/DefaultHeaders";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/routes";

const headers: ListHeadData[] = [{
  name: AvailableProductData.row_index,
  text: "№",
  minWidth: "60px",
  width: "2fr"
// }, {
//   name: AvailableProductData.photo,
//   text: "Фото",
//   type: ListDataType.image,
//   minWidth: "60px",
//   width: "2fr"
},
DefaultHeaders.photo,
DefaultHeaders.barcode,
DefaultHeaders.name,
DefaultHeaders.brand,
DefaultHeaders.sex,
DefaultHeaders.type,
DefaultHeaders.category,
DefaultHeaders.subcategory,
DefaultHeaders.size,
DefaultHeaders.color,
DefaultHeaders.retail_price,
DefaultHeaders.season,
DefaultHeaders.dateOfCreation,
// {
//   name: AvailableProductData.barcode,
//   // text: "Артикул (штрихкод)",
//   content: <>Артикул <br />(штрихкод)</>,
//   minWidth: "100px",
//   width: "3fr"
// },
// {
//   name: AvailableProductData.name,
//   text: "Наименование",
//   sortable: true,
//   minWidth: "100px",
//   width: "4fr"
// }, 
// {
//   name: AvailableProductData.brand,
//   text: "Бренд",
//   sortable: true,
//   minWidth: "120px",
//   width: "4fr"
// }, {
//   name: AvailableProductData.sex,
//   text: "Пол",
//   sortable: true,
//   minWidth: "90px",
//   width: "3fr"
// },
// {
//   name: AvailableProductData.type,
//   text: "Тип",
//   sortable: true,
//   minWidth: "90px",
//   width: "3fr"
// }, 
// {
//   name: AvailableProductData.category,
//   text: "Категория",
//   sortable: true,
//   minWidth: "120px",
//   width: "4fr"
// }, {
//   name: AvailableProductData.subcategory,
//   text: "Подкатегория",
//   sortable: true,
//   minWidth: "120px",
//   width: "4fr"
// }, {
//   name: AvailableProductData.size,
//   text: "Размер",
//   minWidth: "60px",
//   width: "2fr"
// },
// {
//   name: AvailableProductData.color,
//   text: "Цвет",
//   minWidth: "90px",
//   width: "3fr"
// }, 
  // {
  //   name: AvailableProductData.retail_price,
  //   text: "Розничная цена",
  //   sortable: true,
  //   minWidth: "120px",
  //   width: "4fr"
  // }, {
  //   name: AvailableProductData.season,
  //   text: "Сезон",
  //   sortable: true,
  //   minWidth: "90px",
  //   width: "3fr"
  // }, {
  //   name: AvailableProductData.dateOfCreation,
  //   text: "Дата создания",
  //   sortable: true,
  //   minWidth: "90px",
  //   width: "3fr"
  // }
];


const MassEdit: FC = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const selectedIds = useAppSelector(getSelectedIds);
  const data = useAppSelector(getImportedData());
  const errors = useAppSelector(getImportedDataErrors());
  const isFakeBarcodes = useAppSelector(state => state.massEdit.isFakeBarcodes);
  const isArchUploaded = useAppSelector(state => state.massEdit.isArchUploaded);

  type UploadHandle = React.ElementRef<typeof Upload>;
  const drop = useRef<UploadHandle>(null);


  useEffect(() => {
    dispatch(fetchGoods())
  }, [selectedIds])


  const handleClickDownload = () => {
    dispatch(fetchArch());
  }
  const handleClickUpload = () => {
    drop.current?.open_dialog();
  }
  const handleGenerateBCodes = () => {
    dispatch(setFakeBarcodes(true));
  }
  const handleSendToModerate = () => {
    dispatch(sendToModerate());
  }

  const handleBack = () => {
    navigate(Routes.PRODUCTS_LIST)
  }

  return (
    <Layout
      onBack={handleBack}
      title='Массовое редактирование'
      hint='В данном разделе можно массово отредактировать карточки товара. Выгрузите таблицу с карточками товаров на свой компьютер, внесите необходимые изменения и загрузите обновлённую таблицу в этом разделе'
    >
      <Progress />
      <DialogInfo />
      <div className={styles.wrap}>
        <div className={styles.upload}>
          <Upload ref={drop} />
          <div className={styles.info}><img className={styles.icon} src={infoIcon} alt="info" /><span>Не удаляйте и не добавляйте новые столбцы в архиве!</span></div>
        </div>
        <div className={styles.btns}>
          <BlueButton title='Выгрузить таблицу товаров' disabled={data.length < 1} onClick={handleClickDownload} iconSrc={downloadIcon} />
          <BlueButton title='Загрузить таблицу товаров' onClick={handleClickUpload} iconSrc={uploadIcon} />
          <BlueButton title='Сгенерировать штрихкоды' disabled={data.length < 1} onClick={handleGenerateBCodes} />
        </div>
        <ProductList headers={headers} data={data} errors={errors} isFakeBarcodes={isFakeBarcodes} />
        <div className={styles.footer}>
          <BlueButton title='Сохранить и отправить на модерацию' disabled={!isArchUploaded} onClick={handleSendToModerate} />
        </div>
      </div>
    </Layout>
  )
}

export default MassEdit;