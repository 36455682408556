import React from 'react'
import styles from '../MainPage.module.scss'
import { NotificationTrait } from '../../../../types/swagger/llyApi_new'
import Skeleton from 'react-loading-skeleton'
import NotificationsListItem from './NotificationsListItem'
import { CustomHint } from '../../../simples/CustomHint'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../constants/routes'
import { BlueButton, ButtonStyle } from '../../../simples/BlueButton'
import { SellProductTabConfig } from '../../SellProduct/parts/SellProductContent/const'

type Props = {
  notifications: NotificationTrait[]
  isLoading?: boolean
}

// CompanyDeletedNotification - Дней до полного удаления магазина, 
// FeedbackNotification - Необработанные отзывы, 
// ItemsNotification - Товары ждут сборки,
// PurchaseReturnsNotification - Товары вернули в результате проверки, 
// RepresentativeNotification - Пользователи хотят стать вашими представителями
// ["FeedbackNotification","ItemsNotification","PurchaseReturnsNotification","RepresentativeNotification","CompanyDeletedNotification"]

enum NotificationType {
  "FeedbackNotification" = "FeedbackNotification",
  "ItemsNotification" = "ItemsNotification",
  "PurchaseReturnsNotification" = "PurchaseReturnsNotification",
  "RepresentativeNotification" = "RepresentativeNotification",
  "CompanyDeletedNotification" = "CompanyDeletedNotification",
}

export default function NotificationsList({ notifications, isLoading }: Props) {

  const navigate = useNavigate()

  const handleClick = () => {
    // navigate(Routes.TAGS_PAGE)
    navigate(SellProductTabConfig.categories.route)
  }
  const handleClickNotification = (notificationType?: string) => () => {
    switch (notificationType) {
      case NotificationType.FeedbackNotification:
        navigate(Routes.FEEDBACK)
        break;
      default:
        // navigate(Routes.TAGS_PAGE)
        navigate(SellProductTabConfig.categories.route)
        break;
    }
  }

  return (
    <div className={styles.notifications_list}>
      <div className={styles.row}>
        <NotificationsListItem
          onClick={handleClick}
          content={<span>Узнайте, что сейчас в трендах вместе с нашей статистикой в разделе <span className={styles.link}>Что продавать на LookLike?</span></span>}
        />
        <CustomHint hintWidth={300} hint='Раздел с уведомлениями динамический, мы подскажем Вам куда обратить свое внимание при работе с разделами магазина' />
      </div>
      {
        isLoading
          ? <Skeleton count={2} height={51} containerClassName={styles.skeleton} style={{ marginBottom: '4px' }} />
          : notifications.map((n, i) => <NotificationsListItem key={i} content={n.message} onClick={handleClickNotification(n.itemName)} />)
      }
    </div >
  )
}
