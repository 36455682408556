import React, { useState } from 'react'
import styles from './Orders.module.scss'
import { ItemsList } from '../../../../complexes/ItemsList'
import { BlueButton } from '../../../../simples/BlueButton'
import { ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { buildNewDeliveryRequest, fetchNewOrders } from '../../../../../store/slices/fbs'
import Skeleton from 'react-loading-skeleton'

const Headers: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "140px",
  width: "140px"
}, {
  name: 'dedlineDate',
  text: "Доставить до",
  type: ListDataType.text,
  sortable: true,
  minWidth: "120px",
  width: "120px"
}, {
  name: 'address',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "300px",
  width: "300px"
}, {
  name: 'buyer',
  text: "Покупатель",
  type: ListDataType.text,
  sortable: true,
  minWidth: "240px",
  width: "240px"
}, {
  name: 'rates',
  text: "Товары и артикул",
  type: ListDataType.text,
  sortable: true,
  minWidth: "240px",
  width: "240px"
}, {
  //   name: 'costOfDelivery',
  //   text: "Стоимость доставки",
  //   type: ListDataType.text,
  //   sortable: true,
  //   minWidth: "160px",
  //   width: "160px"
  // }, {
  name: 'cost',
  text: "Стоимость без доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "160px",
  width: "160px",
}]

export default function NewOrders() {

  const { isLoadingNewOrders: isLoadingOrders, ordersSearch, newOrders } = useAppSelector(state => state.fbs)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(fetchNewOrders());
  }, []);

  const handleSelectProducts = (ids: string[]) => {
    setSelectedItems([...selectedItems, ...ids.filter(id => !selectedItems.includes(id))])
  }
  const handleDeselectProducts = (ids: string[]) => {
    setSelectedItems([...selectedItems.filter(id => ids.indexOf(id) === -1)])
  }


  // const expired = Math.random() > 0.5
  // const items: ListRow[] = (new Array(5)).fill(0).map(_ => ({
  //   key: '1',
  //   data: [{
  //     name: 'id',
  //     type: ListDataType.content,
  //     content: <div className={styles.id}>
  //       <div className={styles.number}>№2456</div>
  //       <div className={styles.date}>01.02.2023</div>
  //     </div>
  //   }, {
  //     name: 'dedlineDate',
  //     type: ListDataType.content,
  //     content: <div className={styles.dedlineDate}>
  //       <div className={styles.date}>01.02.2023</div>
  //       <div className={classNames(styles.status, { [styles.expired]: expired })}>{expired ? "Просрочен" : "Осталось на сборку: 72ч"}</div>
  //     </div>
  //   }, {
  //     name: 'address',
  //     type: ListDataType.text,
  //     text: 'г. Москва, ул. Библиотечная, д 25'
  //   }, {
  //     name: 'buyer',
  //     type: ListDataType.text,
  //     text: 'Петров Пётр Петрович'
  //   }, {
  //     name: 'rates',
  //     type: ListDataType.content,
  //     content: <div className={styles.rates}>
  //       <div className={styles.product}>Футболка женская</div>
  //       <div className={styles.code}>15560B22222XS</div>
  //     </div>
  //   }, {
  //     name: 'costOfDelivery',
  //     type: ListDataType.text,
  //     text: '300'
  //   }, {
  //     name: 'cost',
  //     type: ListDataType.text,
  //     text: '3 000'
  //   }]
  // }))

  const _s = ordersSearch.toLocaleLowerCase()
  const orders = newOrders.filter(o => o.id.toString().toLocaleLowerCase().includes(_s)
    || o.address.toLocaleLowerCase().includes(_s)
    || o.consumer_name.toLocaleLowerCase().includes(_s)
    || o.id.toString().toLocaleLowerCase().includes(_s)
  )
  const items: ListRow[] = orders.map(o => {
    const expired = o.hours_remain === 0
    return {
      key: o.id.toString(),
      selected: selectedItems.includes(o.id.toString()),
      data: [{
        name: 'id',
        type: ListDataType.content,
        content: <div className={styles.id}>
          <div className={styles.number}>№{o.id}</div>
          <div className={styles.date}>{o.date}</div>
        </div>
      }, {
        name: 'dedlineDate',
        type: ListDataType.content,
        content: <div className={styles.dedlineDate}>
          <div className={styles.date}>{o.delivery_date}</div>
          <div className={classNames(styles.status, { [styles.expired]: expired })}>{expired ? "Просрочен" : `Осталось на сборку: ${o.hours_remain}ч`}</div>
        </div>
      }, {
        name: 'address',
        type: ListDataType.text,
        text: o.address,
        className: styles.address
      }, {
        name: 'buyer',
        type: ListDataType.text,
        text: o.consumer_name
      }, {
        name: 'rates',
        type: ListDataType.content,
        content: <div className={styles.rates}>
          {o.items.map(item => <div className={styles.rate} key={item.id}>
            <div className={styles.product}>{item.product_name}</div>
            <div className={styles.code}>{item.article}</div>
          </div>)}
        </div>
      }, {
        //   name: 'costOfDelivery',
        //   className: styles.cost,
        //   type: ListDataType.text,
        //   text: '0'
        // }, {
        name: 'cost',
        className: styles.cost,
        type: ListDataType.text,
        text: o.price.toLocaleString()
      }]
    }
  })

  const handleBuildOrder = () => {
    dispatch(buildNewDeliveryRequest(selectedItems))
  }

  if (isLoadingOrders) {
    return <Skeleton height={80} count={5} />
  }
  return (
    <div className={styles.new_orders}>
      <div className={styles.table}>
        <ItemsList selectProducts={handleSelectProducts} deselectProducts={handleDeselectProducts} selectedItems={selectedItems} headers={Headers} items={items} emptyText={<div className={styles.empty_text}><h1>Нет заказов</h1><span>Но они скоро появятся! </span></div>} />
      </div>
      <div className={styles.footer}>
        <BlueButton title='Собрать заказ' onClick={handleBuildOrder} disabled={selectedItems.length < 1} />
      </div>
    </div>
  )
}
