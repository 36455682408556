import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { activeVariantSelector, createAdditionalColor, removeAdditionalColor, setActiveVariantAdditionalColor } from '../../../../../store/slices/product'
import { TrashIcon } from '../../../../simples/TrashIcon'
import plus_icon from '../../assets/plus.svg'

import styles from './VariantColors.module.scss'
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2'

type Props = {
  value: number | null
  index: number
}

export default function AdditionalColor({ index, value }: Props) {


  const dispatch = useAppDispatch()
  const { colors_id, additionalColors } = useAppSelector(activeVariantSelector)
  const colors = useAppSelector(state => state.product.commonData.colors)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const colors_idOptions = colors
    .filter(c => c.id === additionalColors[index] || c.id !== colors_id && additionalColors.indexOf(c.id) === -1)
    .map(c => ({ label: c.name, value: c.id }))


  const handleChange = ({ name, value }) => {
    dispatch(setActiveVariantAdditionalColor({ index: name, value }))
  }

  const handleAddColor = () => {
    const newColor = (colors_idOptions[0].value === additionalColors[index]) ? colors_idOptions[1]?.value : colors_idOptions[0]?.value
    if (newColor) {
      dispatch(createAdditionalColor(newColor))
    }
  }
  const handleRemoveColor = () => {
    dispatch(removeAdditionalColor(index))
  }

  return (
    <div className={styles.additional_color}>
      <CustomSelect_v2
        name={index}
        options={colors_idOptions}
        value={value}
        onChange={handleChange}
        required
        hideError
        readonly={readonly}
        hasSearchInput
      />
      {!readonly && <div className={styles.controls} >
        <TrashIcon className={styles.btn_remove} id={index} onRemove={handleRemoveColor} />
        {/* {additionalColors.length < 2 && <AddBtn onClick={handleAddColor} />} */}
        {additionalColors.length < 2 && <img src={plus_icon} className={styles.btn_add} onClick={handleAddColor} />}
      </div>}
    </div >
  )
}
