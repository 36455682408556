import React, { useState } from 'react'

import styles from './LawInfo.module.scss'
import { BlueButton, ButtonStyle } from '../../../../simples/BlueButton'
import { IconBtn, IconType } from '../../../../simples/IconBtn'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { LawDoc } from '../../../../../types/law'
import { useAppDispatch } from '../../../../../hooks/store'
import { removeDoc, uploadDoc } from '../../../../../store/slices/law'


type Props = {
  lawDoc: LawDoc
}


const ErrorText = '! Загрузите документ'
const accept = { "image/png": [".png"], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] }
const maxSize = 32 * 1024 ** 2

// export default function UploadRow({ onChange, fileName, docType, title }: Props) {
export default function UploadRow({ lawDoc }: Props) {

  const dispatch = useAppDispatch()

  const handle = () => { return }
  const onDrop = (files: File[]) => {
    if (files.length === 1) {
      dispatch(uploadDoc({ docType: lawDoc.type, file: files[0] }))
    }
  }

  const handleClear = () => {
    dispatch(removeDoc(lawDoc.type))
  }

  return (

    <div className={classNames(styles.uploads_grid, {
      [styles.has_error]: !lawDoc.file,
      [styles.uploading]: lawDoc.progress !== undefined
      // [styles.dragFile]: false
    })}>
      <div className={styles.name}>{lawDoc.title}</div>
      <div className={styles.file}>{lawDoc.progress !== undefined ? `Загрузка... ${lawDoc.progress}%` : (lawDoc.file || ErrorText)}</div>
      <div className={styles.controls}>
        <Dropzone onDrop={onDrop} multiple={false} accept={accept} maxSize={maxSize} >
          {({ getRootProps, getInputProps, }) => (
            <div {...getRootProps()}>
              <BlueButton title='Загрузить' onClick={handle} buttonStyle={ButtonStyle.Style1} />
              <input {...getInputProps()} />
            </div>)}
        </Dropzone>
        <IconBtn icon={IconType.Trash} onClick={handleClear} />
      </div>
    </div>
  )
}
