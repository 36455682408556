import React from 'react'
import { useAppDispatch } from '../../../../hooks/store'
import { CertificateDTO } from '../../../../types/swagger/llyApi_new'
import styles from '../Brands.module.scss'
import { IconBtn, IconType } from '../../../simples/IconBtn'
import { BlueButton, ButtonStyle } from '../../../simples/BlueButton'
import { setCertIdForRemove, setSelectedCertCardId } from '../../../../store/slices/certificates'
import { formatDate } from '../../../../utils/dates'
// import { formatDate } from '../utils'

type Props = {
  cert: CertificateDTO
}

export default function Cert({ cert }: Props) {
  const dispatch = useAppDispatch()

  const handleRemoveCert = () => {
    // console.log(cert);

    dispatch(setCertIdForRemove(cert.id))
  }

  const handleSelectCertCard = () => {
    dispatch(setSelectedCertCardId(cert.id))
  }

  let expired = cert.is_perpetual
    ? 'Бессрочный'
    : ((cert.expired !== undefined)
      ? formatDate(cert.expired)
      : 'Нет даты')


  return (
    <tr>
      <td className={styles.cert}>{cert.name}</td>
      <td className={styles.issued}>{formatDate(cert.issued)}</td>
      <td className={styles.expired}>{expired}</td>
      <td className={styles.items_count}>{(cert.total_count && cert.total_count > 1) ? cert.total_count : 'Не привязан'}</td>
      <td className={styles.btns}>
        <div className={styles.btns_wrap}>
          <BlueButton title='Привязать карточки' onClick={handleSelectCertCard} buttonStyle={ButtonStyle.Style1} />
          <IconBtn icon={IconType.Trash} hint='Удалить сертификат' hintWidth={200} onClick={handleRemoveCert} />
        </div>
      </td>
    </tr>
  )
}
