import React, { useEffect, useState } from 'react'
import styles from './Turnover.module.scss'
import api from '../../../../../utils/api'
import { CustomSelect } from '../../../../simples/CustomSelect'
import { Option } from '../../../../simples/Select'
import CustomChart, { ChartMode, SerieConfig, Series } from '../CustomChart'
import { TurnoverCollection } from '../../../../../types/swagger/llyApi_new'
import { DatePickerDate } from '../../../../complexes/CustomDatePicker'
import CustomDatePicker, { datePickerDateToDate, dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import { format, sub } from 'date-fns'
import { formatDateForServer } from '../../../../../utils/action'
import { BlueButton, ButtonStyle } from '../../../../simples/BlueButton'
import { CalendarPeriod, CalendarPeriodSelector } from '../../../../complexes/CalendarPeriodSelector'
import FormatedNumber from './FormatedNumber'
import download from '../../../../../utils/download'
import Skeleton from 'react-loading-skeleton'


type FBOData = {
  id: number
  string?: string
}

type ServerFBOResponse = {
  success?: boolean
  data?: FBOData[]
}
type ServerTurnoverResponse = {
  success?: boolean
  data?: TurnoverCollection
}

export default function Turnover() {

  const [isLoading, setIsLoading] = useState(false)
  const [fbo, setFbo] = useState<FBOData[]>()
  const [selectedFbo, setSelectedFbo] = useState<number>()
  const [turnover, setTurnover] = useState<TurnoverCollection>()
  const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(new Date()))
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(
    dateToDatePickerDate(sub(new Date(), {
      months: 1
    }))
  )
  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)
  const [step, setStep] = useState<number>(1)

  useEffect(() => {
    setFbo(undefined)
    const req = '/storages/fbo';
    api.get<ServerFBOResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        setFbo(data.data.data)
      }
    })
  }, [])

  const handleChangeFBO = ({ value }) => {
    setSelectedFbo(value)
  }

  const fboOptions: Option[] = fbo?.map(item => ({
    value: item.id,
    label: item.string || '',
  })) || []

  const seriesConfig: SerieConfig[] = [
    {
      id: 'turnover',
      label: 'turnover',
      data: turnover?.data.map(data => ({
        date: data.date,
        value: data.turnover
      })) || []
    }
  ]

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const to = datePickerDateToDate(dateTo);
    if (d.getTime() < to.getTime()) {
      setPeriod(undefined)
      setDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const from = datePickerDateToDate(dateFrom)
    const now = new Date()

    if (d.getTime() > from.getTime() && d.getTime() < now.getTime()) {
      setPeriod(undefined)
      setDateTo(date)
    }
  }

  const handleSetPeriod = (period: CalendarPeriod) => {
    setPeriod(period)
    const _to = datePickerDateToDate(dateTo)
    switch (period) {
      case CalendarPeriod.day:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            days: 1
          }))
        )
        break;
      case CalendarPeriod.week:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            weeks: 1
          }))
        )
        break;
      case CalendarPeriod.month:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            months: 1
          }))
        )
        break;
      case CalendarPeriod.year:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            years: 1
          }))
        )
        break;
      default:
        break;
    }
  }


  const handleBuildReport = () => {
    setTurnover(undefined)
    if (selectedFbo !== undefined) {
      const req = `/storages/turnover/${selectedFbo}?from_date=${formatDateForServer(datePickerDateToDate(dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(dateTo))}&step=${step}`
      setIsLoading(true)
      api.get<ServerTurnoverResponse>(req).then(data => {
        if (data.data.data !== undefined) {
          setTurnover(data.data.data)
        }
        setIsLoading(false)
      })
    }
  }

  const handleDownloadTable = () => {
    download({
      req: `/storages/turnover-to-excel/${selectedFbo}?from_date=${formatDateForServer(datePickerDateToDate(dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(dateTo))}&step=${step}`
    })
  }

  const btnIsDisabled = selectedFbo === undefined

  const tooltipFormatter = (activeSerie: Series, rawIndex: number) => {
    const data = activeSerie.rawData[rawIndex]
    const value = data.value
    return <div className={styles.chart_tooltip}>
      <div className={styles.days}>{data.value} дней</div>
      <div className={styles.date}>{format(data.date, 'dd.MM.yyyy')}</div>
      {value && value < 60 && <div className={styles.warning_text}>Рекомендуем завезти на склад актуальные модели и новинки</div>}
      {value && value > 90 && <div className={styles.error_text}>Рекомендуем установить скидку от 70% до 90%</div>}
      {value && value >= 60 && value <= 90 && <div className={styles.info_text}>Рекомендуем установить скидку на неликвидный товар</div>}
    </div>
  }

  const _maxValue = Math.max(...seriesConfig[0].data.map(d => d.value || 0))

  return (
    <div className={styles.turnover}>
      <div className={styles.period}>
        <div>Сформировать статистику за</div>
        <div className={styles.period_selector}>
          <CalendarPeriodSelector period={period} onChange={handleSetPeriod} className={styles.calendar_period_selector} />
          <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
          <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
          <BlueButton buttonStyle={ButtonStyle.Style1} title='Применить' onClick={handleBuildReport} disabled={btnIsDisabled} />
        </div>
      </div>
      <div className={styles.controls}>
        <CustomSelect className={styles.fbo_select} name='fbo' options={fboOptions} onChange={handleChangeFBO} value={selectedFbo} hideError />
        <BlueButton className={styles.build_report} title='Сформировать отчет' onClick={handleBuildReport} disabled={btnIsDisabled} />
      </div>
      {(turnover !== undefined || isLoading) && <>
        <h1 className={styles.title}>Динамика оборачиваемости</h1>
        <CustomChart
          seriesConfig={seriesConfig}
          xLabel='Дни' yLabel=''
          mode={ChartMode.zones}
          minValue={-1}
          maxValue={_maxValue < 140 ? 140 : _maxValue}
          yStep={_maxValue < 140 ? 30 : undefined}
          // maxValue={140}
          // yStep={30}
          tooltipFormatter={tooltipFormatter}
          notSwitchableSeries
          hideControls
          isLoading={isLoading}
        />
        {isLoading ? <><Skeleton count={10} height={48} /></>
          : <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <td>Дата</td>
                  <td>Лимит оборачиваемости</td>
                  <td>Сколько дней</td>
                  <td>Изменения</td>
                </tr>
              </thead>
              <tbody>
                {turnover?.data?.map(item => <tr key={item.date}>
                  <td>{format(new Date(item.date), 'dd.MM.yy')}</td>
                  <td>{item.limit.toLocaleString()}</td>
                  <td>{item.turnover.toLocaleString()}</td>
                  <FormatedNumber value={item.diff} />
                </tr>)}
              </tbody>
            </table>
            <div className={styles.download}>
              <BlueButton title='Выгрузить в excel' onClick={handleDownloadTable} />
            </div>
          </>}
      </>}
      <div className={styles.info}>
        <h1>Оборачиваемость товара</h1>
        <div><b>Оборачиваемость товара</b> – это отношение скорости продажи товара к среднему запасу за определенный период. Оборачиваемость может измеряться в разах (сколько раз за период товар успевает «обернуться») или в днях (сколько дней нужно для продажи среднего запаса товара).</div>
        <div className={styles.formula}>
          <div>Оборачиваемость</div>
          <div>=</div>
          <div className={styles.formula_fraction}>
            <div>Объем продаж за период</div>
            <div className={styles.line}></div>
            <div>Средний товарный запас за период</div>
          </div>
        </div>
      </div>
    </div>
  )
}
