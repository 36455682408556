import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import FeedbackService from '../../../../services/FeedbackService'
import { fetchTemplates, setTemplateForDelete } from '../../../../store/slices/feedback'
import { ModalDialog } from '../../../simples/ModalDialog'

export default function RemoveTemplateRequest() {
  const dispatch = useAppDispatch()
  const t = useAppSelector(state => state.feedback.templateForDelete)
  const handleDeleteTemplate = async () => {
    if (!t?.id) return
    const res = await FeedbackService.deleteTemplate(t.id)
    res.data && alert(res.data)
    await FeedbackService.fetchTemplates()
    dispatch(fetchTemplates())
    handleClose()
  }

  const handleClose = () => {
    dispatch(setTemplateForDelete(undefined))
  }

  return (
    <ModalDialog isActive={t !== undefined} buttonsConfig={[{ text: 'Да', onClick: handleDeleteTemplate }, { text: 'Нет', onClick: handleClose }]}>
      <div>Удалить шаблон <span>“{t?.title}”</span>?</div>
    </ModalDialog>
  )
}
