import React from 'react'
import styles from './ProductStatistic.module.scss'
import classNames from 'classnames'

export enum ValueTypes {
  Items = 'Items',
  Percent = 'Percent',
  Clients = 'Clients',
  Money = 'Money',
  Days = 'Days'
}

type Props = {
  label: string
  value?: number
  valueType: ValueTypes
  isReverse?: boolean
}

export default function PanelRow({ label, value, valueType, isReverse }: Props) {

  if (value === undefined) return <></>

  let typeSign = ''
  switch (valueType) {
    case ValueTypes.Items:
      typeSign = ' шт.'
      break;
    case ValueTypes.Percent:
      typeSign = '%'
      break;
    case ValueTypes.Money:
      typeSign = ' ₽'
      break;
    case ValueTypes.Clients:
      typeSign = ' чел.'
      break;
    case ValueTypes.Days:
      typeSign = ' д.'
      break;
    default:
      break;
  }

  const reduction = (value !== undefined && value < 0)
  const growth = (value !== undefined && value > 0)

  return (
    <div className={classNames(styles.panel_datarow, {
      [styles.reduction]: isReverse ? growth : reduction,
      [styles.growth]: isReverse ? reduction : growth,
    })}>{label}: {value}{typeSign}</div>
  )
}
