import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Routes} from "../../../../../constants/routes";
import useDebounce from "../../../../../hooks/lifecycle";
import CompanyService from "../../../../../services/CompaniesService";
import {TinDataType} from "../../../../../types/auth";
import {OrganisationSendData} from "../../../../../types/registration";
import {OrganisationInfo} from "../OrganisationInfo";
import {TinPart} from "../TinPart";

const initialOrganisationData: OrganisationSendData = {
  name: "",
  fullName: "",
  director: "",
  INN: "",
  OGRN: "",
  KPP: "",
  isIP: false,
};

const TinCard = () => {
  const [isTinError, setIsTinError] = useState(false);
  const [tinData, setTinData] = useState<TinDataType>({
    withVat: false,
    tin: "",
  });
  const [organisationData, setOrganisationData] =
    useState<OrganisationSendData>(initialOrganisationData);
  const debaunsedTinData = useDebounce(tinData, 500);
  const location = useLocation();
  const navigate = useNavigate();

  const submit = (infoData: OrganisationSendData) => {
    navigate(Routes.REGISTRATION_BIC_ACTIVE, {
      state: {
        ...location.state,
        tinData,
        organisationInfo: infoData,
      },
    });
  };

  const refreshTin = async () => {
    const organisationData = await CompanyService.getOrganisationInfoByTin(
      tinData.tin,
    );
    organisationData && setOrganisationData(organisationData);
    !organisationData && setOrganisationData(initialOrganisationData);
    setIsTinError(!organisationData);
  };

  useEffect(() => {
    const {tinData, organisationInfo} = location.state;
    if (tinData && organisationInfo) {
      setTinData(tinData);
      setOrganisationData(organisationInfo);
    } else {
      navigate(Routes.AUTH);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (tinData.tin.length === 10 || tinData.tin.length === 12) {
      debaunsedTinData && refreshTin();
    } else {
      setIsTinError(!!tinData.tin.length);
      setOrganisationData(initialOrganisationData);
    }
  }, [debaunsedTinData]);

  return (
    <>
      <TinPart isError={isTinError} setTinData={setTinData} tinData={tinData} />
      <OrganisationInfo
        submit={submit}
        data={organisationData}
        isTinError={isTinError || !tinData.tin.length}
      />
    </>
  );
};

export default TinCard;
