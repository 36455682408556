import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { activeVariantSelector, createAdditionalColor, setActiveVariantValue } from '../../../../../store/slices/product'
import { CustomSelect } from '../../../../simples/CustomSelect'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { FieldTitle } from '../../../../simples/FieldTitle'
import AdditionalColor from './AdditionalColor'
// import { AddBtn } from '../../../../simples/addBtn'
import plus_icon from '../../assets/plus.svg'

import styles from './VariantColors.module.scss'
import classNames from 'classnames'
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2'


type Props = {
  className?: string
}

export default function VariantColors({ className }: Props) {


  const dispatch = useAppDispatch()
  const variants = useAppSelector(state => state.product.variants.values)
  const { man_article, colors_id, color_article, additionalColors } = useAppSelector(activeVariantSelector)
  const colors = useAppSelector(state => state.product.commonData.colors)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)


  const selectedMainColors = variants.map(v => v.colors_id)

  const colors_idOptions = colors
    .filter(c => c.id === colors_id || (additionalColors.indexOf(c.id) === -1 && selectedMainColors.indexOf(c.id) === -1))
    .map(c => ({ label: c.name, value: c.id }))



  const handleChange = ({ name, value }) => {
    if (readonly) return
    dispatch(setActiveVariantValue({ name, value }))
  }
  const handleAddColor = () => {
    if (readonly) return
    const newColor = (colors_idOptions[0].value === colors_id) ? colors_idOptions[1]?.value : colors_idOptions[0]?.value
    if (newColor) {
      dispatch(createAdditionalColor(newColor))
    }
  }

  const handle = () => { return }

  return (
    <div className={classNames(styles.variant_colors, className)}>
      <CustomTextInput name='man_article' label='Артикул производителя' required onChange={handleChange} value={man_article} readonly={readonly} />
      <CustomSelect_v2 name='colors_id' options={colors_idOptions} value={colors_id} onChange={handleChange} label='Основной цвет' required readonly={readonly} hasSearchInput />

      {(additionalColors.length > 0 || !readonly) && <div className={styles.additional_colors}>
        <FieldTitle text='Дополнительные цвета' />
        <div className={styles.list}>
          {additionalColors.map((c, i) => <AdditionalColor key={i} index={i} value={c} />)}
          {/* {additionalColors.length < 1 && <AddBtn onClick={handleAddColor} />} */}
          {(additionalColors.length < 1 && !readonly) && <img src={plus_icon} className={styles.btn_add} onClick={handleAddColor} />}
        </div>
      </div>}

      <CustomTextInput name='color_article' label='Артикул цвета' required disabled onChange={handle} value={color_article?.toString()} readonly={readonly} />
    </div>
  )
}
