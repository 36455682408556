import styles from "./Trash.module.scss";
import classNames from "classnames";

interface Props {
  onRemove: (_id: number) => void;
  id: number;
  className?: string;

  classes?: {
    root?: string;
    title?: string;
  };
}

const TarshIcon: React.FunctionComponent<Props> = ({onRemove, id, classes, className}) => {
  return (
    <span
      onClick={onRemove.bind(null, id)}
      className={classNames(styles.root, classes?.root, className)}>
      <span className={classNames(styles.title, classes?.title)}>
        Удалить поле
      </span>
    </span>
  );
};

export default TarshIcon;
