export enum Endpoints {
  LOGIN = "/auth/login",
  GET_SMS_CODE = "/auth/get-sms-code",
  CONFIRM_PHONE = "/auth/confirm-phone",
  REFRESH_TOKEN = "/auth/refresh-token",
  COMPANIES_INN = "/companies/inn",
  COMPANIES_BIK = "/companies/bik",
  AUTH_SIGNUP = "/auth/signup",
  LOGOUT = "/auth/logout",

  // получение options
  OPTIONS_COLOR = "/colors",
  OPTIONS_SEASONS = "/seasons",
  OPTIONS_COUNTRIES = "/countries",
  OPTIONS_CATEGORIES_PARENT = "/categories/parent",
  OPTIONS_CATEGORIES = "/categories",
  OPTIONS_PACKAGES = "/categories/packages-types",
  OPTIONS_MATERIALS = "/categories/materials",
  ALL_TAGS = "/tags",

  LIST_COMPOUNDS = "/categories/compounds",
  LIST_SIZES = "/categories/sizes",
  LIST_DETAILS = "/categories/details",

  // загрузка файлов
  POST_FILE = "/files/create",
  USER = "/user",
  GET_PRODUCT = "/products/view",
  GET_PRODUCTS = "/products",
  UPDATE_PRODUCT = "/products/update",

  //   Категории товаров
  GET_CATEGORIES = "products?status_id=",
  // SOFT_DELETE_PRODUCTS = "/products/soft-delete",
  SOFT_DELETE_PRODUCTS = "/products/delete-all",
  DELETE_PRODUCTS = "/products/delete-all",
  RESTORE_PRODUCTS = "/products/restore-all",
  PRODUCTS_COUNT = "/products/count",

  // massimport
  GET_ARCHIVE_TEMPLATE = "/excel?category=",
  UPLOAD_ARCHIVE = "/excel/upload/",
  SEND_TO_MODERATE = "/excel/moderate",

  // mass edit
  GET_ARCHIVE = "/excel/products-to-excel?products_id=",
  UPLOAD_EDITED_ARCHIVE = "/excel/upload-all",
  GET_SELECTED_PRODUCTS = "/products/get-by-ids",
  CREATE_DISCOUNT = "/discount/create",
  REMOVE_DISCOUNT = "/discount/delete-products",
  FETCH_AVAILABLE_BANNER_VALUES = "/discounts-banners/available-properties",
  DISCOUNT_HISTORY = "/discount/history",
  PRODUCTS_PRICES_HISTORY = "/products-prices/history",
  GET_PRODUCTS_FOR_PRICE_EDIT = "/excel/price-to-excel",
  EDIT_PRODUCTS_PRICE_UPLOAD = "/excel/upload-price",
  SET_PRODUCTS_PRICES = "/products-prices/set",
  GET_PROMOTIONS = "/promotions",
  GET_PROMOTION = "/promotions/view/",

  GET_STATISTICS = "/categories/statistics",

  CREATE_BARCODES = "/barcodes",

  TEMPLATES = '/templates',
  TEMPLATE_CREATE = '/templates/create',
  TEMPLATE_DELETE = '/templates/delete/',
  FEEDBACK = '/feedbacks',
  FEEDBACK_RESPONSE = '/feedbacks/response',
  FEEDBACK_MASS_RESPONSE = '/feedbacks/mass-response',

  // FBS
  GET_FBS_NEW_ORDERS = "/fbs-orders",
  GET_FBS_COLLECTED_ORDERS = "/fbs-orders/collected",
  GET_FBS_SHIPPED_ORDERS = "/fbs-orders/delivered",
  GET_FBS_CLOSED_ORDERS = "/fbs-orders/closed",
  GET_ITEMS_BALANCE = "/storages/balance",
  GET_FBS_CREATE_NEW_ORDER = "/fbs-orders/collect",
  RELOAD_ORDERS = "/fbs-orders/collected-pulse",



}
