import React from 'react'
import styles from './DPInfo.module.scss'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { CdekOffice } from '../../../../../../../../../types/swagger/llyApi_new'

type Props = {
  className?: string
  deliveryPoint: CdekOffice
}

export default function DPInfo({ deliveryPoint, className }: Props) {

  const handleCopyCoor = () => {
    navigator.clipboard.writeText(`${deliveryPoint.location.latitude}, ${deliveryPoint.location.longitude}`).then(function () {
      toast.success("Координаты скопированы в буфер обмена", { theme: "light" });
    }, function (err) {
      toast.error("Ошибка", { theme: "light" });
    });
  }
  return (
    <div className={classNames(styles.dp, className)}>
      <div className={styles.head}>
        <div className={styles.title}>Отделение CDEK №{deliveryPoint.code}</div>
        <div className={styles.address}>{deliveryPoint.location.address_full}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.schedule}>
          <div className={styles.title}>График работы</div>
          <div className={styles.content}>{deliveryPoint.work_time.map((wt, i) => <div key={i}>{wt}</div>)}</div>
        </div>
        <div className={styles.schedule}>
          <div className={styles.title}>Доп. информация</div>
          <div className={styles.content}>{deliveryPoint.note}</div>
        </div>
        <div className={styles.coordinates} onClick={handleCopyCoor}>Копировать координаты</div>
      </div>
    </div>
  )
}
