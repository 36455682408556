import React from 'react'

import styles from './CustomRadioButtons.module.scss'
import classNames from 'classnames'
import { CustomHint } from '../CustomHint'

export type CustomRadioButton = {
  label: string
  name: string
}

type IOnChange = {
  name: string | number
  value: string | number
}

type Props = {
  label: string
  name: string | number
  onChange: (data: IOnChange) => void
  btns: CustomRadioButton[]
  activeName: string | number
  hint?: string | JSX.Element
  hintWidth?: number
  readonly?: boolean
  isColumn?: boolean
}

export default function CustomRadioButtons({ btns, onChange, activeName, label, hint, hintWidth, name, readonly, isColumn }: Props) {

  const handleClick = (value: string | number) => () => {
    onChange && onChange({ name, value })
  }

  const body = readonly
    ? <div>{btns.find(btn => btn.name === activeName)?.label || 'Не указано'}</div>
    : < div className={styles.btns} >
      {
        btns.map((btn, i) => <div
          key={`${btn.name}-${i}`}
          className={classNames(styles.btn, { [styles.active]: activeName === btn.name })}
          onClick={handleClick(btn.name)}
        >{btn.label}</div>)
      }
    </div >

  return (
    <div className={classNames(styles.radio_group, {
      [styles.readonly]: readonly,
      [styles.column]: isColumn,
    })}>
      <div className={styles.head}>
        <div className={styles.label}>{label}</div>
        {hint && <CustomHint hint={hint} hintWidth={hintWidth} />}
      </div>
      {body}
    </div>
  )
}
