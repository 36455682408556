import React, { useState } from 'react'

import styles from './Logo.module.scss'

import plus from './assets/plus.svg'
import Dropzone from 'react-dropzone'
import api from '../../../utils/api'
import { Files } from '../../../types/swagger/llyApi_new'
import { AxiosProgressEvent } from 'axios'
import classNames from 'classnames'


type Props = {
  logoUrl?: string,
  editMode?: boolean,
  progress: number
  onUpload?: (id: number, url: string) => void
}

type UploadResponse = {
  success?: boolean
  data?: Files
  errors?: {
    field_name?: string
  }[]
}

export default function Logo({ logoUrl, editMode, onUpload, progress }: Props) {

  const [uploadProgress, setUploadProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const onDrop = (data: File[]) => {
    if (data.length === 1) {
      const url = '/files/create';
      setUploadProgress(0)
      setIsUploading(true)

      const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / (progressEvent.total || -1)) * 100) as any,
        );
        setUploadProgress(uploadPercentage)
      }

      api.post<UploadResponse>(url, { file: data[0] }, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress })
        .then(response => {
          if (response?.data?.success) {
            onUpload && onUpload(response.data.data?.id || -1, response.data.data?.url || '')
          }
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          setIsUploading(false)
        })
    }
  }

  const accept = { "image/png": [".png"], 'image/jpg': ['.jpg'] }
  const maxSize = 5 * 1024 ** 2

  const gag = editMode
    ? <div className={styles.gag}>
      <img src={plus} />
      <div className={styles.gag_text}>Загрузить логотип</div>
    </div>
    : undefined

  let _logo: JSX.Element | undefined
  if (isUploading) {
    _logo = <div className={styles.uploading_info}>Загрузка: {uploadProgress}%</div>
  } else {
    _logo = logoUrl ? <img src={logoUrl} /> : gag
  }

  return (
    <div className={styles.logo}>
      <Dropzone onDrop={onDrop} multiple={false} accept={accept} maxSize={maxSize} disabled={!editMode} >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>

            <div className={styles.logo_wrap}>
              {_logo}
            </div>
            {(!isUploading && editMode && logoUrl !== undefined) && <div className={styles.replace_btn_wrap}>
              <div className={styles.replace_btn}>
                <div className={styles.replace_btn_text}>Заменить логотип</div>
              </div>
            </div>}
            <div className={classNames(styles.progress_img, {
              [styles.progress0]: progress < 20,
              [styles.progress20]: progress >= 20 && progress < 40,
              [styles.progress40]: progress >= 40 && progress < 60,
              [styles.progress60]: progress >= 60 && progress < 80,
              [styles.progress80]: progress >= 80 && progress < 100,
              [styles.progress100]: progress === 100,
            })} />
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    </div>
  )
}
