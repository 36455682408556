import React, { useEffect, useState } from 'react'
import api from "../../../../../utils/api";
import { BlueButton } from '../../../../simples/BlueButton'
import { Currency, PaymentDetailsDTO } from '../../../../../types/swagger/llyApi_new'
import { CustomTextInput, IOnChange } from '../../../../simples/CustomTextInput'
import { CustomSelect, IOnSelectChange, Option } from '../../../../simples/CustomSelect';

import styles from './PaymentRequisites.module.scss'
import { toast } from 'react-toastify';
import CustomHintTexts from '../../../../../constants/customHints';
import { useAppSelector } from '../../../../../hooks/store';
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2';

type FetchDataResponse = {
  success?: boolean
  data?: PaymentDetailsDTO
  available_currencies?: Currency[]
  errors?: string[]
}

export type SaveDataResponse = {
  success?: boolean
  data?: PaymentDetailsDTO
  errors?: string[]
}


function getEmptyData() {
  return {
    /** БИК */
    bik: '',
    /** Наиминование банка */
    bank_name: '',
    /** Адрес банка */
    bank_address: '',
    /** Корреспондентский счет */
    correspondent_account: '',
    /** Расчетный счет */
    checking_account: '',
    // /** Номер счета */
    // account: '',
    /** ID валюты */
    // currency_id: 810, // 643
    /** Возможно редактирование */
    editable: true,
  }
}

const defaultData = getEmptyData()

enum Mode {
  view = 'view',
  edit = 'edit',
  update = 'update'
}


type FetchDataByBIKResponse = {
  success?: boolean
  data?: Array<{
    data: {
      bic: string
      name: {
        payment: string
      }
      address: {
        unrestricted_value: string
      }
      correspondent_account: string
    }
    unrestricted_value: string
    value: string
  }>
}

function getDisabledFields(values: PaymentDetailsDTO) {
  const disabledFields: string[] = []
  // ['bank_name', 'bank_address', 'correspondent_account', 'currency_id']
  if (values.bank_name && values.bank_name?.length > 0) {
    disabledFields.push('bank_name')
  }
  if (values.bank_address && values.bank_address?.length > 0) {
    disabledFields.push('bank_address')
  }
  if (values.correspondent_account && values.correspondent_account?.length > 0) {
    disabledFields.push('correspondent_account')
  }
  if (values.currency_id !== undefined && values.currency_id !== null) {
    disabledFields.push('currency_id')
  }
  return disabledFields
}

const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });

export default function PaymentRequisites() {

  const [mode, setMode] = useState(Mode.view)
  const [values, setValues] = useState<PaymentDetailsDTO>(defaultData)
  const [bik, setBik] = useState<string | number>()
  const [loading, setLoading] = useState(true)
  const [disabledFields, setDisabledFields] = useState<string[]>([])

  const isActive = useAppSelector(state => state.auth.user?.companyStatus?.active)

  const [currencies, setCurrencies] = useState<Currency[]>([])


  useEffect(() => {
    setLoading(true)
    api.get<FetchDataResponse>('/companies/bank-info').then(data => {
      if (data.data.success) {
        setCurrencies(data.data.available_currencies || [])
        const newValues = data.data.data || getEmptyData()
        setValues(newValues)
        setDisabledFields(getDisabledFields(newValues))
        // setLoading(false)
      } else {
        errorMsg()
      }
    }).catch(e => {
      console.log(e);
      errorMsg()
      const newValues = getEmptyData()
      setValues(newValues)
      setDisabledFields(getDisabledFields(newValues))
    }).finally(() => {
      setLoading(false)
    })
  }, [mode])

  useEffect(() => {
    if (bik === undefined) return
    setLoading(true)
    api.get<FetchDataByBIKResponse>('/companies/bik?bik=' + bik).then(data => {
      if (data.data.success && data.data.data !== undefined && data.data.data.length > 0) {
        const bikData = data.data.data[0]
        const newValues = {
          ...values,
          bik: bikData.data.bic,
          bank_name: bikData.data.name.payment,
          bank_address: bikData.data.address.unrestricted_value,
          correspondent_account: bikData.data.correspondent_account,
        }
        setValues(newValues)
        setDisabledFields(getDisabledFields(newValues))
      } else {
        errorMsg()
      }
    }).catch(e => {
      console.log(e);
      errorMsg()
      const newValues = getEmptyData()
      newValues.bik = bik.toString()
      setValues(newValues)
      setDisabledFields(getDisabledFields(newValues))
    }).finally(() => {
      setLoading(false)
    })
  }, [bik])




  const handleChangeValue = ({ name, value }: IOnChange) => {
    setValues({ ...values, [name]: value })
    if (name === 'bik') setBik(value)
  }
  const handleChangeCurrency = ({ name, value }: IOnSelectChange) => {
    setValues({ ...values, [name]: value })
  }
  const handleSave = () => {
    setMode(Mode.view)
    api.post<SaveDataResponse>('/companies/edit-bank-info', values
    ).then(data => {
      if (data.data.success) {
        saveSuccessMsg()
        setValues(data.data.data || getEmptyData())
      } else {
        errorMsg()
      }
    }).catch(e => {
      console.log(e);
      errorMsg()
    })
  }
  const handleEdit = () => {
    setMode(Mode.edit)
    // setDisabledFields([])
  }
  const handleUpdate = () => {
    setMode(Mode.update)
    // setDisabledFields([])
  }
  const handleSendUpdate = () => {
    // setMode(Mode.view)
    // return
    handleSave()
  }
  const handleCancel = () => {
    setMode(Mode.view)
  }

  const currencyOprions: Option[] = currencies.map(c => ({
    value: c.id,
    label: `${c.currency} - ${c.description}`
  }))


  const editMode = mode !== Mode.view

  if (loading) {
    return <div className={styles.wrap}>Загрузка данных...</div>
  }

  const isValid = values !== undefined && values.bik !== undefined && values.bik.length === 9
    && values.bank_name !== undefined && values.bank_name.length > 0
    && values.bank_address !== undefined && values.bank_address.length > 0
    && values.correspondent_account !== undefined && values.correspondent_account.length === 20
    && values.checking_account !== undefined && values.checking_account.length === 20
    && values.currency_id !== undefined && values.currency_id !== null


  return (
    <div className={styles.wrap}>
      <CustomTextInput name='bik' value={values.bik} label='Бик' mask={'999999999'} hint={CustomHintTexts.BIC} hintWidth={550} onChange={handleChangeValue} required
        readonly={!editMode} disabled={disabledFields.indexOf('bik') !== -1} />
      <CustomTextInput name='bank_name' value={values.bank_name} label='Наименование банка' onChange={handleChangeValue} required
        readonly={!editMode} disabled={disabledFields.indexOf('bank_name') !== -1} />
      <CustomTextInput name='bank_address' value={values.bank_address} label='Адрес банка' onChange={handleChangeValue} required
        readonly={!editMode} disabled={disabledFields.indexOf('bank_address') !== -1} />
      <CustomTextInput name='correspondent_account' value={values.correspondent_account} label='Корреспондентский счет' onChange={handleChangeValue} required
        readonly={!editMode} disabled={disabledFields.indexOf('correspondent_account') !== -1} mask={'99999999999999999999'}
        error={(values.correspondent_account !== undefined && values.correspondent_account !== '' && values.correspondent_account?.length < 20) ? '! Заполните поле' : undefined}
      />
      <CustomTextInput name='checking_account' value={values.checking_account} label='Расчетный счет' onChange={handleChangeValue} required
        readonly={!editMode} disabled={disabledFields.indexOf('checking_account') !== -1} mask={'99999999999999999999'}
        error={(values.checking_account !== undefined && values.checking_account !== '' && values.checking_account?.length < 20) ? '! Заполните поле' : undefined}
      />
      <CustomSelect name='currency_id' onChange={handleChangeCurrency} label='Валюта' options={currencyOprions} value={values.currency_id}
        readonly={!editMode}
      // disabled={disabledFields.indexOf('currency_id') !== -1}
      />

      {/* {mode === Mode.view && <div className={styles.update} onClick={handleUpdate} >Обновить юридическую информацию</div>} */}
      <div className={styles.btns}>
        {mode === Mode.update && <BlueButton title='Отправить на модерацию' onClick={handleSendUpdate} />}
        {mode === Mode.edit && <BlueButton title='Сохранить' onClick={handleSave} disabled={!isValid} />}
        {mode !== Mode.view && <BlueButton title='Отмена' onClick={handleCancel} />}
        {mode === Mode.view && !isActive && <BlueButton title='Редактировать' onClick={handleEdit} />}
      </div>
    </div>
  )
}

