import React from "react";
import { ButtonSize, ButtonType } from "../../../../simples/BlueButton/BlueButton";
import { ModalDialog, ButtonConfig } from "../../../../simples/ModalDialog";

type Props = {
  text: string
  title?: string
  onClose: () => void
}

export default function ModalNotification({text, title, onClose}: Props) {

  const isActive = text !== "";

  const handleClose = () => {
    onClose()
  };

  const buttonsConfig: ButtonConfig[] = [
    { text: "Ок", type: ButtonType.Ok, onClick: handleClose, size: ButtonSize.Big },
  ];
  return (
    <ModalDialog isActive={isActive} text={text} title={title} buttonsConfig={buttonsConfig} handleClose={handleClose} />
  );
}
