const CustomHintTexts = {
  // TIN = "Налог на добавочную стоимость (НДС) платят компании и ИП на общей системе налогообложения, а также все, кто ввозит товары через границу, то есть занимается импортом.<br/>
  //   Ставка «без НДС» полностью исключает налог из суммы продажи. То есть организация не является плательщиком НДС или временно освобождена от уплаты налога. Организация может не платить НДС в случаях если: <br/>
  //   - Работает на спецрежиме — УСН, НПД или патенте.<br/>
  //   - Применяет ОСНО или ЕСХН и получил освобождение от НДС.<br/>
  //   - Занимается бизнесом, которому по закону разрешено не платить НДС.",
  // VAT = "Идентификационный номер налогоплательщика — цифровой<br/>
  //   код, упорядочивающий учёт налогоплательщиков в Российской<br/>
  //   Федерации. Присваивается налоговой записи как юридических,<br/>
  //   так и физических лиц Федеральной налоговой службой России.",
  BIC: <>
    Банковский идентификационный код (БИК) – девятизначный уникальный код кредитной организации.<br/><br/>Он предназначен для идентификации участников расчетов и является обязательным элементом реквизитов любого банка. БИК используется в платежных документах на территории России. Он позволяет определить название, корреспондентский счет и региональное расположение банка, а также подразделение ЦБ РФ, ответственное за его регистрацию и обслуживание.
  </>
}

export default CustomHintTexts;
