import React from 'react'
import styles from '../MainPage.module.scss'
import { News } from '../../../../types/swagger/llyApi_new'
import NewsListItem from './NewsListItem'
import Skeleton from 'react-loading-skeleton'

type Props = {
  news: News[]
  isLoading?: boolean
}

export default function NewsList({ news, isLoading }: Props) {
  return (
    <div className={styles.news_list}>
      <h1>Новости</h1>
      {isLoading
        ? <Skeleton count={5} height={100} style={{marginBottom: '24px'}} />
        : news.map((n, i) => <NewsListItem key={i} news={n} />)}
    </div>
  )
}
