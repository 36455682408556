import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setCollectionValue } from '../../../../../store/slices/product'
import { CustomTextInput } from '../../../../simples/CustomTextInput'

import styles from "./Collection.module.scss"

export default function Collection() {

  const dispatch = useAppDispatch()
  const collection = useAppSelector(state => state.product.collection.value)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const handleChangeValue = ({ value }) => {
    dispatch(setCollectionValue(value))
  }

  return (
    <div className={styles.colection}>
      <CustomTextInput name='colection' label='Коллекция' onChange={handleChangeValue} value={collection} readonly={readonly} />
    </div>
  )
}
