import React from 'react'
import { IItem, setRemainings } from '../../../../../../../../../store/slices/fbs'

import styles from './Remainings.module.scss'
import { CustomTextInput_v2, IOnChange } from '../../../../../../../../simples/CustomTextInput_v2'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store'
import classNames from 'classnames'

type Remaining = {
  item: IItem
  countInOrders: number
  warehouse: string
  balanceInWarehouse: number
  shipmentFromWarehouse: number
}

export default function Remainings() {

  const dispatch = useAppDispatch()
  const { remainings } = useAppSelector(state => state.fbs)
  // console.log({ remainings });

  /*
  const remainings: Remaining[] = [{
    item: {
      id: 'item 1.1',
      code: '15560B22222XS',
      name: 'Ботинки 1',
    },
    countInOrders: 11,
    warehouse: 'г. Москва, ул. Ленина 34',
    balanceInWarehouse: 10,
    shipmentFromWarehouse: 1
  }, {
    item: {
      id: 'item 1.1',
      code: '15560B22222XS',
      name: 'Ботинки 1',
    },
    countInOrders: 11,
    warehouse: 'г. Москва, ул. Ленина 34',
    balanceInWarehouse: 10,
    shipmentFromWarehouse: 1
  }]
  */


  const handleChangeShipmentValue = (itemId: string, warehousId: string) => ({ value }: IOnChange) => {
    dispatch(setRemainings({ itemId, warehousId, shipmentValue: parseInt(value.toString()) }))
  }

  return (
    <table className={styles.remainings}>
      <thead>
        <tr>
          <th className={styles.item}>Товар</th>
          <th className={styles.count}>Общее кол-во</th>
          <th className={styles.warehouse}>Название склада</th>
          <th className={styles.balance}>Остаток на складе</th>
          <th className={styles.shipment}>Кол-во отгруженного товара со склада</th>
        </tr>
      </thead>
      <tbody>
        {remainings.map((data, index) => data.warehouses.map((w, wi) => {
          const shipmentCount = data.warehouses.map(w => w.shipment).reduce((v, a) => v + (a||0), 0);

          return <tr key={`${data.item.id}_${w.id}`} className={classNames({ [styles.error]: data.count !== shipmentCount })} >
            {wi === 0 && <>
              <td className={styles.item} rowSpan={data.warehouses.length}>
                <div className={styles.name}>{data.item.name}</div>
                <div className={styles.code}>{data.item.code}</div>
              </td>
              <td className={styles.count} rowSpan={data.warehouses.length}>{data.count}</td>
            </>
            }
            <td className={styles.warehouse}>{w.name}</td>
            <td className={styles.balance}>{w.balance}</td>
            <td className={styles.shipment}>
              <CustomTextInput_v2 key={w.id} name='shipmentValue'
                className={styles.input}
                onChange={handleChangeShipmentValue(data.item.id, w.id)}
                value={w.shipment}
                hideError
              />
            </td>
          </tr>
        }))}
      </tbody>
    </table>
  )
}
