import React, { FC } from "react";
import styles from "./Pagination.module.scss";
import btnForwardArrow from "../../../../../../../../../assets/icons/9025211_arrow_circle_right_icon.png";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../hooks/store";
import { RootState } from "../../../../../../../../../store";
import { fetchGoods, setActivePageIndex, setRowsLimit } from "../../../../../../../../../store/slices/goodsList";
import classNames from "classnames";
import { GoodsListTexts } from "../../../../../../../../../constants/goodsListTexts";
import { CustomSelect_v2, IOnSelectChange, Option } from "../../../../../../../../simples/CustomSelect_v2";

const Pagination: FC = () => {

  const dispatch = useAppDispatch();
  const activePageIndex = useAppSelector((state: RootState) => state.productsList.activePageIndex);
  const showRowsCount = useAppSelector((state: RootState) => state.productsList.rowsLimit);

  const pagesCount = useAppSelector((state: RootState) => {
    const { categories, selectedCategoryID } = state.productsList;
    const countGoods = categories.find(c => c.id === selectedCategoryID)?.countGoods || 0;
    const pagesCount = countGoods / state.productsList.rowsLimit;
    return pagesCount;
  });

  const handleSelectPage = index => {
    dispatch(setActivePageIndex(index));
  };

  const handleSelectNextPage = () => {
    if (activePageIndex + 1 < pagesCount) {
      dispatch(setActivePageIndex(activePageIndex + 1));
    }
  };

  const handleSelectPreviousPage = () => {
    if (activePageIndex > 0) {
      dispatch(setActivePageIndex(activePageIndex - 1));
    }
  };

  const btns: JSX.Element[] = [];
  for (let i = 0; i < pagesCount; i++) {
    btns.push(<PaginationBtn key={i.toString()} isSelected={activePageIndex === i} pageIndex={i} selectPage={handleSelectPage} />);
  }

  React.useEffect(() => {
    dispatch(fetchGoods());
  }, [activePageIndex, showRowsCount]);

  // const handleChangeRowsCount = e => {
  //   dispatch(setRowsLimit(e.target.value));
  // };
  const handleChangeRowsCount = ({ value }: IOnSelectChange) => {
    dispatch(setRowsLimit(value));
  };

  const options: Option[] = [{ value: 10, label: '10' },{ value: 50, label: '50' },{ value: 99999999999, label: 'все' }]

  return <div className={styles.wrapper}>
    <div className={styles.footer_txt}>
      <span>{GoodsListTexts.Pagination_rowsCount_title}</span>
      <CustomSelect_v2 name='page' className={styles.select} value={showRowsCount} options={options} onChange={handleChangeRowsCount} hideError isMini isReverse />
      {/* <select value={showRowsCount}
        onChange={handleChangeRowsCount}
      >
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="99999999999">все</option>
      </select> */}
    </div>
    {pagesCount > 1 && <div className={styles.buttons}>
      <img
        className={classNames(styles.footer_btn_backward__arrow, styles.footer_btn)}
        onClick={handleSelectPreviousPage}
        src={btnForwardArrow} />
      {btns}
      <img
        className={classNames(styles.footer_btn_forward__arrow, styles.footer_btn)}
        onClick={handleSelectNextPage}
        src={btnForwardArrow} />
    </div>
    }
  </div>;
};


interface PaginationBtnInterface {
  pageIndex: number,
  selectPage: (pageIndex: number) => void,
  isSelected: boolean;
}

const PaginationBtn: FC<PaginationBtnInterface> = ({ pageIndex, selectPage, isSelected }) => {
  const handleClick = () => {
    selectPage(pageIndex);
  };
  const className = classNames(styles.footer_btn, styles.footer_btn_page, { [styles.selected]: isSelected });
  return (<div onClick={handleClick} className={className}>{pageIndex + 1}</div>);
};

export default Pagination;
