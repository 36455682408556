import React from 'react'
import { addMaterial } from '../../../../../store/slices/product'


import plus_icon from '../../assets/plus.svg'
import { useAppDispatch } from '../../../../../hooks/store'

import styles from "./Compositions.module.scss"

type Props = {
  compoundId: number
}

export default function AddBtn({ compoundId }: Props) {

  const dispatch = useAppDispatch()
  const handleAdd = () => {
    dispatch(addMaterial({ compoundId }))
  }

  return (
    <img src={plus_icon} className={styles.add_btn} onClick={handleAdd} />
  )
}
