import React, {Dispatch, FC, SetStateAction} from "react";
import plus from "../../../../../../../assets/icons/addBtn.svg";
import {LogoType} from "../../../../../../../types/logos";
import {LogoCard} from "./LogoCard";

import styles from "./ListView.module.scss";

interface LogoFormatListProps {
  logoList: LogoType[];
  handleSelected: (id: number) => void;
  setUpload: Dispatch<SetStateAction<boolean>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
  selectedId: number | null;
}
const ListView: FC<LogoFormatListProps> = ({
  logoList,
  handleSelected,
  setUpload,
  setIsUpdate,
  selectedId,
}) => {
  const logoHandler = () => {
    setUpload(true);
    setIsUpdate(false);
  };

  return (
    <div className={styles.container}>
      <button className={styles.create} onClick={logoHandler}>
        <img src={plus} alt="Create Logo" />
        <p className={styles.title}> Создать новый логотип</p>
      </button>
      {logoList.map((item,index) => (
        <LogoCard
          key={item.id}
          item={item}
          selectedId={selectedId}
          handleSelected={handleSelected}
          gridCount={index}
        />
      ))}
    </div>
  );
};

export default ListView;
