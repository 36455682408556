import React, { useState, useEffect } from 'react'
import styles from './SearchInput.module.scss'
import searchIcon from "./assets/search_icon.svg";
import xIcon from "./assets/cros.svg";
import { TextInput } from '../../simples/TextInput';
import classNames from 'classnames';

type Props = {
  onChange: (value: string) => void
  className?: string
}

export default function SearchInput({ onChange, className }: Props) {

  const [value, setValue] = useState('')

  useEffect(() => {
    onChange(value)
  }, [value])


  const handleClearSearch = () => {
    setValue('')
  }
  const handleChange = (value) => {
    setValue(value)
  }

  return (
    <TextInput
      className={classNames(styles.search, className)}
      value={value}
      onChange={handleChange}
      icon={value.length === 0 ? searchIcon : xIcon}
      onClickOnIcon={handleClearSearch}
      placeholder="Поиск" />
  )
}
