import React from 'react'
import { StepsIndicator } from '../../../../complexes/StepsIndicator';
import { Step, setStep, steps } from '../../../../../store/slices/fbs';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import styles from './NewDeliveryRequest.module.scss'
import Confirmation from './parts/Confirmation/Confirmation';
import Packaging from './parts/Packaging/Packaging';
import GearSheet from './parts/GearSheet/GearSheet';
import Planning from './parts/Planning/Planning';



export default function NewDeliveryRequest() {
  const { step } = useAppSelector(state => state.fbs)
  const dispatch = useAppDispatch()
  const handleClick = (step: Step) => {
    dispatch(setStep(step))
  }
  let content: JSX.Element
  switch (step) {
    case Step.Confirmation: {
      content = <Confirmation />
      break;
    }
    case Step.Package: {
      content = <Packaging />
      break;
    }
    case Step.Planning: {
      content = <Planning /> //<Maps />
      break;
    }
    case Step.GearSheet: {
      content = <GearSheet />
      break;
    }

    default:
      content = <div>{step}</div>
      break;
  }

  return (
    <div>
      <StepsIndicator steps={steps} currentStep={step} onClick={handleClick} />
      <div className={styles.content}>
        {content}
      </div>
    </div>
  )
}
