import React, { useEffect, useState, useRef } from 'react'
import { Layout } from '../../complexes/Layout'

import api from "../../../utils/api";
import { CalendarMonth } from './parts/CalendarMonth';
import { CalendarWeek } from './parts/CalendarWeek';
import { Promotions } from '../../../types/swagger/llyApi';
import { CalendarDay } from './parts/CalendarDay';

import iIcon from "./assets/i_icon.svg"

import styles from "./ActionsCalendar.module.scss"
import { Select } from '../../simples/Select';
import { Endpoints } from '../../../constants/server';
// import { OptionSelector } from './parts/OptionSelector';
// import { Option } from './parts/OptionSelector/OptionSelector';
import classNames from 'classnames';
import { PrevNextSwitch } from './parts/PrevNextSwitch';
import { add, endOfWeek, format, startOfMonth, startOfWeek, sub } from 'date-fns';
import { ru } from 'date-fns/locale'
import { ModalActionInfo } from './parts/ModalActionInfo';
import { CustomSelect } from '../../simples/CustomSelect';
import { Option, OptionSelector } from '../../simples/OptionSelector';

type ActionsAnswer = {
  success?: boolean
  data?: Promotions[]
}

enum CalendarPeriod {
  month = "month",
  week = "week",
  day = "day"
}

const months = [
  "Январь",
  "Феварль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];

const yearOptions = [{ label: "2022", value: 2022 }, { label: "2023", value: 2023 }, { label: "2024", value: 2024 }, { label: "2025", value: 2025 }]
const MonthOptions = months.map((m, i) => ({ label: m, value: i }))
const CalendarPeriodOptions: Option<CalendarPeriod>[] = [{
  label: "Месяц",
  value: CalendarPeriod.month
}, {
  label: "Неделя",
  value: CalendarPeriod.week
}, {
  label: "День",
  value: CalendarPeriod.day
}]

const Now = new Date()

export default function ActionsCalendar() {

  // const [fromDate, setFromDate] = useState()
  const [year, setYear] = useState<number>(Now.getFullYear())
  const [month, setMonth] = useState(Now.getMonth())
  const [day, setDay] = useState(Now.getDate())
  const [period, setPeriod] = useState<CalendarPeriod>(CalendarPeriod.month)
  const [actions, setActions] = useState<Promotions[]>([])

  const [selectedActionId, setSelectedActionId] = useState<number>()

  useEffect(() => {
    const req = `${Endpoints.GET_PROMOTIONS}?start_date=${year}-01-01&finish_date=${year + 1}-12-31`
    api.get<ActionsAnswer>(req)
      .then((res) => {
        if (res.data?.success && res.data.data) {
          setActions(res.data.data)
        }
      })
      .catch((err) => {
        setActions([])
        console.log(err)
      });
  }, [year])

  const getDate = () => {
    return new Date(year, month, day)
  }

  const handleSelectAction = (actionId: number) => {
    setSelectedActionId(actionId)
  }
  const handleCloseActionInfo = () => {
    setSelectedActionId(undefined)
  }

  let content: JSX.Element | undefined;
  switch (period) {
    case CalendarPeriod.month: {
      const firstDayOfMonth = startOfMonth(getDate())
      content = <CalendarMonth actions={actions} firstDayOfMonth={firstDayOfMonth} selectAction={handleSelectAction} />
      break;
    }
    case CalendarPeriod.week: {
      const firstDayOfWeek = startOfWeek(getDate(), { weekStartsOn: 1 })
      content = <CalendarWeek actions={actions} firstDayOfWeek={firstDayOfWeek} selectAction={handleSelectAction} />
      break;
    }
    case CalendarPeriod.day: {
      content = <CalendarDay actions={actions} title={format(getDate(), "EEEEEE", { locale: ru })} dayDate={getDate()} selectAction={handleSelectAction} />
      break;
    }
    default:
      break;
  }

  const setDate = (date: Date) => {
    setYear(date.getFullYear())
    setMonth(date.getMonth())
    setDay(date.getDate())
  }
  const handleSwToNextMonth = () => {
    setDate(add(getDate(), { months: 1 }))
  }
  const handleSwToPrevtMonth = () => {
    setDate(sub(getDate(), { months: 1 }))
  }
  const handleSwToNextWeek = () => {
    setDate(add(getDate(), { weeks: 1 }))
  }
  const handleSwToPrevtWeek = () => {
    setDate(sub(getDate(), { weeks: 1 }))
  }
  const handleSwToNextDay = () => {
    setDate(add(getDate(), { days: 1 }))
  }
  const handleSwToPrevtDay = () => {
    setDate(sub(getDate(), { days: 1 }))
  }
  const getWeekDated = () => {
    const date = getDate()
    const weekStart = format(startOfWeek(date, { weekStartsOn: 1 }), 'dd MMMM yyyy', { locale: ru })
    const weekEnd = format(endOfWeek(date, { weekStartsOn: 1 }), 'dd MMMM yyyy', { locale: ru })
    return `${weekStart} - ${weekEnd}`
  }

  let dateSwitch: JSX.Element | undefined;
  switch (period) {
    case CalendarPeriod.month:
      // dateSwitch = <>{months[month]} {year}</>
      // dateSwitch = <>{format(getDate(), 'MMMM yyyy', { locale: ru })}</>
      dateSwitch = <PrevNextSwitch text={`${months[month]} ${year}`} onNext={handleSwToNextMonth} onPrev={handleSwToPrevtMonth} />
      break;
    case CalendarPeriod.week:
      dateSwitch = <PrevNextSwitch text={getWeekDated()} onNext={handleSwToNextWeek} onPrev={handleSwToPrevtWeek} />
      break;
    case CalendarPeriod.day:
      dateSwitch = <PrevNextSwitch text={format(getDate(), 'dd MMMM yyyy', { locale: ru })} onNext={handleSwToNextDay} onPrev={handleSwToPrevtDay} />
      break;

    default:
      break;
  }

  const handleChangeYear = (o) => {
    setYear(parseInt(o.value))
  }

  const handleWheel = (e: any) => {
    if (e.deltaY > 0) {
      switch (period) {
        case CalendarPeriod.month:
          handleSwToNextMonth()
          break;
        case CalendarPeriod.week:
          handleSwToNextWeek()
          break;
        case CalendarPeriod.day:
          handleSwToNextDay()
          break;
        default:
          break;
      }
    } else {

      switch (period) {
        case CalendarPeriod.month:
          handleSwToPrevtMonth()
          break;
        case CalendarPeriod.week:
          handleSwToPrevtWeek()
          break;
        case CalendarPeriod.day:
          handleSwToPrevtDay()
          break;
        default:
          break;
      }
    }
    // console.log(e);
  }
  const ref = useRef<HTMLDivElement>(null)
  const preventDefault = e => e.preventDefault()
  useEffect(() => {
    ref?.current?.addEventListener('wheel', preventDefault)
    return () => {
      ref?.current?.removeEventListener('wheel', preventDefault)
    }
  }, [])


  return (<Layout title='Календарь акций' hint={<span>
    Мы планируем фестивали скидок на определённые даты и предлагаем Вам принять в них участие. Предложение о скидках будут появляться в календаре, чтобы принять участите выберите интересующий Вас период и добавьте необходимые товары к участию в распродаже. Подробнее в <a>инструкции</a>
  </span>}>
    <ModalActionInfo actionId={selectedActionId} handleClose={handleCloseActionInfo} />
    <div className={styles.wrap}>
      <div className={styles.info}>
        <div className={styles.info_item}>
          <div className={styles.title}>
            <img src={iIcon} alt="" />
            <span>Участие в календаре акций</span>
          </div>
          <div className={styles.content}>
            <span>Участие в акциях LL носят рекомендательный характер для всех продавцов площадки. При участии в акциях LL снижает комиссию на акционные товары на 5%</span>
          </div>
        </div>
        <div className={styles.info_item}>
          <div className={styles.title}>
            <img src={iIcon} alt="" />
            <span>Участие в календаре акций</span>
          </div>
          <div className={styles.content}>
            <span>Если Ваш магазин не учавствует в акции, то комиссия на акционные товары будет поднята на 12% автоматически</span>
            <div><a href="">Подробнее в инструкции</a></div>
          </div>
        </div>
      </div>
      <div className={styles.calendar}>
        <div className={styles.controls} onWheel={handleWheel} ref={ref}>
          <div className={classNames(styles.row, styles.year_and_month)}>
            {/* <Select
              name='year'
              options={yearOptions}
              value={year}
              onChange={handleChangeYear}
              className={styles.year_selector}
            /> */}
            <CustomSelect
              name='year'
              options={yearOptions}
              value={year}
              onChange={handleChangeYear}
              className={styles.year_selector}
              hideError
            />
            <OptionSelector<number> selectedOption={month} onSelect={setMonth} options={MonthOptions} />
          </div>
          <div className={classNames(styles.row, styles.period_and_date)}>
            <OptionSelector<CalendarPeriod> selectedOption={period} onSelect={setPeriod} options={CalendarPeriodOptions} />
            <div className={styles.switch_wrap}>{dateSwitch}</div>
          </div>
        </div>
        {content}
      </div>
    </div>

  </Layout>
  )
}
