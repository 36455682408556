import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./components/App/App";
import { store } from "./store";

import "react-datepicker/dist/react-datepicker.css"
import "react-datepicker/src/stylesheets/datepicker.scss";
import "./styles/app.global.scss";
import 'react-loading-skeleton/dist/skeleton.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
