import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { RootState } from "../../../../../store";
import { setModalNotificationText } from "../../../../../store/slices/goodsList";
import { ButtonSize, ButtonType } from "../../../../simples/BlueButton/BlueButton";
import { ModalDialog, ButtonConfig } from "../../../../simples/ModalDialog";

export default function ModalNotification() {

  const dispatch = useAppDispatch();
  const text = useAppSelector((state: RootState) => state.productsList.modalNotificationText);
  const isActive = text !== "";

  const handleClose = () => {
    dispatch(setModalNotificationText(""));
  };

  const buttonsConfig: ButtonConfig[] = [
    { text: "Ок", type: ButtonType.Ok, onClick: handleClose, size: ButtonSize.Big },
  ];
  return (
    <ModalDialog isActive={isActive} text={text} buttonsConfig={buttonsConfig} handleClose={handleClose} />
  );
}
