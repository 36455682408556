import React from 'react';
import styles from "./RadioButtons.module.scss";
import classNames from 'classnames';
import RadioButton from './RadioButton';

type Props = {
  className?: string
  value: string
  options: RadioButtonsOption[]
  onChange: (value: string) => void
  disabled?: boolean
}

export type RadioButtonsOption = {
  label: string
  value: string
  icon?: JSX.Element
}

export default function RadioButtons({ value, options, className, onChange, disabled }: Props) {
  const handleChange = (value) => () => {
    if(disabled) return
    onChange(value);
  }
  return (
    <div className={classNames(styles.wrap, className)}>
      {options.map(o => <RadioButton disabled={disabled} key={o.value} checked={o.value === value} icon={o.icon} label={o.label} onChange={handleChange(o.value)} />)}
    </div>
  )
}
