import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'
import { formatPeriod } from '../../../../../utils/action'
import calendar_icon from "../../assets/calendar_icon.svg"
import styles from "../../JoinInPromotion.module.scss"

type Props = {
  action: Promotions
}

export default function Action({ action }: Props) {
  return (
    <div className={styles.action}>
      <div className={styles.action_title}>
        {action.title}
      </div>
      <div className={styles.action_body}>
        <div className={styles.period}>
          <img src={calendar_icon} alt="" /><span>Период:</span> <span>{formatPeriod(action.start_date || "", action.finish_date || "")}</span>
        </div>
        <div className={styles.discount}>
          <span>{action.text}</span> {action.isStandardDiscount?<span>-{action.percent}%!</span>:<span>2+1</span>}
        </div>
        <div className={styles.description}>
          <h3>Условия акции</h3>
          <span className={styles.description_text}>{action.description}</span>
        </div>
      </div>
    </div>
  )
}
