import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "..";
import { MinPrice } from "../../constants/editingProductCard";
import ProductEditService from "../../services/ProductEdit";
import {
  BarcodesResponseDataType,
  MaterialType,
  OtherFieldsSelect,
  PhotoType,
  productColorsPageType,
  Seasons,
} from "../../types/product";
import { generateNum, getErrorsColorPage } from "../../utils/productEddit";

const productColors: productColorsPageType = {
  colors_id: 0,
  price: MinPrice,
  man_article: "",
  color_article: "",
  additionalColors: [],
  sizes: [],
  productsPhotos: [],
  productsFashion: {
    file_id: 0,
  },
};

export interface FormState {
  isGeneratingBarcodes: boolean,
  barcodesError: string,
  activePage: number,

  otherField: OtherFieldsSelect[];
  photoList: PhotoType[];
  seasons: Seasons[];
  OptionsSubCategory: string;
  upMaxPercentCompound: number[];
  materials: MaterialType[];
  productForm: {
    id: number;

    isPricePerColor: boolean;
    isPricePerSize: boolean;

    category_id: number;
    name: string;
    brand: string;
    description: string;
    // main_article: string;
    country_id: number;
    package: {
      type_id: number;
      equipment: string;
      information: string;
      height: number;
      width: number;
      depth: number;
      gross_weight: number;
      net_weight: number;
    };
    productsColors: productColorsPageType[];

    productsCompound: {
      compound_id: number;
      material_id: number;
      value: number;
    }[];

    productsDetails: {
      detail_id: number;
      option_id?: number;
      value: string;
      option?: {
        id?: number;
        detail_id: number;
        name: string;
      };
    }[];
    seasons: number[];
    price: number;
    tags: number[];
    collection: string;
  };
}

export type ProductFormType = typeof initialState.productForm;

/**
 * Начальное состояние редьюсера ProductEdit/
 */

const initialState: FormState = {
  isGeneratingBarcodes: false,
  barcodesError: "",
  activePage: 1,

  OptionsSubCategory: "",
  otherField: [],
  seasons: [],
  materials: [],
  upMaxPercentCompound: [],
  photoList: [],
  productForm: {
    id: 0,

    isPricePerColor: false,
    isPricePerSize: false,

    category_id: 0,
    name: "",
    brand: "",
    // main_article: "",
    description: "",
    country_id: 1,
    package: {
      type_id: 0,
      equipment: "",
      information: "",
      height: 0,
      width: 0,
      depth: 0,
      gross_weight: 0,
      net_weight: 0,
    },
    collection: "",
    price: MinPrice,
    productsColors: [productColors],
    productsCompound: [],
    productsDetails: [],
    seasons: [],
    tags: [],
  },
};

/**
 * Создание слайса ProductEdit.
 */

export const ProductEditSlice = createSlice({
  name: "productEddit",
  initialState,
  reducers: {
    //Добавлении подкатегории
    productAddSubCategory: (state, action: PayloadAction<string>) => {
      state.OptionsSubCategory = action.payload;
    },
    //Загрузка сезонов
    productFetchSeasons: (state, action: PayloadAction<Seasons[]>) => {
      state.seasons = action.payload;
    },
    //Загрузка материалов
    productFetchMaterials: (state, action: PayloadAction<MaterialType[]>) => {
      state.materials = action.payload;
    },
    //Изменение упаковки
    productChangePackage: (state, action: PayloadAction<number>) => {
      state.productForm.package.type_id = action.payload;
    },
    //Добавление/Удаление страницы цветов
    productAddColorPage: state => {
      if (state.productForm.productsColors.length < 10) {
        state.productForm.productsColors = [
          ...state.productForm.productsColors,
          { ...productColors, price: state.productForm.price },
        ];
      }
    },
    productColorPageRemove: (state, action: PayloadAction<{ index: number }>) => {
      state.productForm.productsColors =
        state.productForm.productsColors.filter(
          (_, i) => i !== action.payload.index,
        );
      if (state.activePage > state.productForm.productsColors.length) {
        state.activePage = state.productForm.productsColors.length;
      }
    },

    // Изменение/Выбор цвета
    productChangeColor: (
      state,
      action: PayloadAction<{ index: number; id: number }>,
    ) => {
      const { index, id } = action.payload;
      state.productForm.productsColors[index].colors_id = id;
    },
    productChangeAdditionalColor: (
      state,
      action: PayloadAction<{
        indexPage: number;
        indexColor: number;
        color_id: number;
      }>,
    ) => {
      const { indexPage, color_id, indexColor } = action.payload;
      state.productForm.productsColors[indexPage].additionalColors[indexColor] =
        { color_id };
    },
    productAddcolor: (
      state,
      action: PayloadAction<{
        indexPage: number;
        id: number;
      }>,
    ) => {
      const { indexPage, id } = action.payload;
      if (
        state.productForm.productsColors[indexPage].additionalColors.length <= 1
      ) {
        state.productForm.productsColors[indexPage].additionalColors = [
          ...state.productForm.productsColors[indexPage].additionalColors,
          { color_id: id },
        ];
      } else return;
    },
    productColorRemove: (
      state,
      action: PayloadAction<{ indexColor: number; indexPage: number }>,
    ) => {
      const { indexPage, indexColor } = action.payload;

      state.productForm.productsColors[indexPage].additionalColors =
        state.productForm.productsColors[indexPage].additionalColors.filter(
          (_, i) => i !== indexColor,
        );
    },
    productSaveColorArticle: (
      state,
      action: PayloadAction<{ index: number; article: string }>,
    ) => {
      const { index, article } = action.payload;
      state.productForm.productsColors[index].color_article = article;
    },

    // Изменение/Выбор размеров
    productCheckedSizeInput: (
      state,
      action: PayloadAction<{ index: number; id: number }>,
    ) => {
      const { index, id } = action.payload;
      const isEmptyCheck = state.productForm.productsColors[index].sizes.find(
        s => s.id === id,
      );
      if (isEmptyCheck) {
        state.productForm.productsColors[index].sizes =
          state.productForm.productsColors[index].sizes.filter(
            s => s.id !== id,
          );
      } else {
        state.productForm.productsColors[index].sizes = [
          ...state.productForm.productsColors[index].sizes,
          { id, man_barcode: "", barcode: "", price: state.productForm.productsColors[index].price },
        ];
      }
    },
    productChangeInput: (
      state,
      action: PayloadAction<{ index: number; id: number; value: string }>,
    ) => {
      const { index, id, value } = action.payload;
      state.productForm.productsColors[index].sizes =
        state.productForm.productsColors[index].sizes.map(s =>
          s.id === id && value.length < 22 ? { ...s, man_barcode: value } : s,
        );
    },
    productCreacteBarCode: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.productForm.productsColors[index].sizes = state.productForm.productsColors[index].sizes.map(s =>
        s.id > 0 && !s.barcode ? { ...s, barcode: "" + generateNum() } : s,
      );
    },
    productColorRemoveSize: (
      state,
      action: PayloadAction<{ id: number; index: number }>,
    ) => {
      const { id, index } = action.payload;
      state.productForm.productsColors[index].sizes =
        state.productForm.productsColors[index].sizes.filter(s => s.id !== id);
    },

    //Загрузка/Удаление фотографий
    productPhotoLoad: (
      state,
      action: PayloadAction<{ file_id: number; index: number }>,
    ) => {
      const { file_id, index } = action.payload;
      state.productForm.productsColors[index].productsFashion.file_id = file_id;
    },
    productPhotoListLoad: (
      state,
      action: PayloadAction<{ fotoList: { id: number }; index: number }>,
    ) => {
      const { fotoList, index } = action.payload;
      const prevState = state.productForm.productsColors[index].productsPhotos;
      if (prevState.length <= 10) {
        state.productForm.productsColors[index].productsPhotos = [
          ...prevState,
          {
            main: prevState.length ? prevState[0].file_id : fotoList.id,
            file_id: fotoList.id,
          },
        ];
      } else return;
    },
    productRemovePhoto: (
      state,
      action: PayloadAction<{ id: number; index: number }>,
    ) => {
      const { id, index } = action.payload;
      state.productForm.productsColors[index].productsPhotos =
        state.productForm.productsColors[index].productsPhotos.filter(
          photo => photo.file_id !== id,
        );
    },
    productLoadingPhoto: (state, action: PayloadAction<PhotoType>) => {
      state.photoList = [...state.photoList, action.payload];
    },

    //Изменение селектов Compound
    productAddcompounds: (
      state,
      action: PayloadAction<
        { compound_id: number; material_id: number; value: number }[]
      >,
    ) => {
      state.productForm.productsCompound = action.payload;
    },
    productChangeCompound: (
      state,
      action: PayloadAction<{
        compound_id: number;
        value: number;
        difference?: string;
      }>,
    ) => {
      const { compound_id, difference } = action.payload;

      let { value } = action.payload;

      const compoundOtherFieldPercent = state.otherField
        .filter(field => field.compound_id === compound_id)
        .reduce((a, b) => a + b.value, 0);

      const remainsPercent = 100 - compoundOtherFieldPercent;

      if (value > remainsPercent) {
        if (!state.upMaxPercentCompound.includes(compound_id)) {
          state.upMaxPercentCompound = [
            ...state.upMaxPercentCompound,
            compound_id,
          ];
        }
      } else {
        state.upMaxPercentCompound = state.upMaxPercentCompound.filter(
          id => id !== compound_id,
        );
      }

      if (difference) {
        state.productForm.productsCompound =
          state.productForm.productsCompound.map(compound =>
            compound.compound_id === compound_id
              ? { ...compound, material_id: value }
              : compound,
          );
      } else {
        if (remainsPercent < value) value = remainsPercent;
        state.productForm.productsCompound =
          state.productForm.productsCompound.map(compound =>
            compound.compound_id === compound_id
              ? { ...compound, value: +value }
              : compound,
          );
      }
    },
    productRemoveCompound: (state, action: PayloadAction<number>) => {
      state.productForm.productsCompound =
        state.productForm.productsCompound.filter(
          compound => compound.compound_id !== action.payload,
        );
    },

    //Изменение дополнительных селектов Compound
    productAddOtherFields: (
      state,
      action: PayloadAction<{ compound_id: number }>,
    ) => {
      const { compound_id } = action.payload;

      state.otherField = [
        ...state.otherField,
        {
          material_id: 0,
          compound_id: compound_id,
          value: 0,
          id: +generateNum(),
        },
      ];
    },
    productOtherFieldChange: (
      state,
      action: PayloadAction<{
        id: number;
        compoundId: number;
        value: number;
        difference?: string;
      }>,
    ) => {
      const { compoundId, id, difference } = action.payload;
      let { value } = action.payload;
      const compoundSelelectPercent =
        state.productForm.productsCompound.find(
          c => c.compound_id === compoundId,
        )?.value || 0;
      const compoundOtherFieldPercent = state.otherField
        .filter(field => field.compound_id === compoundId && field.id !== id)
        .reduce((a, b) => a + b.value, 0);
      const remainsPercent =
        100 - (compoundSelelectPercent + compoundOtherFieldPercent);

      if (value > remainsPercent) {
        if (!state.upMaxPercentCompound.includes(compoundId)) {
          state.upMaxPercentCompound = [
            ...state.upMaxPercentCompound,
            compoundId,
          ];
        }
      } else {
        state.upMaxPercentCompound = state.upMaxPercentCompound.filter(
          id => id !== compoundId,
        );
      }

      if (difference) {
        state.otherField = state.otherField.map(field =>
          field.compound_id === compoundId && field.id === id
            ? { ...field, material_id: +value }
            : field,
        );
      } else {
        if (remainsPercent < value) value = remainsPercent;
        state.otherField = state.otherField.map(field =>
          field.compound_id === compoundId && field.id === id
            ? { ...field, value: value }
            : field,
        );
      }
    },
    productOtherFieldRemove: (
      state,
      action: PayloadAction<{ id: number; compound_id: number }>,
    ) => {
      const { id, compound_id } = action.payload;
      state.otherField = state.otherField.filter(o =>
        o.compound_id === compound_id ? o.id !== id : o,
      );
    },

    //Изменение описания (Description)
    productChangeDescription: (state, action: PayloadAction<string>) => {
      state.productForm.description = action.payload;
    },

    //Добавление тегов
    productAddTags: (state, action: PayloadAction<number>) => {
      const index = state.productForm.tags.findIndex(
        tag => tag === action.payload,
      );

        console.log(state.productForm.tags);
      if (index < 0) {
        state.productForm.tags = [...state.productForm.tags, action.payload];
      } else {
        
        state.productForm.tags = state.productForm.tags.filter(
          tag => tag !== action.payload,
        );
      }
    },

    //Компановка формы
    productCompoudForm: (state, action) => {
      state.productForm = { ...state.productForm, ...action.payload };
    },
    //Сбросс формы до начального значени
    productClearForm: state => {
      state.productForm = initialState.productForm;
    },

    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },

    setColorSizePrice: (state, action) => {
      const { colorIndex, sizeIndex, price } = action.payload;
      state.productForm.productsColors[colorIndex].sizes[sizeIndex].price = price
    },
    setColorPrice: (state, action) => {
      const { colorIndex, price } = action.payload;
      state.productForm.productsColors[colorIndex].price = price
      for (let index = 0; index < state.productForm.productsColors[colorIndex].sizes.length; index++) {
        state.productForm.productsColors[colorIndex].sizes[index].price = price;
      }
    },
    setPrice: (state, action) => {
      const price = action.payload;

      state.productForm.price = price
      for (let colorIndex = 0; colorIndex < state.productForm.productsColors.length; colorIndex++) {
        const color = state.productForm.productsColors[colorIndex];
        color.price = price
        for (let index = 0; index < color.sizes.length; index++) {
          color.sizes[index].price = price;
        }
      }
    },


    setIsPricePerColor: (state, action) => {
      state.productForm.isPricePerColor = action.payload;
    },
    setIsPricePerSize: (state, action) => {
      state.productForm.isPricePerSize = action.payload;
    },

    productSetManArticle: (
      state,
      action: PayloadAction<{ colorIndex: number; man_article: string }>,
    ) => {
      const { colorIndex, man_article } = action.payload
      state.productForm.productsColors[colorIndex].man_article = man_article
    },

    resetBarcodes: (state) => {
      const activeColorState = state.productForm.productsColors[state.activePage - 1]
      activeColorState.sizes = activeColorState.sizes.map(stateSize => ({
        ...stateSize,
        barcode: "",
      }))
    }

  },
  extraReducers: (builder) => {
    builder.addCase(generateBarcodes.pending, (state) => {
      state.isGeneratingBarcodes = true;
      state.barcodesError = "";
    });
    builder.addCase(generateBarcodes.fulfilled, (state, action) => {
      state.isGeneratingBarcodes = false;

      const activeColorState = state.productForm.productsColors[state.activePage - 1]

      activeColorState.sizes = activeColorState.sizes.map(stateSize => {
        const barcode = action.payload?.sizes?.find(s => s.size_id === stateSize.id)?.barcode || ""
        return {
          ...stateSize,
          barcode,
        }
      })

    });
    builder.addCase(generateBarcodes.rejected, (state) => {
      state.isGeneratingBarcodes = false;
      state.barcodesError = "";
    });
  }
});


type GenerateBarcodesProps = {
  color_id: number,
  sizes: { size_id: number }[]
}


export const generateBarcodes = createAsyncThunk<BarcodesResponseDataType | undefined, GenerateBarcodesProps, { state: RootState }>(
  "ProductEdit/generateBarcodes", async ({ color_id, sizes }, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {

    const state = getState();
    const product_id = state.products.productForm.id

    try {
      const data = await ProductEditService.generateBarCodes({ product_id, color_id, sizes })
      if (data.data.success === true) {
        return data.data.data
      } else {
        rejectWithValue("Error!")  // TODO уточнить
      }
    } catch (error) {
      console.log(error);
    }
  }
);

// ==================Обработка Редюсеров===================  //
const { reducer: productEditReducr, actions } = ProductEditSlice;
const {
  productCheckedSizeInput,
  productChangeInput,
  productCreacteBarCode,
  productColorRemoveSize,
  productChangeColor,
  productChangeAdditionalColor,
  productAddSubCategory,
  productAddcolor,
  productColorRemove,
  productAddColorPage,
  productColorPageRemove,
  productPhotoLoad,
  productPhotoListLoad,
  productSaveColorArticle,
  productFetchMaterials,
  productRemovePhoto,
  productCompoudForm,
  productChangePackage,
  productAddOtherFields,
  productFetchSeasons,
  productOtherFieldChange,
  productAddcompounds,
  productChangeCompound,
  productOtherFieldRemove,
  productAddTags,
  productChangeDescription,
  productRemoveCompound,
  productLoadingPhoto,
  productClearForm,
  setActivePage,
  setColorSizePrice,
  setColorPrice,
  setPrice,
  setIsPricePerColor,
  setIsPricePerSize,
  productSetManArticle,
  resetBarcodes,
} = actions;

const { getSeasons, getMaterials } = ProductEditService;

//Добавление подкатегории
export const addProductSubCategory =
  (value: string) => (dispatch: AppDispatch) => {
    dispatch(productAddSubCategory(value));
  };
//Загрузка Options
export const fetchingSeasons = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await getSeasons();
    dispatch(productFetchSeasons(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchingMaterials =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await getMaterials(id);
      dispatch(productFetchMaterials(data));
    } catch (error) {
      console.log(error);
    }
  };

// Изменение типа упаковки
export const changeProductPackage = (id: number) => (dispatch: AppDispatch) => {
  dispatch(productChangePackage(id));
};

// Изменение/Выбор цвета
export const changeProductColor =
  (id: number, index: number) => (dispatch: AppDispatch) => {
    dispatch(productChangeColor({ id, index }));
  };

export const changeProductAdditionalColor =
  ({
    indexColor,
    indexPage,
    color_id,
  }: {
    indexPage: number;
    indexColor: number;
    color_id: number;
  }) =>
    (dispatch: AppDispatch) => {
      dispatch(productChangeAdditionalColor({ indexColor, indexPage, color_id }));
    };

export const removeColorField =
  ({ indexPage, indexColor }: { indexPage: number; indexColor: number }) =>
    (dispatch: AppDispatch) => {
      dispatch(productColorRemove({ indexPage, indexColor }));
    };
export const addColorArticle =
  (index: number, article: string) => (dispatch: AppDispatch) => {
    dispatch(productSaveColorArticle({ index, article }));
  };

export const addProductColor =
  (indexPage: number, id: number) => (dispatch: AppDispatch) => {
    dispatch(productAddcolor({ indexPage, id }));
  };

// Изменение/Выбор размеров
export const chekedProductColorInput =
  ({ id, index }: { id: number; index: number }) =>
    (dispatch: AppDispatch) => {
      dispatch(productCheckedSizeInput({ id, index }));
    };
export const changeProductSizeInput =
  ({ id, index, value }: { id: number; index: number; value: string }) =>
    (dispatch: AppDispatch) => {
      dispatch(productChangeInput({ id, index, value }));
    };

export const createProductBarCode =
  ({ index }: { index: number }) =>
    (dispatch: AppDispatch) => {
      dispatch(productCreacteBarCode({ index }));
    };
export const removeProductColorSize =
  ({ index, id }: { index: number; id: number }) =>
    (dispatch: AppDispatch) => {
      dispatch(productColorRemoveSize({ index, id }));
    };

// Добавление/Удаление страницы (Цвета)
export const addColorPage = () => (dispatch: AppDispatch) => {
  dispatch(productAddColorPage());
};

export const removeColorPage = (index: number) => (dispatch: AppDispatch) => {
  dispatch(productColorPageRemove({ index }));
};

//Загрузка фото
export const loadProductFashionPhoto =
  (index: number, file_id: number) => (dispatch: AppDispatch) => {
    dispatch(productPhotoLoad({ index, file_id }));
  };

export const loadProductPhoto =
  (index: number, fotoList: { id: number }) => (dispatch: AppDispatch) => {
    dispatch(productPhotoListLoad({ index, fotoList }));
  };

export const loadAllPhotoList =
  (photo: PhotoType) => (dispatch: AppDispatch) => {
    dispatch(productLoadingPhoto(photo));
  };
export const getAllPhotoList = () => (state: RootState) =>
  state.products.photoList;

export const removeProductPhoto =
  (index: number, id: number) => (dispatch: AppDispatch) => {
    dispatch(productRemovePhoto({ index, id }));
  };

//Изменение селектов Compound
export const addProductCompound =
  (compound: { compound_id: number; material_id: number; value: number }[]) =>
    (dispatch: AppDispatch) => {
      dispatch(productAddcompounds(compound));
    };
export const changeProductCompound =
  (compound_id: number, value: number, difference?: string) =>
    (dispatch: AppDispatch) => {
      dispatch(productChangeCompound({ compound_id, value, difference }));
    };

export const removeProductCompound =
  (compoundId: number) => (dispatch: AppDispatch) => {
    dispatch(productRemoveCompound(compoundId));
  };

//Изменение описания (Description)
export const changeDescription = (value: string) => (dispatch: AppDispatch) => {
  dispatch(productChangeDescription(value));
};

//Изменение дополнительных селектов Compound
export const addProductsOtherFields =
  (compound_id: number) => (dispatch: AppDispatch) => {
    dispatch(productAddOtherFields({ compound_id }));
  };

export const changeProductOtherFields =
  (id: number, value: number, compoundId: number, difference?: string) =>
    (dispatch: AppDispatch) => {
      dispatch(productOtherFieldChange({ id, value, compoundId, difference }));
    };

export const removeProductOtherField =
  (compound_id: number, id: number) => (dispatch: AppDispatch) => {
    dispatch(productOtherFieldRemove({ id, compound_id }));
  };

export const getOtherField = () => (state: RootState) =>
  state.products.otherField;

//добавление тегов
export const addTags = (tags: number) => (dispatch: AppDispatch) => {
  dispatch(productAddTags(tags));
};
//получение текущей страницы цветов
export const getCurrentColorPage = (index: number) => (state: RootState) =>
  state.products.productForm.productsColors[index];

//Получение состояния формы
export const getProductEdditFormState = () => (state: RootState) =>
  state.products.productForm;

//Получение otherFields
export const getOtherFields = () => (state: RootState) =>
  state.products.otherField;

//Получение сезонов
export const getProductSeasons = () => (state: RootState) =>
  state.products.seasons;

//Получение материалов
export const getProductsMaterials = () => (state: RootState) =>
  state.products.materials;

//Компановка формы
export const completedForm = (form: FormState) => (dispatch: AppDispatch) => {
  dispatch(productCompoudForm(form));
};

// Получение суммы процентов
export const getCompoundPercent = (state: RootState) =>
  state.products.upMaxPercentCompound;

//Получение Ошибок Формы
export const getErrorsStepOne = () => (state: RootState) => {
  const productsForm = state.products.productForm;
  const isValid =
    productsForm.brand.length &&
    // productsForm.main_article.length &&
    productsForm.name.length &&
    productsForm.package.equipment.length &&
    productsForm.package.depth &&
    productsForm.package.width > 0 &&
    productsForm.package.gross_weight > 0 &&
    productsForm.package.net_weight > 0 &&
    productsForm.package.height > 0 &&
    state.products.OptionsSubCategory.length > 0;
  return Boolean(isValid);
};

export const getActivePage = () => (state: RootState) =>
  state.products.activePage;


export const getErrorsStepTwo = () => (state: RootState) => {
  const isEmtyPercent =
    state.products.productForm.productsCompound.filter(
      compound => compound.value === 0,
    ).length === 0 &&
    state.products.otherField.filter(o => o.value === 0).length === 0;

  const isEmptyCompound =
    state.products.productForm.productsCompound.filter(
      compound => compound.material_id === 0,
    ).length === 0;

  const isOtherFieldEmptyPercent =
    state.products.otherField.filter(item => item.value === 0).length === 0;

  return isOtherFieldEmptyPercent && isEmtyPercent && isEmptyCompound;
};

export const getErrorsStepThree = () => (state: RootState) => {
  // return (
  //   state.products.productForm.productsColors.filter(
  //     c => !getErrorsColorPage(c),
  //   ).length === 0
  // );
  const errors: number[] = []
  state.products.productForm.productsColors.forEach((c, i) => {
    if (!getErrorsColorPage(c)) errors.push(i)
  })
  return errors;
};

//Очистка формы
export const clearForm = () => (dispatch: AppDispatch) => {
  dispatch(productClearForm());
};

export { setActivePage, setColorSizePrice, setColorPrice, setPrice, setIsPricePerColor, setIsPricePerSize, productSetManArticle, resetBarcodes }

export default productEditReducr;
