import React from 'react'
import { Layout } from '../../complexes/Layout'

import styles from './ProfileAboutCompany.module.scss'
import { ITab, Tabs } from '../../complexes/Tabs'
import { LawInfo } from './parts/LawInfo'
import Info from './parts/LawInfo/Info'
import { PaymentRequisites } from './parts/PaymentRequisites'
import { useParams } from 'react-router-dom'
import { ProfileAboutCompanyTabConfig } from './const'

export default function ProfileAboutCompany() {

  const tabs: ITab[] = []

  const params = useParams();
  const tabId = params.tabId

  tabs.push({
    // label: "Юридическая информация",
    ...ProfileAboutCompanyTabConfig.lawinfo,
    content: <LawInfo />,
    rightSideBar: <Info />
  })
  tabs.push({
    // label: "Платёжные реквизиты",
    ...ProfileAboutCompanyTabConfig.requisites,
    content: <PaymentRequisites />,
    // rightSideBar: <div></div>
  })
  tabs.push({
    // label: "Адреса компании",
    ...ProfileAboutCompanyTabConfig.addresses,
    content: <div></div>
  })

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)

  return (
    <Layout title='Информация о компании'>
      <div className={styles.wrap}>
        <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
      </div>
    </Layout>
  )
}
