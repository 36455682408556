import React from 'react'
import styles from './PackValue.module.scss'
import { CustomTextInput_v2, IOnChange } from '../../../../../../simples/CustomTextInput_v2'

type Props = {
  value?: string | number
  label: string
  name: string
  onChange: ({ name, value }: IOnChange) => void
}

export default function PackValue({ value, label, name, onChange }: Props) {
  // const handleChange = ({name, value}) => {
  //   console.log({name, value});
  // }
  return (
    <div className={styles.pack_value}>
      <div className={styles.label}>{label}</div>
      <div><CustomTextInput_v2 name={name} onChange={onChange} value={value} hideError className={styles.size_input} isNumber /></div>
    </div>
  )
}
