import React, {FC} from "react";
import styles from "./SideBar.module.scss";

interface Props {
    title: string
    description?: string
}

const SideBar: FC<Props> = ({title, description}) => {
  return (
    <div className={styles.inner}>
      <div className={styles.title}>
        <span className={styles.icon}></span>
        <p>{title}</p>
      </div>
      {description && <p className={styles.description} dangerouslySetInnerHTML={{__html: description}}></p>}
    </div>
  );
};

export default SideBar;