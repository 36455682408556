import { ProductsDTO } from "./swagger/llyApi_new";
import { ProductDataType } from "./update-product";

// TODO объединить ?
export interface ListProductDataType extends Omit<ProductDataType, "category"> {
  product_id: number;
  size_rus?: number;
  barcode?: string;
  url?: string;
  main_color?: string;
  size_name?: string;
  status: number;
  hasDiscount: boolean;
  category: {
    // id: number;
    id: string;
    name: string;
    parent_id: number;
    parent: {
      id: number;
      name: string;
      parent_id: number;
      parent: {
        id: number;
        name: string;
        parent_id: number;
        parent: {
          id: number;
          name: string;
          parent_id: number;
        }
      }
    }
  }
}


export interface ListRow {
  hideCheckbox?: boolean;
  data: ListData[];
  key: string;
  hasErrors?: boolean;
  selected?: boolean;
  // hasDiscount?: boolean;
}

export enum ListDataType {
  text = "text",
  image = "image",
  list = "list",
  content = "content",
  textInput = "textInput",
  // checkbox = "checkbox"
}

export interface ListData {
  isEditable?: boolean;
  text?: string;
  name: string;
  content?: JSX.Element | JSX.Element[];
  type?: ListDataType;
  list?: string[];
  dataForSorting?: string | number;
  // boolValue?: boolean
  alignCenter?: boolean
  className?: string
}
export interface ListHeadData extends ListData {
  sortable?: boolean;
  minWidth: string; // TODO обдумать переделать?
  width: string;
}


export type GoodsAnswer = {
  needUpdate?: any;
  success: boolean;
  data?: Array<ProductsDTO>;
};