import React from 'react'
import classNames from 'classnames'
import styles from './Turnover.module.scss'

type Props = {
  value: number
}

export default function FormatedNumber({ value }: Props) {

  const isPositive = value > 0
  const isNegative = value < 0

  const str = (isPositive ? '+' : '') + value.toLocaleString()

  return (<td className={classNames({
    [styles.positive]: isPositive,
    [styles.negative]: isNegative,
  })}>{str}</td>)
}