import React from 'react'

import styles from '../MainPage.module.scss'
import { NotificationTrait } from '../../../../types/swagger/llyApi_new';
import {  useNavigate } from 'react-router-dom';
import { ProfileTabConfig } from '../../ProfileAccount/const';

type Props = {
  notification: NotificationTrait
}

export default function Warning({ notification }: Props) {

  const navigate = useNavigate()

  const handleNavigate = (route: string) => () => {
    navigate(route)
  }

  switch (notification.itemName) {
    case 'CompanyDeletedNotification':
      return (<div
        className={styles.warning}
        onClick={handleNavigate(ProfileTabConfig.removal.route)}
      >до удаления профиля осталось {notification.count} дней, восстановить можно в <a>профиле</a></div>)
      break;
    default:
      return (<div className={styles.warning}>{notification.message}</div>)
      break;
  }
}
