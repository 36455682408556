import classNames from 'classnames'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
// import { setCategory } from '../../../../../store/slices/product'
import { CustomSelect, Option } from '../../../../simples/CustomSelect'

import styles from "./Category.module.scss"
import { CategoriesValue } from '../../../../../types/product_new'
import { CategorySelector } from './CategorySelector'
import { setCategoriesValue } from '../../../../../store/slices/product'

interface Props {
  className?: string
}

export default function Category({ className }: Props) {

  const dispatch = useAppDispatch()

  const categories = useAppSelector(state => state.product.commonData.categories)
  const category = useAppSelector(state => state.product.category.values)
  const readonly = useAppSelector(state => state.product.step < 0)

  // const options: Option[] = categories.map(c => ({ label: c.name || '', value: c.id }))

  // const handleChange = (data: { value: number, lvl: string }) => {
  // dispatch(setCategory(data))
  const handleChange = (data: CategoriesValue) => {
    dispatch(setCategoriesValue(data))
  }

  return (<CategorySelector categories={categories} category={category}
    readonly={readonly} className={className} onChange={handleChange} />)

  // const options: { [key in CategoryLvl]: Option[] } = {
  //   [CategoryLvl.lvl1]: categories.filter(c => !c.parent_id).map(c => ({ label: c.name || '', value: c.id })),
  //   [CategoryLvl.lvl2]: categories.filter(c => c.parent_id === category[CategoryLvl.lvl1]).map(c => ({ label: c.name || '', value: c.id })),
  //   [CategoryLvl.lvl3]: categories.filter(c => c.parent_id === category[CategoryLvl.lvl2]).map(c => ({ label: c.name || '', value: c.id })),
  //   [CategoryLvl.lvl4]: categories.filter(c => c.parent_id === category[CategoryLvl.lvl3]).map(c => ({ label: c.name || '', value: c.id })),
  // }
  // return (
  //   <div className={classNames(styles.category, className)}>
  //     <CustomSelect name={CategoryLvl.lvl1}
  //       // value={categories.find(c => c.id === category[CategoryLvl.lvl1])?.name || ""}
  //       value={category[CategoryLvl.lvl1]}
  //       options={options[CategoryLvl.lvl1]}
  //       onChange={handleChange}
  //       label='Для кого'
  //       required={true}
  //       placeholder='Выберите для кого'
  //       readonly={readonly}
  //     />
  //     <CustomSelect name={CategoryLvl.lvl3}
  //       value={category[CategoryLvl.lvl3]}
  //       options={options[CategoryLvl.lvl3]}
  //       onChange={handleChange}
  //       label='Категория'
  //       required={true}
  //       placeholder='Выберите категорию'
  //       disabled={!category.lvl2}
  //       readonly={readonly}
  //     />
  //     <CustomSelect name={CategoryLvl.lvl2}
  //       value={category[CategoryLvl.lvl2]}
  //       options={options[CategoryLvl.lvl2]}
  //       onChange={handleChange}
  //       label='Тип'
  //       required={true}
  //       placeholder='Выберите тип'
  //       disabled={!category.lvl1}
  //       readonly={readonly}
  //     />
  //     <CustomSelect name={CategoryLvl.lvl4}
  //       value={category[CategoryLvl.lvl4]}
  //       options={options[CategoryLvl.lvl4]}
  //       onChange={handleChange}
  //       label='Подкатегория'
  //       required={true}
  //       placeholder='Выберите подкатегорию'
  //       disabled={!category.lvl3}
  //       readonly={readonly}
  //     // error="! error"
  //     />
  //   </div>
  // )
}
