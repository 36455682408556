import React from 'react'
import styles from './Packs.module.scss'
import Pack from './Pack'
import { IPack } from '../../../../../../../store/slices/fbo'





type Props = {
  packs: IPack[]
}

export default function Packs({ packs }: Props) {

  return (
    <div className={styles.packs}>
      <table>
        <thead>
          <tr>
            <th>Название упаковки</th>
            <th className={styles.docs}>Документы</th>
            <th>Габариты</th>
            <th>Вес</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {packs.map((p, i) => <Pack key={p.id} index={i} pack={p} />)}
        </tbody>
      </table>
      <div>
      </div>
    </div>
  )
}
