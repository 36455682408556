import React, { ChangeEvent, useEffect, useState } from 'react'
import { Endpoints } from '../../../../../constants/server'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { closeTemplatesDialog, fetchTemplates, setTemplateForDelete } from '../../../../../store/slices/feedback'
import { ModalDialog, ButtonConfig } from '../../../../simples/ModalDialog'
import api from "../../../../../utils/api";
// import x_icon from "../../assets/x.svg"

import styles from "./MassAnswer.module.scss"
import { TextInput } from '../../../../simples/TextInput'

export type FeedbackTemplate = {
  id: number
  title: string
  text: string
}

type Answer = {
  success?: boolean
  data?: FeedbackTemplate[]
}


export default function Templates() {

  const dispatch = useAppDispatch()
  const isActive = useAppSelector(state => state.feedback.isTemplatesOpen)
  const templates = useAppSelector(state => state.feedback.templates)

  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  // const [selectedTemplate, setSelectedTemplate] = useState<number>()

  useEffect(() => {
    dispatch(fetchTemplates())
  }, [])


  const handleMassAnswer = () => {
    api.post<Answer>(Endpoints.TEMPLATE_CREATE, {
      title, text
    }).then((res) => {
      if (res.data?.success) {
        setTitle('')
        setText('')
      }
    }).catch((err) => {
      alert(err)
      console.log(err)
    }).finally(() => {
      dispatch(fetchTemplates())
    });
  }
  const handleClose = () => {
    dispatch(closeTemplatesDialog())
  }

  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }
  const handleRemoveTemplate = (id: number) => async (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    const t = templates.find(t => t.id === id)
    dispatch(setTemplateForDelete(t))
  }

  const handleEditDemplate = (id: number) => () => {
    const t = templates.find(t => t.id === id)
    setText(t?.text || "")
    setTitle(t?.title || "")
    // setSelectedTemplate(id)
  }

  const isMaxTemplatesCount = (templates.length >= 10)
  const saveDisabled = isMaxTemplatesCount || text.length < 3 || title.length < 1

  const btns: ButtonConfig[] = [{ text: "Отправить", onClick: handleMassAnswer, disabled: saveDisabled }]

  return (
    <ModalDialog width={920} buttonsConfig={btns} isActive={isActive} handleClose={handleClose} hasCloseBtn={true}>
      <h1>Мои шаблоны</h1>
      <div className={styles.info}>Здесь можно составить шаблоны ответов на отзывы с рейтингом 4 и 5 звёзд</div>
      <div className={styles.templates}>
        <span className={styles.templates_label}>Созданные шаблоны</span>
        <div className={styles.list}>
          {/* {templates.map(t => <div key={t.id} className={styles.template_label} onClick={handleEditDemplate(t.id)}>{t.title}<img onClick={handleRemoveTemplate(t.id)} src={x_icon} /></div>)} */}
          {templates.map(t => <div key={t.id} className={styles.template_label} onClick={handleEditDemplate(t.id)}>{t.title}<div onClick={handleRemoveTemplate(t.id)} className={styles.x_icon} /></div>)}
        </div>
      </div>

      <div className={styles.title_label}>Заголовок</div>
      <TextInput value={title} onChange={setTitle} disabled={isMaxTemplatesCount} />

      <div className={styles.textarea_label}>Текст</div>
      <textarea className={styles.textarea} onChange={handleChangeText} value={text} disabled={isMaxTemplatesCount} maxLength={170} />
      <>{isMaxTemplatesCount && <div>Достигнуто максимальное число шаблонов</div>}</>
    </ModalDialog>
  )
}
