import React, { useEffect, useState } from 'react'
import Indicators from '../../complexes/Indicators/Indicators'
import { Layout } from '../../complexes/Layout'
import { useParams } from 'react-router-dom';
import styles from './Analytics.module.scss'
import CustomChart, { SerieConfig } from '../Statistic/parts/CustomChart';
import { CalendarPeriod, CalendarPeriodSelector } from '../../complexes/CalendarPeriodSelector';
import { CustomDatePicker, datePickerDateToDate } from '../../complexes/CustomDatePicker';
import { BlueButton, ButtonStyle } from '../../simples/BlueButton';
import api from '../../../utils/api'
import { DatePickerDate, dateToDatePickerDate } from '../../complexes/CustomDatePicker/CustomDatePicker';
import { differenceInCalendarDays, differenceInMonths, format, sub } from 'date-fns';
import { formatDateForServer } from '../../../utils/action';
import { AveragesDTO, ClientsCollection, ItemsInBasketDTO, OrdersDTO, SalesAnalyticsDTO } from '../../../types/swagger/llyApi_new';
import { IndicatorType } from './const';
import download from '../../../utils/download';
import { CustomRadioButton, CustomRadioButtons } from '../../simples/CustomRadioButtons';

type SalesAnalyticsResponse = {
  success?: boolean
  data?: SalesAnalyticsDTO[]
}
type ClientsAnalyticsResponse = {
  success?: boolean
  data?: ClientsCollection
}
type ItemInCartAnalyticsResponse = {
  success?: boolean
  data?: ItemsInBasketDTO[]
}
type OrdersCountAnalyticsResponse = {
  success?: boolean
  data?: OrdersDTO[]
}
type AverageAnalyticsResponse = {
  success?: boolean
  data?: AveragesDTO[]
}

function getPath(tabId?: string) {
  let path = '/analytics/'
  switch (tabId) {
    case IndicatorType.Sales:
      path += 'sales' // Аналитика по продажам
      break;
    case IndicatorType.Clients:
      path += 'clients' // Аналитика по новым клиентам
      break;
    case IndicatorType.ItemInCart:
      path += 'items-in-cart' // Аналитика по товарам в корзине
      break;
    case IndicatorType.OrdersCount:
      path += 'orders' // Аналитика по оформленным заказам
      break;
    case IndicatorType.AverageOrder:
      path += 'averages' // Аналитика по среднему заказу
      break;
    // -excel
    default:
      break;
  }
  return path
}

const NOW = new Date()

export default function Analytics() {

  const params = useParams();
  const tabId = params.tabId

  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.week)
  // const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(new Date()))
  const [dateTo, setDateTo] = useState<{ [key: string]: DatePickerDate }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [chartData, setChartData] = useState<SalesAnalyticsDTO[]>()
  const [clientsData, setClientsData] = useState<ClientsCollection>()
  const [itemInCartData, setItemInCartData] = useState<ItemsInBasketDTO[]>()
  const [ordersCountData, setOrdersCountData] = useState<OrdersDTO[]>()
  const [averageData, setAveragesData] = useState<AveragesDTO[]>()


  const [isCount, setIsCount] = useState(false)
  // const [dateFrom, setDateFrom] = useState<DatePickerDate>(
  //   dateToDatePickerDate(sub(new Date(), {
  //     weeks: 1
  //   }))
  // )
  const [dateFrom, setDateFrom] = useState<{ [key: string]: DatePickerDate }>({})




  function getTabDateTo(): DatePickerDate {
    if (tabId && dateTo[tabId]) { return dateTo[tabId] }
    return dateToDatePickerDate(NOW)
  }
  function setTabDateTo(date: DatePickerDate) {
    if (tabId) {
      setDateTo({ ...dateTo, [tabId]: date })
    }
  }

  useEffect(() => {
    const from = datePickerDateToDate(getTabDateFrom())
    const to = datePickerDateToDate(getTabDateTo())
    const days = differenceInCalendarDays(to, from)
    
    switch (days) {
      case 7:
        setPeriod(CalendarPeriod.week)
        return
      case 1:
        setPeriod(CalendarPeriod.day)
        return
      default:
        break;
    }
    if(differenceInMonths(to, from) === 1){
      return setPeriod(CalendarPeriod.month)
    }
    setPeriod(undefined)
  }, [dateFrom, dateTo, tabId])

  function getTabDateFrom(): DatePickerDate {
    if (tabId && dateFrom[tabId]) { return dateFrom[tabId] }
    return dateToDatePickerDate(sub(NOW, {
      weeks: 1
    }))
  }
  function setTabDateFrom(date: DatePickerDate) {
    if (tabId) {
      setDateFrom({ ...dateFrom, [tabId]: date })
    }
  }


  const handleSetPeriod = (period: CalendarPeriod) => {
    setPeriod(period)
    // const _to = datePickerDateToDate(dateTo)
    const _to = datePickerDateToDate(getTabDateTo())
    switch (period) {
      case CalendarPeriod.day:
        // setDateFrom(
        setTabDateFrom(
          dateToDatePickerDate(sub(_to, {
            days: 1
          }))
        )
        break;
      case CalendarPeriod.week:
        // setDateFrom(
        setTabDateFrom(
          dateToDatePickerDate(sub(_to, {
            weeks: 1
          }))
        )
        break;
      case CalendarPeriod.month:
        //setDateFrom(
        setTabDateFrom(
          dateToDatePickerDate(sub(_to, {
            months: 1
          }))
        )
        break;
      case CalendarPeriod.year:
        //setDateFrom(
        setTabDateFrom(
          dateToDatePickerDate(sub(_to, {
            years: 1
          }))
        )
        break;
      default:
        break;
    }
  }
  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    // const to = datePickerDateToDate(dateTo);
    const to = datePickerDateToDate(getTabDateTo());
    if (d.getTime() < to.getTime()) {
      setPeriod(undefined)
      // setDateFrom(date)
      setTabDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    // const from = datePickerDateToDate(dateFrom)
    const from = datePickerDateToDate(getTabDateFrom())
    const now = new Date()

    if (d.getTime() > from.getTime() && d.getTime() < now.getTime()) {
      setPeriod(undefined)
      // setDateTo(date)
      setTabDateTo(date)
    }
  }




  // const step = 1;
  const handleBuildReport = () => {

    const p = getPath(tabId)

    // const req = `${p}?from=${formatDateForServer(datePickerDateToDate(dateFrom))}&to=${formatDateForServer(datePickerDateToDate(dateTo))}`// &step=${step}`
    const req = `${p}?from=${formatDateForServer(datePickerDateToDate(getTabDateFrom()))}&to=${formatDateForServer(datePickerDateToDate(getTabDateTo()))}`// &step=${step}`
    switch (tabId) {
      case IndicatorType.Sales: {
        // Аналитика по продажам
        setChartData(undefined)
        setIsLoading(true)
        api.get<SalesAnalyticsResponse>(req).then(data => {
          if (data.data.data !== undefined) {
            setChartData(data.data.data)
          }
          setIsLoading(false)
        })
        break;
      }
      case IndicatorType.Clients: {
        // Аналитика по новым клиентам
        setIsLoading(true)
        setClientsData(undefined)
        api.get<ClientsAnalyticsResponse>(req).then(data => {
          if (data.data.data !== undefined) {
            setClientsData(data.data.data)
          }
          setIsLoading(false)
        })
        break;
      }
      case IndicatorType.ItemInCart: {
        // Аналитика по товарам в корзине
        setIsLoading(true)
        setItemInCartData(undefined)
        api.get<ItemInCartAnalyticsResponse>(req).then(data => {
          if (data.data.data !== undefined) {
            setItemInCartData(data.data.data)
          }
          setIsLoading(false)
        })
        break;
      }
      case IndicatorType.OrdersCount: {
        // Аналитика по оформленным заказам
        setIsLoading(true)
        setOrdersCountData(undefined)
        api.get<OrdersCountAnalyticsResponse>(req).then(data => {
          if (data.data.data !== undefined) {
            setOrdersCountData(data.data.data)
          }
          setIsLoading(false)
        })
        break;
      }
      case IndicatorType.AverageOrder: {
        // Аналитика по среднему заказу
        setIsLoading(true)
        setAveragesData(undefined)
        api.get<AverageAnalyticsResponse>(req).then(data => {
          if (data.data.data !== undefined) {
            setAveragesData(data.data.data)
          }
          setIsLoading(false)
        })
        break;
      }
      default:
        break;
    }
  }



  const handleDownloadTable = () => {
    const p = getPath(tabId)
    download({
      // req: `${p}-to-excel?from=${formatDateForServer(datePickerDateToDate(dateFrom))}&to=${formatDateForServer(datePickerDateToDate(dateTo))}`// &step=${step}`
      req: `${p}-to-excel?from=${formatDateForServer(datePickerDateToDate(getTabDateFrom()))}&to=${formatDateForServer(datePickerDateToDate(getTabDateTo()))}`// &step=${step}`
    })
  }
  const handleSWCount = () => {
    setIsCount(!isCount)
  }
  const btns: CustomRadioButton[] = [{
    label: 'Рубли',
    name: 'rub'
  }, {
    label: 'Штуки',
    name: 'count'
  }]

  const btnIsDisabled = false // selectedFbo === undefined

  let content: JSX.Element | undefined

  switch (tabId) {
    case IndicatorType.Sales: {
      // Аналитика по продажа
      if (chartData === undefined && !isLoading) { break; }
      const seriesConfig: SerieConfig[] = [{
        id: 'chartData',
        label: 'chartData',
        data: chartData?.map(data => ({
          date: data.datetime,
          value: isCount ? data.count : data.value
        })) || []
      }]
      content = <div className={styles.chart_wrap}>
        <div className={styles.radio_btns}>
          <CustomRadioButtons activeName={isCount ? 'count' : 'rub'} btns={btns} label='' name={'is_count'} onChange={handleSWCount} />
        </div>
        <CustomChart key='Sales' xLabel={isCount ? 'Шт.' : 'Руб.'} yLabel='' seriesConfig={seriesConfig} isLoading={isLoading} hideControls
          tooltipFormatter={(activeSerie, rawIndex) => <div className={styles.tooltip}>
            <span className={styles.value}>{activeSerie.rawData[rawIndex].value?.toLocaleString()} {isCount ? 'штук' : '₽'}</span>
            <span className={styles.date}>{format(activeSerie.rawData[rawIndex].date, 'hh:mm dd.MM.yyyy')}</span>
          </div>} />
      </div>
      break;
    }
    case IndicatorType.Clients: {
      // Аналитика по новым клиентам  
      if (clientsData === undefined && !isLoading) { break; }
      const seriesConfig: SerieConfig[] = [{
        id: 'chartData',
        label: 'chartData',
        data: clientsData?.clients?.map(data => ({
          date: data.date,
          value: data.count
        })) || []
      }]
      content = <div className={styles.chart_wrap}>
        <div className={styles.chart_info}>
          <span>Клиентов за всё время:</span> <span className={styles.clients_count}>{clientsData?.total || 0}</span>
        </div>
        <CustomChart key='Clients' xLabel='Чел.' yLabel='' seriesConfig={seriesConfig} isLoading={isLoading} hideControls
          tooltipFormatter={(activeSerie, rawIndex) => <div className={styles.tooltip}>
            <span className={styles.value}>{activeSerie.rawData[rawIndex].value?.toLocaleString()} человек</span>
            <span className={styles.date}>{format(activeSerie.rawData[rawIndex].date, 'dd.MM.yyyy')}</span>
          </div>} />
      </div>
      break;
    }
    case IndicatorType.ItemInCart: {
      // Аналитика по товарам в корзине
      if (itemInCartData === undefined && !isLoading) { break; }
      const seriesConfig: SerieConfig[] = [{
        id: 'chartData',
        label: 'chartData',
        data: itemInCartData?.map(data => ({
          date: data.date,
          value: data.count
        })) || []
      }]
      content = <>
        <CustomChart key='ItemInCart' xLabel={'Шт.'} yLabel='' seriesConfig={seriesConfig} isLoading={isLoading} hideControls
          tooltipFormatter={(activeSerie, rawIndex) => <div className={styles.tooltip}>
            <span className={styles.value}>{activeSerie.rawData[rawIndex].value?.toLocaleString()} товаров</span>
            <span className={styles.date}>{format(activeSerie.rawData[rawIndex].date, 'dd.MM.yyyy')}</span>
          </div>} />
      </>
      break;
    }
    case IndicatorType.OrdersCount: {
      // Аналитика по оформленным заказам
      if (ordersCountData === undefined && !isLoading) { break; }
      const seriesConfig: SerieConfig[] = [{
        id: 'chartData',
        label: 'chartData',
        data: ordersCountData?.map(data => ({
          date: data.date,
          value: data.count
        })) || []
      }]
      content = <>
        <CustomChart key='OrdersCount' xLabel='Кол-во' yLabel='' seriesConfig={seriesConfig} isLoading={isLoading} hideControls
          tooltipFormatter={(activeSerie, rawIndex) => <div className={styles.tooltip}>
            <span className={styles.value}>{activeSerie.rawData[rawIndex].value?.toLocaleString()} оформлений</span>
            <span className={styles.date}>{format(activeSerie.rawData[rawIndex].date, 'dd.MM.yyyy')}</span>
          </div>} />
      </>
      break;
    }
    case IndicatorType.AverageOrder: {
      // Аналитика по среднему заказу
      if (averageData === undefined && !isLoading) { break; }
      const seriesConfig: SerieConfig[] = [{
        id: 'chartData',
        label: 'chartData',
        data: averageData?.map(data => ({
          date: data.date,
          value: data.count
        })) || []
      }]
      content = <>
        <CustomChart key='AverageOrder' xLabel='Руб.' yLabel='' seriesConfig={seriesConfig} isLoading={isLoading} hideControls
          tooltipFormatter={(activeSerie, rawIndex) => <div className={styles.tooltip}>
            <span className={styles.value}>{activeSerie.rawData[rawIndex].value} ₽</span>
            <span className={styles.date}>{format(activeSerie.rawData[rawIndex].date, 'dd.MM.yyyy')}</span>
          </div>} />
      </>
      break;
    }
    default:
      break;
  }

  return (
    <Layout title='Аналитика' maxWidth={1920} minWidth={1440} v2 >
      <Indicators activeTabId={tabId} hideBtn />
      <div className={styles.content}>
        <div className={styles.period}>
          <div>Отобразить отчёт</div>
          <div className={styles.period_selector}>
            <CalendarPeriodSelector period={period} onChange={handleSetPeriod} className={styles.calendar_period_selector} />
            {/* <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" /> */}
            <CustomDatePicker date={getTabDateFrom()} onChange={handleSetDateFrom} label="с" />
            {/* <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" /> */}
            <CustomDatePicker date={getTabDateTo()} onChange={handleSetDateTo} label="по" />
            <BlueButton buttonStyle={ButtonStyle.Style1} title='Применить' onClick={handleBuildReport} disabled={btnIsDisabled} />
          </div>
        </div>
        <div className={styles.controls}>
          <BlueButton className={styles.build_report} title='Сформировать отчет' onClick={handleBuildReport} disabled={btnIsDisabled} />
          <BlueButton className={styles.build_report} title='Выгрузить в excel за период' onClick={handleDownloadTable} disabled={btnIsDisabled} />
        </div>
        {content}
      </div>
    </Layout>
  )
}
