import React from "react";
import styles from './LeftRegistrationCard.module.scss'

type Props = {
  children: React.ReactNode;
};

const LeftRegistrationCard = ({ children }: Props) => <div className={styles.wrapper}>{children}</div>;

export default LeftRegistrationCard;
