import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { deselectStarFilter, setSelectedTypeFilter } from '../../../../../store/slices/feedback';
import { BlueButton } from '../../../../simples/BlueButton';

import close_icon from "../../assets/close.svg"
import filters_icon from "../../assets/filters.svg"
import { StarFilterLabels, TypeFilterOptions } from '../../consts';
import { TypeFilterValue } from '../../types';
import FilterLabel from './FilterLabel';

import styles from "./Filters.module.scss";
import Menu from './Menu';

export default function Filters() {

  const dispatch = useAppDispatch()
  const selectedStars = useAppSelector(state => state.feedback.selectedStarsFilter);
  const selectedTypeFilter = useAppSelector(state => state.feedback.selectedTypeFilter);

  const [isOpen, setIsOpen] = useState(false)


  const handleCloceFilters = () => {
    setIsOpen(false)
  }
  const handleOpenFilters = () => {
    if (!isOpen) {
      setIsOpen(true)
    } else {
      handleCloceFilters()
    }
  }

  const handleDeselectStarOption = (v: number) => () => {
    dispatch(deselectStarFilter(v))
  }
  const handleDeselectTypeOption = () => {
    dispatch(setSelectedTypeFilter(TypeFilterValue.ALL))
  }

  const starFilters = selectedStars.map((i) =>
    <FilterLabel
      key={i}
      text={StarFilterLabels[i - 1]}
      starsCount={i}
      deselect={handleDeselectStarOption(i)}
      selected={true}
    />
  )


  const typeOption = TypeFilterOptions.find(o => o.id === selectedTypeFilter)

  return (
    <div className={styles.wrap}>
      <div className={styles.menu_wrap}>
        <BlueButton title='Фильтры' onClick={handleOpenFilters} iconSrc={isOpen ? close_icon : filters_icon} />
        {isOpen && <Menu close={handleCloceFilters} />}
      </div>
      <div className={styles.list}>
        {starFilters}
        <FilterLabel
          key={"type"}
          text={typeOption?.title || ""}
          deselect={handleDeselectTypeOption}
          selected={true}
        />
      </div>
    </div>
  );
}
