import React from 'react'
import { Layout } from '../../complexes/Layout'
import { ITab, Tabs } from '../../complexes/Tabs'
import { LogisticsFBSTabId, LogisticsFBSTabConfig } from './consts'
import DeliveryRequests from './parts/DeliveryRequests/DeliveryRequests'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import NewDeliveryRequest from './parts/NewDeliveryRequest/NewDeliveryRequest'
import { cancelNewDeliveryRequest } from '../../../store/slices/fbs'
import BalancesInWarehouses from './parts/BalancesInWarehouses/BalancesInWarehouses'

export default function Logistics_FBS() {

  const dispatch = useAppDispatch()
  const { isNewDeliveryRequestBuilding } = useAppSelector(state => state.fbs)

  const tabs: ITab[] = [{
    ...LogisticsFBSTabConfig[LogisticsFBSTabId.fbs],
    content: <DeliveryRequests />
  }, {
    ...LogisticsFBSTabConfig[LogisticsFBSTabId.fbs_balances],
    content: <BalancesInWarehouses />
  }]

  if (isNewDeliveryRequestBuilding) {
    const handleCnacelCeration = () => {
      dispatch(cancelNewDeliveryRequest())
    }

    return (
      <Layout title='Заявка для FBO' hint={''} v2 onBack={handleCnacelCeration} >
        <NewDeliveryRequest />
      </Layout>
    )
  } else {
    return (
      <Layout
        title='Логистика'
        hint={'Если задание небработано до указанного срока, то будет начислен штраф, а пользователю вернут деньги'}
        hintWidth={454}
      >
        <Tabs tabs={tabs} />
      </Layout>
    )
  }
}
