import React from "react";
import styles from "./warning.module.scss";
import icons from "../../../../../assets/icons/infoForm.png";
import classNames from "classnames";
import { GoodsListTexts } from "../../../../../constants/goodsListTexts";

function GoodsSearch() {
  const link = "";

  return (
      <article className={styles.body}>
        <section className={classNames(styles.body__worning, styles.body__worning_top)}>
          <div className={styles.body_worning__bottom_title}>
            <div className={styles.icons}><img src={icons} /></div>
            <h3>{GoodsListTexts.Warning1_title}</h3>
          </div>
          <div className={styles.text}>{GoodsListTexts.Warning1_text}</div>
        </section>
        <section className={classNames(styles.body__worning, styles.body__worning_bottom)}>
          <div className={styles.body_worning__bottom_title}>
            <div className={styles.icons}>
              <img src={icons} />
            </div>
            <h3>{GoodsListTexts.Warning2_title}</h3>
          </div>
          <div className={styles.text}>
            <div>{GoodsListTexts.Warning1_text}</div>
            <div>
              <a href={link}>{GoodsListTexts.Warning2_link_text}</a>
            </div>
          </div>
        </section>
      </article>
  );
}

export default GoodsSearch;
