import React from 'react'
import { useDraggable } from '@dnd-kit/core';
import { IItem } from '../../../../../../../../../store/slices/fbs'
import Item from './Item';

type Props = {
  item: IItem
  orderId: string
  className?: string
}

export default function DraggableItem({ item, orderId, className }: Props) {

  const { attributes, listeners, setNodeRef} = useDraggable({
    id: item.id,
    data: { orderId }
  });
  // console.log({orderId});
  
  // const style = transform ? {
  //   transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  // } : undefined;


  return (
    // <div ref={setNodeRef} {...listeners} {...attributes}>
    //   <Item item={item} />
    // </div>
    <div ref={setNodeRef} {...listeners} {...attributes}>
      <Item item={item} className={className} orderId={orderId} />
    </div>
  );
}
