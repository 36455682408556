import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import ProductService, { ProductsColorsStep3, ProductStep1, ProductStep2, ProductStep3 as ProductStep3_4 } from "../../services/Product";
import { CategoriesValue, CategoryLvl, CreateResponseType, MaterialState, Package, ReferenceBook } from "../../types/product_new";
import {
  Categories, Countries, PackagesTypes, Seasons, Tags,
  Colors, Products, CertificateDTO
} from "../../types/swagger/llyApi_new";
import { startAppListening } from "../listenerMiddleware";
import { arraySwap } from "@dnd-kit/sortable";
import { toast } from "react-toastify";

export interface CommonData {
  categories: Categories[]
  countries: Countries[]
  packagesTypes: PackagesTypes[]
  seasons: Seasons[]
  tags: Tags[]
  colors: Colors[]
  certificates: CertificateDTO[]
}

type FieldError = {
  name: string
  error: string
}

type PackageState = {
  values: Package
  errors: FieldError[]
}

type AboutState = {
  values: {
    country_id?: number
    brand: string
    name: string
  }
  errors: FieldError[]
}


type CompositionValue = {
  compoundId: number
  materials: MaterialState[]
}
type CompositionState = {
  values: CompositionValue[]
  hasErrors: boolean
}

type CategoryState = {
  values: CategoriesValue
  hasErrors: boolean
}


type DetailsValues = { detail_id: number, option_id: number | null, value: string }[]
type DetailsState = {
  values: DetailsValues
  hasErrors: boolean
}

type DescriptionState = {
  value: string
  hasErrors: boolean
}


type SeasonsState = {
  values: Array<number | null>
  hasErrors: boolean
}

type CertificatesState = {
  values: Array<number | null>
  hasErrors: boolean
}
type TNVDCertificateState = {
  value: number | null
  hasErrors: boolean
}

type CollectionState = DescriptionState
type TagsState = {
  values: number[],
  hasErrors: boolean
}

type SizeState = {
  size_id: number
  price?: number
  barcode: string
  man_barcode: string
}

type VariantState = {
  // /** ID цвета товара */
  id: number
  // /** ID цвета */
  colors_id?: number
  additionalColors: Array<number | null>
  // /** Артикул цвета */
  color_article?: string // для фронта нужен string чтобы корректно выводилось
  /** Артикул производителя */
  man_article?: string
  sizes: SizeState[]
  priceForAnySize?: number | null

  mainPhoto?: ProductPhoto //{ id: number, url: string }
  photos: ProductPhoto[]//{ id: number, url: string }[]
}


export type ProductPhoto = { id: number, url: string, isMain?: boolean }


const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });

type VariantsState = {
  values: VariantState[],
  activeVariantIndex: number
  hasErrors: boolean
  errors: number[]
  priceForAnyVariant?: number | null
  isPricePerSize: boolean
  isPricePerVariant: boolean
}

type UploadingState = {
  id: number | string
  name: string
  thumbnail: string
  progress: number
  rejected?: boolean
  success?: boolean
  isActive?: boolean
}

export interface ProductState {
  // currentProduct?: Products
  // currentProduct: ProductConfig
  commonData: CommonData
  referenceBook: ReferenceBook
  isLoadingProduct: boolean
  isLoadingProductError: boolean
  isCommonDataLoading: boolean
  isProductCreacion: boolean
  isReferenceBookFetching: boolean
  isLoadingBarcodes: boolean
  isReadonlyMode: boolean

  step: number

  productId?: number
  /** На товар действует скидка */
  hasDiscount?: boolean
  /** Скидка из календаря */
  hasPromotion?: boolean

  //UI
  category: CategoryState
  package: PackageState
  about: AboutState
  composition: CompositionState
  details: DetailsState
  description: DescriptionState
  seasons: SeasonsState
  certificates: CertificatesState
  TNVDCertificate: TNVDCertificateState
  collection: CollectionState
  tags: TagsState
  variants: VariantsState
  priceIsUpdated: boolean
  uploadingProgress: UploadingState[]
}

export const fetchCommonData = createAsyncThunk<any, void, { state: RootState }>(
  "Product/fetchCommonData", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    const commonData: CommonData = {
      categories: [],
      countries: [],
      packagesTypes: [],
      seasons: [],
      tags: [],
      colors: [],
      certificates: [],
    }

    await Promise.all([
      ProductService.fetchCategories().then(data => commonData.categories = data),
      ProductService.fetchCountries().then(data => commonData.countries = data),
      ProductService.fetchPackageTypes().then(data => commonData.packagesTypes = data),
      ProductService.fetchSeasons().then(data => commonData.seasons = data),
      ProductService.fetchTags().then(data => commonData.tags = data),
      ProductService.fetchColors().then(data => commonData.colors = data),
      ProductService.fetchCertificates().then(data => commonData.certificates = data),
      // ProductService.fetchReferenceBook().then(console.log),
    ])

    return commonData
  }
);

export const fetchReferenceBook = createAsyncThunk<any, void, { state: RootState }>(
  "Product/fetchReferenceBook", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    const categoryId = getState().product.category.values[CategoryLvl.lvl4]
    if (categoryId)
      return (await ProductService.fetchReferenceBook(categoryId))
  }
);


function createThumbnail(formDataFile: File, cb: (b64thumbnail: string) => void) {
  let reader = new FileReader();
  reader.onload = function () {
    // console.log(reader.result);
    const img = new Image()
    img.onload = function (e) {

      // console.log(getThumbnail(img));
      const canvas = document.createElement("canvas");
      let scale = Math.min(40 / img.width, 55 / img.height)
      // console.log(scale);

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      canvas?.getContext("2d")?.drawImage(img, 0, 0, canvas.width, canvas.height);
      // console.log(canvas.toDataURL("image/png"));
      // console.log(canvas.toDataURL("image/jpeg"));
      // document.removeChild(canvas)
      cb(canvas.toDataURL("image/png"))
    };
    img.src = reader.result as string
  }
  reader.readAsDataURL(formDataFile);
  // return canvas.toDataURL("image/png")
}

export const uploadPhoto = createAsyncThunk<any, { formDataFile: File, isMain?: boolean }, { state: RootState }>(
  "Product/uploadPhoto", async ({ formDataFile, isMain }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    // const thumbnail = await imageThumbnail(formDataFile, { width: 40, height: 55, responseType: 'base64' });
    // console.log(thumbnail);

    // let reader = new FileReader();
    // reader.onload = function () {
    //   console.log(reader.result);
    //   // let img = document.getElementById(target);
    //   // // can also use "this.result"
    //   // img.src = reader.result;
    //   const img = new Image()
    //   img.onload = function (e) {
    //     // c.drawImage(this, 0, 0, thumbSize, thumbSize);
    //     // document.getElementById("thumb").src = canvas.toDataURL("image/png");
    //     console.log(getThumbnail(img));

    //   };
    //   img.src = reader.result as string
    //   // img.src = formDataFile// reader.result
    //   // const canvas = document.createElement("canvas");

    // }
    // reader.readAsDataURL(formDataFile);
    const id = Math.random()

    dispatch(addUploadingPhoto({
      id,
      name: formDataFile.name,
      thumbnail: '',
      progress: 0
    }))

    createThumbnail(formDataFile, (b64thumbnail: string) => {
      dispatch(setUploadingPhotoThumbnail({ id, thumbnail: b64thumbnail }))
    })

    const state = getState();
    // const category = getSelectedIds(state);
    // if (!category) throw new Error("category not selected");
    // dispatch(setUploadingFileName(formDataFile.name));
    // const response = await MassImportEditService.uploadEditedArch({

    const response = await ProductService.sendFile({
      formDataFile,
      onUploadProgress(percent) {
        dispatch(setUploadingProgress({ id, percent }));
      },
    });

    if (response.success) {
      dispatch(addUploadingPhotoSuccess(id))
      return fulfillWithValue({ id: response.response?.data.id, url: response.response?.data.url, isMain });
    } else {
      dispatch(addUploadingPhotoReject(id))
      return rejectWithValue(response.response?.message || response.error)
    }
  }
);

export const generateBarcodes = createAsyncThunk<any, void, { state: RootState }>(
  "Product/generateBarcodes", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    const state: RootState = getState();
    const { productId, variants } = state.product
    const variant = variants.values[variants.activeVariantIndex]


    const reqData = {
      product_id: productId || 0,
      color_id: variant.colors_id || 0,
      sizes: variant.sizes
    }

    if (!productId || !variant.colors_id || variant.sizes.length < 1) { return }

    try {
      const data = await ProductService.generateBarCodes(reqData)

      if (data.data.success === true) {
        return data.data.data.sizes
      } else {
        rejectWithValue("Error!")  // TODO уточнить
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchProduct = createAsyncThunk<any, { productId: number, variantId: number }, { state: RootState }>(
  "Product/fetchProduct", async ({ productId, variantId }, { getState, rejectWithValue, fulfillWithValue }) => {

    if (productId) {
      return (await ProductService.fetchProduct(productId, variantId))
    }
  }
);

function getProductDataStep1(productState: ProductState): ProductStep1 {
  const { category, about, package: p } = productState

  // изза валидации не должно быть возможности вызвать этот код с невалидными данными
  //// ts-ignore

  const data: ProductStep1 = {
    category_id: category.values[CategoryLvl.lvl4] || 0,
    name: about.values.name,
    country_id: about.values.country_id || 0,
    brand: about.values.brand,
    package: p.values,
    description: ''
  }
  return data
}

function getProductDataStep2(productState: ProductState): ProductStep2 {
  const { category, tags, description, collection, seasons, details, composition } = productState

  // изза валидации не должно быть возможности вызвать этот код с невалидными данными
  //// ts-ignore

  const productsCompound: { compound_id: number, material_id: number, value: string | number }[] = []
  composition.values.forEach(c => {
    c.materials.forEach(m => {
      productsCompound.push({
        compound_id: c.compoundId,
        material_id: m.id as number,
        value: m.percent as number
      })
    })
  })

  const data: ProductStep2 = {
    category_id: category.values[CategoryLvl.lvl4] || 0,
    collection: collection.value,
    description: description.value,
    productsCompound,
    productsDetails: details.values,
    seasons: seasons.values.filter(s => s !== null) as number[],
    tags: tags.values,
  }
  return data
}

function getProductDataStep34(productState: ProductState): ProductStep3_4 {
  const { variants } = productState

  // изза валидации не должно быть возможности вызвать этот код с невалидными данными
  //// ts-ignore


  const data: ProductStep3_4 = {
    productsColors: variants.values.map(v => {
      const productsColors: ProductsColorsStep3 = {
        colors_id: v.colors_id as number,
        color_article: parseInt(v.color_article || '0'),
        // price: number
        man_article: v.man_article as string,
        additionalColors: v.additionalColors.filter(ac => ac !== null).map(ac => ({ color_id: ac as number })),
        sizes: v.sizes.map(s => ({
          id: s.size_id,
          man_barcode: s.man_barcode,
          barcode: s.barcode,
          price: s.price
        })),
        productsPhotos: v.photos.map((p, index) => ({
          file_id: p.id,
          main: (p.isMain) ? 1 : 0
        })),
        productsFashion: { file_id: v.mainPhoto?.id as number, product_color_id: v.colors_id as number }
        // productsPhotos: v.photos.map((p, index) => ({
        //   main: (index === 0) ? 1 : 0,
        //   file_id: p, // !!
        // })),
        // productsFashion: {
        //   file_id: v.mainPhoto,
        //   product_color_id: v.colors_id as number
        // }
      }
      return productsColors
    })
  }

  return data
}


export const createProduct = createAsyncThunk<any, void, { state: RootState }>(
  "Product/createProduct", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    // const { category, about, package: p } = getState().product

    // // изза валидации не должно быть возможности вызвать этот код с невалидными данными
    // //// ts-ignore

    // const data: ProductStep1 = {
    //   category_id: category.values[CategoryLvl.lvl4] || 0,
    //   name: about.values.name,
    //   country_id: about.values.country_id || 0,
    //   brand: about.values.brand,
    //   package: p.values,
    //   description: ''
    // }
    const data = getProductDataStep1(getState().product)
    return (await ProductService.createProduct(data))

  }
);


export const updateProductStep2 = createAsyncThunk<any, void, { state: RootState }>(
  "Product/updateProductStep2", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    // const { category, tags, description, collection, seasons, details, composition, productId } = getState().product

    // // изза валидации не должно быть возможности вызвать этот код с невалидными данными
    // //// ts-ignore

    // const productsCompound: { compound_id: number, material_id: number, value: string | number }[] = []
    // composition.values.forEach(c => {
    //   c.materials.forEach(m => {
    //     productsCompound.push({
    //       compound_id: c.compoundId,
    //       material_id: m.id as number,
    //       value: m.percent as number
    //     })
    //   })
    // })

    // const data: ProductStep2 = {
    //   category_id: category.values[CategoryLvl.lvl4] || 0,
    //   collection: collection.value,
    //   description: description.value,
    //   productsCompound,
    //   productsDetails: details.values,
    //   seasons: seasons.values.filter(s => s !== null) as number[],
    //   tags: tags.values,
    // }

    const state = getState().product
    const { productId } = state
    const data = getProductDataStep2(state)
    return (await ProductService.updateProduct(productId as number, data))

  }
);

export const updateProductStep3_4 = createAsyncThunk<any, void, { state: RootState }>(
  "Product/updateProductStep3_4", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    // const { variants, productId } = getState().product

    // // изза валидации не должно быть возможности вызвать этот код с невалидными данными
    // //// ts-ignore


    // const data: ProductStep3_4 = {
    //   productsColors: variants.values.map(v => {
    //     const productsColors: ProductsColorsStep3 = {
    //       colors_id: v.colors_id as number,
    //       color_article: parseInt(v.color_article || '0'),
    //       // price: number
    //       man_article: v.man_article as string,
    //       additionalColors: v.additionalColors.filter(ac => ac !== null).map(ac => ({ color_id: ac as number })),
    //       sizes: v.sizes.map(s => ({
    //         id: s.size_id,
    //         man_barcode: s.man_barcode,
    //         barcode: s.barcode,
    //         price: s.price
    //       })),
    //       productsPhotos: v.photos.map((p, index) => ({
    //         file_id: p.id,
    //         main: (p.isMain) ? 1 : 0
    //       })),
    //       productsFashion: { file_id: v.mainPhoto?.id as number, product_color_id: v.colors_id as number }
    //       // productsPhotos: v.photos.map((p, index) => ({
    //       //   main: (index === 0) ? 1 : 0,
    //       //   file_id: p, // !!
    //       // })),
    //       // productsFashion: {
    //       //   file_id: v.mainPhoto,
    //       //   product_color_id: v.colors_id as number
    //       // }
    //     }
    //     return productsColors
    //   })
    // }   
    const state = getState().product
    const { productId } = state
    const data = getProductDataStep34(state)
    return (await ProductService.updateProduct(productId as number, data))
  }
);

export const updateProductAll = createAsyncThunk<any, void, { state: RootState }>(
  "Product/updateProductAll", async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const state = getState().product
    const { productId } = state
    const dataStep1 = getProductDataStep1(state)
    const dataStep2 = getProductDataStep2(state)
    const dataStep34 = getProductDataStep34(state)
    const data = {
      ...dataStep1,
      ...dataStep2,
      ...dataStep34,
    }
    return (await ProductService.updateProduct(productId as number, data))
  }
);



export const sendToModeration = createAsyncThunk<any, void, { state: RootState }>(
  "Product/sendToModeration", async (_, { getState, rejectWithValue, fulfillWithValue }) => {

    const { productId } = getState().product
    return (await ProductService.sendToModeration(productId as number))
  }
);



const IsEmptyOrZeroValue = (value) => (value === undefined || value === null || value === '' || value === 0)

const emptyValuesValidator = (values: { [key: string]: number | string | null }): FieldError[] => {
  const errors: FieldError[] = []
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const value = values[key];
      if (IsEmptyOrZeroValue(value)) {
        errors.push({ name: key, error: '! Обязательное поле' })
      }
    }
  }
  return errors
}

const validatePackage = (state: PackageState): FieldError[] => {
  const errors = emptyValuesValidator(state.values)
  // state.hasErrors = state.errors.length > 0
  return errors
}
const validateCategory = (state: CategoryState): boolean => {
  return IsEmptyOrZeroValue(state.values[CategoryLvl.lvl4])
}
const validateAbout = (state: AboutState): FieldError[] => {
  const errors = emptyValuesValidator(state.values)
  // state.hasErrors = state.errors.length > 0
  return errors
}


const validateDescription = (state: DescriptionState): boolean => {
  return false
}
const validateDetails = (state: DetailsState): boolean => {
  return false
}
const validateTags = (state: TagsState): boolean => {
  return false
}
const validateCollection = (state: CollectionState): boolean => {
  // return IsEmptyOrZeroValue(state.value)
  return false
}
const validateSseasons = (state: SeasonsState): boolean => {
  // if (state.values.length < 1) return true
  // return state.values.findIndex(s => s === null) !== -1
  return false
}
const validateCertificates = (state: CertificatesState): boolean => {
  return false
  // if (state.values.length < 1) return true
  // return state.values.findIndex(s => s === null) !== -1
}
const validateTNVDCertificate = (state: TNVDCertificateState): boolean => {
  return false
  // return state.value === null
}


const validateComposition = (state: CompositionState): boolean => {
  const errors: number[] = []
  state.values.forEach(c => {
    c.materials.forEach(m => {
      if (!m.id || !m.percent) errors.push(c.compoundId)
    })
  })
  return errors.length > 0
}


const validateVariant = (state: VariantState): boolean => {
  if (!state.colors_id) return true
  if (!state.mainPhoto?.id || !state.mainPhoto.url) return true
  if (state.photos.find(p => !p.id || !p.url) !== undefined) return true
  if (state.sizes.length === 0) return true
  // if (state.sizes.find(s => !s.barcode || !s.man_barcode) !== undefined) return true
  if (state.sizes.find(s => !s.barcode) !== undefined) return true
  if (!state.man_article) return true
  if (!state.color_article) return true
  if (state.additionalColors.find(ac => ac === null) !== undefined) return true

  return false
}

const _validateVariants = (state: VariantsState): number[] => {
  const errors: number[] = []

  state.values.forEach((v, index) => {
    if (validateVariant(v)) {
      // errors.push(v.id)
      errors.push(index)
    }
  })

  return errors
}


function getVariantPrice(variant: VariantState): number | null | undefined {
  const { sizes } = variant
  if (sizes.length === 0) return null

  let price = sizes[0].price
  for (let index = 0; index < sizes.length; index++) {
    if (sizes[index].price !== price) return null
  }
  return price
}
function getProductPrice(product: ProductState) {
  const variants = product.variants.values
  if (variants.length === 0) return null

  let price = getVariantPrice(variants[0])
  for (let index = 0; index < variants.length; index++) {
    if (getVariantPrice(variants[index]) !== price) return null
  }
  return price
}


function getEmptyReferenceBook(): ReferenceBook {
  return {
    compounds: [],
    details: [],
    materials: [],
    sizes: []
  }
}

function getEmptyMaterial(): MaterialState {
  return {}
}


function setPricesForAllVariants(variants: VariantsState, price?: number) {
  variants.priceForAnyVariant = price
  variants.values.forEach(variant => {
    setPricesForAllSizes(variant, price)
  })
}
function setPricesForAllSizes(variant: VariantState, price?: number) {
  variant.priceForAnySize = price
  variant.sizes.forEach(s => s.price = price)
}

function getEmptyState(): ProductState {

  const state: ProductState = {
    isLoadingProduct: false,
    isLoadingProductError: false,
    isCommonDataLoading: false,
    isProductCreacion: false,
    isReferenceBookFetching: false,
    isLoadingBarcodes: false,
    isReadonlyMode: false,
    commonData: getEmptyCommonData(),


    referenceBook: getEmptyReferenceBook(),
    step: 1,
    // productId: 658, 
    productId: undefined,

    // UI
    category: {
      values: {
        // [CategoryLvl.lvl1]: 1,
        // [CategoryLvl.lvl2]: 2,
        // [CategoryLvl.lvl3]: 3,
        // [CategoryLvl.lvl4]: 4,
        [CategoryLvl.lvl1]: null,
        [CategoryLvl.lvl2]: null,
        [CategoryLvl.lvl3]: null,
        [CategoryLvl.lvl4]: null,
      },
      hasErrors: false // т.к. пустое
    },
    package: {
      values: {
        type_id: null,
        equipment: '',
        // information: '',
        height: null,
        width: null,
        depth: null,
        gross_weight: null,
        net_weight: null,
      },
      errors: [],
      // hasErrors: true // т.к. пустое
    },
    about: {
      values: {
        brand: '',
        name: '',
        country_id: 0,
      },
      errors: [],
      // hasErrors: true
    },
    composition: {
      values: [],
      hasErrors: false
    },
    details: {
      // values: {},
      values: [],
      hasErrors: false
    },
    description: {
      value: '',
      hasErrors: false
    },
    seasons: {
      values: [null],
      hasErrors: false
    },
    certificates: {
      values: [null],
      hasErrors: false,
    },
    TNVDCertificate: {
      value: null,
      hasErrors: false,
    },
    collection: {
      value: '',
      hasErrors: false
    },
    tags: {
      values: [],
      hasErrors: false
    },
    variants: {
      values: [getNewVariantState()],
      activeVariantIndex: 0,
      hasErrors: false, // т.к. пустое
      errors: [],
      isPricePerVariant: false,
      isPricePerSize: false,
      priceForAnyVariant: undefined // спец. значение // MinPrice
    },
    priceIsUpdated: true,
    uploadingProgress: []
  }

  //step 1
  // state.category.hasErrors = validateCategory(state.category)
  // state.about.errors = validateAbout(state.about)
  // state.package.errors = validatePackage(state.package)

  validateStep1(state)
  validateStep2(state)

  return state
}

const getEmptyCommonData = (): CommonData => ({
  categories: [],
  countries: [],
  packagesTypes: [],
  seasons: [],
  tags: [],
  colors: [],
  certificates: [],
})

export const initialState: ProductState = getEmptyState()

function getNewVariantState(price?: number): VariantState {
  return {
    id: Math.random(),
    man_article: '',
    additionalColors: [],
    sizes: [],
    // photos: ["http://88.198.56.78:8150/uploads/files/15/6426f78262217.jpg", "http://88.198.56.78:8150/uploads/files/15/6426f781bf36a.jpg"],
    photos: [],
    // mainPhoto: '',
    mainPhoto: undefined,//{},
    priceForAnySize: price || undefined // || MinPrice
  }
}

function buildColorArticle(variant: VariantState) {
  const additionalColors = variant.additionalColors.map(c => c?.toString().padStart(3, '0'))
  return `${(variant.colors_id || 0).toString().padStart(3, '0')}${additionalColors.join('')}`.padEnd(9, '0')
}

function findCategories(allCategories: Categories[], id: number): { [key in CategoryLvl]: number | null } | undefined {
  const categories: { [key in CategoryLvl]: number | null } = {
    [CategoryLvl.lvl1]: null,
    [CategoryLvl.lvl2]: null,
    [CategoryLvl.lvl3]: null,
    [CategoryLvl.lvl4]: null
  }
  let cid: number | null = id

  if (cid) {
    categories[CategoryLvl.lvl4] = cid
    cid = findParentCategory(allCategories, cid)
    if (cid) {
      categories[CategoryLvl.lvl3] = cid
      cid = findParentCategory(allCategories, cid)
      if (cid) {
        categories[CategoryLvl.lvl2] = cid
        cid = findParentCategory(allCategories, cid)
        if (cid) {
          categories[CategoryLvl.lvl1] = cid
          return categories
        }
      }
    }
  }
  // return categories
}
function findParentCategory(allCategories: Categories[], cid: number) {
  return allCategories.find(c => c.id === cid)?.parent_id || null
}
function updatePricesStates(state: ProductState) {
  state.variants.priceForAnyVariant = getProductPrice(state)
  // state.variants.isPricePerVariant = state.variants.priceForAnyVariant === null
  state.variants.values.forEach(v => {
    v.priceForAnySize = getVariantPrice(v)
    // if (v.priceForAnySize === null) {
    //   state.variants.isPricePerSize = true
    // }
  })
  return
}
function buildStateFromProduct(product: Products, referenceBook: ReferenceBook, oldState: ProductState, activeVariantId?: number): ProductState {
  const newState = getEmptyState()
  newState.commonData = oldState.commonData

  newState.referenceBook = referenceBook // || getEmptyReferenceBook()

  newState.hasPromotion = product.hasPromotion
  newState.hasDiscount = product.hasDiscount

  newState.productId = product.id
  newState.about.values.brand = product.brand || ''
  newState.about.values.country_id = product.country_id
  newState.about.values.name = product.name || ''

  const categories = findCategories(newState.commonData.categories, product.category_id)
  if (!categories) {
    throw new Error("Wrong product data");
  }
  newState.category.values = categories

  newState.collection.value = product.collection || ''

  const compositionValues: { [key: string]: MaterialState[] } = {}
  product.productsCompound?.forEach(c => {
    if (!compositionValues[c.compound_id]) {
      compositionValues[c.compound_id] = []
    }

    compositionValues[c.compound_id].push({
      id: c.material_id,
      percent: c.value,
      // name: c.,
    })
  }) || []

  newState.composition.values = referenceBook.compounds.map((c, i) => ({
    compoundId: c.id,
    // materials: compositionValues[c.id] || ((i === 0) ? [getEmptyMaterial()] : [])
    materials: compositionValues[c.id] || []
  }))



  newState.description.value = product.description || ''

  newState.details.values = product.productsDetails?.map(d => ({
    detail_id: d.detail_id,
    option_id: d.option_id || null,
    value: d.value || ''
  })) || []
  newState.package.values.depth = product.package?.depth || null
  newState.package.values.equipment = product.package?.equipment || ''
  newState.package.values.gross_weight = product.package?.gross_weight || null
  newState.package.values.height = product.package?.height || null
  newState.package.values.net_weight = product.package?.net_weight || null
  newState.package.values.type_id = product.package?.type_id || null
  newState.package.values.width = product.package?.width || null

  newState.seasons.values = product.seasons?.map(s => s.id) || []
  if (newState.seasons.values.length === 0) { newState.seasons.values.push(null) }

  newState.tags.values = product.tags?.map(t => t.id) || []

  newState.variants = {
    activeVariantIndex: 0,
    hasErrors: false,
    errors: [],
    isPricePerSize: false,
    isPricePerVariant: false,
    priceForAnyVariant: undefined, // MinPrice,
    values: product.productsColors?.map(c => {
      const mainPhoto = {
        id: c.productsFashion?.file?.id as number,
        url: c.productsFashion?.file?.url || ''
      }
      const photos = c.productsPhotos?.map(p => ({
        id: p.file?.id as number,
        url: p.file?.url || '',
        isMain: !!p.main
      })) || []

      const sizes: SizeState[] = c.productsColorsSizes?.map(s => {
        const newSize: SizeState = {
          size_id: s.size_id,
          price: s.price || undefined, // MinPrice,
          barcode: s.barcode || '',
          man_barcode: s.man_barcode || '',
        }
        return newSize
      }) || []

      const variant: VariantState = {
        id: c.id,
        mainPhoto,
        photos,

        colors_id: c.colors_id,
        color_article: c.color_article?.toString() || '',
        man_article: c.man_article,

        additionalColors: (c.additionalColors?.map(ac => ac.id || null) || []),
        sizes,
        priceForAnySize: undefined // MinPrice,
      }

      return variant
    }) || []
  }

  if (newState.variants.values.length < 1) {
    newState.variants.values.push(getNewVariantState())
  }

  const activeVariantIdIndex = newState.variants.values.findIndex(v => v.id === activeVariantId)

  if (activeVariantIdIndex >= 0) {
    newState.variants.activeVariantIndex = activeVariantIdIndex
  }

  updatePricesStates(newState)


  if (product.status === 1) { // на создании
    // на шаг 1 вернуться нельзя т.к. товар создаётся только после того как он закончен
    // newState.step = 2 если валидация шаг 2 === ошибка
    // newState.step = 3 если валидация шаг 3 === ошибка
    // иначе newState.step = 4
    newState.isReadonlyMode = false
    if (!validateStep1(newState)) {
      if (!validateStep2(newState)) {
        if (!_validateStep3(newState)) {
          newState.step = 4
        } else {
          newState.step = 3
        }
      } else {
        newState.step = 2
      }
    } else {
      newState.step = 1
    }
  } else {
    newState.isReadonlyMode = true
    newState.step = -1
  }


  return newState
}



export const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    loadEmptyState: (oldState) => {
      const state = getEmptyState()
      state.commonData = oldState.commonData
      return state
    },
    addUploadingPhoto: (state, action: PayloadAction<UploadingState>) => {
      state.uploadingProgress.push(action.payload)
    },
    clearUploadingPhotos: (state) => {
      state.uploadingProgress = []
    },
    setUploadingPhotoThumbnail: (state, action: PayloadAction<{ id: number | string, thumbnail: string }>) => {
      const p = state.uploadingProgress.find(p => p.id === action.payload.id)
      if (p) { p.thumbnail = action.payload.thumbnail }
    },
    setUploadingProgress: (state, action: PayloadAction<{ id: number | string, percent: number }>) => {
      const p = state.uploadingProgress.find(p => p.id === action.payload.id)
      if (p) { p.progress = action.payload.percent }
    },
    addUploadingPhotoSuccess: (state, action: PayloadAction<number>) => {
      const p = state.uploadingProgress.find(p => p.id === action.payload)
      if (p) { p.isActive = false; p.success = true }
    },
    addUploadingPhotoReject: (state, action: PayloadAction<number>) => {
      const p = state.uploadingProgress.find(p => p.id === action.payload)
      if (p) { p.isActive = false; p.rejected = true }
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
      switch (state.step) {
        case 1:
          validateStep1(state)
          break;
        case 2:
          validateStep2(state)
          break;
        case 3:
          _validateStep3(state)
          break;
        case 4:
          updatePricesStates(state) // при переходе на шаг цен нужно обновить состояния флагов, т.к. они могли измениться изза удаления/создания вариантов
          validateStep4(state)
          break;
        default:
          break;
      }
      return state
    },
    setCategoriesValue: (state, action: PayloadAction<CategoriesValue>) => {
      state.category.values = action.payload
      state.category.hasErrors = validateCategory(state.category)
    },
    // setCategory: (state, action: PayloadAction<{ lvl: string, value: number }>) => {
    //   const categoryValues = state.category.values
    //   switch (action.payload.lvl) {
    //     case CategoryLvl.lvl4:
    //       categoryValues[CategoryLvl.lvl4] = action.payload.value
    //       break;
    //     case CategoryLvl.lvl3:
    //       categoryValues[CategoryLvl.lvl4] = null
    //       categoryValues[CategoryLvl.lvl3] = action.payload.value
    //       break;
    //     case CategoryLvl.lvl2:
    //       categoryValues[CategoryLvl.lvl4] = null
    //       categoryValues[CategoryLvl.lvl3] = null
    //       categoryValues[CategoryLvl.lvl2] = action.payload.value
    //       break;
    //     case CategoryLvl.lvl1:
    //       categoryValues[CategoryLvl.lvl4] = null
    //       categoryValues[CategoryLvl.lvl3] = null
    //       categoryValues[CategoryLvl.lvl2] = null
    //       categoryValues[CategoryLvl.lvl1] = action.payload.value
    //       break;
    //     default:
    //       break;
    //   }
    //   state.category.hasErrors = validateCategory(state.category)
    // },
    setPackageValue: (state, action: PayloadAction<{ name: string, value: string }>) => {
      const packageValues = state.package.values
      switch (action.payload.name) {
        case 'height':
        case 'width':
        case 'depth':
          packageValues[action.payload.name] = parseInt(action.payload.value) || null
          break;
        case 'gross_weight':
        case 'net_weight': {
          packageValues[action.payload.name] = parseInt(action.payload.value) || null
          const maxNetWeight = (packageValues['gross_weight'] || 0) - 10
          if (packageValues['net_weight'] && packageValues['net_weight'] > maxNetWeight) {
            packageValues['net_weight'] = maxNetWeight > 0 ? maxNetWeight : null
          }
          break;
        }
        default:
          packageValues[action.payload.name] = action.payload.value
          break;
      }
      state.package.errors = validatePackage(state.package)
    },
    setAboutValue: (state, action: PayloadAction<{ name: string, value: string }>) => {
      state.about.values[action.payload.name] = action.payload.value
      state.about.errors = validateAbout(state.about)
    },
    setCompoundMaterial: (state, action: PayloadAction<{ compoundId: number, materialIndex: number, materialId: number }>) => {
      const { compoundId, materialIndex, materialId } = action.payload

      const compound = state.composition.values.find(v => v.compoundId === compoundId)
      if (compound) {
        compound.materials[materialIndex].id = materialId
      }
      state.composition.hasErrors = validateComposition(state.composition)
    },
    setCompoundMaterialPercent: (state, action: PayloadAction<{ compoundId: number, materialIndex: number, percent: number }>) => {
      const { compoundId, materialIndex, percent } = action.payload

      const compound = state.composition.values.find(v => v.compoundId === compoundId)
      if (compound) {
        let value = parseInt(percent.toString())
        if (isNaN(value)) { value = 0 }
        const sum = compound.materials.filter((m, i) => i !== materialIndex).reduce((s, m) => s + (m.percent || 0), 0)

        if (sum + value > 100) { value = 100 - sum }
        if (value < 0) { value = 0 }
        compound.materials[materialIndex].percent = value
      }
      state.composition.hasErrors = validateComposition(state.composition)
    },
    addMaterial: (state, action: PayloadAction<{ compoundId: number }>) => {
      const { compoundId } = action.payload

      const compound = state.composition.values.find(v => v.compoundId === compoundId)
      if (compound) {
        compound.materials.push(getEmptyMaterial())
      }
      state.composition.hasErrors = validateComposition(state.composition)
    },
    removeMaterial: (state, action: PayloadAction<{ compoundId: number, materialIndex: number }>) => {
      const { compoundId, materialIndex } = action.payload

      const compound = state.composition.values.find(v => v.compoundId === compoundId)
      if (compound) {
        compound.materials.splice(materialIndex, 1);
      }
      state.composition.hasErrors = validateComposition(state.composition)
    },
    setDetailValue: (state, action: PayloadAction<{ detail_id: number, value: string }>) => {
      // state.details.values[action.payload.name] = action.payload.value
      const d = state.details.values.find(d => d.detail_id === action.payload.detail_id)
      if (d) {
        d.value = action.payload.value
      } else {
        state.details.values.push({ detail_id: action.payload.detail_id, option_id: null, value: action.payload.value })
      }
      state.details.hasErrors = validateDetails(state.details)
    },
    setDetailOption: (state, action: PayloadAction<{ detail_id: number, option_id: number }>) => {
      // state.details.values[action.payload.name] = action.payload.value
      const d = state.details.values.find(d => d.detail_id === action.payload.detail_id)
      if (d) {
        d.option_id = action.payload.option_id
      } else {
        state.details.values.push({ detail_id: action.payload.detail_id, option_id: action.payload.option_id, value: '' })
      }
      state.details.hasErrors = validateDetails(state.details)
    },
    setDescriptionValue: (state, action: PayloadAction<string>) => {
      state.description.value = action.payload
      state.description.hasErrors = validateDescription(state.description)
    },
    addSeason: (state, action: PayloadAction<number>) => {
      state.seasons.values.push(action.payload)
      state.seasons.hasErrors = validateSseasons(state.seasons)
    },
    removeSeason: (state, action: PayloadAction<number>) => {
      const seasonIndex = action.payload
      if (state.seasons.values.length === 1) {
        state.seasons.values[0] = null
      } else {
        state.seasons.values.splice(seasonIndex, 1);
      }
      state.seasons.hasErrors = validateSseasons(state.seasons)
    },
    setSeasonValue: (state, action: PayloadAction<{ seasonIndex: string, value: string }>) => {
      state.seasons.values[action.payload.seasonIndex] = action.payload.value
      state.seasons.hasErrors = validateSseasons(state.seasons)
    },
    setCollectionValue: (state, action: PayloadAction<string>) => {
      state.collection.value = action.payload
      state.collection.hasErrors = validateCollection(state.collection)
    },
    addCertificate: (state, action: PayloadAction<number>) => {
      state.certificates.values.push(action.payload)
      state.certificates.hasErrors = validateCertificates(state.certificates)
    },
    removeCertificate: (state, action: PayloadAction<number>) => {
      const certificateIndex = action.payload
      state.certificates.values.splice(certificateIndex, 1);
      state.certificates.hasErrors = validateCertificates(state.certificates)
    },
    setCertificateValue: (state, action: PayloadAction<{ certificateIndex: string, value: string }>) => {
      state.certificates.values[action.payload.certificateIndex] = action.payload.value
      state.certificates.hasErrors = validateCertificates(state.certificates)
    },
    setTNVDCertificateValue: (state, action: PayloadAction<number>) => {
      state.TNVDCertificate.value = action.payload
      state.TNVDCertificate.hasErrors = validateTNVDCertificate(state.TNVDCertificate)
    },
    toggleTag: (state, action: PayloadAction<number>) => {
      if (state.tags.values.indexOf(action.payload) === -1) {
        state.tags.values.push(action.payload)
      } else {
        state.tags.values = state.tags.values.filter(t => t !== action.payload)
      }
      state.tags.hasErrors = validateTags(state.tags)
    },
    addVariant: (state) => {
      state.variants.values.push(getNewVariantState())
      state.variants.activeVariantIndex = state.variants.values.length - 1
      // state.about.errors = validateAbout(state.about)
    },
    removeVariant: (state, action: PayloadAction<number>) => {
      if (state.variants.values.length > 1) {
        const index = action.payload

        if (state.variants.activeVariantIndex === index) {
          state.variants.activeVariantIndex = 0
        } else if (state.variants.activeVariantIndex > state.variants.values.length - 2) {
          state.variants.activeVariantIndex = state.variants.values.length - 2
        }
        state.variants.values.splice(index, 1);
        // state.about.errors = validateAbout(state.about)
      }
    },
    selectVariant: (state, action: PayloadAction<number>) => {
      state.variants.activeVariantIndex = action.payload
    },
    setActiveVariantValue: (state, action: PayloadAction<{ name: string, value: string | number }>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      switch (action.payload.name) {
        case 'colors_id':
          variant[action.payload.name] = action.payload.value as number
          // variant.color_article = `${action.payload.value}`.padEnd(9, '0')
          variant.color_article = buildColorArticle(variant)
          break;
        default:
          variant[action.payload.name] = action.payload.value
          break;
      }
      // state.about.errors = validateAbout(state.about)
    },
    setActiveVariantAdditionalColor: (state, action: PayloadAction<{ index: number, value: number }>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.additionalColors[action.payload.index] = action.payload.value
      variant.color_article = buildColorArticle(variant)
      // state.about.errors = validateAbout(state.about)
    },
    createAdditionalColor: (state, action: PayloadAction<number>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.additionalColors.push(action.payload)
      variant.color_article = buildColorArticle(variant)
      // state.about.errors = validateAbout(state.about)
    },
    removeAdditionalColor: (state, action: PayloadAction<number>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.additionalColors.splice(action.payload, 1);
      variant.color_article = buildColorArticle(variant)
      // state.about.errors = validateAbout(state.about)
    },
    toggleSize: (state, action: PayloadAction<number>) => {
      const size_id = action.payload
      const variant = state.variants.values[state.variants.activeVariantIndex]
      const index = variant.sizes.findIndex(s => s.size_id === size_id)

      if (index > -1) {
        variant.sizes.splice(index, 1);
      } else {
        // variant.sizes.push({ size_id, barcode: '', man_barcode: '', price: MinPrice })
        variant.sizes.push({ size_id, barcode: '', man_barcode: '', price: undefined })
      }
      // state.about.errors = validateAbout(state.about)
    },
    setSizeValue: (state, action: PayloadAction<{ sizeIndex: number, name: string, value: string }>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      const { sizeIndex, name, value } = action.payload
      variant.sizes[sizeIndex][name] = value
      // state.about.errors = validateAbout(state.about)
    },
    addPhoto: (state, action: PayloadAction<ProductPhoto>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.photos.push(action.payload)
      // state.about.errors = validateAbout(state.about)
    },
    removePhoto: (state, action: PayloadAction<number>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.photos = variant.photos.filter(p => p.id !== action.payload)
      // state.about.errors = validateAbout(state.about)
    },
    removePhotos: (state, action: PayloadAction<number[]>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.photos = variant.photos.filter(p => action.payload.indexOf(p.id) === -1)
      if (!variant.photos.find(p => p.isMain) && variant.photos.length > 0) {
        variant.photos[0].isMain = true
      }
      // state.about.errors = validateAbout(state.about)
    },
    sortPhotos: (state, action: PayloadAction<{ oldIndex: number, newIndex: number }>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      const { oldIndex, newIndex } = action.payload
      variant.photos = arraySwap(variant.photos, oldIndex, newIndex)
    },
    setMainPhoto: (state, action: PayloadAction<number>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      const p = variant.photos.find(p => p.id === action.payload)
      if (p) {
        variant.photos.forEach(p => p.isMain = false)
        p.isMain = true
      }
    },
    removeMainPhoto: (state) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      variant.mainPhoto = undefined // {}
      // variant.mainPhoto = ''
      // state.about.errors = validateAbout(state.about)
    },
    setPriceForAnyVariant: (state, action: PayloadAction<number>) => {
      // const price = action.payload
      setPricesForAllVariants(state.variants, action.payload)
      state.variants.priceForAnyVariant = action.payload
      // state.about.errors = validateAbout(state.about)
    },
    setPriceForAnySize: (state, action: PayloadAction<{ variantIndex: number, price: number }>) => {
      const { price, variantIndex } = action.payload
      const variant = state.variants.values[variantIndex]
      setPricesForAllSizes(variant, price)
    },
    setPriceForVariantSize: (state, action: PayloadAction<{ variantIndex: number, sizeIndex: number, price: number }>) => {
      const { price, variantIndex, sizeIndex } = action.payload
      const variant = state.variants.values[variantIndex]
      // state.variants.isPricePerColor = false
      // state.variants.isPricePerSize = false
      variant.sizes[sizeIndex].price = price
      // state.about.errors = validateAbout(state.about)
      updatePricesStates(state)
    },

    setIsPricePerSize: (state, action: PayloadAction<boolean>) => {
      state.variants.isPricePerSize = action.payload
      if (action.payload === false) {
        for (let index = 0; index < state.variants.values.length; index++) {
          const variant = state.variants.values[index];
          if (variant.sizes.length > 0) {
            variant.priceForAnySize = variant.sizes[0].price || undefined // || MinPrice
          } else {
            variant.priceForAnySize = undefined // MinPrice
          }
          setPricesForAllSizes(variant, variant.priceForAnySize)
        }
      }
      // state.about.errors = validateAbout(state.about)
    },
    setIsPricePerVariant: (state, action: PayloadAction<boolean>) => {
      state.variants.isPricePerVariant = action.payload
      if (action.payload === false) {
        state.variants.isPricePerSize = false
        if (state.variants.values.length > 0 && state.variants.values[0].sizes.length > 0) {
          state.variants.priceForAnyVariant = state.variants.values[0].sizes[0].price || undefined // || MinPrice
        } else {
          state.variants.priceForAnyVariant = undefined // MinPrice
        }
        setPricesForAllVariants(state.variants, state.variants.priceForAnyVariant)
      }
      // state.about.errors = validateAbout(state.about)
    },
    validateVariants: (state) => {
      state.variants.errors = _validateVariants(state.variants)
      state.variants.hasErrors = state.variants.errors.length > 0
      state.priceIsUpdated = true
    },
    setPriceSaved: (state) => {
      state.priceIsUpdated = false
    },
    resetState: () => {
      return getEmptyState()
    },
    // toggleEditMode: (state) => {
    //   state.isReadonlyMode = !state.isReadonlyMode
    // },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      if (action.payload) { // перед редактированием делаем валидацию
        validateStep1(state)
        validateStep2(state)
        _validateStep3(state)
        updatePricesStates(state) // при переходе на шаг цен нужно обновить состояния флагов, т.к. они могли измениться изза удаления/создания вариантов
        validateStep4(state)
      }
      state.isReadonlyMode = !action.payload
    }
  },

  extraReducers: (builder) => {


    builder.addCase(fetchProduct.pending, (state) => {
      state.isLoadingProduct = true
      state.isLoadingProductError = false
    });
    builder.addCase(fetchProduct.fulfilled, (state, action: PayloadAction<{ product: Products, referenceBook: ReferenceBook, variantId: number }>) => {
      const newState = buildStateFromProduct(action.payload.product, action.payload.referenceBook, state, action.payload.variantId)
      newState.isLoadingProduct = false
      return newState
    });
    builder.addCase(fetchProduct.rejected, (state, action) => {
      // state.commonData = getEmptyCommonData()
      state.isLoadingProduct = false
      state.isLoadingProductError = true
    });

    builder.addCase(fetchCommonData.pending, (state) => {
      state.isCommonDataLoading = true
    });
    builder.addCase(fetchCommonData.fulfilled, (state, action) => {
      state.commonData = action.payload
      state.isCommonDataLoading = false
    });
    builder.addCase(fetchCommonData.rejected, (state, action) => {
      state.commonData = getEmptyCommonData()
      state.isCommonDataLoading = false
    });


    // builder.addCase(uploadPhoto.pending, (state) => {});  // TODO
    builder.addCase(uploadPhoto.fulfilled, (state, action: PayloadAction<{ id: number, url: string, isMain?: boolean }>) => {
      const variant = state.variants.values[state.variants.activeVariantIndex]
      if (action.payload.isMain) {
        variant.mainPhoto = { url: action.payload.url, id: action.payload.id }
      } else {
        const isMain = variant.photos.length === 0
        variant.photos.push({ url: action.payload.url, id: action.payload.id, isMain })
      }
    });
    // builder.addCase(uploadPhoto.rejected, (state, action) => {}); // TODO



    builder.addCase(generateBarcodes.pending, (state) => {
      state.isLoadingBarcodes = true
    });
    builder.addCase(generateBarcodes.fulfilled, (state, action: PayloadAction<{ size_id: number, barcode: string }[]>) => {
      state.isLoadingBarcodes = false

      const variant = state.variants.values[state.variants.activeVariantIndex]
      for (const size of variant.sizes) {
        size.barcode = action.payload?.find(s => s.size_id === size.size_id)?.barcode || ''
      }
      // state.variants.hasErrors = _validateVariants(state.variants)
    });
    builder.addCase(generateBarcodes.rejected, (state, action) => {
      state.isLoadingBarcodes = false
    });


    builder.addCase(createProduct.pending, (state) => {
      state.isProductCreacion = true
    });
    builder.addCase(createProduct.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
      if (action.payload) {
        state.step = 2
        state.productId = action.payload.data.data.id
      }
      state.isProductCreacion = false
    });
    builder.addCase(createProduct.rejected, (state, action) => {
      // state.commonData = getEmptyCommonData()
      state.isProductCreacion = false
    });


    builder.addCase(updateProductStep2.pending, (state) => {
      state.isProductCreacion = true
    });
    builder.addCase(updateProductStep2.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
      state.step = 3
      state.isProductCreacion = false
    });
    builder.addCase(updateProductStep2.rejected, (state, action) => {
      state.isProductCreacion = false
    });

    builder.addCase(updateProductStep3_4.pending, (state) => {
      state.isProductCreacion = true
    });
    builder.addCase(updateProductStep3_4.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
      saveSuccessMsg()
      state.step = 4
      state.isProductCreacion = false
    });
    builder.addCase(updateProductStep3_4.rejected, (state, action) => {
      // state.commonData = getEmptyCommonData()
      errorMsg()
      state.isProductCreacion = false
    });

    builder.addCase(updateProductAll.pending, (state) => {
      state.isProductCreacion = true
    });
    builder.addCase(updateProductAll.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
      saveSuccessMsg()
      // state.step = 4
      state.isProductCreacion = false
    });
    builder.addCase(updateProductAll.rejected, (state, action) => {
      errorMsg()
      state.isProductCreacion = false
    });




    builder.addCase(fetchReferenceBook.pending, (state) => {
      state.isReferenceBookFetching = true
      state.referenceBook = getEmptyReferenceBook()
      state.composition.values = []
      state.composition.hasErrors = false
    });
    builder.addCase(fetchReferenceBook.fulfilled, (state, action: PayloadAction<ReferenceBook>) => {
      const referenceBook = action.payload || getEmptyReferenceBook()
      const values: CompositionValue[] = referenceBook.compounds.map(c => ({
        compoundId: c.id,
        materials: c.required ? [getEmptyMaterial()] : []
      }))
      // const values: CompositionValue[] = referenceBook.compounds.map(c => ({ compoundId: c.id, materials: [] }))
      state.referenceBook = referenceBook
      state.composition.values = values
      state.isReferenceBookFetching = false
    });
    builder.addCase(fetchReferenceBook.rejected, (state, action) => {
      state.isReferenceBookFetching = false
      state.referenceBook = getEmptyReferenceBook()
    });
  }
});

// const listenerMiddleware = createListenerMiddleware<RootState>()

startAppListening({
  predicate: (action, currentState, previousState) => {
    // return action.type === 'Product/setCategory' && currentState.product.category.values[CategoryLvl.lvl4] !== previousState.product.category.values[CategoryLvl.lvl4]
    return action.type === 'Product/setCategoriesValue' && currentState.product.category.values[CategoryLvl.lvl4] !== previousState.product.category.values[CategoryLvl.lvl4]
    // || currentState.product.productId !== previousState.product.productId
  },
  effect: (action, { dispatch }) => {
    dispatch(fetchReferenceBook())
  },
})
// startAppListening({
//   predicate: (action, currentState, previousState) => {
//     return currentState.product.productId !== previousState.product.productId
//   },
//   effect: (action, { dispatch }) => {
//     dispatch(fetchCommonData())
//   },
// })
startAppListening({
  predicate: (action, currentState, previousState) => {
    return action.type !== 'Product/validateVariants' && currentState.product.variants !== previousState.product.variants
  },
  effect: (action, { dispatch }) => {
    dispatch(validateVariants())
    // state.product.variants.hasErrors = validateVariants(state.product.variants)
  },
})



export function step1HasErrors(state: ProductState): boolean {
  // const state = rootState.product
  // return validateAbout(state.about).length > 0 || validateCategory(state.category) || validatePackage(state.package).length > 0
  // console.log(state.package.errors, state.category.hasErrors, state.about.errors);

  return state.package.errors.length > 0 || state.category.hasErrors || state.about.errors.length > 0
}
export function step2HasErrors(state: ProductState): boolean {
  // const state = rootState.product
  // return validateAbout(state.about).length > 0 || validateCategory(state.category) || validatePackage(state.package).length > 0
  // console.log(state.collection.hasErrors, state.seasons.hasErrors, state.composition.hasErrors, state.details.hasErrors,
  //   state.description.hasErrors, state.certificates.hasErrors, state.TNVDCertificate.hasErrors);

  return state.collection.hasErrors || state.seasons.hasErrors || state.composition.hasErrors || state.details.hasErrors
    || state.description.hasErrors || state.certificates.hasErrors || state.TNVDCertificate.hasErrors
}
export function step3HasErrors(state: ProductState): boolean {
  return state.variants.hasErrors
}
export function step4HasErrors(state: ProductState): boolean {
  // const state = rootState.product
  // return validateAbout(state.about).length > 0 || validateCategory(state.category) || validatePackage(state.package).length > 0
  return state.package.errors.length > 0 || state.category.hasErrors || state.about.errors.length > 0
}
export function productHasErrors(state: ProductState): boolean {
  // console.log(step1HasErrors(state), step2HasErrors(state), step3HasErrors(state), step4HasErrors(state));

  return step1HasErrors(state) || step2HasErrors(state) || step3HasErrors(state) || step4HasErrors(state)
}

function validateStep1(state: ProductState) {
  state.category.hasErrors = validateCategory(state.category)
  state.about.errors = validateAbout(state.about)
  state.package.errors = validatePackage(state.package)

  return step1HasErrors(state)
}
function validateStep2(state: ProductState) {
  state.collection.hasErrors = validateCollection(state.collection)
  state.seasons.hasErrors = validateSseasons(state.seasons)
  state.composition.hasErrors = validateComposition(state.composition)
  state.details.hasErrors = validateDetails(state.details)
  state.description.hasErrors = validateDescription(state.description)
  state.tags.hasErrors = validateTags(state.tags)
  state.certificates.hasErrors = validateCertificates(state.certificates)
  state.TNVDCertificate.hasErrors = validateTNVDCertificate(state.TNVDCertificate)

  return step2HasErrors(state)
}
function _validateStep3(state: ProductState) {

  state.variants.errors = _validateVariants(state.variants)
  state.variants.hasErrors = state.variants.errors.length > 0

  return step3HasErrors(state)
}
function validateStep4(state: ProductState) {
  state.category.hasErrors = validateCategory(state.category)
  state.about.errors = validateAbout(state.about)
  state.package.errors = validatePackage(state.package)

  return step4HasErrors(state)
}


export const activeVariantSelector = (rootState: RootState): VariantState => {
  const { activeVariantIndex } = rootState.product.variants
  return rootState.product.variants.values[activeVariantIndex]
}
export const colorSelector = (cid?: number) => (rootState: RootState): Colors | undefined => {
  return rootState.product.commonData.colors.find(c => c.id === cid)
}




export const createNewProduct = () => async (dispatch) => {
  return await dispatch(loadEmptyState())
  // await dispatch(fetchCommonData())
  // return 
}

const { validateVariants, setUploadingProgress, addUploadingPhoto, setUploadingPhotoThumbnail, addUploadingPhotoSuccess, addUploadingPhotoReject } = productSlice.actions;

export const {
  // setCategory, 
  setCategoriesValue, setPackageValue, setAboutValue, setStep, setCompoundMaterial, setCompoundMaterialPercent,
  removeMaterial, addMaterial, setDetailValue, setDescriptionValue, setCollectionValue, setSeasonValue,
  removeSeason, addSeason, toggleTag, removeVariant, addVariant, selectVariant, setActiveVariantValue,
  setActiveVariantAdditionalColor, createAdditionalColor, removeAdditionalColor, toggleSize, setSizeValue,
  removePhoto, addPhoto, removeMainPhoto, setPriceForAnySize, setPriceForAnyVariant, setIsPricePerVariant,
  setIsPricePerSize, setPriceForVariantSize, loadEmptyState, setDetailOption, setPriceSaved, resetState,
  setEditMode, setMainPhoto, sortPhotos, removePhotos, clearUploadingPhotos, addCertificate, removeCertificate,
  setCertificateValue, setTNVDCertificateValue
} = productSlice.actions;

export default productSlice.reducer;