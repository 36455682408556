import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Routes} from "../../../../../constants/routes";
import CompanyService from "../../../../../services/CompaniesService";
import {PinkErrorText} from "../../../../simples/PinkErrorText";
import {PurpleButton} from "../../../../simples/PurpleButton";
import LeftRegistrationCard from "../LeftRegistrationCard/LeftRegistrationCard";
import TinCheckBox from "../TinCheckBox/TinCheckBox";
import TinTextInput from "../TinTextInput/TinTextInput";
import styles from "./ActiveTinPart.module.scss";
import { BlueButton } from "../../../../simples/BlueButton";

type TinData = {
  withVat: boolean;
  tin: string;
};

const ActiveTinPart = () => {
  const [error, setError] = useState(false);
  const [data, setData] = useState<TinData>({
    withVat: false,
    tin: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const setTin = (tin: string) => {
    setData(prevState => ({...prevState, tin}));
    error && setError(false);
  };

  const setWithVat = (withVat: boolean) => {
    setData(prevState => ({...prevState, withVat}));
    error && setError(false);
  };

  const goNext = async () => {
    const organisationInfo = await CompanyService.getOrganisationInfoByTin(
      data.tin,
    );

    !organisationInfo && setError(true);
    organisationInfo &&
      navigate(Routes.REGISTRATION_TIN, {
        state: {
          organisationInfo,
          tinData: {...data},
          ...location.state,
        },
      });
  };

  return (
    <LeftRegistrationCard>
      <h2 className={styles.title}>Регистрация</h2>
      <PinkErrorText
        className={styles.errorText}
        isShow={error}
        title={"ИНН не найден"}
      />
      <TinTextInput className={styles.inputContainer} setTin={setTin} />
      <TinCheckBox setWithVat={setWithVat} withVat={data.withVat} />
      <BlueButton
        disabled={data.tin.length < 10}
        className={styles.button}
        title="Далее"
        onClick={goNext}
      />
      <div className={styles.info}>Нажимая “Далее”, я даю согласие на <a>обаботку персональных данных</a></div>
    </LeftRegistrationCard>
  );
};

export default ActiveTinPart;
