import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { setSelectedTypeFilter } from '../../../../../store/slices/feedback';
import { TypeFilterOptions } from '../../consts';
import { TypeFilterValue } from '../../types';
import FilterLabel from './FilterLabel';

import styles from "./Filters.module.scss";

export default function TypeFilter() {
  const dispatch = useAppDispatch()

  const selectedType = useAppSelector(state => state.feedback.selectedTypeFilter);
  const availableFiltersOptionsStatuses = useAppSelector(state => state.feedback.availableFiltersOptionsStatuses)

  const handleSelectOption = (v: TypeFilterValue) => () => {
    dispatch(setSelectedTypeFilter(v))
  }

  const typeFilterOptions = TypeFilterOptions.map(to => {
    const so = availableFiltersOptionsStatuses.find(o => o.name === to.id)
    return { ...to, value: so?.count || 0 }
  })

  const labels = typeFilterOptions.map((o, i) =>
    <FilterLabel
      key={i}
      text={o.title}
      value={o.value}
      select={handleSelectOption(o.id)}
      selected={selectedType === o.id}
      deselect={handleSelectOption(TypeFilterValue.ALL)}
    />
  )

  return <div className={styles.col}>
    {labels}
  </div>;
}