import React, { useEffect, useState } from 'react'
import styles from '../MainPage.module.scss'
import { Panel } from '../../../simples/Panel'
import { toast } from 'react-toastify'
import { Promotions } from '../../../../types/swagger/llyApi_new'
import api from "../../../../utils/api";
import { formatDateForServer, formatPeriod } from '../../../../utils/action'

import calendar_icon from '../assets/calendar.svg'
import { BlueButton } from '../../../simples/BlueButton'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../constants/routes'

type FetchResponse = {
  success?: boolean
  data?: Promotions[]
}

export default function Actions() {

  const [promotions, setPromotions] = useState<Promotions[]>()
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const today = formatDateForServer(Date.now())

  useEffect(() => {
    setIsLoading(true)
    api.get<FetchResponse>(`/promotions?start_date=${today}&finish_date=${today}`).then(resp => {
      if (resp.data.success) {
        setPromotions(resp.data.data)
      } else {
        toast.error("Ошибка", { theme: "light" });
      }
      setIsLoading(false)
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      console.log(e);
    })
  }, [])

  const handle = () => { navigate(Routes.ACTIONS_CALENDAR) }

  return (
    <Panel className={styles.sidebar_content} isLoading={isLoading}>
      <div className={styles.actions}>
        <h1>акции календаря</h1>
        {promotions?.map(action => <div
          onClick={handle}
          key={action.id}
          // className={classNames(styles.action, styles["col-" + action.daysOnThisWeek], styles["offset-" + action.startDayOfThisWeek])}
          className={styles.action}
        >
          <div className={styles.action_head}>{action.title}</div>
          <div className={styles.action_body}>
            <div className={styles.period}>
              <img src={calendar_icon} alt="" /><span>Период:</span> <span>{formatPeriod(action.start_date || "", action.finish_date || "")}</span>
            </div>
            <div className={styles.discount}>
              <span>{action.text}</span> <span>-{action.percent}%!</span>
            </div>
          </div>
        </div>)}
        <div className={styles.actions_btns}>
          <BlueButton title='Подробнее' onClick={handle} />
        </div>
      </div>
    </Panel>
  )
}
