import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { RootState } from "../../../../../store";
import { closeInfoDialog } from "../../../../../store/slices/massImport";
import { ButtonSize, ButtonType } from "../../../../simples/BlueButton/BlueButton";
import { ModalDialog, ButtonConfig } from "../../../../simples/ModalDialog";

export default function DialogInfo() {
  const dispatch = useAppDispatch();
  const sendToModerateAnswer = useAppSelector((state: RootState) => state.massImport.sendToModerateAnswer);
  const isActive = !!sendToModerateAnswer;

  const handleClose = () => {
    dispatch(closeInfoDialog());
  };

  const buttonsConfig: ButtonConfig[] = [
    { text: "Отлично", type: ButtonType.Ok, onClick: handleClose, size: ButtonSize.Big },
  ];
  return (
    <ModalDialog
      isActive={isActive}
      text={`Отправлено на модерацию ${sendToModerateAnswer?.moderate || 0}, сохранено ${sendToModerateAnswer?.saved || 0} карточек`}
      buttonsConfig={buttonsConfig}
      handleClose={handleClose}
    />
  );
}
