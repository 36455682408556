import classNames from 'classnames'
import React, { useState } from 'react'
import arrowIcon from "./assets/arrow.svg"

type Props = {
  selectedYear: number
  min: number
  max: number
  onChange: (selectedYear: number) => void
}

export default function YearSelector({ selectedYear, onChange, min, max }: Props) {

  const [fy, setFy] = useState(selectedYear)
  const handleSelectYear = (y: number) => () => {
    onChange(y)
  }
  const handleScrollYearsL = () => {
    const y = fy - 1
    if (y >= min) { setFy(y) }
  }
  const handleScrollYearsR = () => {
    const y = fy + 1
    if (y <= max) { setFy(y) }
  }

  const options: Array<JSX.Element> = []
  for (let index = 0; index < 4; index++) {
    const y = fy + index
    options.push(<div key={y}
      className={classNames("year", { selected: y === selectedYear })}
      onClick={handleSelectYear(y)}
    >{y}</div>)
  }

  return (
    <div className='year_selector'>
      <img src={arrowIcon} className='scroll_years_left' onClick={handleScrollYearsL} />
      <div className='years'>{options}</div>
      <img src={arrowIcon} className='scroll_years_right' onClick={handleScrollYearsR} />
    </div>
  )
}
