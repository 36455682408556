import React from "react";
import HintTexts from "../../../../../constants/hints";
import {LightInput} from "../../../../simples/LightInput";
import {QuestionHint} from "../../../../simples/QuestionHint";
import styles from "./TinTextInput.module.scss";

type Props = {
  className?: string;
  setTin: (text: string) => void;
  tin?: string;
};

const TinTextInput = ({className, setTin, tin}: Props) => {
  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setTin(value);
  };

  return (
    <div className={className || ""}>
      <span className={styles.headerContainer}>
        <h3 className={styles.header}>ИНН</h3>
        <QuestionHint hint={HintTexts.VAT} />
      </span>
      <LightInput
        className={styles.input}
        mask={"999999999999"}
        onChange={onInput}
        value={tin}
      />
      <p className={styles.recomandation}>
        Введите ИНН регистрируемой организации
      </p>
    </div>
  );
};

export default TinTextInput;
