export enum Routes {
  HOME = "/",
  AUTH = "/login",
  REGISTRATION = "/registration",
  REGISTRATION_BIC = "/registration/bic",
  REGISTRATION_TIN = "/registration/tin",
  REGISTRATION_TIN_ACTIVE = "/registration/tin/active",
  REGISTRATION_BIC_ACTIVE = "/registration/bic/active",
  CONFIRM_CODE = "/confirm/sms",

  INDEX = "/index",
  // UPDATE_PRODUCT_CARD = "/product/view",
  // UPDATE_PRODUCT_CARD_ID = "/product/view/:id",
  // UPDATE_PRODUCT_CARD_ID_COLOR_ID = "/product/view/:id/:color_id",
  LOGISTIC_PAGE = "/logistic",
  STOCK_PAGE = "/stock",
  // PRICE_PAGE = "/price/",
  PRICE_PAGE = "/price/:tabId",
  ANALITIC_PAGE = "/analitic",
  CARD_PAGE = "/card",
  // TAGS_PAGE = "/tags",
  STATISTIC =  '/statistic/:tabId',
  WHAT_TO_SELL = '/what_to_sell/:tabId',

  API_PAGE = "/api",
  INSTRUCTION_PAGE = "/instruction/",
  SETTINGS_PAGE = "/settings",
  LOGOUT = "/logout",
  EDITING_PRODUCT_CARD = "/product/create",
  GET_PRODUCT = "/products/view",

  //   Список товаров
  PRODUCTS_LIST = "/products/",
  IMPORT_TABLE = "/import_table/",
  MASS_EDIT = "/mass_edit/",

  ACTIONS_CALENDAR = "/actions/calendar",

  JOIN_IN_PROMOTION = "/promotion/join",

  FEEDBACK = "/feedback/",

  // PROFILE_ACCOUNT = "/profile/account",
  PROFILE = "/profile/:tabId",
  // PROFILE_ABOUT = "/profile/about",
  ABOUT = "/about/:tabId",

  PRODUCT = "/product/",
  // PRODUCT_CARD_ID = "/product/:id",
  PRODUCT_CARD_ID_COLOR_ID = "/product/:id/:color_id",

  EMPLOYEES = '/employees/',

  DOCS = '/company_documents/',
  
  SCHEDULE = '/schedule/',
  
  BRANDS = '/brands/:tabId',
  MAINPAGE = '/main/',
  WAREHOUSES = '/warehouses/',
  ANALYTICS = '/analytics/:tabId',


  LOGISTICS_FBO = '/logistics/FBO',
  LOGISTICS_FBS = '/logistics/FBS',
}
