import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../services/UserService";
import { Companies, Users } from '../../types/swagger/llyApi_new'


const hasRefreshToken = !!localStorage.getItem("refresh_token")

export const initialState = {
  // company: null as OrganisationData | null,
  // user: null as UserData | null,
  company: null as Companies | null,
  user: null as Users | null,
  isAuth: false,
  isLoadingData: hasRefreshToken,
};

export const fetchUserData = createAsyncThunk("/user", async () => {
  const data = await UserService.getUserData();
  return data;
});


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsDeleted: (state, action: PayloadAction<boolean>) => {
      const companyStatus = state.user?.companyStatus
      if (companyStatus) { companyStatus.deleted = action.payload }
    },
    loguot: () => {
      // state.company =  null;
      // state.user = null;
      // state.isAuth = false;
      return {...initialState, isLoadingData: false}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.pending, (state) => {
      state.isLoadingData = true;
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      if (action.payload) {
        const { company, ...userData } = action.payload;
        state.company = company || null;
        state.user = userData;
        state.isAuth = true;
      }
      state.isLoadingData = false;
    });
    builder.addCase(fetchUserData.rejected, (state) => {
      state.isLoadingData = false;
    });
  },
});

export const { setIsDeleted, loguot } = authSlice.actions

export default authSlice.reducer;
