import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import updateProductSlice from './slices/update-product';
import productEditReducr from './slices/productEdit';
import goodsListReducer from './slices/goodsList';
import massImportReducer from './slices/massImport';
import massEdittReducer from './slices/massEdit';
import logosSlice from "./slices/getters/logo-list";
import joinInPromotionReducer from './slices/joinInPromotion';
import sellProduct from './slices/sellProduct';
import feedbackReducer from './slices/feedback';
import productReducr from './slices/product';
import lawReducer from './slices/law';
import companyDocumentsReducer from './slices/companyDocuments';
import brandsReducer from './slices/brands';
import certificatesReducer from './slices/certificates';
import mainMenuReducer from './slices/mainMenu';
import fboReducer from './slices/fbo';
import fbsReducer from './slices/fbs';
import { listenerMiddleware } from './listenerMiddleware';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    updateProduct: updateProductSlice,
    products: productEditReducr,
    productsList: goodsListReducer,
    massImport: massImportReducer,
    massEdit: massEdittReducer,
    logoList: logosSlice.reducer,
    joinInPromotion: joinInPromotionReducer,
    sellProduct: sellProduct,
    feedback: feedbackReducer,
    product: productReducr,
    law: lawReducer,
    companyDocuments: companyDocumentsReducer,
    brands: brandsReducer,
    certificates: certificatesReducer,
    mainMenu: mainMenuReducer,
    fbo: fboReducer,
    fbs: fbsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});


// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
