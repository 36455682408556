import React from "react";
import styles from "./LightInput.module.scss";
import InputMask from "react-input-mask";
import classNames from "classnames";

const LightInput = (nativeProps: any) => (
  <InputMask
    {...nativeProps}
    maskChar={null}
    className={classNames(styles.input, nativeProps?.className, {[styles.error]: nativeProps?.isError})}
  />
);

export default LightInput;
