import React from "react";
import { ButtonSize, ButtonType } from "../../simples/BlueButton";
import ModalDialog, { ButtonConfig } from "../../simples/ModalDialog/ModalDialog";

import styles from "./ModalServerError.module.scss"

export type ServerError = {
  "title": "string",
  "errors": string[]
}

type Props = {
  error?: ServerError
  onClose: () => void
}

export default function ModalServerError({ error, onClose }: Props) {

  if (!error) {
    return null;
  }

  const handleClose = () => {
    onClose()
  };

  const buttonsConfig: ButtonConfig[] = [
    { text: "Ок", type: ButtonType.Ok, onClick: handleClose, size: ButtonSize.Big },
  ];

  const { title, errors } = error
  const dialogContent = <div className={styles.items}>{errors.map((e, n) => <div key={n} className={styles.content_item}>{e}</div>)}</div>

  return (
    <ModalDialog isActive={true} title={title} content={dialogContent} buttonsConfig={buttonsConfig} handleClose={handleClose} />
  );
}
