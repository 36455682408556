import React from "react";
import HintTexts from "../../../../../constants/hints";
import { PurpleRadioInput } from "../../../../simples/PurpleRadioInput";
import { QuestionHint } from "../../../../simples/QuestionHint";
import styles from "./TinCheckBox.module.scss";

type Props = {
  withVat: boolean;
  setWithVat: (withTin: boolean) => void;
};

const TinCheckBox = ({ withVat, setWithVat }: Props) => (
  <div className={styles.container}>
    <span className={styles.headerContainer}>
      <h3 className={styles.header}>Форма организации</h3>
      <QuestionHint className={styles.hint} hint={HintTexts.TIN} />
    </span>
    <div className={styles.radioButtonContainers}>
      <PurpleRadioInput
        title="c НДС"
        onClick={setWithVat.bind(null, true)}
        isChecked={withVat}
      />
      <PurpleRadioInput
        title="без НДС"
        onClick={setWithVat.bind(null, false)}
        isChecked={!withVat}
      />
    </div>
  </div>
);

export default TinCheckBox;
