import classNames from "classnames";
import { FC, useState, useEffect } from "react";

import styles from "./CustomTextInput_v2.module.scss";
import { LightInput } from "../LightInput";
import { CustomHint } from "../CustomHint";

export type IOnChange = {
  name: string | number
  value: string | number
}

interface CustomInputProps {
  label?: string;
  error?: string;
  warningText?: string;
  required?: boolean;
  disabled?: boolean;
  disabledValue?: string;
  className?: string;
  value?: string | number;
  placeholder?: string;
  name: string | number;
  isNumber?: boolean;
  max?: number;
  min?: number;
  readonly?: boolean;
  onChange: ({ name, value }: IOnChange) => void;
  hideError?: boolean;
  maxLength?: number;
  mask?: string | Array<(string | RegExp)>;
  hint?: string | JSX.Element
  hintWidth?: number;
  hideEmpty?: boolean;
}

const DefaultMaxLength = 255

const DefaultErrorText = '! Заполните поле'

const CustomInput: FC<CustomInputProps> = ({
  label, error, required, disabled, className, value, hideError,
  placeholder, name, isNumber, max, min, readonly, onChange, disabledValue,
  maxLength, mask, hint, hintWidth, warningText, hideEmpty
}) => {

  const [rawValue, setRawValue] = useState<string | number>('')
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    setRawValue(value || '')
  }, [value])

  const isEmpty = !rawValue || rawValue === ""
  const hasError = !!error || !isActive && required && isEmpty || readonly && required && isEmpty

  const errorText = error || DefaultErrorText

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    const { value } = e.target
    if (isNumber) {
      let validatedValue: number | string = parseInt(value)
      if (isNaN(validatedValue)) { validatedValue = 0 }
      if (typeof min === 'number' && validatedValue < min) validatedValue = min
      if (typeof max === 'number' && validatedValue > max) validatedValue = max

      setRawValue((validatedValue > 0) ? validatedValue.toString() : '')
    } else {
      setRawValue(value)
    }
  }
  const handleBlur = () => {
    setRawValue(value || '')  // на случай если валидация не пройдёт и value не обновиться

    onChange({ value: rawValue, name })
    setIsActive(false)
  }

  const handleFocus = () => {
    setIsActive(true)
  }

  // if (readonly && required) {
  if (readonly && isEmpty && hideEmpty) {
    return (<></>)
  }

  return (
    <div className={classNames(styles.wrap, {
      [styles.disabled]: disabled,
      [styles.required]: required,
      [styles.has_error]: hasError,
      [styles.is_empty]: isEmpty,
      [styles.readonly]: readonly,
      [styles.hide_error]: hideError,
      [styles.has_hint]: hint !== undefined,
    }, className)}>
      <div className={styles.input_wrap}>
        {label && <div className={styles.label}>
          <div className={styles.label_text}>{label}</div>
          {hint && <CustomHint hint={hint} hintWidth={hintWidth} />}
        </div>}
        {readonly
          ? <div className={classNames(styles.readonly_value, styles.input)}>{rawValue || placeholder}</div>
          : (mask
            ? <LightInput mask={mask} maskChar={null} className={styles.input} type='text' disabled={disabled} placeholder={placeholder} value={(disabled && disabledValue) ? disabledValue : rawValue} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus} />
            : <input maxLength={maxLength || DefaultMaxLength} className={styles.input} type='text' disabled={disabled} placeholder={placeholder} value={(disabled && disabledValue) ? disabledValue : rawValue} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus} />)
        }
      </div>
      {!hideError && <div className={styles.error}><div>{warningText}</div><div>{hasError && errorText}</div></div>}
    </div>
  );
};

export default CustomInput;
