import classNames from "classnames";
import React, { FC, MouseEvent } from "react";
import styles from "./BlueButton.module.scss";


export enum IconPosition {
  start = "start",
  end = "end",
}

export enum ButtonType {
  Ok = "Ok",
  Yes = "Yes",
  Cancel = "Cancel",
  No = "No",
  Close = "Close",
}

export enum ButtonSize {
  Normal = "Normal",
  Big = "Big",
  Small = "Small"
}

export enum ButtonStyle {
  Default = 'Default',
  Style1 = 'Style1',
  Style2 = 'Style2',
}

type Props = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  title: JSX.Element | string;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
  size?: ButtonSize;
  iconSrc?: string;
  iconPosition?: IconPosition;
  buttonStyle?: ButtonStyle;
  active?: boolean
};

const BlueButton: FC<Props> = ({ onClick, title, disabled, className, type, size, iconSrc, iconPosition, buttonStyle, active }) => {
  const classes = classNames(
    styles.button,
    className,
    active && styles.active,
    disabled && styles.disabled,
    {
      [styles.ok]: type === ButtonType.Ok,
      [styles.yes]: type === ButtonType.Yes,
      [styles.cancel]: type === ButtonType.Cancel,
      [styles.no]: type === ButtonType.No,
      [styles.close]: type === ButtonType.Close,
    },
    {
      [styles.big]: size === ButtonSize.Big,
      [styles.normal]: size === ButtonSize.Normal,
      [styles.small]: size === ButtonSize.Small,
    },
    {
      [styles.default]: buttonStyle === undefined || buttonStyle === ButtonStyle.Default,
      [styles.style1]: buttonStyle === ButtonStyle.Style1,
      [styles.style2]: buttonStyle === ButtonStyle.Style2,
    }
  );

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {iconSrc && iconPosition !== IconPosition.end && <img className={styles.icon} src={iconSrc} alt="" />}
      {title}
      {iconSrc && iconPosition === IconPosition.end && <img className={styles.icon} src={iconSrc} alt="" />}
    </button>
  );
};

export default BlueButton;
