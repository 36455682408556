import classNames from "classnames";
import React, { FC } from "react";
import { BlueButton } from "../BlueButton";
import { ButtonSize, ButtonType } from "../BlueButton/BlueButton";
import { DialogCloseBtn } from "../DialogCloseBtn";
import styles from "./ModalDyalog.module.scss";

export interface ButtonConfig {
  type?: ButtonType;
  size?: ButtonSize;
  text: string;
  onClick: () => void;
  disabled?: boolean
}

interface ModalDyalogProps {
  isActive: boolean;
  title?: string;
  text?: string;
  width?: number;
  height?: number;
  content?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  warning?: string
  hasCloseBtn?: boolean;
  buttonsConfig: ButtonConfig[];
  handleClose?: () => void;
  hideBtns?: boolean
  disableOverflowScroll?: boolean
}

const ModalDialog: FC<ModalDyalogProps> = ({ isActive, title, text, buttonsConfig, handleClose, content,
  children, width, height, hasCloseBtn, warning, hideBtns, disableOverflowScroll }) => {

  if (!isActive) {
    return null;
  }

  return <article className={styles.modalContainer}>
    <div onClick={handleClose} className={styles.modalUnderflow}></div>

    <section className={classNames(styles.content)} style={{ width: width && `${width}px`, height: height && `${height}px` }}>
      {hasCloseBtn && <DialogCloseBtn onClick={handleClose} className={styles.closeButton} />}
      {title && <h3 className={styles.title}>{title}</h3>}

      <div className={classNames(styles.content_wrap, { [styles.overflow_visible]: disableOverflowScroll })}>
        <div className={styles.text}>{text}</div>
        {content}
        {children}
      </div>

      {warning && <div className={styles.warning}>{warning}</div>}

      {!hideBtns && <div className={styles.buttons}>
        {buttonsConfig.map((c, i) => <BlueButton
          title={c.text}
          key={i}
          onClick={c.onClick}
          className={styles.button}
          type={c.type}
          size={c.size}
          disabled={c.disabled}
        />)}
      </div>}
    </section>

  </article>;
};

export default React.memo(ModalDialog);
