import React, { useEffect } from "react";
import styles from "./TextTimer.module.scss";
import { useTimer } from "react-timer-and-stopwatch";
import { getWithoutHoursTime } from "../../../utils/composeString";

type Props = {
  seconds: number;
  minutes?: number;
  onFinish: () => void;
};

const TextTimer = ({ minutes, seconds, onFinish }: Props) => {
  const timer = useTimer({
    create: {
      timerWithDuration: {
        time: {
          minutes: minutes || 0,
          seconds: seconds,
        },
      },
    },
    callbacks: {
      onFinish: onFinish,
    },
  });

  useEffect(() => {
    timer.resetTimer();
    timer.resetTimer();
  }, [minutes, seconds]);

  return <p className={styles.text}>{getWithoutHoursTime(timer.timerText)}</p>;
};

export default TextTimer;
