import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthRoutes from "./parts/AuthRoutes";
import MainRoutes from "./parts/MainRoutes";

type Props = {
  isAuth: boolean;
};

const AppRoutes: FC<Props> = ({ isAuth }) => (
  <BrowserRouter>{isAuth ? <MainRoutes /> : <AuthRoutes />}</BrowserRouter>
  // <BrowserRouter><MainRoutes /></BrowserRouter>
);

export default AppRoutes;
