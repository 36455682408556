import React, { useState } from 'react'

import styles from './VariantPhotos.module.scss'
import Photos from './Photos'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { ProductPhoto, activeVariantSelector, removeMainPhoto, removePhoto, setMainPhoto, sortPhotos, uploadPhoto } from '../../../../../store/slices/product'
import { BlueButton } from '../../../../simples/BlueButton'

import eye_icon from '../../assets/eye.svg'
import Preview from './Preview'
import UploadingDialog from './UploadingDialog'


export default function VariantPhotos() {

  const dispatch = useAppDispatch()
  const { photos, mainPhoto } = useAppSelector(activeVariantSelector)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const [previewPhotos, setPreviewPhotos] = useState(false)

  const handleRemoveMainPhoto = () => {
    dispatch(removeMainPhoto())
  }
  const handleRemovePhoto = (id: number) => {
    dispatch(removePhoto(id))
  }
  const handleSortPhotos = (oldIndex: number, newIndex: number) => {
    dispatch(sortPhotos({oldIndex, newIndex}))
  }
  const handleSetMainPhoto = (id: number) => {
    dispatch(setMainPhoto(id))
  }

  const handleDrop = (files: File[]) => {
    files.forEach(file => {
      dispatch(uploadPhoto({ formDataFile: file }))
    });
  }
  const handleDropMain = (files: File[]) => {
    files.forEach(file => {
      dispatch(uploadPhoto({ formDataFile: file, isMain: true }))
    });
  }

  const _mainPhoto: ProductPhoto[] = []
  if (mainPhoto) _mainPhoto.push(mainPhoto)

  const title1 = 'Фото для образа'
  const title2 = 'Фото для карточки'

  if (readonly) {

    const handleShowPreview = () => {
      setPreviewPhotos(true)
    }
    const closeShowPreview = () => {
      setPreviewPhotos(false)
    }

    const allPhotos: string[] = photos.map(p => p.url)
    if (mainPhoto?.url) {
      allPhotos.unshift(mainPhoto?.url)
    }

    return (
      <div className={styles.readonly}>
        <Preview isActive={previewPhotos} closeShowPreview={closeShowPreview} photos={allPhotos} />
        <div>
          <div className={styles.readonly_title}>{title1}</div>
          <div className={styles.readonly_photos_list}>
            {_mainPhoto.map((p, i) => <div className={styles.readonly_photo} key={`${i}-${p.id}`}><img src={p.url} /></div>)}
          </div>
        </div>
        <div>
          <div className={styles.readonly_title}>{title2}</div>
          <div className={styles.readonly_photos_list}>
            {photos.map((p, i) => <div className={styles.readonly_photo} key={`${i}-${p.id}`}>
              <img src={p.url} />
              {p.isMain && <div className={styles.readonly_main_photo_label}>Основное фото товара</div>}
            </div>)}
          </div>
        </div>
        <BlueButton title='Предварительный просмотр карточки товара' iconSrc={eye_icon} onClick={handleShowPreview} />
      </div>
    )
  }

  return (
    <div className={styles.variant_photos_wrap}>
      <UploadingDialog />
      <Photos
        onDrop={handleDropMain}
        onRemove={handleRemoveMainPhoto}
        photos={_mainPhoto}
        title={title1}
        emptyDropzoneContent={<span>Загрузите фото товара с Вашего устройства</span>}
        formats={['jpg', 'png']}
      />
      <Photos
        onDrop={handleDrop}
        onRemove={handleRemovePhoto}
        photos={photos}
        title={title2}
        emptyDropzoneContent={<span className={styles.dropzone_content}>Загрузите до 10 фото, перетащив их в данное поле или нажав “Загрузить фото”</span>}
        formats={['jpg', 'png']}
        multiple={true}
        onSort={handleSortPhotos}
        onSetMain={handleSetMainPhoto}
      />
    </div>
  )
}
