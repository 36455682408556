import React, { useState } from 'react'
import { LegalCompanyInfoDTO } from '../../../../../types/swagger/llyApi_new'

import styles from './LawInfo.module.scss'
import { ButtonConfig, ModalDialog } from '../../../../simples/ModalDialog'
import { CustomRadioButton, CustomRadioButtons } from '../../../../simples/CustomRadioButtons'

type Props = {
  list: LegalCompanyInfoDTO[] | undefined
  onSelect: (data: LegalCompanyInfoDTO) => void
  onClose: () => void
}

export default function ModalList({ list, onClose, onSelect }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleApply = () => {
    if (list) {
      onSelect(list[selectedIndex])
    } else {
      onClose()
    }
  }

  const btns: ButtonConfig[] = [{
    text: 'Применить',
    onClick: handleApply
  }, {
    text: 'Отмена',
    onClick: onClose
  }]

  const options: CustomRadioButton[] = (list || []).map((item, index) => ({
    name: index.toString(),
    label: `${item.full_name}` + (item.kpp ? `, КПП: ${item.kpp}` : '')
  }))

  const handleSelect = ({ value }) => {
    setSelectedIndex(value)
  }

  return (
    <ModalDialog isActive={list !== undefined && list.length > 1} buttonsConfig={btns} title='Выберите подходящий вариант'
      handleClose={onClose} hasCloseBtn width={960}>
      <div className={styles.variants}>
        {/* <div className={styles.head}>Выберите подходящий вариант</div> */}
        <div className={styles.variants_list}>
          <CustomRadioButtons btns={options} activeName={selectedIndex} label='' name='' onChange={handleSelect} isColumn/>
        </div>
        {/* <div className={styles.btns}></div> */}
      </div>
    </ModalDialog>
  )
}
