const RouteBase = '/about/'

export enum ProfileAboutCompanyTabId {
  lawinfo = 'lawinfo',
  requisites = 'requisites',
  addresses = 'addresses'
}

type TabConfig = {
  id: ProfileAboutCompanyTabId
  label: string
  route: string
}

type IProfileAboutCompanyTabConfig = {
  [key in ProfileAboutCompanyTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: ProfileAboutCompanyTabId) {
  return routeBase + tabId
}

export const ProfileAboutCompanyTabConfig: IProfileAboutCompanyTabConfig = {
  [ProfileAboutCompanyTabId.lawinfo]: {
    id: ProfileAboutCompanyTabId.lawinfo,
    label: 'Юридическая информация',
    route: buildTabRoute(RouteBase, ProfileAboutCompanyTabId.lawinfo)
  },
  [ProfileAboutCompanyTabId.requisites]: {
    id: ProfileAboutCompanyTabId.requisites,
    label: 'Платежные реквизиты',
    route: buildTabRoute(RouteBase, ProfileAboutCompanyTabId.requisites)
  },
  [ProfileAboutCompanyTabId.addresses]: {
    id: ProfileAboutCompanyTabId.addresses,
    label: 'Адреса компании',
    route: buildTabRoute(RouteBase, ProfileAboutCompanyTabId.addresses)
  },
}