import React, {Dispatch, SetStateAction, FC, useEffect, useState, useCallback } from "react";
import {Search} from "./parts/Search";
import {ReactComponent as GridIcon} from "../../assets/grid.svg";
import {ReactComponent as ListIcon} from "../../assets/list.svg";
import {ListView} from "./parts/ListView";
import {GridView} from "./parts/GridView";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../store";
import {fetchLogoList} from "../../../../../store/slices/getters/logo-list";
import zoom from "../../assets/zoom.svg";

import classNames from "classnames";
import styles from "./LogoList.module.scss";

interface LogoCardProps {
  viewType: boolean;
  handleSelected: (id: number) => void;
  setUpload: Dispatch<SetStateAction<boolean>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
  selectedId: number | null;
  setViewType: Dispatch<SetStateAction<boolean>>;
}

const LogoList: FC<LogoCardProps> = ({
  viewType,
  handleSelected,
  setUpload,
  setIsUpdate,
  selectedId,
  setViewType,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { logoList } = useSelector((state: RootState) => state.logoList);

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(fetchLogoList());
  }, [dispatch, selectedId, handleSelected]);

  const setView = useCallback(() => setViewType(prevState => !prevState), []);

  const filtered = logoList?.filter(logo =>
    logo.name.toUpperCase().includes(search.toUpperCase()),
  );

  return (
    <div className={styles.container}>
      <div className={styles.viewControl}>
        <Search handleSearch={setSearch} />
        <button
          className={styles.scaleLogo}
          onClick={setView}>
          <img src={zoom} alt="zoom" />
          <span>Изменить масштаб</span>
        </button>
        <div className={styles.viewControlIcons}>
          <GridIcon className={classNames(styles.icon, !viewType && styles.active)} />
          <ListIcon className={classNames(styles.icon, viewType && styles.active)} />
        </div>
      </div>
      {viewType ? (
        <ListView
          logoList={filtered ?? []}
          handleSelected={handleSelected}
          setUpload={setUpload}
          setIsUpdate={setIsUpdate}
          selectedId={selectedId || null}
        />
      ) : (
        <GridView
          logoList={filtered ?? []}
          handleSelected={handleSelected}
          setUpload={setUpload}
          setIsUpdate={setIsUpdate}
          selectedId={selectedId || null}
        />
      )}
    </div>
  );
};

export default LogoList;
