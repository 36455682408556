import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AuthService } from "../services/AuthService";

const config: AxiosRequestConfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

if (process.env.REACT_APP_API_URL) {
  config.baseURL = process.env.REACT_APP_API_URL;
}


const instance = axios.create(config);

// Подключаем плагин для обновления токена
createAuthRefreshInterceptor(instance, AuthService.refreshToken);

// Устанавливаем токен в заголовок при каждом запросе
instance.interceptors.request.use((request: any) => {
  const token = localStorage.getItem("access_token");
  request.headers["Authorization"] = token;
  return request;
});

export default instance;
