import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import {LogoType} from "../../../types/logos";

export const fetchLogoList= createAsyncThunk(
  "logos/fetchLogoList",
  async (_, {rejectWithValue})  => {
    const {data: response} = await api.get("logos");
    return response.data;
  }
);

interface LogoState {
    logoList: LogoType[]
    loading: "idle" | "pending" | "succeeded" | "failed"
}


const initialState = {
  logoList: [],
  loading: "idle",
} as LogoState;

const logosSlice = createSlice({
  name: "logos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogoList.fulfilled, (state, action) => {
        state.logoList = [...action.payload];
      });
  },
});

export default logosSlice;