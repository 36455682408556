import { FeedbackTemplate } from "../components/pages/Feedback/parts/Templates/Templates";
import { Endpoints } from "../constants/server";
import { FeedbackDTO } from "../types/swagger/llyApi_new";
import api from "../utils/api";

export enum Order { ASC, DESC }

export interface GetFeedbackInterface {
  /** Получить вопросы. Любое значение, которое можно привести к true */
  isQuestions: boolean
  /** Максимальное количество записей в ответе. По-умолчанию вернет 10 */
  limit?: number
  /** Смещение записей в ответе. По-умолчанию 0 - без смещения */
  offset?: number
  /** Поля по которым происходит сортировка, для обратной сортировки перед названием поля поставить '-' поля разделять запятой */
  order_by?: string
  /** Рейтинг */
  filter_stars?: number[]
  /** Один из статусов */
  // filter_status?: 'ALL' | 'NO_ANSWER' | 'WITH_ANSWER' | 'ARCHIVE' | 'ON_MODERATION'
  filter_status?: string
}

// interface DeleteTemplateAnswer {
interface DefaultAnswer {
  success?: boolean
  data?: string
}

interface FetchTemplatesAnswer {
  success?: boolean
  data?: FeedbackTemplate[]
}

export interface GetFeedbackResponseInterface {
  /** @example true */
  success?: boolean
  data?: FeedbackDTO[]
  availableFiltersOptions?: {
    stars?: {
      /** Кол-во звезд */
      stars?: number
      /** Кол-во отзывов с этими звездами */
      count?: number
    }[]
    statuses?: {
      /** Название статуса */
      name?: string
      /** Кол-во отзывов с этим статусом */
      count?: number
    }[]
  }
  templates?: {
    /** Название шиблона */
    title?: string
    /** Текст шаблона */
    text?: string
    /** ID шаблона */
    id?: number
  }[]
  /** Общее количество записей с учетом примененных фильтров */
  totalCount?: number
}

export const DISCOUNT_CATEGORY_ID = 5

class FeedbackService {

  static async getFeedback({
    isQuestions, filter_stars, filter_status, limit, offset, order_by
    // id, searchRequest, limit, offset, order_by, order
  }: GetFeedbackInterface) {
    let data: GetFeedbackResponseInterface = { success: false };

    let req = `${Endpoints.FEEDBACK}?questions=${isQuestions ? 1 : 0}`;
    if (filter_stars) { req += `&filter_stars=${filter_stars.join(',')}`; }
    if (filter_status) { req += `&filter_status=${filter_status}`; }
    if (limit && limit > 0) { req += `&limit=${limit}`; }
    if (offset && offset > 0) { req += `&offset=${offset}`; }

    if (order_by) {
      req += `&order_by=${order_by}`;
    }

    await api
      .get<GetFeedbackResponseInterface>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data;
  }


  static async sendResponse({ id, text }: { id: string, text: string }) {
    let data: DefaultAnswer = { success: false };
    await api.post<DefaultAnswer>(Endpoints.FEEDBACK_RESPONSE, { id, text })
      .then((res) => {
        if (res.data?.success) {
          data = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      });
    return data;
  }

  static async deleteTemplate(id: number) {
    let data: DefaultAnswer = { success: false };
    let req = `${Endpoints.TEMPLATE_DELETE}${id}`;
    await api.delete(req).then((res) => {
      return data
    }).catch((err) => console.log(err));
    return data;
  }

  static async fetchTemplates() {
    let data: FetchTemplatesAnswer = { success: false, data: [] };
    await api.get<FetchTemplatesAnswer>(Endpoints.TEMPLATES)
      .then((res) => {
        if (res.data?.success && res.data.data) {
          data = res.data
        }
      })
      .catch((err) => {
        console.log(err)
      });
    return data;
  }
}




export default FeedbackService;
