import React, { useState } from 'react'
import { Layout } from '../../complexes/Layout'
import { useParams } from 'react-router-dom';
import { LogisticsFBOTabConfig, LogisticsFBOTabId } from './consts';
import { ITab, Tabs } from '../../complexes/Tabs';
import { Leftovers } from './parts/Leftovers';
import { CurrentOrders } from './parts/CurrentOrders';
import { Proposal } from './parts/Proposal';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { setIsActive } from '../../../store/slices/fbo';

export default function Logistics_FBO() {


  const dispatch = useAppDispatch()
  const isProposalCreation = useAppSelector(state => state.fbo.isActive)


  const tabs: ITab[] = [{
    ...LogisticsFBOTabConfig[LogisticsFBOTabId.leftovers_in_stock],
    content: <Leftovers />
  }, {
    ...LogisticsFBOTabConfig[LogisticsFBOTabId.current_orders],
    content: <CurrentOrders />
  }]


  if (isProposalCreation) {
    const handleCnacelCeration = () => {
      dispatch(setIsActive(false))
    }

    return (
      <Layout title='Заявка для FBO' hint={''} v2 onBack={handleCnacelCeration} >
        <Proposal />
      </Layout>
    )
  } else {
    return (
      <Layout title='Логистика' hint={''} >
        <Tabs tabs={tabs} />
      </Layout>
    )
  }


}
