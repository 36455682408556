import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./btnAddGoods.module.scss";

type PropsType = {
  text: string;
  icons: any;
  to: string;
};

function BtnAddGoods({ text, icons, to }: PropsType) {
  return (
    <NavLink to={to} className={styles.wrapper}>
      <div className={styles.icons}>
        <img className={styles.img} src={icons} />
      </div>
      <div className={styles.txt}>{text}</div>
    </NavLink>
  );
}

export default BtnAddGoods;
