import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CategorySelectorState } from "../../components/pages/ProductsImportTable/parts/CategorySelector";
import MassImportEditService from "../../services/MassImportEditService";
import { CategorySelectTypes } from "../../types/selectOptions";
import { ProductsDTO } from "../../types/swagger/llyApi_new";

export interface MassEditState {
  selected_ids: number[];
  isFakeBarcodes: boolean; // TODO remove
  sendToModerateAnswer: {
    moderate: number;
    saved: number;
  } | null;

  isFetchingGoods: boolean;
  fetchingGoodsError?: string;

  isArchUploaded: boolean;
  isFetchingArch: boolean;
  fetchingArchError?: string;
  fetchingArchProgress: number;

  isUploadingArch: boolean;
  uploadingProgress: number;
  uploadingFileName?: string;
  uploadingError?: string;
  importedData: ProductsDTO[];
  importedDataErrors: string[];

  templateCategory: number;
  templateSelectorState: CategorySelectorState;
}

export const initialState: MassEditState = {
  selected_ids: [],
  isFakeBarcodes: false,
  sendToModerateAnswer: null,
  isFetchingGoods: false,
  isArchUploaded: false,
  isFetchingArch: false,
  isUploadingArch: false,
  uploadingProgress: 0,
  fetchingArchProgress: 0,
  templateCategory: 33,
  templateSelectorState: {
    values: {},
    options: {
      [CategorySelectTypes.for]: [],
      [CategorySelectTypes.type]: [],
      [CategorySelectTypes.category]: [],
      [CategorySelectTypes.subcategory]: [],
    }
  },
  importedData: [],
  importedDataErrors: []
};

export interface FetchGoodsRequest {
  categoryID: number;
  searchRequest?: string;
}

export const fetchArch = createAsyncThunk<boolean, void, { state: RootState }>(
  "MassEdit/fetchArch", async (_, { getState, dispatch, rejectWithValue }) => {

    const state = getState();
    const products_id = getSelectedIds(state);

    const data = await MassImportEditService.getArch({
      products_id,
      onDownloadProgress(percent) {
        dispatch(setFetchingArchProgress(percent));
      }
    });
    if (!data || !data.success) {
      rejectWithValue(data?.error || "error");
    }
    return data.success;
  }
);
export const uploadArch = createAsyncThunk<any, { formDataFile: File }, { state: RootState }>(
  "MassEdit/uploadEditedArch", async ({ formDataFile }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    const state = getState();
    const category = getSelectedIds(state);

    if (!category) throw new Error("category not selected");

    dispatch(setUploadingFileName(formDataFile.name));

    const response = await MassImportEditService.uploadEditedArch({
      formDataFile,
      onUploadProgress(percent) {
        dispatch(setUploadingProgress(percent));
      },
    });

    if (response.success) {
      return fulfillWithValue(response.response?.data);
    } else {
      return rejectWithValue(response.response?.message || response.error)
    }
  }
);


export const fetchGoods = createAsyncThunk<ProductsDTO[], void, { state: RootState }>(
  "MassEdit/fetchGoods", async (_, { getState }) => {
    const state = getState();
    const { selected_ids } = state.massEdit;
    const data = await MassImportEditService.getGoods({ ids: selected_ids });
    return data;
  });


export const sendToModerate = createAsyncThunk<any, void, { state: RootState, }>(
  "MassEdit/sendToModerate", async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await MassImportEditService.sendToModerate();
    if (response.success) {
      return fulfillWithValue(response?.data);
    } else {
      return rejectWithValue(response.error || "Error!");
    }
  }
);


export const massEditSlice = createSlice({
  name: "massEdit",
  initialState,
  reducers: {
    setSelectedIds: (state, action) => {
      state.selected_ids = action.payload;
    },
    setFakeBarcodes: (state, action) => {
      state.isFakeBarcodes = action.payload;
    },
    closeInfoDialog: (state) => {
      state.sendToModerateAnswer = null;
    },
    setTemplateCategory: (state, action) => {
      state.templateCategory = action.payload;
    },
    setTemplateSelectorState: (state, action) => {
      state.templateSelectorState = action.payload;
    },
    setUploadingProgress: (state, action) => {
      state.uploadingProgress = action.payload;
    },
    setFetchingArchProgress: (state, action) => {
      state.fetchingArchProgress = action.payload;
    },
    setUploadingFileName: (state, action) => {
      state.uploadingFileName = action.payload;
    },
    clearUploadedData: (state) => {
      state.uploadingFileName = "";
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArch.pending, (state) => {
      state.isFetchingArch = true;
      state.fetchingArchError = "";
      state.fetchingArchProgress = 0;
    });
    builder.addCase(fetchArch.fulfilled, (state, action) => {
      state.isFetchingArch = false;
    });
    builder.addCase(fetchArch.rejected, (state) => {
      state.isFetchingArch = false;
      state.fetchingArchError = "error";
    });


    builder.addCase(fetchGoods.pending, (state) => {
      state.isFetchingGoods = true;
      state.fetchingGoodsError = "";
    });
    builder.addCase(fetchGoods.fulfilled, (state, action) => {
      state.isFetchingGoods = false;
      state.importedData = action.payload || [];
      state.importedDataErrors = [];
    });
    builder.addCase(fetchGoods.rejected, (state) => {
      state.isFetchingGoods = false;
      state.fetchingGoodsError = "";
    });


    builder.addCase(uploadArch.pending, (state) => {
      state.isUploadingArch = true;
      state.isArchUploaded = false;
      state.uploadingProgress = 0;
      state.uploadingError = "";
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    });
    builder.addCase(uploadArch.fulfilled, (state, action) => {
      state.isUploadingArch = false;
      state.isArchUploaded = true;
      state.importedData = action.payload?.products || [];
      state.importedDataErrors = action.payload?.errors || [];
      // if (action.payload) { }
    });
    builder.addCase(uploadArch.rejected, (state, action) => {
      state.isUploadingArch = false;
      state.uploadingError = action.payload as string || "Error!";
    });

    // builder.addCase(sendToModerate.pending, (state) => {
    // });
    builder.addCase(sendToModerate.fulfilled, (state, action) => {
      state.sendToModerateAnswer = action.payload;
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    });
    builder.addCase(sendToModerate.rejected, (state, action) => {
      alert("Error!"); // TODO remove
    });
  },
});

export const getSelectedIds = (state: RootState) =>
  state.massEdit.selected_ids

export const selectedSubcategorySelector = () => getSelectedIds;
export const getUploadingProgress = () => (state: RootState) => state.massEdit.uploadingProgress;
export const getUploadingStatus = () => (state: RootState) => state.massEdit.isUploadingArch;
export const getUploadingError = () => (state: RootState) => state.massEdit.uploadingError;
export const getImportedData = () => (state: RootState) => state.massEdit.importedData;
export const getImportedDataErrors = () => (state: RootState) => state.massEdit.importedDataErrors;
export const getUploadingFileName = () => (state: RootState) => state.massEdit.uploadingFileName;

export const getDownloadProgress = () => (state: RootState) => state.massEdit.fetchingArchProgress;
export const getDownloadStatus = () => (state: RootState) => state.massEdit.isFetchingArch;

export const {
  setSelectedIds,

  clearUploadedData, closeInfoDialog, setFakeBarcodes,
  setTemplateCategory, setTemplateSelectorState, setUploadingProgress, setFetchingArchProgress, setUploadingFileName
} = massEditSlice.actions;



export default massEditSlice.reducer;