import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import Filter from './Filter';
import styles from "./FiltersPanel.module.scss";
import xIcon from "../../assets/x-icon.svg";
import { BlueButton } from '../../../../simples/BlueButton';
import v_icon from "../../assets/v.svg"
import { CustomSelect_v2, IOnSelectChange, Option } from '../../../../simples/CustomSelect_v2';

export enum FilterKey {
  seasons = "seasons",
  brands = "brands",
  categories = "categories",
  subcategories = "subcategories",
  sizes = "sizes"
}

const FiltersTitlse = {
  [FilterKey.seasons]: "Сезон",
  [FilterKey.brands]: "Бренды",
  [FilterKey.categories]: "Категория",
  [FilterKey.subcategories]: "Подкатегория",
  [FilterKey.sizes]: "Размер",
}

export type FilterOption = {
  title: string
  id: string
}

export type FiltersOptions = {
  [key in FilterKey]?: FilterOption[]
}

export type FiltersValues = {
  [key in FilterKey]?: string[]
}


export enum DiscountOption {
  all = "all",
  without = "without",
  with = "with"
}

export const DiscountOptionTitle = {
  [DiscountOption.all]: "Все",
  [DiscountOption.with]: "Со скидкой",
  [DiscountOption.without]: "Без скидки",
}

export type LimitOption = { value: number, title: string }

// const limitOptions: LimitOption[] = [
//   { value: 10, title: "10" },
//   { value: 30, title: "30" },
//   { value: 50, title: "50" },
//   { value: 0, title: "Все" },
// ]
const limitOptions: Option[] = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 0, label: "Все" },
]


type Props = {
  isVisible: boolean
  availableFiltersOption: FiltersOptions
  selectedFiltersOptions: FiltersValues
  discountOptionValue: DiscountOption
  hideZeroBalances: boolean
  className?: string
  onClose: () => void
  applyFilters: (selectedFiltersOptions: FiltersValues, hideZeroBalances: boolean, discountOptionValue: DiscountOption) => void
  resetFilters: () => void
}

export default function FiltersPanel({
  isVisible, availableFiltersOption, selectedFiltersOptions, onClose,
  discountOptionValue, hideZeroBalances, className, resetFilters, applyFilters,
}: Props) {

  const [limit, setLimit] = useState(10);

  const [localSelectedFiltersOptions, setLoacalSelectedFiltersOptions] = useState<FiltersValues>({});
  const [loacalDiscountOptionValue, setLoacalDiscountOptionValue] = useState(DiscountOption.all);
  const [loacalHideZeroBalances, setLoacalHideZeroBalances] = useState(false);

  useEffect(() => {
    setLoacalSelectedFiltersOptions(selectedFiltersOptions)
    setLoacalDiscountOptionValue(discountOptionValue)
    setLoacalHideZeroBalances(hideZeroBalances)
  }, [discountOptionValue, hideZeroBalances, selectedFiltersOptions])

  const onSelectOption = (option: string) => (valueId: string) => {
    const filterValues: string[] = localSelectedFiltersOptions[option] || [];
    if (filterValues.indexOf(valueId) === -1) {
      setLoacalSelectedFiltersOptions({
        ...localSelectedFiltersOptions,
        [option]: [...filterValues, valueId]
      })
    } else {
      setLoacalSelectedFiltersOptions({
        ...localSelectedFiltersOptions,
        [option]: filterValues.filter(vid => vid !== valueId)
      })
    }
  }


  // const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setLimit(parseInt(e.target.value));
  // }
  const handleLimitChange = ({ value }: IOnSelectChange) => {
    setLimit(parseInt((value||10).toString()));
  }

  const handleToggleDiscountValue = (value) => () => {
    if (value !== loacalDiscountOptionValue) {
      setLoacalDiscountOptionValue(value);
    } else {
      setLoacalDiscountOptionValue(DiscountOption.all);
    }
  }

  const handleToggleZeroBalancesValue = () => {
    setLoacalHideZeroBalances(!loacalHideZeroBalances);
  }


  const handleResetFilters = () => {
    resetFilters()
  }
  const handleApplyFilters = () => {
    applyFilters(localSelectedFiltersOptions, loacalHideZeroBalances, loacalDiscountOptionValue)
  }

  return !isVisible ? null
    :
    <div className={classNames(styles.wrap, className)}>
      <div onClick={onClose} className={styles.modalUnderflow}></div>
      <div className={styles.content}>
        <div className={styles.limit}>
          <span>Отображать по:</span>
          {/* <select value={limit} onChange={handleLimitChange}>
            {limitOptions.map(o => <option key={o.value} value={o.value}>{o.title}</option>)}
          </select> */}
          <CustomSelect_v2 name='page' className={styles.select} value={limit} options={limitOptions} onChange={handleLimitChange} hideError isMini />
        </div>
        <div className={styles.filter}>
          <h2>Статус</h2>
          <div className={styles.options}>
            <div
              onClick={handleToggleDiscountValue(DiscountOption.all)}
              className={classNames(styles.filter_option, styles.filter_default_option, { [styles.selectd_option]: loacalDiscountOptionValue === DiscountOption.all })}
            >
              <span>{DiscountOptionTitle[DiscountOption.all]}</span>
            </div>
            <div
              onClick={handleToggleDiscountValue(DiscountOption.without)}
              className={classNames(styles.filter_option, { [styles.selectd_option]: loacalDiscountOptionValue === DiscountOption.without })}
            >
              <span>{DiscountOptionTitle[DiscountOption.without]}</span>
              <img className={styles.remove_option} src={xIcon} alt="X" />
            </div>
            <div
              onClick={handleToggleDiscountValue(DiscountOption.with)}
              className={classNames(styles.filter_option, { [styles.selectd_option]: loacalDiscountOptionValue === DiscountOption.with })}>
              <span>{DiscountOptionTitle[DiscountOption.with]}</span>
              <img className={styles.remove_option} src={xIcon} alt="X" />
            </div>
            <div
              onClick={handleToggleZeroBalancesValue}
              className={classNames(styles.filter_option, { [styles.selectd_option]: loacalHideZeroBalances })}><span>Скрыть нулевые остатки</span>
              <img className={styles.remove_option} src={xIcon} alt="X" />
            </div>
          </div>
        </div >
        <Filter
          title={FiltersTitlse[FilterKey.seasons]} onSelectOption={onSelectOption(FilterKey.seasons)}
          values={availableFiltersOption.seasons} limit={limit} selectedValues={localSelectedFiltersOptions[FilterKey.seasons]}
        />
        <Filter
          title={FiltersTitlse[FilterKey.brands]} onSelectOption={onSelectOption(FilterKey.brands)}
          values={availableFiltersOption.brands} limit={limit} selectedValues={localSelectedFiltersOptions[FilterKey.brands]}
        />
        <Filter title={FiltersTitlse[FilterKey.categories]} onSelectOption={onSelectOption(FilterKey.categories)}
          values={availableFiltersOption.categories} limit={limit} selectedValues={localSelectedFiltersOptions[FilterKey.categories]}
        />
        <Filter title={FiltersTitlse[FilterKey.subcategories]} onSelectOption={onSelectOption(FilterKey.subcategories)}
          values={availableFiltersOption.subcategories} limit={limit} selectedValues={localSelectedFiltersOptions[FilterKey.subcategories]}
        />
        <Filter title={FiltersTitlse[FilterKey.sizes]} onSelectOption={onSelectOption(FilterKey.sizes)}
          values={availableFiltersOption.sizes} limit={limit} selectedValues={localSelectedFiltersOptions[FilterKey.sizes]}
        />
      </div>
      <div className={styles.footer}>
        <BlueButton title='Сбросить фильтры' onClick={handleResetFilters} />
        <BlueButton title='Применить фильтры' onClick={handleApplyFilters} />
      </div>
    </div >
}
