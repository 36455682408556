import classNames from 'classnames'
import React from 'react'
import { useAppSelector } from '../../../../../hooks/store'
import { Compounds } from '../../../../../types/swagger/llyApi_new'
import { FieldTitle } from '../../../../simples/FieldTitle'
import Component from './Component'


import styles from "./Compositions.module.scss"
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import AddBtn from './AddBtn'

type Props = {
  compound: Compounds
  // required?: boolean
}

export default function Composition({ compound }: Props) {

  const compositions = useAppSelector(state => state.product.composition.values)
  const composition = compositions.find(c => c.compoundId === compound.id)
  const materials = composition?.materials || []
  const allMaterials = useAppSelector(state => state.product.referenceBook.materials)
  
  const readonly = useAppSelector(state => state.product.isReadonlyMode)


  const isRequired = (compound.required === 1)

  if (readonly) {
    const handle = () => { return }

    const values = materials.map(m => {
      const name = allMaterials.find(_m => _m.id === m.id)?.name
      return `${name} ${m.percent}%`
    })

    return (
      <CustomTextInput
        name={compound.id}
        placeholder='Укажите'
        label={compound.name.toString()}
        required={isRequired}
        value={values.join(', ')}
        onChange={handle}
        readonly={readonly}
      />
    )
  }
  return (
    <div className={styles.compound}>

      <div className={classNames(styles.compound_head, styles.grid)}>
        <FieldTitle text={compound.name} required={isRequired} />
        <div className={styles.compound_percent_sign}>%</div>
      </div>
      <div className={styles.materials}>
        {materials.map((m, i) => <Component
          required={isRequired}
          key={i}
          selectedMaterials={materials}
          percent={m.percent}
          compoundId={composition?.compoundId || 0} // fix, массив будет пустой если composition не определена
          materialId={m.id} materialIndex={i}
        />)}
        {materials.length === 0 && <AddBtn compoundId={composition?.compoundId || 0} />}
      </div>
    </div>
  )
}
