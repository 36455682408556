const RouteBase = '/brands/'

export enum BrandsTabId {
  brands = 'brands',
  certificates = 'certificates',
}

type TabConfig = {
  id: BrandsTabId
  label: string
  route: string
}

type IBrandsTabConfig = {
  [key in BrandsTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: BrandsTabId) {
  return routeBase + tabId
}

export const BrandsTabConfig: IBrandsTabConfig = {
  [BrandsTabId.brands]: {
    id: BrandsTabId.brands,
    label: 'Бренды',
    route: buildTabRoute(RouteBase, BrandsTabId.brands)
  },
  [BrandsTabId.certificates]: {
    id: BrandsTabId.certificates,
    label: 'Сертификаты',
    route: buildTabRoute(RouteBase, BrandsTabId.certificates)
  },
}