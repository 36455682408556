import React, { useState } from 'react'
import styles from './SalesStatistics.module.scss'
import { ItemsList } from '../../../../complexes/ItemsList'
import { ListData, ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes'
import { TotalConfig } from '../../../../complexes/ItemsList/parts/List'
import { CalendarPeriod, CalendarPeriodSelector } from '../../../../complexes/CalendarPeriodSelector'
import { CustomDatePicker, DatePickerDate } from '../../../../complexes/CustomDatePicker'
import { BlueButton, ButtonStyle } from '../../../../simples/BlueButton'
import { Sales, SalesCollection } from '../../../../../types/swagger/llyApi_new'
import { datePickerDateToDate, dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import { sub } from 'date-fns'
import { formatDateForServer } from '../../../../../utils/action'
import api from '../../../../../utils/api'
import CustomInput, { IOnChange } from '../../../../simples/CustomTextInput/CustomTextInput'
import { SearchInput } from '../../../../complexes/SearchInput'
import { ModalProductSearch } from '../../../../complexes/ModalProductSearch'
import Skeleton from 'react-loading-skeleton'


type ServerDataResponse = {
  success?: boolean
  data?: SalesCollection
  sales_count?: number
}

function isProductMatch(product: Sales, searchStr: string) {
  return product.name.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
    || product.article?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
}

function countFormatter(value: string | number): string {
  return `${value.toLocaleString()} шт.`
}

function amountFormatter(value: string | number): string {
  return `${value.toLocaleString()} ₽`
}

function addOrSetValue(totalConfig: TotalConfig, key: string, value?: number, formatter?: (value: string | number) => string) {
  if (totalConfig.values[key] === undefined) { totalConfig.values[key] = { value: 0, formatter } }
  totalConfig.values[key].value = (totalConfig.values[key].value as number || 0) + (value || 0)
}


export default function SalesStatistics() {

  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)
  const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(new Date()))
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(
    dateToDatePickerDate(sub(new Date(), {
      months: 1
    }))
  )
  const [search, setSearch] = useState('')
  const [salesData, setSalesData] = useState<Sales[]>()
  const [isProductsSelection, setIsProductsSelection] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)


  const handleSetPeriod = (period: CalendarPeriod) => {
    setPeriod(period)
    const _to = datePickerDateToDate(dateTo)
    switch (period) {
      case CalendarPeriod.day:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            days: 1
          }))
        )
        break;
      case CalendarPeriod.week:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            weeks: 1
          }))
        )
        break;
      case CalendarPeriod.month:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            months: 1
          }))
        )
        break;
      case CalendarPeriod.year:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            years: 1
          }))
        )
        break;
      default:
        break;
    }
  }

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const to = datePickerDateToDate(dateTo);
    if (d.getTime() < to.getTime()) {
      setPeriod(undefined)
      setDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const from = datePickerDateToDate(dateFrom)
    const now = new Date()

    if (d.getTime() > from.getTime() && d.getTime() < now.getTime()) {
      setPeriod(undefined)
      setDateTo(date)
    }
  }
  const handleBuildReport = () => {
    // if (search.length > 0) {
    setSelectedProducts([])
    setIsLoading(true)
    setSalesData(undefined)
    setSearch('')
    api.get<ServerDataResponse>(`/sales/statistic?from_date=${formatDateForServer(datePickerDateToDate(dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(dateTo))}`).then(data => {
      if (data.data.success && data.data.data?.sales && data.data.data?.sales.length > 0) {
        // validateion
        const storagesCount = data.data.data.sales[0].storages?.length
        for (let i = 0; i < data.data.data.sales.length; i++) {
          if (data.data.data?.sales[i].storages?.length !== storagesCount) {
            setIsLoading(false)
            alert('Ошибка! Неверный формат данных (не совпадает количество хранилищ).')
            return setSalesData(undefined)
          }
        }

        setSalesData(data.data.data?.sales)
      } else {
        setSalesData(undefined)
      }
      setIsLoading(false)
    })
    // }
  }

  const headers: ListHeadData[] = [{
    name: 'article',
    content: <>Артикул<br />(штрихкод)</>,
    minWidth: '120px',
    width: '120px',
    sortable: true,
  }, {
    name: 'url',
    text: 'Фото',
    minWidth: '120px',
    width: '120px',
    sortable: true,
  }, {
    name: 'name',
    text: 'Наименование',
    minWidth: '120px',
    width: '120px',
    sortable: true,
    alignCenter: true
  }, {
    name: 'main_color',
    text: 'Цвет',
    minWidth: '140px',
    width: '140px',
    sortable: true,
  }, {
    name: 'size_name',
    text: 'Размер',
    minWidth: '120px',
    width: '120px',
    sortable: true,
  }]


  if (salesData && salesData.length > 0) {
    salesData[0].storages?.forEach(storage => {
      headers.push({
        name: `storages-${storage.id}_sales_count`,
        // text: `Продажи ${storage.name}, шт`,
        content: <div className={styles.storage_title}>Продажи {storage.name}, шт</div>,
        minWidth: '120px',
        width: '120px',
        sortable: true,
        alignCenter: true
      }, {
        name: `storages-${storage.id}_sales_amount`,
        // text: `Продажи ${storage.name}, руб`,
        content: <div className={styles.storage_title}>Продажи {storage.name}, руб</div>,
        minWidth: '120px',
        width: '120px',
        sortable: true,
        alignCenter: true
      }, {
        name: `storages-${storage.id}_commission`,
        // text: `Комиссия LL ${storage.name}`,
        content: <div className={styles.storage_title}>Комиссия LL {storage.name}</div>,
        minWidth: '120px',
        width: '120px',
        sortable: true,
        alignCenter: true
      })
    })
  }

  headers.push({
    name: `fbo`,
    text: 'Общая выручка FBO',
    minWidth: '140px',
    width: '140px',
    sortable: true,
    alignCenter: true
  }, {
    name: `fbs`,
    text: 'Общая выручка FBO',
    minWidth: '140px',
    width: '140px',
    sortable: true,
    alignCenter: true
  }, {
    name: `penalty`,
    text: 'Штрафы, руб',
    minWidth: '120px',
    width: '120px',
    sortable: true,
    alignCenter: true
  }, {
    name: `total_count`,
    text: 'Итог, шт',
    minWidth: '120px',
    width: '120px',
    sortable: true,
    alignCenter: true
  }, {
    name: `total_sales`,
    text: 'Итог, руб',
    minWidth: '120px',
    width: '120px',
    sortable: true,
    alignCenter: true
  })

  // const visibleData: ListRow[] = [{
  //   key: 'asd1',
  //   data: [{
  //     name: 'asd1',
  //     text: 'asfasdf'
  //   }, {
  //     name: 'asd2',
  //     text: 'asfasdf'
  //   }, {
  //     name: 'asd3',
  //     text: 'asfasdf'
  //   }]
  // }]


  const filteredData = salesData?.filter(s => (selectedProducts.length === 0 || selectedProducts.indexOf(s.id) !== -1)
    && (search.length === 0 || isProductMatch(s, search))
  )



  const totalConfig: TotalConfig = {
    startFrom: 5,
    values: {}
  }




  const visibleData: ListRow[] = filteredData?.map(data => {

    const storagesData: ListData[] = []
    data.storages?.forEach(storage => {
      const _ssc = `storages-${storage.id}_sales_count`
      const _ssa = `storages-${storage.id}_sales_amount`
      const _sc = `storages-${storage.id}_commission`

      // totalConfig.values[_ssc].value = (totalConfig.values[_ssc].value as number || 0) + (storage.count || 0)
      addOrSetValue(totalConfig, _ssc, storage.count, countFormatter)
      addOrSetValue(totalConfig, _ssa, storage.count, amountFormatter)
      addOrSetValue(totalConfig, _sc, storage.count, amountFormatter)

      storagesData.push({
        name: _ssc,
        text: storage.count?.toLocaleString(),
        dataForSorting: storage.count,
        alignCenter: true
      }, {
        name: _ssa,
        text: storage.sales?.toLocaleString() + ' ₽',
        dataForSorting: storage.sales,
        alignCenter: true
      }, {
        name: _sc,
        text: storage.commission?.toLocaleString() + ' ₽',
        dataForSorting: storage.commission,
        alignCenter: true
      })
    })



    addOrSetValue(totalConfig, 'fbo', data.fbo, amountFormatter)
    addOrSetValue(totalConfig, 'fbs', data.fbs, amountFormatter)
    addOrSetValue(totalConfig, 'penalty', data.penalty, amountFormatter)
    addOrSetValue(totalConfig, 'total_count', data.total_count, countFormatter)
    addOrSetValue(totalConfig, 'total_sales', data.total_sales, amountFormatter)

    return {
      key: data.id,
      data: [{
        name: 'article',
        text: data.article
      }, {
        name: 'url',
        type: ListDataType.image,
        text: data.url
      }, {
        name: 'name',
        text: data.name
      }, {
        name: 'main_color',
        text: data.main_color
      }, {
        name: 'size_name',
        text: data.size_name
      },
      ...storagesData,
      {
        name: `fbo`,
        text: data.fbo?.toLocaleString() + ' ₽',
        dataForSorting: data.fbo,
        alignCenter: true
      }, {
        name: `fbs`,
        text: data.fbs?.toLocaleString() + ' ₽',
        dataForSorting: data.fbs,
        alignCenter: true
      }, {
        name: `penalty`,
        text: data.penalty?.toLocaleString() + ' ₽',
        dataForSorting: data.penalty,
        alignCenter: true
      }, {
        name: `total_count`,
        text: data.total_count?.toLocaleString(),
        dataForSorting: data.total_count,
        alignCenter: true
      }, {
        name: `total_sales`,
        text: data.total_sales?.toLocaleString() + ' ₽',
        dataForSorting: data.total_sales,
        alignCenter: true
      }]
    }
  }
  ) || []


  const handleSelectProducts = () => {
    setIsProductsSelection(true)
  }

  const handleProductsSelected = (list: string[]) => {
    setIsProductsSelection(false)
    setSelectedProducts(list)
  }

  const btnIsDisabled = false


  return (
    <div className={styles.wrap}>
      <ModalProductSearch isVisible={isProductsSelection} onClose={handleProductsSelected} />
      <div className={styles.controls}>
        <div className={styles.period}>
          <div>Сформировать статистику за</div>
          <div className={styles.period_selector}>
            <CalendarPeriodSelector period={period} onChange={handleSetPeriod} className={styles.calendar_period_selector} />
            <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
            <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
            <BlueButton buttonStyle={ButtonStyle.Style1} title='Применить' onClick={handleBuildReport} disabled={btnIsDisabled} />
          </div>
        </div>
        {/* <CustomInput name='search' onChange={handleChangeSearch}   /> */}
        <BlueButton title='Сформировать отчет' onClick={handleBuildReport} />
      </div>
      <div className={styles.report}>
        {isLoading && <Skeleton height={400} />}
        {salesData?.length && <div>
          <div className={styles.search_wrap}>
            <SearchInput onChange={setSearch} className={styles.search} />
            <div className={styles.open_serach_dialog} onClick={handleSelectProducts}>Смотреть все</div>
          </div>
          <h1>Статистика по продажам:</h1>
          <ItemsList
            className={styles.list}
            headers={headers}
            items={visibleData}
            // selectProducts={selectProducts}
            // deselectProducts={deselectProducts}
            // isRemovable={isRemovable}
            // removeItemsFromList={removeItemsFromList}
            // selectedItems={selectedProducts}
            // onChange={handleChange}
            emptyText='список пуст'
            totalConfig={totalConfig}
          />
        </div>}
      </div>

    </div >
  )
}
