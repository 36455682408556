import React, { useState, useEffect } from 'react'
import styles from './Leftovers.module.scss'
import { SearchInput } from '../../../../complexes/SearchInput'
import { BlueButton } from '../../../../simples/BlueButton'
import { PrintPDF } from '../../../../complexes/PrintPDF'
import { Proposal } from '../Proposal'
import { useAppDispatch } from '../../../../../hooks/store'
import { setIsActive } from '../../../../../store/slices/fbo'
import { ProductList } from '../../../../complexes/ProductList'
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders'
import { GoodsAnswer, ListHeadData } from '../../../../../types/productListTypes'
import { ProductsDTO } from '../../../../../types/swagger/llyApi_new'
import { Endpoints } from '../../../../../constants/server'
// import RadioBtns from './parts/RadioBtns/RadioBtns'
import api from "../../../../../utils/api";

enum Filters {
  Orders = 'Orders',
  Collected = 'Collected',
  Departures = 'Departures',
  Closed = 'Closed',
}

const FiltersConfig = [{
  title: 'Заказы',
  fid: Filters.Orders
}, {
  title: 'Собрано',
  fid: Filters.Collected
}, {
  title: 'Отправления',
  fid: Filters.Departures
}, {
  title: 'Закрытые',
  fid: Filters.Closed
}]

const headers: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,
  { ...DefaultHeaders.retail_price, text: "Розничная цена" },
  DefaultHeaders.discountValue,
  DefaultHeaders.discountPercent,
  DefaultHeaders.price,
  DefaultHeaders.fbo,
  DefaultHeaders.fbs,
  DefaultHeaders.discount_value,
  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.type,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.size,
  DefaultHeaders.color,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
];

export default function Leftovers() {

  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const [data, setData] = useState<ProductsDTO[]>([]);


  useEffect(() => {
    setData([]);
    const storage_id  = '1'
    let req = `/logistics-fbo/leftovers/${storage_id}?` //`${Endpoints.GET_PRODUCTS}?`;
    const parameters: string[] = []
    if (search !== "") { parameters.push(`search=${search}`) }
    req += parameters.join('&')

    api.get<GoodsAnswer>(req)
      .then((res) => {
        setData(res.data.data || []);
      })
      .catch((err) => console.log(err));
  }, [search])
  

  const handleCreateProposal = () => {
    dispatch(setIsActive(true))
  }

  return (
    <div className={styles.leftovers}>
      {/* <CustomTextInput_v2 name='search' placeholder='Поиск'  value={search} onChange={handleChangeSearch} /> */}
      <div className={styles.controls}>
        <SearchInput onChange={setSearch} className={styles.search} />
        <div className={styles.btns}>
          <BlueButton title='Скачать таблицу' />
          <PrintPDF fileTitle='Test PDF' fileURL='/test.pdf' title='Распечатать таблицу' />
          {/* <BlueButton title='Распечатать таблицу' /> */}
        </div>
      </div>
      <div>С базовой стоимостью логистики товаров можно ознакомиться <a href=''>здесь</a></div>
      <div>
        {/* <RadioBtns activeBtn={activeFilter} btnsConfig={FiltersConfig.map(fc => ({id: fc.fid, title: fc.title, value: '5'}))} /> */}
        <ProductList
          data={data} 
          headers={headers}
          // className={styles.products_list}
        />
      </div>
      <div>
        <BlueButton title='Создать заявку' onClick={handleCreateProposal} />
      </div>
    </div>
  )
}
