import React from 'react'
import { useDroppable } from '@dnd-kit/core';
import { IBox, IOrder } from '../../../../../../../../../store/slices/fbs';
import Box from './Box';

type Props = {
  box: IBox
  order: IOrder
  // index: number
}

export default function DroppableBox(props: Props) {
  const { isOver, setNodeRef, over, active } = useDroppable({
    id: props.box.id,
    data: { accepts: [props.order.id] }
  });

  const isTarget = isOver && over?.data?.current?.accepts.includes(active?.data?.current?.orderId)

  // const style = {
  //   color: isTarget ? 'green' : undefined,
  // };

  return (
    // <div ref={setNodeRef} style={style}>
    <div ref={setNodeRef}>
      <Box {...props} isTarget={isTarget} />
    </div>
  )
}
