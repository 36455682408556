import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { activeVariantSelector, generateBarcodes } from '../../../../../store/slices/product'
import { BlueButton } from '../../../../simples/BlueButton'
import { CustomHint } from '../../../../simples/CustomHint'
import Title from '../Title'
import Size from './Size'


import styles from './VariantSizesForCard.module.scss'
import classNames from 'classnames'

export default function VariantSizesForCard() {

  const dispatch = useAppDispatch()
  const sizes = useAppSelector(state => state.product.referenceBook.sizes)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const activeVariant = useAppSelector(activeVariantSelector)
  const hasEmptyBC = (activeVariant.sizes.find(s => !s.barcode) !== undefined)

  const handleGenCodes = () => {
    dispatch(generateBarcodes())
  }

  return (
    <div className={styles.wrap}>
      <Title text='Размеры и Штрихкоды' />
      <div className={styles.sizes}>
        <div className={classNames(styles.thead, styles.grid)}>
          <div className={styles.checkbox}>Размер</div>
          <div className={styles.price}>Розничная цена</div>
          <div className={styles.man_barcode}>Штрихкод <br />производителя</div>
          <div className={styles.barcode}>Штрихкод</div>
        </div>
        <div>
          {sizes.map(s => <Size key={s.id} size={s} />)}
        </div>
      </div>
      {!readonly && <div className={styles.footer}>
        <BlueButton title='Сгенерировать штрихкоды' onClick={handleGenCodes} disabled={!hasEmptyBC} />
        <CustomHint hint={<div>Мы автоматически создадим <br />штрихкоды для выбранных размеров</div>} hintWidth={353} />
      </div>}
    </div >
  )
}
