import React from "react";
import {TinDataType} from "../../../../../types/auth";
import {PinkErrorText} from "../../../../simples/PinkErrorText";
import LeftRegistrationCard from "../LeftRegistrationCard/LeftRegistrationCard";
import TinCheckBox from "../TinCheckBox/TinCheckBox";
import TinTextInput from "../TinTextInput/TinTextInput";
import styles from "./TinPart.module.scss";

type Props = {
  tinData: TinDataType;
  setTinData: React.Dispatch<React.SetStateAction<TinDataType>>;
  isError: boolean;
};

const TinPart = ({isError, tinData, setTinData}: Props) => {
  const setTin = (tin: string) =>
    setTinData(prevState => ({...prevState, tin}));

  const setWithVat = (withVat: boolean) =>
    setTinData(prevState => ({...prevState, withVat}));

  return (
    <LeftRegistrationCard>
      <h2 className={styles.title}>Регистрация</h2>
      <TinTextInput
        className={styles.textInput}
        setTin={setTin}
        tin={tinData.tin}
      />
      <TinCheckBox setWithVat={setWithVat} withVat={tinData.withVat} />
      <PinkErrorText
        className={styles.errorText}
        isShow={isError}
        title={"ИНН введён неверно"}
      />
      <div className={styles.info}>Нажимая “Всё верно”, я даю согласие на <a>обаботку персональных данных</a></div>
    </LeftRegistrationCard>
  );
};

export default TinPart;
