import React from 'react'
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { removeMaterial, setCompoundMaterial, setCompoundMaterialPercent } from '../../../../../store/slices/product';
import { MaterialState } from '../../../../../types/product_new';
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { TrashIcon } from '../../../../simples/TrashIcon';

import styles from "./Compositions.module.scss"
import AddBtn from './AddBtn';
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2';

type Props = {
  materialId?: number
  materialIndex: number
  compoundId: number
  percent?: number
  selectedMaterials: MaterialState[]
  required?: boolean
}

export default function Component({ percent, materialId, compoundId, materialIndex, selectedMaterials, required }: Props) {

  const dispatch = useAppDispatch()
  const allMaterials = useAppSelector(state => state.product.referenceBook.materials)
  const materials = allMaterials.filter(m => m.compound_id === compoundId)


  const percentSum = selectedMaterials.reduce((c, m) => c + (m.percent || 0), 0)
  let maxPercentValue = 100 - (percentSum - (percent || 0))
  if (maxPercentValue < 0) maxPercentValue = 0

  const canAdd = selectedMaterials.length < materials.length && maxPercentValue > (percent || 0) // && selectedMaterials.length < 10

  const selectedMaterialsIds = selectedMaterials.map(sm => sm.id)
  const options = materials
    .filter(m => m.id === materialId || selectedMaterialsIds.indexOf(m.id) === -1)  // фильтруем уже выбранные в других селектах
    .map(m => ({ label: m.name || '', value: m.id }))


  const handleChangeMaterial = ({ value }) => {
    dispatch(setCompoundMaterial({ compoundId, materialIndex, materialId: value }))
  }
  const handleChangePercent = ({ value }) => {
    dispatch(setCompoundMaterialPercent({ compoundId, materialIndex, percent: value }))
  }
  const handleRemove = () => {
    dispatch(removeMaterial({ compoundId, materialIndex }))
  }
  

  return (
    <div className={classNames(styles.component, styles.grid)}>
      <CustomSelect_v2
        name={`${compoundId}_${materialIndex}`}
        options={options}
        value={materialId}
        placeholder='Выберите материал'
        onChange={handleChangeMaterial}
        className={styles.material_select}
        hideError={true}
        required
        hasSearchInput
      />
      <CustomTextInput
        name={`${compoundId}_${materialIndex}_percent`}
        value={!percent ? '' : percent.toString()}
        isNumber={true}
        max={maxPercentValue}
        min={0}
        onChange={handleChangePercent}
        className={styles.material_percent}
        hideError={true}
        required
      />
      <div className={styles.controls}>
        {(materialIndex > 0 || !required) && <TrashIcon id={1} onRemove={handleRemove} />}
        {canAdd && <AddBtn compoundId={compoundId} />}
      </div>
    </div>
  )
}
