import { Action } from "./const";
import api from "../../../utils/api";

export async function postRequest({ action, id: uid, code }: { code?: string, action: Action, id: number }) {
  let req = '/employees/'
  const data = { code }
  switch (action) {
    case Action.Lock:
      req += 'lock/' + uid
      break;
    case Action.Unlock:
      req += 'unlock/' + uid
      break;
    case Action.Remove:
      req += 'delete/' + uid
      break;

    default:
      break;
  }

  const resp = await api.post<{ success: boolean, errors: { codeHasError?: boolean } }>(req, data)
  return resp.data
}