import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import GoodsListService, { Order } from "../../services/GoodsListService";
import { ProductsDTO } from "../../types/swagger/llyApi_new";
import api from "../../utils/api";
import { toast } from "react-toastify";

export type GoodsListCategory = {
  id: number,
  title: string,
  countGoods: number,
}

export interface GoodsListState {
  searchRequest: string;
  goods: Array<ProductsDTO>;
  selectedGoods: Array<string>;
  goodsToDelete: Array<string>;
  categories: Array<GoodsListCategory>;
  selectedCategoryID: number;
  activePageIndex: number;
  rowsLimit: number;
  sortByColumn: string;
  order: Order;
  isLoadingGoodsList: boolean;
  isLoadingGoodsStatuses: boolean;
  modalNotificationText: string;
  isRestoringProducts: boolean;
}

export const initialState: GoodsListState = {
  searchRequest: "",
  goods: [],
  selectedGoods: [],
  goodsToDelete: [],
  categories: [], // goodsCategories.map(c => ({ ...c, countGoods: 0 })),
  selectedCategoryID: 0,
  activePageIndex: 0,
  rowsLimit: 10,
  sortByColumn: "",
  order: Order.ASC,
  isLoadingGoodsList: false,
  isLoadingGoodsStatuses: false,
  modalNotificationText: "",
  isRestoringProducts: false,
};

export interface FetchGoodsRequest {
  categoryID: number;
  searchRequest?: string;
}

export const fetchGoods = createAsyncThunk<ProductsDTO[], void, { state: RootState }>(
  "GoodsList/fetchGoods", async (_, { getState }) => {
    const state = getState();
    const { searchRequest, selectedCategoryID, rowsLimit: showRowsCount, activePageIndex,
      sortByColumn, order
    } = state.productsList;

    const data = await GoodsListService.getGoods({
      id: selectedCategoryID,
      searchRequest,
      limit: showRowsCount,
      offset: showRowsCount * activePageIndex,
      order_by: (order === Order.DESC ? '-' : '') + sortByColumn,
      // order: order === Order.DESC ? "DESC" : "ASC"
    });
    return data;
  });

export const fetchGoodsStatuses = createAsyncThunk("GoodsList/fetchCategories", async () => {
  const data = GoodsListService.getGoodsStatuses();
  return data;
});
// export const softDeleteProducts = createAsyncThunk("GoodsList/softDeleteProduct", async (ids: Array<number>, selectedStateId: number) => {
export const softDeleteProducts = createAsyncThunk("GoodsList/softDeleteProduct",
  async ({ goodsToDelete, selectedStateId }: { goodsToDelete: Array<string>, selectedStateId: number }, { rejectWithValue, fulfillWithValue }) => {
    // const data = GoodsListService.softDeleteProducts(ids);
    const data = await GoodsListService.deleteProducts(goodsToDelete, false, selectedStateId);
    if (!data || !data.success) {
      return rejectWithValue("Ошибка на стороне сервера.");
    } else {
      // return fulfillWithValue(data);
      return data
    }
    // const data = GoodsListService.deleteProducts(goodsToDelete, false, selectedStateId);
    // return data;
  });
// export const deleteProducts = createAsyncThunk("GoodsList/deleteProducts", async (ids: Array<number>) => {
export const deleteProducts = createAsyncThunk("GoodsList/deleteProducts",
  async ({ goodsToDelete, selectedStateId }: { goodsToDelete: Array<string>, selectedStateId: number }) => {
    const data = GoodsListService.deleteProducts(goodsToDelete, true, selectedStateId);
    return data;
  });
// export const restoreProducts = createAsyncThunk("GoodsList/restoreProducts", async (ids: Array<number>) => {
export const restoreProducts = createAsyncThunk("GoodsList/restoreProducts",
  async ({ goodsToRestore, selectedStateId }: { goodsToRestore: Array<string>, selectedStateId: number }) => {
    const data = GoodsListService.restoreProducts(goodsToRestore, selectedStateId);
    return data;
  });


// TODO remove 
export const setModeratedProducts = createAsyncThunk<void, Array<string>, { state: RootState }>("GoodsList/setModeratedProducts",
  async (goodsIds: Array<string>, { dispatch }) => {
    const success = () => toast.success("Запрос выполнен", { theme: "light" });
    await api
      .post('/admin-tools/moderate', { products: goodsIds })
      .then((res) => { success() })
      .catch(console.log)
      .finally(() => {
        // dispatch(setSearchRequest(''))
        dispatch(fetchGoods())
        dispatch(fetchGoodsStatuses())
      });
  });





export const goodsListSlice = createSlice({
  name: "productsList",
  initialState,
  reducers: {
    setGoodsToDelet: (state, action) => {
      state.goodsToDelete = action.payload;
    },
    setModalNotificationText: (state, action) => {
      state.modalNotificationText = action.payload;
    },
    setSearchRequest: (state, action) => {
      state.searchRequest = action.payload;
      state.activePageIndex = 0;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    setSelectedCategoryID: (state, action) => {
      state.selectedCategoryID = action.payload;
      state.activePageIndex = 0;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    setActivePageIndex: (state, action) => {
      state.activePageIndex = action.payload;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
      state.activePageIndex = 0;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    setSortOrder: (state, action) => {
      state.order = action.payload;
      state.activePageIndex = 0;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    setSelectedGoods: (state, action) => {
      state.selectedGoods = action.payload;
    },
    setRowsLimit: (state, action) => {
      state.rowsLimit = action.payload;
      state.activePageIndex = 0;
      state.goodsToDelete = [];
      state.selectedGoods = [];
    },
    toggleProductSelection: (state, action) => {
      if (state.selectedGoods.indexOf(action.payload) === -1) {
        state.selectedGoods.push(action.payload);
      } else {
        state.selectedGoods = state.selectedGoods.filter(pid => pid !== action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGoods.pending, (state) => {
      state.isLoadingGoodsList = true;
    });
    builder.addCase(fetchGoods.fulfilled, (state, action) => {
      if (action.payload) {
        state.goods = action.payload;
        state.goodsToDelete = [];
        state.selectedGoods = [];
      }
      state.isLoadingGoodsList = false;
    });
    builder.addCase(fetchGoods.rejected, (state) => {
      state.isLoadingGoodsList = false;
    });


    builder.addCase(fetchGoodsStatuses.pending, (state) => {
      state.isLoadingGoodsStatuses = true;
    });
    builder.addCase(fetchGoodsStatuses.fulfilled, (state, action) => {
      if (action.payload) {
        state.categories = action.payload;
      }
      state.isLoadingGoodsStatuses = false;
    });
    builder.addCase(fetchGoodsStatuses.rejected, (state) => {
      state.isLoadingGoodsStatuses = false;
    });


    // builder.addCase(softDeleteProducts.pending, (state) => {
    //   // state.isLoadingCategories = true;
    // });
    builder.addCase(softDeleteProducts.fulfilled, (state, action) => {
      state.goodsToDelete = [];
      state.selectedGoods = [];
      // TODO проверить ответ от сервера на ошибки (когда запрос заработает)
      if (action.payload?.goods) {
        state.goods = action.payload.goods;
        state.activePageIndex = 0;
      }
      if (action.payload?.statuses) {
        state.categories = action.payload.statuses;
      }
      state.modalNotificationText = "Все объекты успешно перемещены в архив";
    });
    builder.addCase(softDeleteProducts.rejected, (state, action) => {
      // state.isLoadingCategories = false;
      state.goodsToDelete = [];
      state.modalNotificationText = "Ошибка удаления карточек. Попробуйте ещё раз.";
    });

    builder.addCase(deleteProducts.fulfilled, (state, action) => {
      state.goodsToDelete = [];
      state.selectedGoods = [];
      // TODO проверить ответ от сервера на ошибки (когда запрос заработает)
      if (action.payload?.goods) {
        state.goods = action.payload.goods;
        state.activePageIndex = 0;
      }
      if (action.payload?.statuses) {
        state.categories = action.payload.statuses;
      }
      state.modalNotificationText = "Все объекты успешно удалены";
    });
    builder.addCase(deleteProducts.rejected, (state) => {
      // state.isLoadingCategories = false;
      state.modalNotificationText = "Произошёл сбой при удалении данных. Попробуйте ещё раз";
    });

    builder.addCase(restoreProducts.pending, (state) => {
      state.isRestoringProducts = true
    });
    builder.addCase(restoreProducts.fulfilled, (state, action) => {
      state.goodsToDelete = [];
      state.selectedGoods = [];
      // TODO проверить ответ от сервера на ошибки (когда запрос заработает)
      if (action.payload?.goods) {
        state.goods = action.payload.goods;
        state.activePageIndex = 0;
      }
      if (action.payload?.statuses) {
        state.categories = action.payload.statuses;
      }
      state.isRestoringProducts = false
      state.modalNotificationText = "Все объекты успешно восстановлены";
    });
    builder.addCase(restoreProducts.rejected, (state) => {
      state.isRestoringProducts = false
      state.modalNotificationText = "Произошёл сбой. Попробуйте ещё раз";
    });
  },
});


export const {
  setSearchRequest, setSelectedCategoryID, setActivePageIndex, setSortByColumn,
  setSortOrder, toggleProductSelection, setSelectedGoods, setRowsLimit, setModalNotificationText,
  setGoodsToDelet
} = goodsListSlice.actions;



export default goodsListSlice.reducer;