import React from "react";
import {DisabledMultilineInput} from "../DisabledMultilineInput";
import {TitledLightInput} from "../TitledLightInput";

type Props = {
  setValue: (val: string) => void;
  value: string;
  title: string;
  className?: string;
  mask?: string;
  isActive?: boolean;
};

const SwitchTextInput = ({
  setValue,
  value,
  title,
  className,
  mask,
  isActive,
}: Props) => (
  <>
    {!isActive ? (
      <DisabledMultilineInput
        className={className}
        header={title}
        body={value || ""}
      />
    ) : (
      <TitledLightInput
        title={title}
        className={className}
        setValue={setValue}
        value={value}
        mask={mask}
      />
    )}
  </>
);

export default SwitchTextInput;
