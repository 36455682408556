import classNames from "classnames";
import React from "react";
import { LightInput } from "../LightInput";
import styles from "./TitledLightInput.module.scss";

type Props = {
  title: string;
  setValue: (value: string) => void;
  className?: string;
  mask?: string;
  value?: string;
  isError?: boolean;
};

const TitledLightInput = ({ title, setValue, className, ...props }: Props) => {
  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = ev.target.value;
    value = value.substring(0, props.mask?.length || value.length)
    setValue(value);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <h2 className={styles.title}>{title}</h2>
      <LightInput className={styles.input} onInput={onInput} {...props} />
    </div>
  );
};

export default TitledLightInput;
