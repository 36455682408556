import { Endpoints } from "../constants/server";
import { OrganisationData } from "../types/registration";
import { Users } from "../types/swagger/llyApi_new";
import api from "../utils/api";

// type UserDataAnswer = {
//   success: boolean;
//   data?: {
//     id: number;
//     phone: string;
//     email: string | null;
//     firstName: string | null;
//     lastName: string | null;
//     secondName: string | null;
//     dateOfBirth: string | null;
//     createdAt: number;
//     updatedAt: number;
//     loggedAt: number | null;
//     active: 1 | 0;
//     company: OrganisationData;
//     companyRating: number;
//   };
// };

type UserDataAnswer = {
  success?: boolean
  data?: Users
}

class UserService {
  static async getUserData() {
    let data: UserDataAnswer = { success: false };

    await api
      .get<UserDataAnswer>(Endpoints.USER)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data;
  }
}

export default UserService;
