import React, { useEffect, useState } from "react";
import styles from "./BicInfo.module.scss";
import { DisabledMultilineInput } from "../../../../simples/DisabledMultilineInput";
import { TitledLightInput } from "../../../../simples/TitledLightInput";
import { PurpleButton } from "../../../../simples/PurpleButton";
import { PinkErrorText } from "../../../../simples/PinkErrorText";
import { PaymentInfo } from "../../../../../types/registration";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/routes";
import { BlueButton } from "../../../../simples/BlueButton";

//Todo: set type
type Props = {
  paymentInfo: PaymentInfo;
  signup: (paymentAccount: string) => void;
  isBicError: boolean;
};

const BicInfo = ({ paymentInfo, signup, isBicError }: Props) => {
  const [paymentAccount, setPaymentAccount] = useState<string>("");
  const [isPaymentError, setIsPaymentError] = useState<boolean>(false);
  const navigate = useNavigate();

  const confirm = () => {
    paymentInfo.name && paymentAccount.length < 20 && setIsPaymentError(true);
    paymentAccount.length === 20 && signup(paymentAccount);
  };

  const onTypePaymentAccount = (paymentAcc: string) => {
    setPaymentAccount(paymentAcc);
    isPaymentError && setIsPaymentError(false);
  };

  const cancel = () => navigate(Routes.AUTH);

  useEffect(() => {
    paymentInfo?.paymentAccount && setPaymentAccount(paymentInfo.paymentAccount);
  }, [paymentInfo]);

  return (
    <div className={styles.container}>
      <DisabledMultilineInput
        className={styles.input}
        header="Наименование банка"
        body={paymentInfo.name}
      />
      <DisabledMultilineInput
        className={styles.input}
        header="Корреспондентский счет"
        body={paymentInfo.correspondentAccount}
      />
      {!paymentInfo.name && (
        <DisabledMultilineInput
          className={styles.input}
          header="Расчетный счет"
          body={""}
        />
      )}
      {paymentInfo.name && (
        <TitledLightInput
          className={styles.input}
          setValue={onTypePaymentAccount}
          mask={"99999999999999999999"}
          title="Расчетный счет"
          value={paymentAccount}
          isError={isPaymentError}
        />
      )}
      <PinkErrorText
        isShow={isPaymentError}
        title={"Не заполнен Расчётный счёт организации"}
        className={styles.error}
      />
      <div className={styles.buttonsContainer}>
        <button className={styles.disableButton} onClick={cancel}>
          Отменить
        </button>
        {/* <PurpleButton
          title={"Всё верно"}
          className={styles.confirmButton}
          onClick={confirm}
          disabled={isPaymentError || isBicError}
        /> */}
        <BlueButton
          title={"Всё верно"}
          className={styles.confirmButton}
          onClick={confirm}
          disabled={isPaymentError || isBicError} />
      </div>
    </div>
  );
};

export default BicInfo;
