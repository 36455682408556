import React from "react";
import { CloseButton } from "../../../../simples/CloseButton";
import { PopupWrapper } from "../../../../simples/PopupWrapper";
import { PurpleButton } from "../../../../simples/PurpleButton";
import styles from "./ModarateModal.module.scss";
import { BlueButton } from "../../../../simples/BlueButton";

type Props = {
  isShow: boolean;
  onClose: () => void;
};

const ModarateModal = ({ isShow, onClose }: Props) => {
  return (
    <PopupWrapper
      onClose={onClose}
      className={styles.container}
      isShow={isShow}
    >
      <div className={styles.modal}>
        <CloseButton onClick={onClose} className={styles.closeButton} />
        <h2 className={styles.header}>Ваша заявка отправлена на модерацию</h2>
        {/* <PurpleButton
          onClick={onClose}
          className={styles.goInitialButton}
          title={"Вернуться на первый экран"}
        /> */}
        <BlueButton
          onClick={onClose}
          className={styles.goInitialButton}
          title={"Вернуться на первый экран"}
        />
      </div>
    </PopupWrapper>
  );
};

export default ModarateModal;
