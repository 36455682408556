import React from "react";
import styles from "./CustomHint.module.scss";
import classNames from "classnames";

export enum CustomHintDirection {
  Left,
  Right
}

type Props = {
  hint?: string | JSX.Element;
  className?: string;
  children?: JSX.Element | JSX.Element[] | string
  hintWidth?: number
  direction?: CustomHintDirection
};

const CustomHint = ({ hint, className, children, hintWidth, direction }: Props) => {

  const width = (hintWidth && hintWidth > 0) ? `${hintWidth}px` : undefined

  return (
    <div className={classNames(styles.wrap, className, {
      [styles.active]: !!hint
    })}>
      {children || <div className={styles.icon} />}
      <div className={classNames(styles.hint, {
        [styles.direction_right]: direction === CustomHintDirection.Right
      })} style={{ width }} >{hint}</div>
    </div>
  );
};

export default CustomHint;
