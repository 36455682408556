import React, { FC } from "react";
import styles from "./ModalProgress.module.scss";

interface ModalProgressProps {
  progress: number;
  isActive: boolean;
  title?: string;
  text?: string;
}

const ModalProgress: FC<ModalProgressProps> = ({ isActive, title, text, progress }) => {

  if (!isActive) {
    return null;
  }

  return <article className={styles.modalContainer}>
    <div className={styles.modalUnderflow}></div>

    <section className={styles.content}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.text}>{text}</div>
      <progress className={styles.progress} value={progress} max="100">{progress}%</progress>
    </section>

  </article>;
};

export default ModalProgress;
