import React, { useState } from 'react'
import { ModalDialog } from '../../../../simples/ModalDialog'

import styles from './VariantPhotos.module.scss'
import classNames from 'classnames'

type Props = {
  closeShowPreview: () => void
  isActive: boolean
  photos: string[]
}

export default function Preview({ closeShowPreview, isActive, photos }: Props) {

  const [index, setIndex] = useState(0)

  const hanleNext = () => {
    let i = index + 1
    if (i >= photos.length) { i = 0 }
    setIndex(i)
  }
  const hanlePrev = () => {
    let i = index - 1
    if (i < 0) { i = photos.length - 1 }
    setIndex(i)
  }

  return (
    <ModalDialog isActive={isActive} buttonsConfig={[]} handleClose={closeShowPreview} width={920} hasCloseBtn>
      <div className={styles.preview}>
        <div>Фото для образа</div>
        <div className={styles.photo_wrap}>
          <img src={photos[index]} />
        </div>
        <div onClick={hanlePrev} className={classNames(styles.btn, styles.btn_prev)}></div>
        <div onClick={hanleNext} className={classNames(styles.btn, styles.btn_next)}></div>
        <div className={styles.info}>{`${index + 1} из ${photos.length}`}</div>
      </div>
    </ModalDialog>
  )
}
