import React from 'react'
import styles from "./Checkbox.module.scss"
import v from "./assets/v.svg"
import o from "./assets/o.svg"
import classNames from 'classnames'

type Props = {
  checked?: boolean
  label?: string
  onChange?: (checked: boolean) => void
  disabled?: boolean
  readonly?: boolean
  className?: string
}

export default function Checkbox({ checked, label, onChange, disabled, readonly, className }: Props) {
  const handleClick = () => {
    if (disabled || readonly) return
    onChange && onChange(!checked)
  }


  return (
    <div onClick={handleClick} className={classNames(styles.wrap, className, { [styles.disabled]: disabled })}>
      {!readonly && <img className={classNames(styles.box, { [styles.checked]: checked })} src={checked ? v : o} alt="V" />}
      {label && <div className={styles.label}>{label}</div>}
    </div>
  )
}
