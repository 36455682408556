import React, { FC } from "react";
import styles from "./Pagination.module.scss";
import classNames from "classnames";
import { CustomSelect_v2, IOnSelectChange } from "../../simples/CustomSelect_v2";

interface PaginationProps {
  pagesCount: number;
  showRowsCount: number;
  showRowsOptions?: Array<{ value: number, title: string | number }>;
  activePageIndex: number;
  isModalDialog?: boolean;

  onSelectPage: (count: number) => void;
  onSelectRowsLimit: (count: number) => void;
}

const DefaultOptions = [{ title: 10, value: 10 }, { title: 20, value: 20 }, { title: "все", value: Infinity }]


const Pagination: FC<PaginationProps> = ({
  pagesCount, showRowsCount, activePageIndex, showRowsOptions, isModalDialog,
  onSelectPage, onSelectRowsLimit
}) => {

  const handleSelectNextPage = () => {
    if (activePageIndex + 1 < pagesCount) {
      onSelectPage(activePageIndex + 1);
    }
  };

  const handleSelectPreviousPage = () => {
    if (activePageIndex > 0) {
      onSelectPage(activePageIndex - 1);
    }
  };

  const btns: JSX.Element[] = [];
  for (let i = 0; i < pagesCount; i++) {
    btns.push(<PaginationBtn key={i.toString()} isSelected={activePageIndex === i} pageIndex={i} selectPage={onSelectPage} />);
  }

  // const handleChangeRowsCount = e => {
  //   onSelectRowsLimit(e.target.value);
  // };
  
  const handleChangeRowsCount = ({ value }: IOnSelectChange) => {
    onSelectRowsLimit(parseInt((value||10).toString()));
  };

  const options = (showRowsOptions || DefaultOptions).map(o => ({label: o.title.toString(), value: o.value}))

  return <div className={styles.wrapper}>
    <div className={styles.footer_txt}>
      <span>Отображать по</span>
      {/* <select value={showRowsCount}
        onChange={handleChangeRowsCount}
      >
        {(showRowsOptions || DefaultOptions).map(({ value, title }) => <option key={value} value={value}>{title}</option>)}
      </select> */}
      <CustomSelect_v2 name='page' className={styles.select} value={showRowsCount} options={options} onChange={handleChangeRowsCount} hideError isMini isReverse={isModalDialog} />
    </div>
    {pagesCount > 1 && <div className={styles.buttons}>
      <div
        className={classNames(styles.footer_btn_backward__arrow, styles.footer_btn)}
        onClick={handleSelectPreviousPage}
      />
      {btns}
      <div
        className={classNames(styles.footer_btn_forward__arrow, styles.footer_btn)}
        onClick={handleSelectNextPage}
      />
    </div>
    }
  </div>;
};


interface PaginationBtnInterface {
  pageIndex: number,
  selectPage: (pageIndex: number) => void,
  isSelected: boolean;
}

const PaginationBtn: FC<PaginationBtnInterface> = ({ pageIndex, selectPage, isSelected }) => {
  const handleClick = () => {
    selectPage(pageIndex);
  };
  const className = classNames(styles.footer_btn, styles.footer_btn_page, { [styles.selected]: isSelected });
  return (<div onClick={handleClick} className={className}>{pageIndex + 1}</div>);
};

export default Pagination;
