import classNames from 'classnames'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setDescriptionValue } from '../../../../../store/slices/product'
import { CustomTextarea } from '../../../../simples/CustomTextarea'
import Title from '../Title'

import styles from "./Description.module.scss"

export default function Description() {

  const dispatch = useAppDispatch()

  const description = useAppSelector(state => state.product.description.value)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)


  const handleChangeValue = (value: string) => {
    dispatch(setDescriptionValue(value))
  }


  return (
    <div className={styles.description}>
      <CustomTextarea className={styles.textarea} label='Описание товара' placeholder='Введите описание товара' value={description} 
      onChange={handleChangeValue} maxLength={2000} showTextLength={true} readonly={readonly} />
    </div>
  )
}
