import React, { useState, useEffect } from 'react'
import useDebounce from '../../../hooks/lifecycle'
import { TextInput, TextInputProps } from '../../simples/TextInput'

interface Props extends TextInputProps {
  debounceDelay: number
  /// валидация для вводимого значения, например проверка что всегда число
  validator?: (value: string) => string
  /// валидация для конечного значения, например соответствие диапазону значений
  preValidator?: (value: string) => string
}

export default function DebouncedInput({ debounceDelay, preValidator, validator, onChange, value, ...inputProps }: Props) {

  const [rawValue, setRawValue] = useState(value)
  const debouncedValue = useDebounce(rawValue, debounceDelay);

  useEffect(() => {
    handleSetRawValue(value)
  }, [value])

  const handleSetRawValue = (v: string) => {
    const validatedValue = preValidator ? preValidator(v) : v
    setRawValue(validatedValue)
  }

  const handleOnChange = (v: string) => {
    if(inputProps.disabled) return
    const validatedValue = validator ? validator(v) : v
    if (validatedValue !== v) { setRawValue(validatedValue) }
    onChange(validatedValue)
  }

  useEffect(() => {
    handleOnChange(debouncedValue)
  }, [debouncedValue])

  const handleBlur = () => {
    handleOnChange(rawValue)
  }

  return (<TextInput onChange={handleSetRawValue} value={rawValue} onBlur={handleBlur} {...inputProps} />)
}
