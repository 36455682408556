import React, { useEffect } from 'react'

import styles from './CompanyDocuments.module.scss'
import Doc from './parts/Doc'
import { Layout } from '../../complexes/Layout'
import { CustomRadioButtons } from '../../simples/CustomRadioButtons'
import { BlueButton } from '../../simples/BlueButton'
import { SellerType, TaxSystemType } from '../../../types/law'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { fetchDocs, isDocumentsUploaded, sendToModeration, setTaxSystemType } from '../../../store/slices/companyDocuments'
import USNDoc from './parts/USNDoc'



export default function CompanyDocuments() {

  const dispatch = useAppDispatch()

  const docs = useAppSelector(state => state.companyDocuments.docs)
  const sellerType = useAppSelector(state => state.companyDocuments.sellerType)
  const isAllDocsUploaded = useAppSelector(isDocumentsUploaded())
  const taxSystemType = useAppSelector(state => state.companyDocuments.taxSystemType)

  useEffect(() => {
    dispatch(fetchDocs())
  }, [])


  const handleSave = () => {
    dispatch((sendToModeration()))
  }
  const handleChangeTaxSystemType = ({ value }) => {
    dispatch(setTaxSystemType(value as TaxSystemType))
  }



  return (
    <Layout title='Документы компании'>
      <div className={styles.wrap}>
        <div className={styles.info}>! Загружаемый файл должен быть в формате  .pdf .jpeg, .jpg или .png, а размер не должен превышать 32 Мб</div>

        {sellerType === SellerType.IP ? <h1>Индивидуальный предприниматель</h1> : <h1>Юридическое лицо</h1>}
        <div className={styles.docs}>
          {docs.map((d, index) => <Doc key={d.type} doc={d} />)}
        </div>
        <h1>Система налогообложения компании</h1>
        <CustomRadioButtons label='' name='taxSystemType' onChange={handleChangeTaxSystemType} activeName={taxSystemType}
          isColumn
          btns={[{
            label: 'ОСНО (Основная Система Налогооблажения)',
            name: TaxSystemType.OSNO
          }, {
            label: 'УСН (Упрощённая Система Налогооблажения)',
            name: TaxSystemType.USN
          }]} />
        <USNDoc />
        <div className={styles.link}>Ссылка на Ваш договор с LL</div>
        <div className={styles.btns}>
          <BlueButton title='Отправить на модерацию' onClick={handleSave} disabled={!isAllDocsUploaded} />
        </div>
      </div>
    </Layout>
  )
}
