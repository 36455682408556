import React, { useState } from 'react'

import styles from '../Brands.module.scss'
import back_icon from '../assets/back.svg'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { addCertProducts, setSelectedCertCardId } from '../../../../store/slices/certificates'
import CertEditor from './CertEditor'
import CertProductsList from './CertProductsList'
import { BlueButton } from '../../../simples/BlueButton'
import { ModalProductSearch } from '../../../complexes/ModalProductSearch'
import { AditionlParameter } from '../../../complexes/ModalProductSearch/ModalProductSearch'

export default function CertCard() {

  const dispatch = useAppDispatch()
  const id = useAppSelector(state => state.certificates.selectedCertCardId)
  const [isProductsSelection, setIsProductsSelection] = useState(false)

  const handleSelectProducts = () => {
    setIsProductsSelection(true)
  }
  const handleAddProducts = (list: string[]) => {
    const ids = list.map(id => parseInt(id.split('-')[0]))
    setIsProductsSelection(false)
    dispatch(addCertProducts(ids))
  }
  const handleBack = () => {
    dispatch(setSelectedCertCardId(undefined))
  }

  const aditionlParameters: AditionlParameter[] = []
  if (id) {
    aditionlParameters.push({ key: 'certificate_id', value: id })
  }

  return (
    <div className={styles.cert_card}>
      <ModalProductSearch isVisible={isProductsSelection} onClose={handleAddProducts} aditionlParameters={aditionlParameters} />
      <h1><img src={back_icon} onClick={handleBack} />Карточка сертификата</h1>
      <CertEditor readonly />
      <div className={styles.btns}>
        <BlueButton title='Привязать карточки' onClick={handleSelectProducts} />
      </div>
      <CertProductsList />
    </div>
  )
}
