import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/routes";
import { useAppDispatch } from "../../../../../hooks/store";
import { AuthService } from "../../../../../services/AuthService";
import { fetchUserData } from "../../../../../store/slices/auth";
import { getOnlyNumbers } from "../../../../../utils/composeString";
import { ConfirmCodeInput } from "../../../../complexes/ConfirmCodeInput";
import { PinkErrorText } from "../../../../simples/PinkErrorText";
import { PurpleButton } from "../../../../simples/PurpleButton";
import { ActiveWithTimerText } from "../ActiveWithTimerText";
import { AuthCardWrapper } from "../AuthCardWrapper";
import styles from "./SmsAuthCard.module.scss";
import { BlueButton } from "../../../../simples/BlueButton";

type Props = {
  phone: string;
};

const SmsAuthCard = ({ phone }: Props) => {
  const [currCode, setCurrCode] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [triggerResetSend, setTriggerResetSend] = useState({
    sms: false,
    push: false,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const repeatSend = async (smsOnly: boolean) => {
    const onlyNumbersPhone = getOnlyNumbers(phone);
    const isSend = await AuthService.login(onlyNumbersPhone, smsOnly);
    if (isSend) {
      smsOnly &&
        setTriggerResetSend(prevState => ({ ...prevState, sms: !prevState.sms }));
      !smsOnly &&
        setTriggerResetSend(prevState => ({
          ...prevState,
          push: !prevState.push,
        }));
    }
  };

  // Plug for push notif
  const plugNotifPress = (smsOnly: boolean) => {
    !smsOnly &&
      setTriggerResetSend(prevState => ({ ...prevState, push: !prevState.push }));
  };

  const changeCode = (code: string) => {
    setCurrCode(code);
    isError && setIsError(false);
  };

  const confirmCode = async () => {
    const confirmData = await AuthService.confirmCode({
      phone: getOnlyNumbers(phone),
      smsCode: currCode,
    });
    confirmData.result === "register" && goToRegistration();
    confirmData.result === "login" && dispatch(fetchUserData());
    !confirmData.success && setIsError(true);
  };

  const goToRegistration = () =>
    navigate(Routes.REGISTRATION, {
      state: {
        phone,
        code: currCode,
      },
    });

  useEffect(() => {
    setIsDisabled(currCode.length !== 4);
  }, [currCode]);

  return (
    <AuthCardWrapper>
      <div className={styles.container}>
        <p className={styles.darkGrayFont}>
          Код отправлен в приложение <br />
          или СМС на номер <b>{phone}</b>
        </p>
        <ConfirmCodeInput
          classes={styles.confirmCode}
          onEnter={confirmCode}
          setValue={changeCode}
          isError={isError}
        />
        <PinkErrorText
          className={styles.errorText}
          isShow={isError}
          title={"Код введён неверно"}
        />
        <ActiveWithTimerText
          className={styles.sendPushButton}
          buttonText="Отправить код пуш-уведомлением повторно"
          onPress={plugNotifPress.bind(null, false)}
          middleText={"можно через"}
          timerSeconds={5}
          triggerReset={triggerResetSend.push}
        />
        <ActiveWithTimerText
          className={styles.sendSmsButton}
          buttonText="Отправить SMS-код"
          onPress={repeatSend.bind(null, true)}
          middleText={"можно через"}
          timerSeconds={10}
          triggerReset={triggerResetSend.sms}
        />
        {/* <PurpleButton
          disabled={isDisabled}
          title={"Войти"}
          className={styles.button}
          onClick={confirmCode}
        /> */}
        <BlueButton
          disabled={isDisabled}
          title={"Войти"}
          className={styles.button}
          onClick={confirmCode} />
      </div>
    </AuthCardWrapper>
  );
};

export default SmsAuthCard;
