import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { getImportedData, getImportedDataErrors, sendToModerate } from "../../../store/slices/massImport";
import { Layout } from "../../complexes/Layout";
import { WarningPanel } from "../../simples/WarningPanel";
import { TemplateSelector } from "./parts/TemplateSelector";
import { Upload } from "./parts/Upload";
import exclamationIcon from "./assets/exclamation.svg";
import styles from "./ProductsImportTable.module.scss";
import { BlueButton, IconPosition } from "../../simples/BlueButton";
import classNames from "classnames";
import { DialogInfo } from "./parts/DialogInfo";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/routes";
import { AvailableProductData } from "../../complexes/ProductList";
import { ListHeadData } from "../../../types/productListTypes";
import ProductList from "../../complexes/ProductList/ProductList";
import { DefaultHeaders } from "../../complexes/ProductList/DefaultHeaders";

const headers: ListHeadData[] = [{
  name: AvailableProductData.row_index,
  text: "№",
  minWidth: "60px",
  width: "2fr"
},
DefaultHeaders.photo,
DefaultHeaders.barcode,
DefaultHeaders.name,
DefaultHeaders.brand,
DefaultHeaders.category,
DefaultHeaders.size,
DefaultHeaders.season,
DefaultHeaders.color,
{ ...DefaultHeaders.retail_price, text: "Розничная цена" },
];

function ProductsImportTable() {

  const dispatch = useAppDispatch();
  const importedData = useAppSelector(getImportedData());
  const importedDataErrors = useAppSelector(getImportedDataErrors());
  const isFakeBarcodes = useAppSelector((state) => state.massImport.isFakeBarcodes);

  const handleSendToModerate = () => {
    dispatch(sendToModerate());
  }

  const navigate = useNavigate();
  const handleGoToList = () => {
    navigate(`${Routes.PRODUCTS_LIST}`);
  }

  const handleBack = () => {
    navigate(Routes.PRODUCTS_LIST)
  }

  return (
    <Layout
      onBack={handleBack}
      title='Добавление таблицей'
      hint={<span>Скачайте шаблон на свой компьютер, нажав кнопку &quot;Выгрузить таблицу товаров&quot;. После заполнения шаблона, загрузите его в систему, нажав кнопку &quot;Загрузить таблицу товаров&quot;. После отображения таблицы на экране Вы сможете сгенерировать штрихкоды и загрузить фото товаров согласно <a>инструкции</a></span>}
    >
      <DialogInfo />
      <section className={styles.wrapper}>
        <WarningPanel
          title="Внимание к тексту"
          text="При обнаружении одинаковых характеристик (повторяющиеся значения или слова) у двух или более карточек, эти карточки товаров могут быть отмечены как спам и отклонены модератором"
        />
        <section className={styles.body}>
          <h3 className={styles.body_title}>Выберите шаблон</h3>
          <div className={styles.row1}>
            <TemplateSelector />
            <Upload />
          </div>
          <ProductList data={importedData} errors={importedDataErrors} headers={headers} isFakeBarcodes={isFakeBarcodes} />
          <div className={styles.footer}>
            {importedData.length > 0 && <div className={styles.btns}>
              <BlueButton title="Сохранить и отправить на модерацию" onClick={handleSendToModerate} />
              <BlueButton title="Перейти к карточкам товаров" onClick={handleGoToList} />
            </div>}
            <span className={classNames(styles.warning, { [styles.is_visible]: importedDataErrors.length > 0 })}><img src={exclamationIcon} />Измените данные в подсвеченном поле и загрузите таблицу ещё раз</span>

          </div>
        </section>
      </section>

    </Layout>
  );
}

export default ProductsImportTable;
