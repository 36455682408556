import classNames from 'classnames'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setSelectedFeedbackId } from '../../../../../store/slices/feedback'
import { FeedbackDTO } from '../../../../../types/swagger/llyApi_new'
import { Card } from '../Card'
import { Respons } from '../Respons'

import styles from "./Cards.module.scss"

interface Props {
  feedbackData: FeedbackDTO
}

export default function Row({ feedbackData }: Props) {

  const dispatch = useAppDispatch()
  const selectedId = useAppSelector(state => state.feedback.selectedFeedbackId)


  const handleClick = () => {
    dispatch(setSelectedFeedbackId(feedbackData.id))
  }

  const selected = selectedId === feedbackData.id

  return (
    <div
      className={classNames(styles.grid_row, { [styles.selected]: selected })}
      onClick={handleClick}
    >
      <div className={styles.grid_cel}>
        <Card selected={selected} feedbackData={feedbackData} />
      </div>
      <div className={styles.grid_cel}>
        <Respons selected={selected} feedbackData={feedbackData} />
      </div>
    </div>
  )
}
