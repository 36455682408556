import classNames from 'classnames';
import React, { FC, useState } from 'react'
import { ListData, ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes';
import { Order } from '../../ItemsList';
import styles from "./List.module.scss";
import checkboxIcon from "../../assets/checkbox.svg";
import crosIcon from "../../assets/cros.svg";
import { ListCheckbox } from '../ListCheckbox';
import TextInput from './TextInput';
import { EditedValue } from '../../../ProductList';

export type EditableData = {
  fieldName: string
  values: EditedValue[]
}

export type TotalConfig = {
  startFrom: number
  values: {
    [k: string]: {
      value: string | number
      formatter?: (value: string | number) => string
    }
  }
}

interface ListProps {
  sortBy: number;
  orderBy: Order;
  headers: ListHeadData[];
  data: Array<ListRow>;
  onClickOnHeader: (index: number) => void;
  // selectedProducts?: number[]
  isSelectableRows?: boolean
  selectProducts?: (selectedProducts: string[]) => void
  deselectProducts?: (selectedProducts: string[]) => void
  removeItemsFromList?: (selectedProducts: string[]) => void
  isRemovable?: boolean
  onChange?: (dataId: string, fieldName: string, newValue: string) => void
  editableData?: EditableData[]
  emptyText?: string | JSX.Element
  totalConfig?: TotalConfig
}


function defaultFormatter(value: string | number): string {
  return value.toLocaleString()
}

const List: FC<ListProps> = ({
  headers, data, onClickOnHeader, sortBy, orderBy, isRemovable, onChange, editableData, 
  isSelectableRows, selectProducts, deselectProducts, removeItemsFromList, emptyText, totalConfig
}) => {

  const [deletionRowKey, setDeletionRowKey] = useState("")

  const handleOnClickOnHeader = (index: number) => () => {
    onClickOnHeader(index);
  }
  const handleChangeProductSelection = (id: string, select: boolean) => () => {
    if (select) {
      selectProducts && selectProducts([id])
    } else {
      deselectProducts && deselectProducts([id])
    }
  }
  const handleRemoveProductSelection = (id: string) => () => {
    removeItemsFromList && removeItemsFromList([id])
  }

  const handleSelectAll = () => {
    selectProducts && selectProducts(data.map(r => r.key))
  }

  const handleRemoveAll = () => {
    removeItemsFromList && removeItemsFromList(data.map(item => item.key))
  }

  const handleChangeCellValue = (dataId: string, fieldName: string) => (newValue: string) => {
    onChange && onChange(dataId, fieldName, newValue)
  }

  let gridTemplateColumns = "";
  if (isSelectableRows) {
    gridTemplateColumns += `minmax(30px, 50px) `
  }
  if (isRemovable) {
    gridTemplateColumns += `minmax(30px, 50px) `
  }
  gridTemplateColumns += headers.map(h => `minmax(${h.minWidth}, ${h.width})`).join(" ")


  const handlePointerEnterRemoveBtn = (key: string) => () => {
    setDeletionRowKey(key)
  }
  const handlePointerLeaveRemoveBtn = () => {
    setDeletionRowKey("")
  }


  let tbody
  if (data.length === 0 && emptyText) {
    tbody = <tbody className={styles.tbody}>
      <tr className={styles.empty_text}><td colSpan={headers.length}>{emptyText}</td></tr>
    </tbody>
  } else {
    tbody = <tbody className={styles.tbody}>
      {data.map((rowData, i) => <tr
        key={`${rowData.key}_${i}`}
        className={classNames(styles.row, {
          [styles.has_errors]: rowData.hasErrors,
          [styles.deletion]: deletionRowKey === rowData.key
        })}
      >
        {isSelectableRows && <td key='checkbox' className={styles.checkbox}>
          {!rowData.hideCheckbox && <ListCheckbox checked={rowData.selected} onChange={handleChangeProductSelection(rowData.key, !rowData.selected)} />}
        </td>}
        {isRemovable && <td key='remove_btn' className={styles.remove_btn}>
          <img
            onPointerEnter={handlePointerEnterRemoveBtn(rowData.key)}
            onPointerLeave={handlePointerLeaveRemoveBtn}
            src={crosIcon}
            onClick={handleRemoveProductSelection(rowData.key)}
          />
        </td>}
        {rowData.data.map((cellData, j) => <td key={cellData.name} className={classNames(styles[cellData.name], cellData.className, { [styles.center]: cellData.alignCenter })}>
          <CellData data={cellData} dataType={cellData.type} onChange={cellData.isEditable ? handleChangeCellValue(rowData.key, cellData.name) : undefined} />
        </td>)}
      </tr>)}
      {totalConfig && <tr className={styles.total}>
        <td className={styles.total_label} style={{ gridColumn: `1/${totalConfig.startFrom + 1}` }}>Итог</td>
        {/* {isSelectableRows && <td key='checkbox' className={styles.checkbox}></td>}
        {isRemovable && <td key='remove_btn' className={styles.remove_btn}></td>} */}
        {data[0].data.filter((v, _i) => (_i >= totalConfig.startFrom)).map((cellData, j) => <td
          key={cellData.name}
          className={classNames(styles[cellData.name], styles.total_value)}
        >{totalConfig.values[cellData.name] && (totalConfig.values[cellData.name].formatter || defaultFormatter)(totalConfig.values[cellData.name].value)}</td>)}
      </tr>}
    </tbody>
  }


  return (<table
    className={styles.table}
    style={{ gridTemplateColumns }}
  >
    <thead className={styles.thead}>
      <tr>
        {isSelectableRows && <th
          key='checkbox'
          className={styles.checkbox}
          onClick={handleSelectAll}
        >
          <img src={checkboxIcon} />
        </th>}
        {isRemovable && <th
          key='remove_btn'
          className={styles.remove_btn}
          onClick={handleRemoveAll}
        >
          <img src={crosIcon} />
        </th>}
        {headers.map((h, i) => <th
          className={classNames(
            styles[h.name],
            styles.title_wrap,
            h.className,
            {
              [styles.can_be_sorted]: h.sortable,
              [styles.order_desc]: (sortBy === i && orderBy === Order.DESC),
              [styles.order_asc]: (sortBy === i && orderBy === Order.ASC),
              [styles.center]: h.alignCenter
            }
          )}
          key={h.name}
          onClick={handleOnClickOnHeader(i)}
        ><span className={styles.title}>{h.text}{h.content}</span></th>)}
      </tr>
    </thead>
    {tbody}
  </table>)
}

const CellData: FC<{ data: ListData, dataType?: ListDataType, onChange?: (v: string) => void }> = ({ data, dataType, onChange }) => {
  switch (dataType) {
    case ListDataType.image:
      return <img src={data.text} />
    case ListDataType.list:
      return <>{data.list?.map((s, i) => (<span key={i}>{s}</span>))}</>
    case ListDataType.content:
      return <>{data.content}</>
    case ListDataType.textInput:
      return <TextInput value={data.text || ""} onChange={onChange} />
    default:
      return <>{data.text}</>;
  }
}

export default List;