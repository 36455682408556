import React from 'react'
// import styles from './MenuIcon.module.scss'
import styles from './Menu.module.scss'
import classNames from 'classnames'

export enum IconType {
  home = 'home',
  product = 'product',
  price = 'price',
  logistics = 'logistics',
  analytics = 'analytics',
  statistic = 'statistic',
  warehouses = 'warehouses',
  marketing = 'marketing',
  account = 'account',
  employees = 'employees',
  about = 'about',
  documents = 'documents',
  schedule = 'schedule',
  certificate = 'certificate',
  instruction = 'instruction',
  accounting = 'accounting',
  what_to_sell = 'what_to_sell',
  chat = 'chat',
  notifications = 'notifications'
}

type Props = {
  active?: boolean
  className?: string
  type?: IconType
  hasNotification?: boolean
}

export default function MenuIcon({ active, className, type, hasNotification }: Props) {
  const iconTypeClass = type ? styles[type] : undefined
  return (
    <div className={classNames(
      styles.menu_icon,
      className,
      iconTypeClass,
      {
        [styles.active]: active,
        [styles.has_notification]: hasNotification
      }
    )} />
  )
}
