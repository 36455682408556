import React from 'react'
import { useAppSelector } from '../../../../../hooks/store'
import Title from '../Title'

import styles from "./Seasons.module.scss"
import Season from './Season'
import { CustomTextInput } from '../../../../simples/CustomTextInput'

export default function Seasons() {

  const seasons = useAppSelector(state => state.product.seasons.values)
  const commonDataSeasons = useAppSelector(state => state.product.commonData.seasons)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const handle = () => { return }

  return (
    <div className={styles.seasons}>
      <Title text='Коллекция и сезон' />
      <div className={styles.list}>
        {readonly
          ? <div>
            <CustomTextInput
              name='seasons'
              placeholder='Укажите сезон'
              label='Сезон'
              value={seasons.map(s => commonDataSeasons.find(cds => cds.id === s)?.name).join(', ')}
              onChange={handle}
              readonly={readonly}
            />
          </div>
          : seasons.map((s, i) => <Season key={i} season={s} seasonIndex={i} />)}
      </div>
    </div>
  )
}
