import React from 'react'
import styles from './Limits.module.scss'

export type Limit = {
  date: string
  values: {
    title: string
    value: string
  }[]
}

type Props = {
  limits: Limit[]
}

export default function Limits({ limits }: Props) {
  return (
    <div className={styles.limits}>{limits.map(l => <div key={l.date} className={styles.limit}>
      <div className={styles.date}>{l.date}</div>
      <div className={styles.values}>{l.values.map(v => <div key={v.title} className={styles.value}>
        <div className={styles.title}>{v.title}:</div>
        <div className={styles.value}>{v.value}</div>
      </div>)}</div>
    </div>)}</div>
  )
}
