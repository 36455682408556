// ignore all ts errors in this file
// FIXME remove this once refactor is done
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import LawService, { UploadDocAnswer } from "../../services/LawService";
import { DocType, LawDoc, LawDocStatus, SellerType } from "../../types/law";
import { CompaniesDocumentsDTO } from "../../types/swagger/llyApi_new";
import { toast } from "react-toastify";
import api from '../../utils/api'
import { TaxSystemType } from "../../types/law";

type State = {
  docs: LawDoc[]
  isFetchingDocs: boolean
  sellerType: SellerType
  /** Заявление о переходе на УСН ранее было загружено */
  taxSystemType: TaxSystemType
  /** Список обязательных документов */
  required_docs: DocType[]
  usnDoc: LawDoc
}



export const initialState: State = {
  docs: [],
  isFetchingDocs: false,
  sellerType: SellerType.IP,
  taxSystemType: TaxSystemType.OSNO,
  required_docs: [],
  usnDoc: { type: DocType.TYPE_USN_STATEMENT, title: 'Заявление о переходе на УСН (или справка по форме 26.2-7)' }
};


const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });


type SendToModerationResp = {
  success: boolean
  errors?: string[]
}
type FetchDocsResp = {
  success: boolean
  data?: CompaniesDocumentsDTO[]
  /** Признак ИП */
  is_individual?: boolean
  /** Заявление о переходе на УСН ранее было загружено */
  is_usn_checked?: boolean
  /** Список обязательных документов */
  required_docs?: {
    TYPE_OGRN?: string
    TYPE_INN?: string
    TYPE_ARTICLE?: string
    TYPE_ORDER?: string
    TYPE_OGRNIP?: string
    TYPE_PASSPORT?: string
  }
  errors?: string[]
}


function getDefaultDocsState(types: { [key in DocType]?: string | undefined }): LawDoc[] {
  const docs: LawDoc[] = []
  for (const type in types) {
    if (Object.prototype.hasOwnProperty.call(types, type)) {
      docs.push({
        type: type as DocType,
        file: '',
        // id: Math.random(),
        title: types[type],
      })
    }
  }
  return docs
}

function docTypeHasId(doc?: LawDoc) {
  return doc?.id !== undefined
}
function allDocTypesHasId(docs: LawDoc[]) {
  for (const doc of docs) {
    if (!docTypeHasId(doc)) return false
  }
  return true
}





export const fetchDocs = createAsyncThunk<FetchDocsResp, void, { state: RootState }>(
  "CompanyDocuments/fetchDocs", async () => {
    let ansver: FetchDocsResp = { success: false }
    await api.get<FetchDocsResp>('/companies/get-documents').then((data) => {
      if (data.data.data)
        ansver = data.data
    })
    return ansver
  }
);

export const uploadDoc = createAsyncThunk<UploadDocAnswer, { file: File, docType: DocType }, { state: RootState }>(
  "CompanyDocuments/uploadDoc", async ({ file, docType }, { dispatch }) => {
    dispatch(setUploadingProgress({ type: docType, percent: 0 }));

    const response: UploadDocAnswer= await LawService.uploadDoc({
      file,
      type: docType,
      onProgress(percent) {
        dispatch(setUploadingProgress({ type: docType, percent }));
      },
    }).finally(() => {
      dispatch(setUploadingProgress({ type: docType, percent: undefined }));
    })

    if (response.success) {
      // return fulfillWithValue(response);
    } else {
      errorMsg()
      // return rejectWithValue(response)
    }
    return response
  }
);



export const sendToModeration = createAsyncThunk<SendToModerationResp, void, { state: RootState }>(
  "CompanyDocuments/sentToModeration", async (_, { getState, dispatch }) => {

    const state = getState();
    // const response = await LawService.saveLawData(state.law.lawData)
    let response: SendToModerationResp = { success: false }
    const data: { type: DocType, id: number }[] = []
    for (const doc of state.companyDocuments.docs) {
      data.push({ type: doc.type, id: doc.id as number })
    }
    if (state.companyDocuments.taxSystemType === TaxSystemType.USN) {
      data.push({
        type: state.companyDocuments.usnDoc.type,
        id: state.companyDocuments.usnDoc.id as number
      })
    }

    await api.post<SendToModerationResp>('/companies/update-documents', data)
      .then(data => {
        response = data.data
      })
      .catch(() => {
        errorMsg()
      })

    dispatch(fetchDocs())
    if (response.success) {
      saveSuccessMsg()
      // return fulfillWithValue(response);
    } else {
      errorMsg()
      // return rejectWithValue(response)
    }
    return response
  }
);


export const companyDocuments = createSlice({
  name: "CompanyDocuments",
  initialState,
  reducers: {
    removeDoc: (state, action: PayloadAction<DocType>) => {
      const doc = state.docs.find(d => d.type === action.payload)
      if (doc) {
        doc.file = undefined
        doc.id = undefined
      }
    },
    setUploadingProgress: (state, action: PayloadAction<{ type: DocType, percent?: number }>) => {
      const doc = (action.payload.type === DocType.TYPE_USN_STATEMENT) ? state.usnDoc : state.docs.find(doc => doc.type === action.payload.type)
      if (doc) {
        doc.progress = action.payload.percent
      }
    },
    setTaxSystemType: (state, action: PayloadAction<TaxSystemType>) => {
      state.taxSystemType = action.payload
    }
  },
  extraReducers: (builder) => {

    builder.addCase(fetchDocs.pending, (state) => {
      state.isFetchingDocs = true
      state.docs = []
    });
    builder.addCase(fetchDocs.fulfilled, (state, action: PayloadAction<FetchDocsResp>) => {

      const tss = action.payload.is_usn_checked ? TaxSystemType.USN : TaxSystemType.OSNO
      const _sellerType = action.payload.is_individual ? SellerType.IP : SellerType.UL


      const defaultDocs: LawDoc[] = action.payload.required_docs ? getDefaultDocsState(action.payload.required_docs) : []
      const serverDocs = action.payload.data || []
      const _docs: LawDoc[] = []
      for (const doc of defaultDocs) {
        const serverDoc = serverDocs.find(d => d.type === doc.type)
        if (serverDoc) {
          doc.file = serverDoc.name
          doc.id = serverDoc.id
          doc.moderationsStatusName = serverDoc.status_name
          doc.moderationsStatus = serverDoc.status
          doc.moderationsDescription = serverDoc.description
          switch (serverDoc.status) {
            case 0:
              doc.status = LawDocStatus.moderation
              break;
            case 1:
              doc.status = LawDocStatus.ok
              break;
            case -1:
              doc.status = LawDocStatus.rejected
              break;
            default:
              break;
          }
        }
        if (doc.type === DocType.TYPE_USN_STATEMENT) {
          state.usnDoc = doc
        } else {
          _docs.push(doc)
        }
      }
      state.sellerType = _sellerType
      state.taxSystemType = tss
      state.docs = _docs
      state.isFetchingDocs = false
    });
    builder.addCase(fetchDocs.rejected, (state) => {
      state.isFetchingDocs = false
    });


    builder.addCase(uploadDoc.fulfilled, (state, action: PayloadAction<UploadDocAnswer>) => {
      if (action.payload.success) {
        const doc = (action.payload.type === DocType.TYPE_USN_STATEMENT) ? state.usnDoc : state.docs.find(doc => doc.type === action.payload.type)
        if (doc) {
          doc.progress = undefined
          doc.file = action.payload.name
          doc.id = action.payload.id
        }
      }
    });

    // builder.addCase(uploadDoc.rejected, () => {
    //   errorMsg()
    // });

  },
});



export const isDocumentsUploaded = () => (state: RootState) => {
  if (state.companyDocuments.taxSystemType === TaxSystemType.USN && !docTypeHasId(state.companyDocuments.usnDoc)) {
    return false
  }
  return allDocTypesHasId(state.companyDocuments.docs)
}

export const {
  setUploadingProgress, removeDoc, setTaxSystemType
} = companyDocuments.actions

export default companyDocuments.reducer;
