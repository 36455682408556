import React, { useEffect, useRef, useState } from 'react'
import { BlueButton } from '../../simples/BlueButton';

type Props = {
  title: string
  fileURL: string
  fileTitle: string
}

export default function PrintPDF({ fileURL, title, fileTitle }: Props) {

  const [isLoading, setIsLoading] = useState(true);
  const refIframe: any = useRef(null);


  const printIframe = () => {
    const iframe = refIframe.current
    const iframeWindow = iframe?.contentWindow || iframe;
    iframeWindow?.focus();
    iframeWindow?.print();
  }

  useEffect(() => {
    // refIframe.current.setAttribute('src', '/test.pdf',);
    refIframe.current.setAttribute('src', fileURL);
  }, []);

  const handlePDFLoaded = () => {
    setIsLoading(false)
  }

  return (
    <div>
      <iframe
        title={fileTitle}
        ref={refIframe}
        style={{ display: 'none' }}
        onLoad={handlePDFLoaded}
      />

      <BlueButton title={title} onClick={printIframe} disabled={isLoading} />
    </div>
  )
}
