import React, { useEffect, useState } from 'react'

import styles from './AboutShop.module.scss'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { BlueButton } from '../../../../simples/BlueButton'
import { ShopInfoDTO } from '../../../../../types/swagger/llyApi_new'
import api from "../../../../../utils/api";
// import { CustomHint } from '../../../../simples/CustomHint'
// import Logo from './Logo'
import { toast } from 'react-toastify'
// import { ModalDialog } from '../../../../simples/ModalDialog'
import ShopLvlInfoDialog from './ShopLvlInfoDialog'


// import mock_logo from '../../assets/mock_logo.svg'
import { useAppSelector } from '../../../../../hooks/store'
import Logo from '../../../../complexes/Logo/Logo'
import ShopLvlHint from '../../../../simples/ShopLvlHint'

type FetchAnswer = {
  success?: boolean
  data?: ShopInfoDTO
}


export default function AboutShop() {

  const shopIsActive = useAppSelector(state => state.auth.user?.active)
  // console.log({shopIsActive});

  const [editmode, setEditmode] = useState(false)

  const [commission, setCommission] = useState(0)
  const [lvl, setLvl] = useState(0)
  const [lvlText, setLvlText] = useState('')
  const [shopName, setShopName] = useState('')
  const [site, setSite] = useState('')
  const [phone, setPhone] = useState('')
  const [shopDescription, setShopDescription] = useState('')
  const [logoUrl, setLogoUrl] = useState<string | undefined>()
  const [logoId, setLogoId] = useState<number | undefined>()

  const [country, setCountry] = useState('')
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [region, setRegion] = useState('')
  const [street, setStreet] = useState('')
  const [index, setIndex] = useState('')
  const [house, setHouse] = useState('')
  const [corpus, setCorpus] = useState('')
  const [office, setOffice] = useState('')
  const [cabinet, setCabinet] = useState('')

  const [progress, setProgress] = useState(0)

  const [infoDialogIsActive, setInfoDialogIsActive] = useState(false)

  const saveSuccess = () => toast.success("Данные сохранены", { theme: "light" });
  const saveError = () => toast.error("Ошибка", { theme: "light" });

  const fetchInfo = () => {
    api.get<FetchAnswer>('/companies/info')
      .then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          const data = res.data.data
          const _logoUrl = (data?.avatars?.length !== undefined && data?.avatars?.length > 0) ? data.avatars[0] : undefined// mock_logo


          setLvl(data?.level || -1)
          setLvlText(data?.level_name || '')
          setCommission(data?.commission || -1)
          setLogoUrl(_logoUrl?.url)
          setLogoId(_logoUrl?.id)

          setShopName(data?.company_name || '')
          setSite(data?.company_url || '')
          setPhone(data?.phone || '')
          setShopDescription(data?.description || '')
          setCountry(data?.fbs?.country || '')
          setDistrict(data?.fbs?.district || '')
          setCity(data?.fbs?.town || '')
          setRegion(data?.fbs?.region || '')
          setStreet(data?.fbs?.street || '')
          setIndex(data?.fbs?.index || '')
          setHouse(data?.fbs?.house || '')
          setCorpus(data?.fbs?.building || '')
          setOffice(data?.fbs?.office || '')
          setCabinet(data?.fbs?.room || '')

          setProgress(data?.progress || 0)
        }
      })
      .catch((err) => { alert(err) });
  }

  const saveInfo = () => {
    const data = {
      "company_name": shopName,
      "company_url": site,
      "phone": phone,
      "description": shopDescription,
      "avatars": [{ "id": logoId }],
      "fbs": {
        "country": country,
        "region": region,
        "town": city,
        "district": district,
        "street": street,
        "index": index,
        "house": house,
        "building": corpus,
        "office": office,
        "room": cabinet
      }
    }
    api.post<FetchAnswer>('/companies/update', data)
      .then((res) => {
        if (res.data.success) {
          // const data = res.data.data
          saveSuccess()
          fetchInfo()
          setEditmode(false)
        }
      })
      .catch((err) => {
        console.log(err);
        saveError()
      })
  }

  useEffect(() => {
    fetchInfo()
  }, [])


  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'shopName':
        setShopName(value)
        break;
      case 'site':
        setSite(value)
        break;
      case 'phone':
        setPhone(value)
        break;
      case 'shopDescription':
        setShopDescription(value)
        break;
      case 'country':
        setCountry(value)
        break;
      case 'district':
        setDistrict(value)
        break;
      case 'city':
        setCity(value)
        break;
      case 'area':
        setRegion(value)
        break;
      case 'street':
        setStreet(value)
        break;
      case 'index':
        setIndex(value)
        break;
      case 'house':
        setHouse(value)
        break;
      case 'corpus':
        setCorpus(value)
        break;
      case 'office':
        setOffice(value)
        break;
      case 'cabinet':
        setCabinet(value)
        break;

      default:
        break;
    }
  }
  const handleSave = () => {
    saveInfo()
  }
  const handleEdit = () => {
    setEditmode(true)
  }
  const handleShowInfoDialog = () => {
    setInfoDialogIsActive(true)
  }
  const handleCloseInfoDialog = () => {
    setInfoDialogIsActive(false)
  }
  const handleUpload = (id: number, url: string) => {
    setLogoUrl(url)
    setLogoId(id)
  }


  // const lvlText = lvl

  // const lvlHint = <div className={styles.lvl_hint}>Комиссия площадки:<br /><br />
  //   0 - Новичок - 15%;<br /><br />
  //   1 - Начинающий - 13%;<br /><br />
  //   2 - Любитель - 11%;<br /><br />
  //   3 - Специалист 9%;<br /><br />
  //   4 - Пофессионал- 7%;<br /><br />
  //   5 - Мастер - 5%</div>

  const snameHint = <div>Будет показано на карточках Ваших товаров на площадке LL. Согласно ГК РФ (<a>гиперссылка.рф</a>), это название не может совпадать с зарегистрированным товарным знаком, если у Вас нет разрешение на его использование.</div>

  return (
    <div className={styles.wrap}>
      <ShopLvlInfoDialog isActive={infoDialogIsActive} onClose={handleCloseInfoDialog} commission={commission} shopLvl={lvl} shopLvlText={lvlText} logoUrl={logoUrl} progress={progress} />
      <div className={styles.about}>
        <div className={styles.col}>
          <Logo logoUrl={logoUrl} editMode={editmode} onUpload={handleUpload} progress={progress} />
          <div className={styles.info}>
            <div className={styles.lvl}>Уровень магазина №{lvl} <div className={styles.lvl_name}>{lvlText}</div>
              {/* <CustomHint hint={lvlHint} hintWidth={300} /> */}
              <ShopLvlHint />
            </div>
            <div className={styles.commission}>Комиссия <div className={styles.value}>{commission}%</div></div>
            <div className={styles.more_btn} onClick={handleShowInfoDialog}>Подробнее</div>
          </div>
          <CustomTextInput readonly={!editmode || shopIsActive === 1} name='shopName' value={shopName} label='Название магазина' onChange={handleChange} hint={snameHint} hintWidth={400} />
          <CustomTextInput readonly={!editmode} name='site' value={site} label='Сайт компании' onChange={handleChange} />
          <CustomTextInput readonly={!editmode} name='phone' value={phone} label='Контактный телефон' onChange={handleChange}
            placeholder={"+7 (___) ___-__-__"} mask={"+7 (999) 999-99-99"} />
          <CustomTextInput readonly={!editmode} name='shopDescription' value={shopDescription} label='Описание магазина' onChange={handleChange} />
        </div>
        <div className={styles.col}>
          <h1>Адрес склада FBS</h1>
          <CustomTextInput readonly={!editmode} name='country' value={country} label='Страна' onChange={handleChange} />
          <CustomTextInput readonly={!editmode} name='district' value={district} label='Автономный округ (область); край; область; название республики;' onChange={handleChange} />
          <CustomTextInput readonly={!editmode} name='city' value={city} label='Город' onChange={handleChange} />
          <CustomTextInput readonly={!editmode} name='area' value={region} label='Название района' onChange={handleChange} />
          <CustomTextInput readonly={!editmode} name='street' value={street} label='Улица' onChange={handleChange} />
          <div className={styles.row}>
            <CustomTextInput readonly={!editmode} name='index' value={index} isNumber={true} label='Индекс' onChange={handleChange} />
            <CustomTextInput readonly={!editmode} name='house' value={house} isNumber={true} label='Дом' onChange={handleChange} />
            <CustomTextInput readonly={!editmode} name='corpus' value={corpus} isNumber={true} label='Корпус, литер' onChange={handleChange} />
          </div>
          <div className={styles.row}>
            <CustomTextInput readonly={!editmode} name='office' value={office} isNumber={true} label='Офис' onChange={handleChange} />
            <CustomTextInput readonly={!editmode} name='cabinet' value={cabinet} isNumber={true} label='Кабинет' onChange={handleChange} />
            <div></div>
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        {!editmode && <BlueButton title='Редактировать' onClick={handleEdit} />}
        {editmode && <BlueButton onClick={handleSave} title='Отправить на модерацию' />}
      </div>
    </div>
  )
}
