import React from 'react'
import styles from "../../ActionsCalendar.module.scss"
import prev_icon from "../../assets/prev_icon.svg"
import next_icon from "../../assets/next_icon.svg"

type Props = {
  text: string
  onPrev: () => void
  onNext: () => void
}

export default function PrevNextSwitch({ text, onNext, onPrev }: Props) {
  return (
    <div className={styles.prev_next_switch}>
      <img src={prev_icon} alt="<" className={styles.prev} onClick={onPrev} />
      <span>{text}</span>
      <img src={next_icon} alt=">" className={styles.next} onClick={onNext} />
    </div>
  )
}
