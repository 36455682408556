import React from 'react'

type Props = {
  value: string
  onChange?: (value: string) => void
}

export default function TextInput({ value, onChange }: Props) {
  const handleChange = e => {
    onChange && onChange(e?.target?.value || "")
  }
  return (
    <input type="text" value={value} onChange={handleChange} />
  )
}
